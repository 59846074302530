import styled from "styled-components";

import { OpacityYoYo } from "../Animations";
import { FallbackCommon } from "../FallbackStates";

const { Container, Content, Header, Heading, HelperText, BigIcon } =
  FallbackCommon;

export function Loading() {
  return (
    <StyledContainer>
      <Content>
        <Header>
          <OpacityYoYo>
            <BigIcon type="autometrics" noBorder />
          </OpacityYoYo>
          <Heading>Loading</Heading>
          <HelperText>
            Fetching your SLO data from Prometheus&hellip;
          </HelperText>
        </Header>
      </Content>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 72px;
  height: auto;
  min-height: 0;
`;
