import styled, { css } from "styled-components";

import { SourceDefinition } from "../../../../schemas";
import VscodeIcon from "./vscode.svg";

type Props = {
  path: string;
  definition: SourceDefinition;
};

export function VscodeLink(props: Props) {
  const {
    path,
    definition: { range, file },
  } = props;
  const line = range.start.line + 1;
  const column = range.start.column + 1;

  return (
    <Link
      href={`vscode://file/${path}/${file}:${line}:${column}`}
      key={file}
      aria-label={`Open in VS Code`}
    >
      <VscodeIcon />
      <span>
        {file} L:
        {line} C:
        {column}
      </span>
    </Link>
  );
}

const Link = styled.a(
  ({ theme }) => css`
    display: grid;
    grid: auto / min-content auto;
    align-items: center;
    width: fit-content;
    gap: 8px;
    color: ${theme.color.fg.muted};

    &:hover {
      color: ${theme.color.fg.default};
      text-decoration: underline;
    }
  `
);
