import {
  CommandIcon,
  CommandItemName,
  StyledCommandGroup,
  StyledCommandItem,
} from "../styled";
import {
  computeTimeRangeFromPresetOption,
  timeRangeOptions,
} from "../../../utils";
import { CommandMenuPageProps } from "./CommandMenuPage";
import { useTimeRange } from "../../../hooks";

type TimeRangePageProps = Omit<CommandMenuPageProps, "pageType">;

export function TimeRangePage({
  hideCommandMenu,
  setPage,
}: TimeRangePageProps) {
  const { onChangeTimeRange } = useTimeRange();

  return (
    <StyledCommandGroup heading="Change time range">
      {timeRangeOptions.map((option) => (
        <StyledCommandItem
          key={option.value}
          onSelect={() => {
            onChangeTimeRange(computeTimeRangeFromPresetOption(option));
            setPage(null);
            hideCommandMenu();
          }}
        >
          <CommandIcon type="time_duotone" />
          <CommandItemName>
            Change time range to {option.label.toLowerCase()}
          </CommandItemName>
        </StyledCommandItem>
      ))}
    </StyledCommandGroup>
  );
}
