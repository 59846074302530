import { SourceLocation } from "../../../../schemas";
import { TextLink } from "./TextLink";
import { VscodeLink } from "./VscodeLink";

export function SourceCodeLink(props: {
  sourceLocation: SourceLocation;
  type: "vscode" | "text";
}) {
  const { sourceLocation } = props;
  const definition =
    sourceLocation.definition || sourceLocation.instrumentation;

  if (!definition) {
    return null;
  }

  if (props.type === "text") {
    return <TextLink definition={definition} />;
  }

  return <VscodeLink definition={definition} path={sourceLocation.path} />;
}
