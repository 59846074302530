import { useSelector } from "react-redux";
import { useMemo } from "react";
import {
  MetricsChart,
  TickFormattersFactory,
  getScientificFormatterForAxis,
  getTimeFormatterForAxis,
} from "@fiberplane/charts";
import { Timeseries } from "@fiberplane/prometheus-query";

import { usePrometheusQueryRangeQuery } from "../../../api";
import { selectActivePrometheus } from "../../../selectors";
import { useChartTheme, useTimeRange } from "../../../hooks";
import { AlertFrame } from "./AlertFrame";
import { showTooltip } from "../../../thunks";
import { ChartTooltip } from "../../Tooltip";
import { dispatch } from "../../../store";
import { noop } from "../../../utils";

type Props = { query: string } & Pick<Timeseries, "labels">;

export function RuleDetail(props: Props) {
  const { labels, query } = props;

  const environment = useSelector(selectActivePrometheus);
  const { timeRange, onChangeTimeRange } = useTimeRange();

  const result = usePrometheusQueryRangeQuery({
    environmentUrl: environment?.url,
    start: timeRange.from,
    end: timeRange.to,
    query,
  });

  const graphTheme = useChartTheme("successRate");

  const tickFormattersFactory = useMemo(
    (): TickFormattersFactory => (xAxis, yAxis) => ({
      xFormatter: getTimeFormatterForAxis(xAxis),
      yFormatter: getScientificFormatterForAxis(yAxis),
    }),
    []
  );

  if (!result.data) {
    return <AlertFrame labels={labels}>Loading</AlertFrame>;
  }

  return (
    <AlertFrame labels={labels}>
      <MetricsChart
        chartTheme={graphTheme}
        areaGradientShown={result.data.length < 5}
        graphType="line"
        stackingType="none"
        timeRange={timeRange}
        timeseriesData={result.data}
        tickFormatters={tickFormattersFactory}
        footerShown={false}
        shouldAnimateYScale={false}
        onChangeTimeRange={onChangeTimeRange}
        showTooltip={(anchor, [series, point]) => {
          if (point) {
            return dispatch(
              showTooltip(anchor, <ChartTooltip content={[series, point]} />, {
                placement: "top",
              })
            );
          }

          return noop;
        }}
      />
    </AlertFrame>
  );
}
