/**
 * TODO:
 * On a first load, it'd be nice for the SLO tables to slide in from the right.
 * However, when the table refetches data, we just want a normal fade in,
 * or any sort of minimal transition.
 *
 * The issues I ran into while trying to do this are:
 *
 * (1) Detecting a "first load" of the table is nontrivial and inconsistent,
 *     since RTK query will not set `isLoading` when you're fetching data
 *     after having visited the page once already.
 *     (We could clear the cache on page transitions though?)
 *
 * (2) Sequencing the page loading animation with the slide in is a pain,
 *     and when I tried to do anything fancy, it was either firing inconsistnetly,
 *     or just looked unnatural for table refetches.
 *
 * Here is my scratchwork for variants that would accomplish the "slide in" effect:
 *
 *
  firstLoadInitial: {
    x: "100vw", // Start from the right
    opacity: 0,
  },
  firstLoadIn: {
    x: 0, // Slide into to center
    opacity: 1,
    transition: {
      type: "tween",
      ease: "anticipate",
      duration: 0.35,
    },
  },
 */

export const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
    transition: {
      type: "tween",
      ease: "anticipate",
      duration: 0.05,
    },
  },
};
