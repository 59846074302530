import { useEffect, useMemo } from "react";
import useMedia from "react-use/lib/useMedia";

import { useHandler } from "./useHandler";
import { Theme, isValidTheme } from "../types";
import { useLocalStorage } from "./useLocalStorage";

/**
 * Hook that returns the current theme and getters & setters for changing the
 * theme. It stores the theme in localStorage if it's not the system's preferred
 * color scheme.
 * @returns {object}
 */
export function useThemeSelect() {
  const systemDefaultTheme = useSystemThemePreference();
  const [storedTheme, setStoredTheme] = useLocalStorage<Theme | null>(
    "theme",
    null
  );

  const setToSystemTheme = useHandler(() => {
    setStoredTheme(null);
  });

  const setThemePreference = useHandler((theme: Theme) => {
    setStoredTheme(theme);
  });

  // Keeping this around for the toggle button which'll be used in dev only.
  // Handler to toggle the theme from dark to light and vice-versa
  const toggleTheme = useHandler(() => {
    setStoredTheme(theme === "dark" ? "light" : "dark");
  });

  // The computed theme value
  // - If there's a stored theme and it's valid, use it
  // - Otherwise, use the system default
  const theme = useMemo<Theme>(() => {
    if (storedTheme && isValidTheme(storedTheme)) {
      return storedTheme;
    }

    return systemDefaultTheme;
  }, [storedTheme, systemDefaultTheme]);

  useSetToBody(theme);

  return {
    currentTheme: theme,
    isSystemPreference: !storedTheme,
    setThemePreference,
    setToSystemTheme,
    toggleTheme,
  };
}

/**
 * Hook that sets the current theme on the body element.
 */
export function useCurrentTheme() {
  useThemeSelect();
}

function useSetToBody(theme: Theme) {
  // Effect to set the theme on the body element when it changes
  useEffect(() => {
    document.body.dataset.switching = "true";

    setTimeout(() => {
      document.body.dataset.theme = theme;
    });

    const listenerId = setTimeout(() => {
      document.body.dataset.switching = "false";
    }, 100);
    return () => clearTimeout(listenerId);
  }, [theme]);
}

// Hook that returns a theme based on the system's preferred color scheme
function useSystemThemePreference(): Theme {
  const systemPrefersDarkTheme = useMedia("(prefers-color-scheme: dark)");
  return systemPrefersDarkTheme ? "dark" : "light";
}
