import styled, { css } from "styled-components";

export const Container = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.xs};
  `
);

export const Top = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.xs};
  `
);

export const Text = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.sm.medium};
  `
);
