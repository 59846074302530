import styled, { css } from "styled-components";
import { Button } from "@fiberplane/ui";

import { dispatch } from "../../../store";
import { SHOW_COMMAND_MENU } from "../../../actions";
import { Icon } from "../../UI";
import { isMac } from "../../../utils";

export function CommandMenuToggle() {
  return (
    <StyledButton
      buttonStyle="secondary"
      onClick={() => dispatch({ type: SHOW_COMMAND_MENU })}
    >
      <Icon type="search_duotone" />
      <KeyCombo>
        <Key>{isMac ? "⌘" : "ctrl"}</Key>
        <Key>K</Key>
      </KeyCombo>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  padding: 7px 8px;
`;

const KeyCombo = styled.span`
  display: inline-flex;
  gap: 2px;
`;

const Key = styled.span(
  ({ theme }) =>
    css`
      display: inline-flex;
      border-radius: ${theme.radius.minimal};
      border: 1px solid ${theme.color.button.secondary.border};
      color: ${theme.color.fg.muted};
      font: ${theme.font.buttons.sm};
      padding: 4px;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
    `
);
