import { useRef } from "react";
import styled, { css } from "styled-components";

import { useOpen, useThemeSelect } from "../../hooks";
import { Icon, AttachedPopup } from "../UI";
import { isMac } from "../../utils";

export const GraphKeyboardShortcutsHelp = () => {
  const { currentTheme } = useThemeSelect();
  const isDarkTheme = currentTheme === "dark";

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { opened, setOpened, modalRef } = useOpen(containerRef);

  return (
    <>
      <GraphHelperIconContainer
        $open={opened}
        ref={containerRef}
        onMouseDown={(event) => {
          // HACK - this is a workaround to accommodate behavior of `useOpen`
          event.stopPropagation();
        }}
        onClick={() => {
          setOpened((currentOpened) => !currentOpened);
        }}
      >
        <GraphHelperIcon />
      </GraphHelperIconContainer>
      {opened && (
        <AttachedPopup
          element={containerRef.current}
          placement="top-end"
          offset={[0, 8]}
        >
          <KeyboardShortcutsContainer ref={modalRef} $dark={isDarkTheme}>
            <KeyboardShortcutTitle>Keyboard shortcuts</KeyboardShortcutTitle>
            <KeyboardShortcuts>
              <KeyboardShortcut>
                <KeyboardShortcutDescription>
                  Drag time range
                </KeyboardShortcutDescription>
                <KeyboardShortcutKeys>
                  <KeyboardShortcutKey>shift</KeyboardShortcutKey>
                  <KeyboardShortcutKey>drag</KeyboardShortcutKey>
                </KeyboardShortcutKeys>
              </KeyboardShortcut>
              <KeyboardShortcut>
                <KeyboardShortcutDescription>
                  Zoom in
                </KeyboardShortcutDescription>
                <KeyboardShortcutKeys>
                  <KeyboardShortcutKey>
                    {isMac ? "cmd" : "ctrl"}
                  </KeyboardShortcutKey>
                  <KeyboardShortcutKey>drag</KeyboardShortcutKey>
                </KeyboardShortcutKeys>
              </KeyboardShortcut>
              <KeyboardShortcut>
                <KeyboardShortcutDescription>
                  Zoom out
                </KeyboardShortcutDescription>
                <KeyboardShortcutKeys>
                  <KeyboardShortcutKey>
                    {isMac ? "cmd" : "ctrl"}
                  </KeyboardShortcutKey>
                  <KeyboardShortcutKey>scroll</KeyboardShortcutKey>
                </KeyboardShortcutKeys>
              </KeyboardShortcut>
            </KeyboardShortcuts>
          </KeyboardShortcutsContainer>
        </AttachedPopup>
      )}
    </>
  );
};

const KeyboardShortcutsContainer = styled.div<{ $dark: boolean }>(
  ({ $dark, theme }) => css`
    border-radius: ${theme.radius.minimal};
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background-color: ${theme.color.bg.default};
    width: 300px;

    box-shadow: 0 4px 25px 0
      ${$dark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.25)"};
  `
);

const KeyboardShortcutTitle = styled.h5`
  color: ${({ theme }) => theme.color.fg.default};
  margin: 0;
  height: 30px;
  display: flex;
  align-items: center;
`;

const KeyboardShortcuts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const KeyboardShortcut = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const KeyboardShortcutDescription = styled.p`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.fg.default};
`;

const KeyboardShortcutKeys = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

const KeyboardShortcutKey = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    width: 46px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: ${theme.radius.default};
    background: ${theme.color.bg.hover};
    color: ${theme.color.fg.default};
    font: ${theme.font.body.sm.medium};
  `
);

const GraphHelperIconContainer = styled.div<{ $open: boolean }>(
  ({ $open, theme }) => css`
    cursor: pointer;
    margin-top: 20px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: ${theme.radius.rounded};
    border: 1px solid
      ${$open
        ? theme.color.border.primary
        : theme.color.button.secondary.border};
    background-color: ${theme.color.button.secondary.bg};

    ${$open &&
    css`
      box-shadow: 0px 0px 0px 4px rgba(165, 80, 255, 0.2);
    `}
  `
);

const GraphHelperIcon = styled(Icon).attrs({ type: "keyboard_duotone" })`
  width: 20px;
  height: 20px;
`;
