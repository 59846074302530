const os =
  typeof navigator === "undefined"
    ? ""
    : navigator.platform.match(/mac|win|linux/i)?.[0]?.toLowerCase();

export const isMac = os === "mac";

export const isSafari =
  typeof navigator === "undefined"
    ? false
    : /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
