import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Icon, IconType, LazilyLoadedImage } from "../UI";

type CardProps = {
  description?: string;
  title: string;
  url?: string;
};

export type CTACardProps = CardProps & {
  brandColor: string;
  brandColorGradientTo: string;
  cta: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export type DocumentationCardProps = CardProps & {
  brandColor: string;
  iconType: IconType;
};

export function DocumentationCard({
  brandColor,
  description,
  title,
  iconType,
  url = "#",
}: DocumentationCardProps) {
  return (
    <StyledLink to={url} target="_blank" rel="noopener">
      <DocumentationCardContainer $brandColor={brandColor}>
        <CardIcon type={iconType} width="20" height="20" />
        <CardTitle>{title}</CardTitle>

        {description && <CardDescription>{description}</CardDescription>}
      </DocumentationCardContainer>
    </StyledLink>
  );
}

export type BlogCardProps = CardProps & {
  image: string;
  meta: {
    author: string;
    authorImage: string;
    date: string;
  };
};

export function BlogCard({
  description,
  image,
  meta,
  title,
  url = "#",
}: BlogCardProps) {
  return (
    <StyledLink to={url}>
      <TutorialCardContainer>
        <TutorialCardImageContainer>
          <VisualCardImage src={image} />
        </TutorialCardImageContainer>

        <BlogCardContent>
          <CardTitle>{title}</CardTitle>
          {description && <CardDescription>{description}</CardDescription>}

          <BlogCardMetaContainer>
            <AutorImageContainer>
              <VisualCardImage src={meta.authorImage} />
            </AutorImageContainer>
            <MetaText>
              {meta.author} &bull; {meta.date}
            </MetaText>
          </BlogCardMetaContainer>
        </BlogCardContent>
      </TutorialCardContainer>
    </StyledLink>
  );
}

export type TutorialCardProps = CardProps & {
  image?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  ctas: Array<{
    title: string;
    url: string;
  }>;
};

export function TutorialCard({
  icon: LanguageIcon,
  image,
  title,
  description,
  ctas,
}: TutorialCardProps) {
  return (
    <TutorialCardContainer>
      <VisualCardImageWrapper>
        <TutorialCardImageContainer>
          {LanguageIcon && (
            <VisualCardIconContainer>
              <LanguageIcon height={32} width={32} />
            </VisualCardIconContainer>
          )}

          {image && <VisualCardImage src={image} />}
        </TutorialCardImageContainer>
      </VisualCardImageWrapper>

      <TutorialCardContent>
        <CardTitle>{title}</CardTitle>

        {description && <CardDescription>{description}</CardDescription>}

        {ctas && (
          <CTAsContainer>
            {ctas.map((cta) => (
              <StyledLink key={cta.url} to={cta.url}>
                <CardCTA>
                  {cta.title}
                  <Icon type="arrow_right" height="1.1em" width="1.1em" />
                </CardCTA>
              </StyledLink>
            ))}
          </CTAsContainer>
        )}
      </TutorialCardContent>
    </TutorialCardContainer>
  );
}

export type VisualCardProps = CardProps & {
  image: string;
  url: string;
};

export function VisualCard({
  image,
  title,
  description,
  url,
}: VisualCardProps) {
  return (
    <StyledLink to={url}>
      <VisualCardContainer>
        <VisualCardImageWrapper>
          <VisualCardImageContainer>
            <VisualCardImage src={image} />
          </VisualCardImageContainer>
        </VisualCardImageWrapper>

        <VisualCardContent>
          <CardTitle>{title}</CardTitle>

          {description && <CardDescription>{description}</CardDescription>}
        </VisualCardContent>
      </VisualCardContainer>
    </StyledLink>
  );
}

const BaseContentCard = styled.div<{ $brandColor?: string }>(
  ({ $brandColor, theme }) => {
    return css`
      --brand-color: ${$brandColor || theme.color.fg.primary};
      --border-radius: 8px;

      display: grid;
      gap: 8px;
      border-radius: var(--border-radius);
      padding: 20px; /* use CSS var */
      background-color: ${theme.color.bg.subtle};
      box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
      height: 100%;
    `;
  }
);

const DocumentationCardContainer = styled(BaseContentCard)`
  grid: "icon title" auto "icon description" 1fr / 20px 1fr;
  gap: 8px 20px;
  transition: scale 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    scale: 1.03;
  }
`;

const VisualCardImageWrapper = styled.div`
  grid-area: image;
  overflow: hidden;
`;

const TutorialCardImageContainer = styled.div(
  ({ theme }) =>
    css`
      display: grid;
      place-items: center;
      height: 100%;
      width: 100%;
      aspect-ratio: 16 / 9;
      background: linear-gradient(
        225deg,
        ${theme.color.fg.primary} 0%,
        ${theme.color.fg.accent[1]} 35.35%,
        ${theme.color.fg.accent[2]} 85.92%,
        ${theme.color.fg.accent[3]} 100%
      );
      box-shadow: inset 0 0 2rem ${theme.color.neutral.alpha.light[200]};
      position: relative;
      transition: scale 0.2s ease-in-out;
    `
);

const VisualCardImageContainer = styled(TutorialCardImageContainer)`
  aspect-ratio: 9 / 4;
`;

const TutorialCardContainer = styled(BaseContentCard)`
  display: grid;
  grid: "image" auto "content" 1fr / 1fr;
  padding: 0;
  overflow: hidden;
  gap: 0;
`;

const VisualCardContainer = styled(TutorialCardContainer)`
  &:hover ${TutorialCardImageContainer} {
    scale: 1.03;
  }
`;

const VisualCardImage = styled(LazilyLoadedImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  inset: 0;
`;

const VisualCardIconContainer = styled.div(
  ({ theme }) =>
    css`
      background: grey;
      border-radius: 10px;
      padding: 16px;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.95) 0%,
        rgba(255, 255, 255, 0.6) 100%
      );
      box-shadow: 0px 4px 24px 0px ${theme.color.fg.primary};
      height: 65px;
      color: ${theme.color.neutral[900]};
    `
);

const BlogCardContent = styled(BaseContentCard)`
  grid-area: content;
  grid: "title" auto "description" 1fr "meta" auto / 1fr;
  border: none;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
`;

const TutorialCardContent = styled(BlogCardContent)`
  grid: "title" auto "description" 1fr "ctas" auto / 1fr;
`;

const VisualCardContent = styled(BlogCardContent)`
  grid: "title" auto "description" 1fr / 1fr;
`;

const BlogCardMetaContainer = styled.div`
  grid-area: meta;
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 12px;
  margin-top: 32px;
`;

const AutorImageContainer = styled(TutorialCardImageContainer)`
  aspect-ratio: 1;
  border-radius: 50%;
  grid-area: unset;
  height: unset;
  overflow: hidden;
`;

const MetaText = styled.p(
  ({ theme }) =>
    css`
      font: ${theme.font.body.sm.medium};
      margin: 0;
    `
);

const CardTitle = styled.h3(
  ({ theme }) =>
    css`
      grid-area: title;
      font: ${theme.font.body.l.medium};
    `
);

const CardDescription = styled.p(
  ({ theme }) =>
    css`
      grid-area: description;
      font: ${theme.font.body.md.regular};
      color: ${theme.color.fg.muted};
    `
);

const CardCTA = styled.span(
  ({ theme }) =>
    css`
      grid-area: cta;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      font: ${theme.font.buttons.md};
      color: ${theme.color.fg.primary};
      margin: 10px 0 0;
      margin: 0; /* HACK - Only here while we have the description commented out */

      &:hover {
        text-decoration: underline;
      }
    `
);

const CTAsContainer = styled.div`
  grid-area: ctas;
  margin-top: 4px;
  display: grid;

  ${CardCTA} {
    grid-area: unset;
    cursor: pointer;
  }
`;

const CardIcon = styled(Icon)`
  grid-area: icon;
  color: var(--brand-color);
`;

const StyledLink = styled(Link)`
  color: unset;
  text-decoration: unset;
`;
