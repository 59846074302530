import styled, { css } from "styled-components";
import { useMeasure } from "react-use";
import { Timeseries, getStepFromTimeRange } from "@fiberplane/prometheus-query";
import {
  useInteractiveControls,
  useMouseControls,
  getCursorFromState,
} from "@fiberplane/charts";
import { useEffect, useRef, useState } from "react";

import { GRAPH_CONTENT_SIZE } from "./constants";
import { Graph } from "./Graph";
import { useHandler, useTimeRange } from "../../../hooks";
import { Labels } from "./Labels";
import { TimelineTickLabels } from "./TimelineTickLabels";
import { useTimeTicks } from "./hooks";
import { ZoomBar } from "./ZoomBar";
import { CurrentLine } from "./CurrentLine";
import { CurrentTimeLabel } from "./CurrentTimeLabel";
import { TooltipState } from "../../../state";
import { CloseTooltipFn } from "../../../types";
import { dispatch } from "../../../store";
import { showTooltip } from "../../../thunks";
import { EmptyGraph } from "./EmptyGraph";

type Props = {
  data: Array<{ alert: Timeseries; matchingIndex: number }>;
};

export function EventTimelineContent({ data }: Props) {
  const { timeRange, onChangeTimeRange } = useTimeRange();

  const [ref, { width, height }] = useMeasure<HTMLDivElement>();

  const interactiveControls = useInteractiveControls(false);
  const { mouseInteraction, updatePressedKeys } = interactiveControls;

  const cursor = getCursorFromState(interactiveControls);

  const dimensions = { xMax: width, yMax: height };
  const {
    onMouseDown,
    onMouseUp,
    onWheel,
    onMouseMove: onMouseMoveControls,
  } = useMouseControls({
    dimensions,
    interactiveControls,
    onChangeTimeRange,
    timeRange,
  });

  const { formatter, ticks, getX, axis } = useTimeTicks({
    from: timeRange.from,
    to: timeRange.to,
    width,
    height,
    mouseInteraction,
  });

  const [current, setCurrent] = useState<null | number>(null);
  const setCurrentTimeIndicator = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.currentTarget || event.target;
    if (!element) {
      return null;
    }

    const rect = (element as Element).getBoundingClientRect();
    const fraction = (event.clientX - rect.left) / width;
    setCurrent(fraction);
  };

  const resetCurrentTimeIndicator = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    const { currentTarget, target } = event;

    if ((target as HTMLDivElement).contains(currentTarget)) {
      return;
    }

    setCurrent(null);
  };

  useEffect(() => {
    const wheelListenerOptions: AddEventListenerOptions = { passive: false };
    window.addEventListener("keydown", updatePressedKeys);
    window.addEventListener("keyup", updatePressedKeys);
    window.addEventListener("wheel", onWheel, wheelListenerOptions);
    return () => {
      window.removeEventListener("keydown", updatePressedKeys);
      window.removeEventListener("keyup", updatePressedKeys);
      window.removeEventListener("wheel", onWheel, wheelListenerOptions);
    };
  }, [onWheel, updatePressedKeys]);

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    updatePressedKeys(event);
    onMouseMoveControls(event);
    setCurrentTimeIndicator(event);
    event.preventDefault();
  };

  const stepSize = getStepFromTimeRange(timeRange)[1];
  const closeRef = useRef<CloseTooltipFn | null>(null);
  const setTooltipState = useHandler((tip: null | TooltipState) => {
    if (tip === null) {
      closeRef.current && closeRef.current();
      closeRef.current = null;
      return;
    }

    closeRef.current = dispatch(
      showTooltip(tip.anchor(), tip.content(), tip.options)
    );
  });

  return (
    <SplitContainer>
      <OverflowContainer>
        <GraphContainer
          ref={ref}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={resetCurrentTimeIndicator}
          style={{ cursor }}
        >
          {data.length === 0 && (
            <EmptyContainer>
              <EmptyGraph />
            </EmptyContainer>
          )}
          <Graph
            height={height}
            width={width || 1}
            data={data}
            ticks={ticks}
            getX={getX}
            stepSize={stepSize}
            showTooltip={setTooltipState}
          />
          <ZoomBar width={width} mouseInteraction={mouseInteraction} />
          {current !== null && (
            <CurrentLine
              x={current * width}
              height={Math.min(GRAPH_CONTENT_SIZE, height)}
            />
          )}
        </GraphContainer>
        <LabelContainer>
          <Labels data={data} showTooltip={setTooltipState} />
        </LabelContainer>
      </OverflowContainer>
      <FixedContainer>
        <TimelineTickLabels
          formatter={formatter}
          getX={getX}
          ticks={ticks}
          width={width}
        />
        {current !== null && (
          <CurrentTimeLabel x={current * width} type="current">
            {formatter(
              current * (axis.maxValue - axis.minValue) + axis.minValue
            )}
          </CurrentTimeLabel>
        )}
      </FixedContainer>
    </SplitContainer>
  );
}

const SplitContainer = styled.div`
  display: grid;
  grid-template:
    "overflow" auto
    "fixed" 24px;
  gap: 8px;
  position: relative;
  height: 278px;
`;

const OverflowContainer = styled.div`
  grid-area: overflow;
  display: grid;
  grid: "graph label" auto / 1fr 218px;
  max-height: ${GRAPH_CONTENT_SIZE}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const FixedContainer = styled.div`
  grid-area: fixed;
  grid-template-columns: auto 218px;
  position: relative;
`;

const GraphContainer = styled.div`
  grid-area: graph;
  min-width: 0;
  position: relative;
  overflow: hidden;
  /* This is needed to keep the graphs on top of the label hover background */
  z-index: 1;
`;

const LabelContainer = styled.div`
  grid-area: label;
`;

const EmptyContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
