import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { Container } from "./common";
import { MetricCard } from "./MetricCard";
import {
  CALL_GRAPH,
  getSupportedContentTypes,
  GRAPH,
  useFunctionPageParams,
  usePrometheusData,
} from "./hooks";
import { PrometheusError } from "../../FallbackStates";
import { Icon, ScrollContainer, Tab, TabList, Tabs } from "../../UI";
import type { MetricType } from "../../../api";
import { Graph } from "./Graph";
import { Query } from "./Query";
import {
  useAccentColorForIndex,
  useSourceCodeLinkSettings,
  useTimeRange,
} from "../../../hooks";
import { LastUpdated } from "./LastUpdated";
import { SourceCodeLinks } from "./SourceCodeLinks";
import { CallGraph } from "./CallGraph";
import {
  getFunctionPath,
  getMetricMetadata,
  replaceQueryParameter,
} from "../../../utils";
import "./FunctionPage.css";

export function FunctionsPage() {
  const {
    metricType,
    moduleParameter,
    contentType,
    searchParams: currentSearchParams,
  } = useFunctionPageParams();
  const supportedContentTypes = getSupportedContentTypes(metricType);

  const shouldShowGraph = contentType === GRAPH;
  const shouldShowCallGraph = contentType === CALL_GRAPH;
  const { getAccentColorForIndex } = useAccentColorForIndex();

  const {
    timeRange,
    isRelativeTimeRange,
    onChangeTimeRange,
    refreshRelativeTimeRange,
  } = useTimeRange();

  const {
    moduleName,
    functionName,
    serviceName,
    prometheusData,
    callGraphData,
    events,
    sourceCodeLinks,
  } = usePrometheusData(timeRange, moduleParameter === undefined);

  const currentMetric = prometheusData[metricType];
  const currentMetricData = currentMetric?.data;

  const { title, index } = getMetricMetadata(metricType);
  const color = getAccentColorForIndex(index);

  const { value: linkSettings } = useSourceCodeLinkSettings();

  const loadingGraphData =
    callGraphData.outgoingQuery.isFetching ||
    callGraphData.incomingQuery.isFetching;

  if (!currentMetric) {
    return null;
  }

  if (currentMetric.error) {
    return <PrometheusError />;
  }

  // NOTE - WE only show "refresh" logic for relative time ranges (i.e., the presets in the date picker)
  const shouldShowRefresh = isRelativeTimeRange && currentMetric.isSuccess;
  const lastUpdatedAt = new Date(currentMetric.fulfilledTimeStamp);
  const isRefetching = currentMetric.isFetching;

  const refetchMetricData = () => {
    // NOTE - We cannot update the data using RTK-query's `refetch`,
    //        since it will just use the same prometheus query as before,
    //        which contains a hardcoded time range.
    //        (This means we would end up getting the same data back, and nothing would change in the UI.)
    //
    //        Instead, we update everything by just recomputing the relative time range.
    //
    refreshRelativeTimeRange();
  };

  return (
    <FunctionsExplorerContainer>
      <FunctionsExplorerContent className="functionPageContainerChild">
        <HeadingWrapper>
          <PageHeading>
            {moduleName ?? "Live metrics for your autometrics function"}
          </PageHeading>
          <PageSubheadingWrapper>
            <NameAndLastUpdated>
              <h1>{functionName}</h1>
              {shouldShowRefresh && (
                <LastUpdated
                  isRefreshing={isRefetching}
                  lastUpdatedAt={lastUpdatedAt}
                  refresh={refetchMetricData}
                />
              )}
            </NameAndLastUpdated>
            {linkSettings !== "off" && sourceCodeLinks.data && (
              <SourceCodeLinks data={sourceCodeLinks.data} />
            )}
          </PageSubheadingWrapper>
        </HeadingWrapper>

        <ScrollContainer>
          {Object.entries(prometheusData).map(([key, { metrics, data }]) => {
            const latestMetric = metrics.find(
              ({ reportType }) => reportType === "latest"
            );
            if (!latestMetric) {
              return null;
            }

            const { title, index, description } = getMetricMetadata(
              key as MetricType
            );
            const color = getAccentColorForIndex(index);

            const path = getFunctionPath(
              functionName,
              moduleName,
              serviceName,
              key as MetricType
            );

            return (
              <MetricLink
                to={{
                  pathname: path,
                  search: currentSearchParams.toString(),
                }}
                key={key}
              >
                <MetricCard
                  brandColor={color}
                  isActive={metricType === key}
                  latestValue={latestMetric.value}
                  latestValueUnit={latestMetric.unit}
                  title={title}
                  info={description}
                  data={data}
                  timeRange={timeRange}
                />
              </MetricLink>
            );
          })}
        </ScrollContainer>

        <FunctionDetailsContainer>
          <FunctionDescriptionWrapper>
            <h4>{title}</h4>
          </FunctionDescriptionWrapper>

          <TabContainer>
            <Tabs>
              {supportedContentTypes.length > 1 && (
                <TabList>
                  <Tab
                    key={GRAPH}
                    to={{
                      search: replaceQueryParameter(
                        currentSearchParams,
                        "contentType",
                        GRAPH
                      ).toString(),
                      replace: shouldShowGraph,
                    }}
                    isActive={shouldShowGraph}
                    asElement="link"
                  >
                    <Icon type="graph_duotone"></Icon>
                    Graph
                  </Tab>

                  {supportedContentTypes.includes(CALL_GRAPH) && (
                    <Tab
                      to={{
                        search: replaceQueryParameter(
                          currentSearchParams,
                          "contentType",
                          CALL_GRAPH
                        ).toString(),
                        replace: shouldShowCallGraph,
                      }}
                      isActive={shouldShowCallGraph}
                      asElement="link"
                      key={CALL_GRAPH}
                    >
                      <Icon type="call_graph"></Icon>
                      Call graph
                    </Tab>
                  )}
                </TabList>
              )}

              {shouldShowGraph && (
                <>
                  <Graph
                    color={color}
                    currentMetric={currentMetric}
                    currentMetricData={currentMetricData}
                    metricType={metricType}
                    onChangeTimeRange={onChangeTimeRange}
                    events={events}
                    timeRange={timeRange}
                  />
                  <QueryContainer>
                    <h4>PromQL</h4>
                    <Query text={currentMetric?.promqlQuery} />
                  </QueryContainer>
                </>
              )}

              {shouldShowCallGraph && (
                <CallGraph
                  functionName={functionName}
                  isLoading={loadingGraphData}
                  incomingResult={
                    callGraphData.incomingQuery.data?.data?.result
                  }
                  outgoingResult={
                    callGraphData.outgoingQuery.data?.data?.result
                  }
                  metricType={metricType}
                />
              )}
            </Tabs>
          </TabContainer>
        </FunctionDetailsContainer>
      </FunctionsExplorerContent>
    </FunctionsExplorerContainer>
  );
}

const FunctionsExplorerContainer = styled.div`
  /* @container defined in ./FunctionPage.css */
  container: functionPageContainer / inline-size;
`;

const FunctionsExplorerContent = styled.div(
  ({ theme: { spacing } }) =>
    css`
      display: grid;
      grid-template-rows: repeat(3, auto) 1fr;
      position: relative;

      padding-inline: ${spacing.page.inline};
      padding-block: ${spacing.page.block};
      gap: ${spacing.page.gap};
    `
);

const HeadingWrapper = styled.div`
  display: grid;
  gap: 8px;
`;

const PageHeading = styled.h4(
  ({ theme }) =>
    css`
      color: ${theme.color.fg.subtle};
    `
);

const PageSubheadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const NameAndLastUpdated = styled.div`
  display: flex;
  flex-direction: var(--flex-direction-name-last-updated, row);
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`;

const MetricLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const FunctionDetailsContainer = styled(Container)(
  ({ theme }) =>
    css`
      display: grid;
      gap: 24px; /* TODO (Oscar): add responsive spacing value */
      grid-template:
        "heading" auto
        "graph" auto
        "query" auto / auto;
      padding: 0;
      border-radius: ${theme.radius.rounded};
      background-color: ${theme.color.bg.subtle};
    `
);

const FunctionDescriptionWrapper = styled.div(
  ({ theme }) =>
    css`
      grid-area: heading;
      padding: 8px 20px;
      // Get the same border radius as the container
      border-radius: inherit;
      background: ${theme.color.bg.hover};
      color: ${theme.color.fg.default};
      font: ${theme.font.headings.h3};
    `
);

const TabContainer = styled.div`
  grid-area: graph;
  padding: 0 20px;
`;

const QueryContainer = styled.div`
  display: grid;
  grid: "heading" 40px "query" auto / auto;
`;
