import { useMemo } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";

import {
  DatePickerTimeRange,
  getRelativeTimeRangeFromLabel,
  isTimeRangePresetLabel,
  timeRangePresetLabels,
} from "../utils";

/**
 * Hook that handles the logic of loading a time range from query parameters, etc
 */
export function useTimeRange() {
  const [searchParams, setSearchParams] = useSearchParams();

  const timeRange = useMemo(() => {
    const queryFrom = searchParams.get("from");
    const queryTo = searchParams.get("to");
    const queryLabelRaw = searchParams.get("relativeTimeRangeLabel");
    const queryLabel =
      queryLabelRaw && isTimeRangePresetLabel(queryLabelRaw)
        ? queryLabelRaw
        : undefined;

    // If a relative time range is provided in the url, we re-compute the from/to properties
    // If from/to are provided, we simply use those
    // Otherwise, we default to "Last 15 mins"
    if (queryLabel) {
      return getRelativeTimeRangeFromLabel(queryLabel);
    } else if (queryFrom && queryTo) {
      return { from: queryFrom, to: queryTo };
    }

    return getFifteenMinutesAgoTimeRange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()]);

  const isRelativeTimeRange =
    "relativeTimeRangeLabel" in timeRange &&
    isTimeRangePresetLabel(timeRange.relativeTimeRangeLabel);

  const updateSearchParams = (
    newTimeRange: DatePickerTimeRange,
    options?: NavigateOptions
  ) => {
    // Get the current searchParams object
    const currentSearchParams = Object.fromEntries(searchParams);

    // Create a new object to store updated search parameters
    const updatedSearchParams: Record<string, string> = {};

    // Copy all values from currentSearchParams except "from" and "to"
    for (const [key, value] of Object.entries(currentSearchParams)) {
      if (key !== "from" && key !== "to" && key !== "relativeTimeRangeLabel") {
        updatedSearchParams[key] = value;
      }
    }

    // Call setSearchParams with the updated search parameters
    setSearchParams({ ...updatedSearchParams, ...newTimeRange }, options);
  };

  const refreshRelativeTimeRange = () => {
    if (isRelativeTimeRange && timeRange.relativeTimeRangeLabel) {
      const newTimeRange = getRelativeTimeRangeFromLabel(
        timeRange.relativeTimeRangeLabel
      );
      // NOTE - Refreshing the timerange should replace the entry in history, to preserve behavior of the back/forwards button
      updateSearchParams(newTimeRange, { replace: true });
    }
  };

  const onChangeTimeRange = (newTimeRange: DatePickerTimeRange) => {
    // NOTE - This creates a new entry in history, to allow users to use back/forward to navigate between time ranges they've selected
    updateSearchParams(newTimeRange);
  };

  return {
    timeRange,
    isRelativeTimeRange,
    onChangeTimeRange,
    refreshRelativeTimeRange,
  };
}

export function getFifteenMinutesAgoTimeRange() {
  return {
    from: fifteenMinutesAgo().toISOString(),
    to: new Date().toISOString(),
    relativeTimeRangeLabel: timeRangePresetLabels.LAST_15_MINUTES,
  };
}

function fifteenMinutesAgo(d?: Date) {
  d = d ? new Date(d) : new Date();
  d.setMinutes(d.getMinutes() - 15);
  return d;
}
