import { Fragment } from "react";
import { useTheme } from "styled-components";

import {
  LABEL_HEIGHT,
  MINIMAL_NODE_HEIGHT,
  NODE_WIDTH,
  PADDING,
  TOP,
} from "./constants";
import {
  EmptyPattern,
  GradientDefinition,
  StyledPath,
  StyledRectangle,
} from "./sharedStyles";
import { NodeLabel } from "./NodeLabel";
import { CallGraphNode } from "./types";

type Props = {
  outgoingNodes: CallGraphNode[];
  valueToPixelRatio: number;
  totalNodeHeight: number;
  middle: number;
  right: number;
  renderValue: (value: number) => string;
  renderGradients?: boolean;
};

export function OutgoingNodes(props: Props) {
  const {
    outgoingNodes,
    valueToPixelRatio,
    renderValue,
    middle,
    right,
    renderGradients = false,
    totalNodeHeight,
  } = props;
  let outgoingNodeY = TOP;
  let centerY = 1;
  const rightBetweenPoint = (right - middle) * 0.5 + middle;
  const theme = useTheme();

  return (
    <g>
      <EmptyPattern id="outgoing-empty" reverse />
      {outgoingNodes.map((node, index) => {
        const nodeHeight = Math.max(
          node.value * valueToPixelRatio,
          MINIMAL_NODE_HEIGHT
        );
        centerY += nodeHeight;

        const topCoordinate =
          nodeHeight < LABEL_HEIGHT
            ? outgoingNodeY + 0.5 * (LABEL_HEIGHT - nodeHeight)
            : outgoingNodeY;
        const labelY = Math.max(
          outgoingNodeY,
          outgoingNodeY - 0.5 * (LABEL_HEIGHT - nodeHeight)
        );
        outgoingNodeY += Math.max(nodeHeight, LABEL_HEIGHT) + PADDING;

        const topAmountCoordinate = centerY - nodeHeight;
        const fraction = (node.value * valueToPixelRatio) / totalNodeHeight;
        return (
          <Fragment key={node.name}>
            {renderGradients && (
              <defs>
                <GradientDefinition
                  id={`outgoing${index}`}
                  to={theme.color.accent.rust[700]}
                  toOpacity={Math.min(1, fraction)}
                />
              </defs>
            )}
            <StyledRectangle
              x={right}
              y={topCoordinate}
              width={NODE_WIDTH}
              height={nodeHeight}
            />
            {renderGradients && (
              <StyledPath
                fill={`url(#outgoing${index})`}
                d={`
              M${right} ${topCoordinate} 
              C${rightBetweenPoint},${topCoordinate} ${rightBetweenPoint},${topAmountCoordinate} ${middle},${topAmountCoordinate} 
              L${middle},${centerY} 
              C${rightBetweenPoint},${centerY} ${rightBetweenPoint},${
                  topCoordinate + nodeHeight
                } ${right},${topCoordinate + nodeHeight} 
              Z
            `}
              />
            )}
            <StyledPath
              fill={node.value === 0 ? "url(#outgoing-empty)" : undefined}
              d={`
              M${right} ${topCoordinate} 
              C${rightBetweenPoint},${topCoordinate} ${rightBetweenPoint},${topAmountCoordinate} ${middle},${topAmountCoordinate} 
              L${middle},${centerY} 
              C${rightBetweenPoint},${centerY} ${rightBetweenPoint},${
                topCoordinate + nodeHeight
              } ${right},${topCoordinate + nodeHeight} 
              Z
            `}
            />
            <NodeLabel
              x={right + NODE_WIDTH + 8}
              y={labelY - 2}
              width={192}
              height={LABEL_HEIGHT}
              align="start"
              name={node.name}
              module={node.module}
              value={renderValue(node.value)}
            />
          </Fragment>
        );
      })}
    </g>
  );
}
