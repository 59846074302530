import styled, { css } from "styled-components";

/**
 * Container for horizontally scrollable content.
 * Exposes custom properties for the block and inline spacing.
 */
export const ScrollContainer = styled.div(
  ({ theme: { spacing } }) => css`
    /*
      We're using custom properties here as the scroll container defaults to
      block- and inline values from the theme. These variables can be
      overwritten if the scroll container isn't dependent on the page or content
      values.
    */
    --spacing-scroll-container-block: ${spacing.content.gap.md};
    --spacing-scroll-container-inline: ${spacing.page.inline};

    display: flex;
    gap: ${spacing.content.gap.md};
    overflow-x: auto;
    padding: var(--spacing-scroll-container-block)
      var(--spacing-scroll-container-inline);
    margin: calc(var(--spacing-scroll-container-block) * -1)
      calc(var(--spacing-scroll-container-inline) * -1);

    mask: linear-gradient(
      to right,
      transparent 0%,
      black var(--spacing-scroll-container-inline),
      black calc(100% - var(--spacing-scroll-container-inline)),
      transparent 100%
    );
  `
);
