import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { AnimatePresence } from "framer-motion";
import useClickAway from "react-use/lib/useClickAway";
import { Button } from "@fiberplane/ui";

import { AnimatedCaretIcon, AnimatedMenu, Icon } from "../UI";
import { DatePickerContent } from "./DatePickerContent";
import { useHandler } from "../../hooks";
import { selectIsDatePickerOpen } from "../../selectors";
import { dispatch } from "../../store";
import { toggleDatepickerVisibility } from "../../actions";
import { DatePickerTimeRange, isTimeRangePresetLabel } from "../../utils";

type Props = {
  className?: string;
  onChange: (timeRange: DatePickerTimeRange) => void;
  timeRange: DatePickerTimeRange;
};

export function DatePicker({ onChange, timeRange, className }: Props) {
  const isDatePickerOpen = useSelector(selectIsDatePickerOpen);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleDatePicker = useHandler(() => {
    dispatch(toggleDatepickerVisibility());
  });

  const onClickAway = useHandler((event: Event) => {
    const target = event.target;
    if (target instanceof HTMLButtonElement && target.dataset.datePicker) {
      return;
    }

    toggleDatePicker();
  });

  useClickAway(contentRef, onClickAway);

  // Show nudge animation when time range changes
  const [nudgeVisible, setNudgeVisibility] = useState(false);
  // Store timer ref to clear it on unmount
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Store initial time range to compare with the current one
  const initialRef = useRef(timeRange);

  useEffect(() => {
    if (initialRef.current !== timeRange) {
      setNudgeVisibility(true);
      timerRef.current = setTimeout(() => {
        setNudgeVisibility(false);
        timerRef.current = null;
      }, 1050);
    }
  }, [setNudgeVisibility, timeRange]);

  // Cleanup timer when needed
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handler = useHandler((timeRange: DatePickerTimeRange) => {
    toggleDatePicker();
    initialRef.current = timeRange;
    onChange(timeRange);
  });

  return (
    <DatePickerContainer className={className}>
      <DatePickerButton
        $isActive={isDatePickerOpen}
        buttonStyle="secondary"
        onClick={toggleDatePicker}
        data-date-picker
      >
        {nudgeVisible && <Nudge />}
        <Icon type="time_duotone" />
        <DatePickerFormattedTimeRange>
          {toFormattedTimeRange(timeRange)}
        </DatePickerFormattedTimeRange>
        <AnimatedCaretIcon isActive={isDatePickerOpen} />
      </DatePickerButton>

      <AnimatePresence>
        {isDatePickerOpen && (
          <Content ref={contentRef}>
            <DatePickerContent timeRange={timeRange} onChange={handler} />
          </Content>
        )}
      </AnimatePresence>
    </DatePickerContainer>
  );
}

const fade = keyframes`
  from { opacity: 1 }
  to { opacity: 0 }
`;

const Nudge = styled.div(
  ({ theme }) => css`
    & {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${theme.color.bg.emphasis["primary-subtle"]};
      border-radius: inherit;
      box-shadow: ${theme.effect.focus.primary};
      animation: 1s ease-in-out forwards ${fade};
    }
  `
);

const DatePickerContainer = styled.div`
  position: relative;
`;

const DatePickerFormattedTimeRange = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled(AnimatedMenu)`
  position: absolute;
  right: 0;
  top: calc(100% + 8px); /* offset the button height with 8px spacing */
  z-index: 2;
  min-width: 430px;
  border-radius: ${({ theme }) => theme.radius.default};

  /* Same in light & dark themes; also no variable defined in Figma */
  box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 25%);
`;

const DatePickerButton = styled(Button)<{ $isActive: boolean }>(
  ({ $isActive, theme }) => css`
    padding: 8px;
    font: ${theme.font.body.md.medium};
    position: relative;

    /* Prevents clicking child elements in onClickAway */
    & *:not(${Nudge}) {
      pointer-events: none;
      position: relative;
    }

    /* Force :focus styles when DatePicker is opened */
    ${$isActive &&
    css`
      border-color: ${theme.color.border.primary};
      box-shadow: ${theme.effect.focus.primary};
    `}
  `
);

const ONE_DAY = 24 * 60 * 60 * 1000;

function toFormattedTimeRange(timeRange: DatePickerTimeRange) {
  const startTimeNumeric = +new Date(timeRange.from);

  // If the time range contians a valid preset label, render that label
  if (isTimeRangePresetLabel(timeRange?.relativeTimeRangeLabel)) {
    return timeRange.relativeTimeRangeLabel;
  }

  // If start date is less than 24 hours ago, render the time without the date
  const isStartTimeLessThan24HoursAgo = startTimeNumeric > Date.now() - ONE_DAY;
  if (isStartTimeLessThan24HoursAgo) {
    return (
      <>
        {formatLocaleTime(timeRange.from)} &mdash;{" "}
        {formatLocaleTime(timeRange.to)}
      </>
    );
  }

  // Otherwise, render the absolute time range
  return (
    <>
      {timeRange.from} &mdash; {timeRange.to}
    </>
  );
}

function formatLocaleTime(timestamp: string) {
  const date = new Date(timestamp);
  // If the date is less than 24 hours ago, show the time
  return date.toLocaleTimeString();
}
