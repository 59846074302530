export function createExpandedSearchParamsUpdater(
  value: Record<string, boolean>
) {
  return (params: URLSearchParams) => {
    params.delete("expanded");
    for (const key of Object.keys(value)) {
      params.append("expanded", key);
    }

    return params;
  };
}
