// If registration is not, it set means the service worker was either:
// * never initialized
// * not active
// * not supported
// * failed to register
let _registration: ServiceWorkerRegistration | undefined;

export const getServiceWorker = () => _registration?.active;

export const hasServiceWorkerSupport = "serviceWorker" in navigator;

export async function setupNotificationWorker() {
  if (hasServiceWorkerSupport === false) {
    return;
  }

  try {
    _registration = await navigator.serviceWorker.register(
      new URL("serviceWorker", import.meta.url),
      { type: "module" }
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error registering service worker", error);
  }
}
