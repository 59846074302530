import { CreateCompletionResponse } from "../types";
import {
  add,
  appendById,
  markErrorById,
  setAskAutometricsVisibility,
} from "../slices";
import { Thunk } from "../store";

const REMOTE_HOST = "https://xlkarzuhgbiwmmvkjnwa.supabase.co";

export const createAskPrompt =
  (prompt: string): Thunk =>
  (dispatch, getState) => {
    const { askAutometrics } = getState();
    const id = askAutometrics.ids.length.toString();

    setTimeout(() => {
      dispatch(setAskAutometricsVisibility(true));
    }, 0);

    dispatch(
      add({
        id,
        prompt,
      })
    );

    const query = new URLSearchParams({
      query: prompt,
    });
    const projectUrl = `${REMOTE_HOST}/functions/v1/vector-search`;
    const queryURL = `${projectUrl}?${query}`;
    const eventSource = new EventSource(queryURL);

    eventSource.addEventListener("error", async () => {
      eventSource.close();
      dispatch(markErrorById(id));
      for (const text of chunkSubstr(
        "Rate limit has been exceeded. Please try again in a bit.",
        4
      )) {
        dispatch(
          appendById({
            id,
            text,
          })
        );
        await awaitableTimeout(50);
      }
    });

    eventSource.addEventListener("message", (message: MessageEvent) => {
      if (message.data === "[DONE]") {
        eventSource.close();
        return;
      }

      const completionResponse: CreateCompletionResponse = JSON.parse(
        message.data
      );
      const text = completionResponse.choices[0]?.text ?? "";
      dispatch(
        appendById({
          id,
          text,
        })
      );
    });
  };

function chunkSubstr(input: string, size: number): string[] {
  const numChunks = Math.ceil(input.length / size);
  const chunks: string[] = Array.from({ length: numChunks });

  for (let i = 0, o = 0; i < numChunks; i++, o += size) {
    chunks[i] = input.slice(o, o + size);
  }

  return chunks;
}

function awaitableTimeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
