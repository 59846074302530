import { ReactNode } from "react";

import { FallbackCommon } from "../../FallbackStates";

const { BigIcon, Container, Content, Header, Heading, HelperText } =
  FallbackCommon;

export function FunctionsQueryError({
  heading,
  helperText,
}: {
  heading: ReactNode;
  helperText: ReactNode;
}) {
  return (
    <Container>
      <Content>
        <Header>
          <BigIcon type="warning" />
          <Heading>{heading}</Heading>
          <HelperText>{helperText}</HelperText>
        </Header>
      </Content>
    </Container>
  );
}
