import type { MetricType } from "../api";

type MetricMetadata = {
  title: string;
  description: string;
  index: number;
};

export function getMetricMetadata(key: MetricType): MetricMetadata {
  switch (key) {
    case "requestRate":
      return {
        title: "Request rate",
        description:
          "Rate of calls to the function per second, averaged over 5 minute windows",
        index: 0,
      };
    case "errorRatio":
      return {
        title: "Error ratio",
        description:
          "Percentage of calls to the function that return errors, averaged over 5 minute windows",
        index: 1,
      };
    case "latency":
      return {
        title: "Latency",
        description:
          "99th and 95th percentile latency or response time for the function. For example, if the 99th percentile latency is 500 milliseconds, that means that 99% of calls to the function are handled within 500ms or less.",
        index: 2,
      };
  }
}
