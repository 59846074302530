import { Timestamp } from "@fiberplane/prometheus-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useHandler, useTimeRange } from "../../hooks";
import {
  TimeRangePresetLabel,
  isInsideTimeRange,
  timeRangeOptions,
  timeRangePresetLabels,
} from "../../utils";

export function useNavigateToAlerts() {
  const navigate = useNavigate();
  const { timeRange } = useTimeRange();
  const [currentSearchParams] = useSearchParams();
  return useHandler((timestamp: Timestamp) => {
    if (isInsideTimeRange(timestamp, timeRange)) {
      navigate({
        pathname: "/alerts",
        search: currentSearchParams.toString(),
      });
    } else {
      const relativeTimeRangeLabel =
        getClosestRelativeTimeRangeLabel(timestamp);
      navigate({
        pathname: "/alerts",
        search: new URLSearchParams({
          relativeTimeRangeLabel,
        }).toString(),
      });
    }
  });
}

export const getClosestRelativeTimeRangeLabel = (
  timestamp: Timestamp,
  minimalRange: number = 15 * 60 * 1000
): TimeRangePresetLabel => {
  const now = Date.now();
  const target = new Date(timestamp).getTime();
  const diff = now - target;
  const usableDiff = diff < minimalRange ? minimalRange : diff;
  const preset = timeRangeOptions.find((option) => option.value >= usableDiff);

  if (!preset) {
    return timeRangePresetLabels.LAST_15_MINUTES;
  }

  return preset.label;
};
