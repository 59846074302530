import {
  getDurationFormatterForAxis,
  getPercentageFormatter,
  getScientificFormatterForAxis,
  getTimeFormatterForAxis,
  MetricsChart,
  TickFormattersFactory,
  TimeRange,
  Timeseries,
} from "@fiberplane/charts";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css, useTheme } from "styled-components";

import { usePrometheusQueryRangeQuery } from "../../../api";
import {
  useChartTheme,
  useHandler,
  useScrapeIntervalAsBuildInfoInterval,
  useThrottledLoadingState,
} from "../../../hooks";
import { Objective } from "../../../schemas";
import { selectActivePrometheus } from "../../../selectors";
import { Prometheus } from "../../../services";
import { TooltipState } from "../../../state";
import { FadeIn, PopIn } from "../../Animations";
import { PrometheusError } from "../../FallbackStates";
import { ChartTooltip, Tooltip } from "../../Tooltip";
import { CardContainer, CardTitle } from "./styled";
import { GraphKeyboardShortcutsHelp } from "../../GraphKeyboardShortcutsHelp";
import { DatePickerTimeRange } from "../../../utils";
import { Skeleton } from "../../Skeleton";

const EMPTY_FUNCTIONS_LIST: Timeseries[] = [];

type GraphProps = {
  objective: Objective;
  timeRange: TimeRange;
  onChangeTimeRange: (newTimeRange: DatePickerTimeRange) => void;
};

export function Graph({ objective, timeRange, onChangeTimeRange }: GraphProps) {
  const instance = useSelector(selectActivePrometheus);

  const { metric: metricType, target } = objective;

  const targetLatency =
    metricType === "latency" ? Number.parseFloat(target.threshold) : undefined;

  const tickFormattersFactory = useMemo(
    (): TickFormattersFactory => (xAxis, yAxis) => ({
      xFormatter: getTimeFormatterForAxis(xAxis),
      yFormatter:
        metricType === "latency"
          ? getDurationFormatterForAxis(yAxis)
          : metricType === "successRate"
          ? getPercentageFormatter()
          : getScientificFormatterForAxis(yAxis),
    }),
    [metricType]
  );

  const { shouldWaitForBuildInfoInterval, buildInfoInterval } =
    useScrapeIntervalAsBuildInfoInterval(instance?.url);

  const chartPromqlQuery =
    metricType === "successRate"
      ? Prometheus.createSloAllFunctionsSuccessRateQuery(
          objective,
          timeRange.from,
          timeRange.to,
          buildInfoInterval
        )
      : metricType === "latency"
      ? Prometheus.createSloAllFunctionsLatencyQuery(
          objective,
          timeRange.from,
          timeRange.to,
          buildInfoInterval
        )
      : "";

  const {
    data = EMPTY_FUNCTIONS_LIST,
    error,
    isFetching,
    isUninitialized,
  } = usePrometheusQueryRangeQuery(
    {
      environmentUrl: instance?.url,
      query: chartPromqlQuery,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      // HACK - If we don't wait for the build info interval, we can get the "dots and dashes" effect in charts
      skip: shouldWaitForBuildInfoInterval || !chartPromqlQuery,
    }
  );

  const throttledIsFetching = useThrottledLoadingState(
    isFetching && isUninitialized,
    500
  );
  const [hiddenTimeseriesKeys, setHiddenTimeseriesKeys] = useState<string[]>(
    []
  );

  const dataWithVisibility = useMemo(() => {
    return data.map((series) => {
      const visible = !hiddenTimeseriesKeys.includes(getTimeseriesKey(series));

      return {
        ...series,
        visible,
      };
    });
  }, [data, hiddenTimeseriesKeys]);

  const onToggleTimeseriesVisibility = useHandler(
    ({ timeseries }: { timeseries: Timeseries }) => {
      const key = getTimeseriesKey(timeseries);
      if (hiddenTimeseriesKeys.includes(getTimeseriesKey(timeseries))) {
        setHiddenTimeseriesKeys((current) => current.filter((c) => c !== key));
      } else {
        setHiddenTimeseriesKeys((current) => [...current, key]);
      }
    }
  );

  const {
    color: {
      bg: { accent },
    },
  } = useTheme();

  const accentColor = metricType === "successRate" ? accent[2] : accent[3];

  const graphTheme = useChartTheme(accentColor);

  const [tooltipState, setTooltipState] = useState<null | TooltipState>(null);

  return (
    <GraphContainer>
      {isUninitialized ? null : throttledIsFetching ? (
        <FadeIn key="sloDetailGraphIsLoading">
          <Title>
            {metricType === "successRate" ? "Success rate" : "Latency"} by
            function
          </Title>
          <SkeletonGraphContainer />
        </FadeIn>
      ) : error ? (
        <FadeIn key="sloDetailGraphError">
          <PrometheusError />
        </FadeIn>
      ) : (
        <>
          <Title>
            {metricType === "successRate" ? "Success rate" : "Latency"} by
            function
          </Title>
          <StyledPopIn key="sloDetailGraph">
            <GraphWrapper>
              <MetricsChart
                chartTheme={graphTheme}
                areaGradientShown={dataWithVisibility.length < 5}
                graphType="line"
                stackingType="none"
                timeRange={timeRange}
                timeseriesData={dataWithVisibility}
                onChangeTimeRange={(newTimeRange) => {
                  onChangeTimeRange(newTimeRange);
                }}
                onToggleTimeseriesVisibility={onToggleTimeseriesVisibility}
                footerShown={false}
                tickFormatters={tickFormattersFactory}
                targetLatency={targetLatency}
                showTooltip={(anchor, [series, point]) => {
                  if (point) {
                    setTooltipState({
                      anchor: () => anchor,
                      content: () => <ChartTooltip content={[series, point]} />,
                      options: { placement: "top" },
                    });
                  }

                  return () => setTooltipState(null);
                }}
                shouldAnimateYScale={false}
              />
              {tooltipState && <Tooltip tip={tooltipState} />}
              {dataWithVisibility && <GraphKeyboardShortcutsHelp />}
            </GraphWrapper>
          </StyledPopIn>

          <SelectionInfo>
            {data.length - hiddenTimeseriesKeys.length} out of {data.length}{" "}
            selected
          </SelectionInfo>
        </>
      )}
    </GraphContainer>
  );
}

function getTimeseriesKey(timeseries: Timeseries): string {
  return Object.entries(timeseries.labels)
    .map((series) => series.join(":"))
    .join("/");
}

const SkeletonGraphContainer = () => {
  return (
    <SkeletonGraphGrid>
      <SkeletonYAxis>
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
        <SkeletonYAxisLabel />
      </SkeletonYAxis>
      <SkeletonGraph />
    </SkeletonGraphGrid>
  );
};

const SkeletonGraphGrid = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 290px;
  padding: 16px 0;
`;

const SkeletonYAxis = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48px;
  padding: 8px 0;
`;

const SkeletonYAxisLabel = styled(Skeleton)`
  min-height: 20px;
`;

const SkeletonGraph = styled(Skeleton)`
  flex-grow: 1;
  margin-left: 20px;
`;

const GraphContainer = styled(CardContainer)`
  padding: 16px 12px 12px;
`;

const Title = styled(CardTitle)`
  margin-bottom: 16px;
`;

const StyledPopIn = styled(PopIn)`
  /* Prevent grid overflow by setting min-width */
  min-width: 0;
`;

const GraphWrapper = styled.div`
  position: relative;
  /* Prevent grid overflow by setting min-width */
  min-width: 0;
`;

const SelectionInfo = styled.footer(
  ({ theme }) =>
    css`
      border-top: 1px solid ${theme.color.border.default};
      margin: 12px 8px 0;
      padding-top: 12px;
      font: ${theme.font.body.sm.regular};
    `
);
