import { motion } from "framer-motion";
import styled from "styled-components";

export const popInVariants = {
  initial: {
    opacity: 0,
    y: "8%",
  },
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    transition: {
      type: "tween",
      ease: "anticipate",
      duration: 0.07,
    },
  },
};

export const popInTransition = {
  type: "spring",
  stiffness: 800,
  damping: 80,
};

export const PopIn = styled(motion.div).attrs({
  initial: "initial",
  animate: "in",
  exit: "out",
  variants: popInVariants,
  transition: popInTransition,
})``;
