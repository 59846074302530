import { usePrometheusScrapeIntervalsQuery } from "../api";

/**
 * Helper hook to get the miniumu scrape interval for a given environment,
 * and translate that into an interval that we can use in build_info queries.
 */
export function useScrapeIntervalAsBuildInfoInterval(
  environmentUrl?: string | null
) {
  const {
    data: scrapeIntervals,
    isLoading: scrapeIntervalIsLoadng,
    isUninitialized: scrapeIntervalIsUninitialized,
  } = usePrometheusScrapeIntervalsQuery(
    { environmentUrl },
    {
      skip: !environmentUrl,
    }
  );

  const shouldWaitForBuildInfoInterval =
    scrapeIntervalIsLoadng || scrapeIntervalIsUninitialized;

  const buildInfoInterval = getMinScrapeInterval(scrapeIntervals);

  return {
    shouldWaitForBuildInfoInterval,
    buildInfoInterval,
  };
}

/**
 * @note - Will not consider scrape intervals of less than 1s
 * @note - Returns the ceiling of the minimum scrape interval, to avoid fractions of seconds
 */
function getMinScrapeInterval(scrapeIntervals?: Array<number> | null) {
  const filteredScrapeIntervals = scrapeIntervals?.filter(
    (interval) => interval >= 1
  );

  if (filteredScrapeIntervals?.length) {
    const minimumConfiguredScrapeInterval = filteredScrapeIntervals?.reduce(
      (min, current) => Math.min(min, current)
    );

    if (typeof minimumConfiguredScrapeInterval === "number") {
      const intervalSeconds = Math.ceil(minimumConfiguredScrapeInterval);
      return `${intervalSeconds}s`;
    }

    return minimumConfiguredScrapeInterval;
  }
}
