import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import useIntersection from "react-use/lib/useIntersection";

import { useHandler } from "../../hooks";

type LazilyLoadedImageProps = {
  src: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

export function LazilyLoadedImage({
  src,
  ...attributes
}: LazilyLoadedImageProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const intersection = useIntersection(imageRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  const setImageIsLoaded = useHandler(() => {
    setIsImageLoaded(true);
  });

  useEffect(() => {
    const imageElement = new Image();

    if (!isImageLoaded && intersection?.isIntersecting) {
      imageElement.src = src;
      imageElement.addEventListener("load", setImageIsLoaded);
    }

    return () => {
      imageElement.removeEventListener("load", setImageIsLoaded);
    };
  }, [intersection?.isIntersecting, isImageLoaded, setImageIsLoaded, src]);

  return (
    <Img
      {...attributes}
      ref={imageRef}
      src={isImageLoaded ? src : ""}
      $isVisible={isImageLoaded}
    />
  );
}

const Img = styled.img<{ $isVisible: boolean }>(
  ({ $isVisible }) => css`
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    ${$isVisible &&
    css`
      opacity: 1;
    `}
  `
);
