import { Link } from "react-router-dom";

import {
  Container,
  Content,
  Header,
  Heading,
  HelperText,
  BigIcon,
} from "./common";

export function PrometheusError() {
  return (
    <Container>
      <Content>
        <Header>
          <BigIcon type="prometheus_filled_grey" />
          <Heading>Error contacting Prometheus</Heading>
          <HelperText>
            Please check your Prometheus connection. You can add different
            hostnames on the <Link to="/settings">settings</Link> page.
          </HelperText>
        </Header>
      </Content>
    </Container>
  );
}
