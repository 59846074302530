import styled, { css } from "styled-components";

export function EmptyGraph() {
  return (
    <Container>
      <Title>No alerts in this time range</Title>
      <Body>
        Great news, none of your alerts are pending or firing in this time range
      </Body>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

const Title = styled.h4(
  ({ theme }) => css`
    color: ${theme.color.fg.success};
  `
);

const Body = styled.div(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    width: 400px;
    max-width: 80%;
  `
);
