import { createPortal } from "react-dom";
import { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
};

export function TooltipPortal({ children }: Props) {
  const [element] = useState(createElement);

  useEffect(() => {
    return () => element.remove();
  }, [element]);

  return createPortal(children, element);
}

function createElement() {
  const element = document.createElement("div");
  element.dataset.tooltipContainer = "";
  element.style.pointerEvents = "none";
  // HACK - give this a z-index greater than the modal overlay so we can show tooltips in modals
  element.style.position = "relative";
  element.style.zIndex = "10000";
  document.body.append(element);

  return element;
}
