import type { ProviderEvent, Timeseries } from "@fiberplane/charts";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import type { MetricReportType } from "../common";
import {
  MetricType,
  useGetAllTrackedFunctionsQuery,
  usePrometheusQueryRangeQuery,
  usePrometheusQueryQuery,
  useLazyGetAllSourceLocationsQuery,
} from "../../../../api";
import {
  createErrorRatioQuery,
  createLatencyQuery,
  createRequestCountQuery,
  createRequestRateQuery,
  createErrorCountQuery,
  createVersionQuery,
} from "../data";
import { selectActivePrometheus } from "../../../../selectors";
import { PrometheusQueryResponse } from "../../../../schemas";
import { useCallGraph } from "./useCallGraph";
import {
  useScrapeIntervalAsBuildInfoInterval,
  useSourceCodeLinkSettings,
} from "../../../../hooks";
import { useFunctionPageParams } from "./useFunctionPageParams";

type TimeRangeArguments = {
  from: string;
  to: string;
};

export type DetailMetric = {
  reportType: MetricReportType;
  unit?: string;
  value?: string;
};

export type FunctionDetailsMetricPrometheusData = ReturnType<
  typeof usePrometheusQueryRangeQuery
> & {
  metrics: Array<DetailMetric>;
};

type FunctionDataType = {
  functionName: string;
  moduleName?: string;
  serviceName?: string;
  sourceCodeLinks: ReturnType<typeof useLazyGetAllSourceLocationsQuery>[1];
  prometheusData: {
    [key in MetricType]: FunctionDetailsMetricPrometheusData;
  };
  events?: Array<ProviderEvent>;
  callGraphData: ReturnType<typeof useCallGraph>;
  buildInfoInterval?: string;
};

/**
 * Hook to fetch Prometheus data based on a given time range. It checks the
 * current route to find the function name and module name, and then fetches
 * the data for that function.
 */
export function usePrometheusData(
  timeRange: TimeRangeArguments,
  forceIgnoreModule = false
) {
  // NOTE - Module might be undefined because of the way we're using the router
  const {
    name: functionName,
    module: moduleName,
    service: serviceName,
  } = useParams();

  const environment = useSelector(selectActivePrometheus);

  const { buildInfoInterval, shouldWaitForBuildInfoInterval } =
    useScrapeIntervalAsBuildInfoInterval(environment?.url);

  const [fetchSourceCodeLinks, sourceCodeLinks] =
    useLazyGetAllSourceLocationsQuery({
      selectFromResult: ({ data, ...rest }) => {
        // Regex pattern to remove the first part of a module/function name definition
        // This is because module name and function names as specified by the am list
        // endpoint don't always match with what the autometrics libraries report
        const regexPattern = /^.*?(::|\.)/;
        const simplifiedModule = moduleName?.replace(regexPattern, "");

        return {
          ...rest,
          data: data
            // This function returns null if there's no match
            // and otherwise returns the entry with definition
            // It also returns a "cleaned up" module name (sometimes the full path is in the module name)
            ?.map((entry) => {
              const { id, ...rest } = entry;
              // There's sometimes an issue where the module name contains the full path
              const cleanedUpModule = id.module.startsWith(rest.path)
                ? id.module.slice(rest.path.length)
                : id.module;

              const moduleMatches =
                moduleName === undefined ||
                cleanedUpModule === moduleName ||
                cleanedUpModule === simplifiedModule ||
                cleanedUpModule.replace(regexPattern, "") === moduleName;
              const functionMatches =
                id.function === functionName ||
                (moduleName &&
                  id.function.replace(regexPattern, "") === functionName);

              const valid = moduleMatches && functionMatches;

              if (valid) {
                return {
                  ...rest,
                  id: {
                    ...id,
                    // Return updated module
                    module: cleanedUpModule,
                  },
                };
              }

              return null;
            })
            .filter(Boolean),
        };
      },
    });

  const { value: linkSettings } = useSourceCodeLinkSettings();
  useEffect(() => {
    // Only AM is injecting a prometheus URL into the DOM
    // So if this value is set, we know explorer is hosted
    // by AM
    if (linkSettings !== "off") {
      fetchSourceCodeLinks({});
    }
  }, [fetchSourceCodeLinks, linkSettings]);

  const { data: matchedData } = useGetAllTrackedFunctionsQuery(
    environment?.url,
    {
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data: forceIgnoreModule
          ? { name: functionName, module: undefined }
          : data?.find((entry) =>
              moduleName === undefined
                ? entry.name === functionName
                : entry.module === moduleName && entry.name === functionName
            ),
      }),
    }
  );

  const matchedFunctionName = matchedData?.name ?? functionName ?? "";
  const matchedModuleName = matchedData?.module ?? moduleName;

  const promqlRequestRateQuery = createRequestRateQuery({
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
    buildInfoInterval,
  });
  const queriedRequestRate = usePrometheusQueryRangeQuery(
    {
      environmentUrl: environment?.url,
      query: promqlRequestRateQuery,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
      // HACK - to prevent double-queries, we skip the query if we haven't determined the scrape interval yet
      skip: shouldWaitForBuildInfoInterval,
    }
  );

  const promqlErrorRatioQuery = createErrorRatioQuery({
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
    buildInfoInterval,
  });
  const queriedErrorRatio = usePrometheusQueryRangeQuery(
    {
      environmentUrl: environment?.url,
      query: promqlErrorRatioQuery,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
      // HACK - to prevent double-queries, we skip the query if we haven't determined the scrape interval yet
      skip: shouldWaitForBuildInfoInterval,
    }
  );

  const promqlLatencyQuery = createLatencyQuery({
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
    buildInfoInterval,
  });
  const queriedLatency = usePrometheusQueryRangeQuery(
    {
      environmentUrl: environment?.url,
      query: promqlLatencyQuery,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
      // HACK - to prevent double-queries, we skip the query if we haven't determined the scrape interval yet
      skip: shouldWaitForBuildInfoInterval,
    }
  );

  const promqlRequestCountQuery = createRequestCountQuery({
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
    timeRange,
  });
  const requestCountQuery = usePrometheusQueryQuery(
    {
      environmentUrl: environment?.url,
      query: promqlRequestCountQuery,
      time: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
    }
  );

  const promqlErrorCountQuery = createErrorCountQuery({
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
    timeRange,
  });
  const errorCountQuery = usePrometheusQueryQuery(
    {
      environmentUrl: environment?.url,
      query: promqlErrorCountQuery,
      time: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
    }
  );

  const { metricType } = useFunctionPageParams();
  const callGraphData = useCallGraph({
    environmentUrl: environment?.url,
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
    metricType,
    timeRange,
  });

  const promqlVersionQuery = createVersionQuery({
    functionName: matchedFunctionName,
    moduleName: matchedModuleName,
  });

  const queriedVersion = usePrometheusQueryRangeQuery(
    {
      environmentUrl: environment?.url,
      query: promqlVersionQuery,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: versions, isSuccess: eventsReady } = queriedVersion;
  const events = useMemo(() => {
    if (eventsReady === false) {
      return;
    }

    return versions
      ?.map((series: Timeseries): ProviderEvent | undefined => {
        const { metrics, ...info } = series;
        const time = metrics[0]?.time;

        if (!time || time < timeRange.from) {
          return undefined;
        }

        return {
          time,
          attributes: info.attributes,
          labels: info.labels,
          resource: info.resource,
          title: info.name,
        };
      })
      .filter(
        (item: ProviderEvent | undefined): item is ProviderEvent =>
          item !== undefined
      );
  }, [versions, timeRange.from, eventsReady]);

  // FIXME: a bit of a dirty way adding some additional data to the cards
  const functionData: FunctionDataType = {
    functionName: matchedFunctionName,
    moduleName,
    serviceName,
    sourceCodeLinks,
    prometheusData: {
      requestRate: {
        ...queriedRequestRate,
        promqlQuery: promqlRequestRateQuery,
        metrics: [
          {
            reportType: "latest",
            unit: "calls/sec",
            value: getLatestValue(queriedRequestRate.data),
          },
          {
            reportType: "sum",
            unit: "calls",
            value: getSumOfAllCalls(requestCountQuery.data),
          },
        ],
      },
      errorRatio: {
        ...queriedErrorRatio,
        promqlQuery: promqlErrorRatioQuery,
        metrics: [
          {
            reportType: "latest",
            unit: undefined,
            value: getLatestValueAsPercentage(queriedErrorRatio.data),
          },
          {
            reportType: "sum",
            unit: "errors",
            value: getSumOfAllCalls(errorCountQuery.data),
          },
        ],
      },
      latency: {
        ...queriedLatency,
        promqlQuery: promqlLatencyQuery,
        metrics: [
          {
            reportType: "latest",
            unit: "seconds",
            value: getLatestSecondsValue(queriedLatency.data),
          },
        ],
      },
    },
    events,
    callGraphData,
    buildInfoInterval,
  };

  return functionData;
}

function getLatestValueFromData(data?: Array<Timeseries>) {
  const [metricsData] = data ?? [];
  const metrics = metricsData?.metrics;

  return metrics?.[metrics.length - 1]?.value;
}

function getLatestValue(
  data?: Array<Timeseries>,
  maximumFractionDigits: number = 2
) {
  const value = getLatestValueFromData(data);
  if (value) {
    return value.toLocaleString(undefined, { maximumFractionDigits });
  }
}

function getLatestSecondsValue(data?: Array<Timeseries>) {
  const value = getLatestValueFromData(data);
  if (!value) {
    return;
  }

  // NOTE - We want to show:
  //        * 3 decimal places for values < 1,
  //        * 2 decimal places for values between 1 and 10,
  //        * 1 decimal place for values > 10
  //
  let maximumFractionDigits = 2;
  if (value < 1) {
    maximumFractionDigits = 3;
  } else if (value > 10) {
    maximumFractionDigits = 1;
  }

  return value.toLocaleString(undefined, {
    maximumFractionDigits,
  });
}

function getLatestValueAsPercentage(data?: Array<Timeseries>) {
  const value = getLatestValueFromData(data);
  if (value) {
    return `${(value * 100).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}%`;
  }
}

function getSumOfAllCalls(data?: PrometheusQueryResponse) {
  const value = data?.data?.result?.[0]?.value?.[1] ?? "NaN";
  const numericValue = typeof value === "string" && Number.parseFloat(value);
  if (numericValue) {
    return Math.round(numericValue).toLocaleString();
  }
}
