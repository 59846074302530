import { TimeRange } from "@fiberplane/charts";

import {
  EnvironmentUrl,
  MetricType,
  usePrometheusQueryQuery,
} from "../../../../api";
import { Prometheus } from "../../../../services";

export function useCallGraph(options: {
  functionName: string;
  moduleName?: string;
  metricType: Omit<MetricType, "latency">;
  environmentUrl?: EnvironmentUrl;
  timeRange: TimeRange;
}) {
  const { functionName, moduleName, metricType, environmentUrl, timeRange } =
    options;

  const interval = Prometheus.getPrometheusWindowFromTimeRange(
    timeRange.from,
    timeRange.to
  );

  let query =
    metricType === "requestRate"
      ? `
# Get the call count of all functions called by the given function
sum(
            rate({
              __name__=~"function_calls(_count)?(_total)?",
              function!="",
              module!="",
              caller_function=~"${functionName}",
              caller_module=~"${moduleName}",
              # Range below is the total time (i.e. the past X minutes/hours/etc)
            }[${interval}])
) by (function, module) > 0`
      : `
    # Get the error ratio
    (
      sum by (function, module) (
        increase({
          __name__=~"function_calls(_count)?(_total)?",
          result="error",
          function!="",
          module!="",
          caller_function=~"${functionName}",
          caller_module=~"${moduleName}",
        }[${interval}])
        )
        or
        0
        * group by (function, module) (
          present_over_time({
              __name__=~"function_calls(_count)?(_total)?",
              function!="",
              module!="",
              caller_function=~"${functionName}",
              caller_module=~"${moduleName}",
            }[${interval}]
          )
      )
    )
    /
    (
      sum by (function, module) (
        increase({
          __name__=~"function_calls(_count)?(_total)?",
          function!="",
          module!="",
          caller_function=~"${functionName}",
          caller_module=~"${moduleName}",
        }[${interval}])
      ) > 0
    )
    * 100`;

  const outgoingQuery = usePrometheusQueryQuery({
    environmentUrl,
    query,
    time: timeRange.to,
  });

  query =
    metricType === "requestRate"
      ? `
# Get the call count of all functions called by the given function
sum(
            rate({
              __name__=~"function_calls(_count)?(_total)?",
              caller_function!="",
              module!="",
              function=~"${functionName}",
              module=~"${moduleName}",
            # Range below is the total time (i.e. the past X minutes/hours/etc)
            }[${interval}])
) by (caller_function, caller_module) > 0`
      : `
# Get the other error ratio
(
  sum by (caller_function, caller_module) (
    increase({
      __name__=~"function_calls(_count)?(_total)?",
      result="error",
      caller_function!="",
      caller_module!="",
      function=~"${functionName}",
      module=~"${moduleName}",
    }[${interval}])
  )
or
0
* group by (caller_function, caller_module) (
    present_over_time({
      __name__=~"function_calls(_count)?(_total)?",
      caller_function!="",
      caller_module!="",
      function=~"${functionName}",
      module=~"${moduleName}",
    }[${interval}])
  )
)
/
(
  sum by (caller_function, caller_module) (
    increase({
      __name__=~"function_calls(_count)?(_total)?",
      caller_function!="",
      caller_module!="",
      function=~"${functionName}",
      module=~"${moduleName}",
    }[${interval}])
  ) > 0
)
* 100`;

  const incomingQuery = usePrometheusQueryQuery({
    environmentUrl,
    query,
    time: timeRange.to,
  });

  return {
    outgoingQuery,
    incomingQuery,
  };
}
