import { MouseInteractionState } from "@fiberplane/charts";
import styled, { css } from "styled-components";

type Props = {
  width: number;
  mouseInteraction: MouseInteractionState;
};

export function ZoomBar({
  width,
  mouseInteraction,
}: Props): JSX.Element | null {
  if (mouseInteraction.type !== "zoom") {
    return null;
  }

  const { start, end } = mouseInteraction;
  if (end === undefined) {
    return null;
  }

  // The value below is in the range [0, 1]
  const x = Math.min(start, end);
  // The value below is in the range [0, 1]
  const barWidth = Math.max(start, end) - x;

  return (
    <Rect
      style={{
        left: `${x * width}px`,
        width: `${barWidth * width}px`,
      }}
    />
  );
}

const Rect = styled.div(
  ({ theme }) => css`
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    background: ${theme.color.bg.emphasis["primary-subtle"]};
    height: 100%;
    border-left: 1px dashed ${theme.color.fg.primary};
    border-right: 1px dashed ${theme.color.fg.primary};
  `
);
