import { z } from "zod";

// Schemas for queries from Prometheus

const MetricValue = z
  .object({
    // Labels in result set
    metric: z.object({}).passthrough(),
    // Values in result set [timestamp, value]
    value: z.tuple([z.number(), z.string()]),
  })
  .passthrough();

/**
 * Schema for a response from the Prometheus `/query` endpoint
 */
export const PrometheusQueryResponseSchema = z.object({
  status: z.literal("success"),
  data: z.object({
    result: z.array(MetricValue),
    resultType: z.string(),
  }),
});

export type PrometheusQueryResponse = z.infer<
  typeof PrometheusQueryResponseSchema
>;
