import { Command } from "cmdk";
import styled, { DefaultTheme, css } from "styled-components";

import { Icon } from "../UI";

export const StyledCommandGroup = styled(Command.Group)(
  ({ theme }) =>
    css`
      [cmdk-group-heading] {
        font: ${theme.font.body.sm.medium};
        color: ${theme.color.fg.subtle};
        padding: 6px 24px;
      }
    `
);

export const CommandGroupHeading = styled.header`
  display: flex;
  gap: 8px;
`;

export const StyledCommandItem = styled(Command.Item)(
  ({ theme }) =>
    css`
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      gap: 0 12px;
      padding: 6px;
      margin: 0 16px;
      border-radius: ${theme.radius.rounded};
      cursor: pointer;
      color: ${theme.color.fg.default};
      font: ${theme.font.body.md.regular};

      &[data-selected] {
        background: ${theme.color.primary.grape.alpha[50]};
      }
    `
);

export const CommandIcon = styled(Icon)`
  grid-column: 1/ 2;
  grid-row: 1 / 3;
  width: 20px;
  height: 20px;
`;

export const CommandItemName = styled.span`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CommandItemDescription = styled.span(
  ({ theme }) =>
    css`
      color: ${theme.color.fg.muted};
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    `
);

export const CommandItemInfo = styled.span`
  grid-column: 3 / 4;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CommandShortcut = styled.span`
  display: flex;
  gap: 4px;
`;

export const CommandShortcutKey = styled.span(
  ({ theme }) =>
    css`
      display: flex;
      min-width: 28px;
      height: 28px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 6px;
      background: ${theme.color.bg.subtle};
    `
);

export const ExperimentalLabel = styled.span(
  ({ theme }) =>
    css`
      display: flex;
      height: 20px;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: ${theme.radius.minimal};
      background: linear-gradient(
        90deg,
        ${theme.color.accent.mustard["500"]} 0%,
        ${theme.color.accent.magenta["500"]} 52.08%,
        ${theme.color.primary.grape["600"]} 100%
      );
      text-align: center;
      color: ${theme.color.fg.onemphasis.default};
      font: ${theme.font.headings.h6};
    `
);

export const StyledInputMixin = ({ theme }: { theme: DefaultTheme }) =>
  css`
    padding: 0;
    border: none;
    border-bottom: 1px solid ${theme.color.border.default};
    background: ${theme.color.bg.default};
    color: ${theme.color.fg.default};
    flex: 1;
    font: ${theme.font.body.l.regular};
    outline: none;
    border: none;

    ::placeholder {
      color: ${theme.color.fg.muted};
    }
  `;
