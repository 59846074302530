import { useAccentColors } from "./useAccentColors";
import { getSafeIndex } from "./utils";

/**
 * Hook that returns a function that returns an accent color for a given index
 * based on theme values.
 * @param type
 */
export function useAccentColorForIndex(type: "bg" | "fg" = "bg") {
  const accentColors = useAccentColors(type);

  const getAccentColorForIndex = (index: number) => {
    const safeIndex = getSafeIndex(index, accentColors.length);
    return accentColors[safeIndex]!;
  };

  return { getAccentColorForIndex };
}
