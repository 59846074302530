import styled from "styled-components";

import { Objective } from "../../schemas";
import {
  formatPercentageWithSignificantDigits,
  getIsActualFunctionValueViolatingTarget,
  getTargetLatencyBare,
} from "../../utils";

export const SloValue = ({
  currentValue,
  objective,
  highlightSuccess = false,
}: {
  currentValue: string | null;
  objective: Objective;
  highlightSuccess?: boolean;
}) => {
  if (!currentValue || !objective.target) {
    return (
      <WarnableSpan highlightSuccess={false} warn={false}>
        &mdash;
      </WarnableSpan>
    );
  }

  const shouldWarn = getIsActualFunctionValueViolatingTarget(
    objective,
    currentValue
  );

  if (objective.metric === "successRate") {
    return (
      <WarnableSpan highlightSuccess={highlightSuccess} warn={shouldWarn}>
        {formatPercentageWithSignificantDigits(currentValue)}
      </WarnableSpan>
    );
  }

  return (
    <WarnableSpan highlightSuccess={highlightSuccess} warn={shouldWarn}>
      {formatPercentageWithSignificantDigits(currentValue)} &lt;{" "}
      {getTargetLatencyBare(objective.target)}
    </WarnableSpan>
  );
};

const WarnableSpan = styled.span<{ highlightSuccess: boolean; warn: boolean }>`
  color: ${({ theme, highlightSuccess, warn }) =>
    warn
      ? theme.color.fg.danger
      : highlightSuccess
      ? theme.color.fg.success
      : "currentColor"};
`;
