import { useMemo } from "react";
import { useTheme } from "styled-components";
import type { ChartTheme } from "@fiberplane/charts";

import { useGraphLineColors } from "./colors";

export function useChartTheme(color: string) {
  const theme = useTheme();
  const shapeListColors = useGraphLineColors(color);

  // HACK (Oscar): temporary solution for getting the fontAxis values
  // programatically from the theme shorthand.
  const fontAxis = useMemo(() => {
    const bodyElement = window.getComputedStyle(document.body);

    const removeVariableFunction = /var\((.*?)\)/g;
    const variable = theme.font.body.xs.regular.replace(
      removeVariableFunction,
      "$1"
    );

    const variableValue = bodyElement.getPropertyValue(variable);
    const font = variableValue.replace(/["']+/g, "");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [axisFontWeight, axisFontSizeMaybeWithSlash, _, axisFontFamily] = font
      .split(" ")
      .filter((x) => x !== "/");

    // HACK - In production, the space is stripped from the font declaration, so instead of
    //        "10px / 16px" we get "10px/16px". This is a temporary solution for this issue.
    const axisFontSize =
      (axisFontSizeMaybeWithSlash ?? "").split("/")[0] ?? undefined;

    return {
      axisFontFamily,
      axisFontSize,
      axisFontWeight,
    };
  }, [theme.font.body.xs.regular]);

  const chartTheme: ChartTheme = useMemo(
    () => ({
      ...(fontAxis
        ? {
            axisFontFamily: fontAxis.axisFontFamily,
            axisFontSize: fontAxis.axisFontSize,
            axisFontWeight: fontAxis.axisFontWeight,
          }
        : {}),
      axisColor: theme.color.fg.muted,
      eventColor: color,
      expandableGradientColor: theme.color.bg.default,
      gridStrokeColor: theme.color.border.muted,
      legendItemBorderRadius: theme.radius.default,
      legendItemCheckboxBorderRadius: theme.radius.minimal,
      legendItemCheckboxColor: theme.color.neutral.white,
      legendItemColor: theme.color.fg.muted,
      legendItemEmphasisBackgroundColor: theme.color.bg.hover,
      legendItemEmphasisBorderRadius: theme.radius.default,
      legendItemEmphasisColor: theme.color.fg.default,
      legendItemEmphasisFont: theme.font.body.xs.medium,
      legendItemFont: theme.font.body.sm.regular,
      legendItemOnHoverBackgroundColor: theme.color.bg.subtle,
      legendItemOnHoverColor: theme.color.fg.default,
      shapeListColors,
      targetLatencyColor: theme.color.fg.success,
    }),
    [
      color,
      fontAxis,
      shapeListColors,
      theme.color.bg.default,
      theme.color.bg.hover,
      theme.color.bg.subtle,
      theme.color.border.muted,
      theme.color.fg.default,
      theme.color.fg.muted,
      theme.color.fg.success,
      theme.color.neutral.white,
      theme.font.body.sm.regular,
      theme.font.body.xs.medium,
      theme.radius.default,
      theme.radius.minimal,
    ]
  );

  return chartTheme;
}
