import { Timeseries } from "@fiberplane/prometheus-query";

import { notifications } from "../../services";

export async function notify(
  data: Array<Timeseries>,
  recentAlerts: Array<string>,
  startTime: string
): Promise<Array<string>> {
  const allowed = window.Notification.permission === "granted";
  if (allowed === false) {
    return [];
  }

  const active = await notifications.getServiceWorker();
  if (!active) {
    return [];
  }

  const newRecentAlerts: Array<string> = [];

  const newAlerts = data
    .map((alert): notifications.CoreAlertInfo => {
      const { state, lastseen, ...labels } = alert.labels;

      return {
        name: alert.name,
        labels,
        attributes: alert.attributes,
      };
    })
    .filter((alert) => {
      // Our poor mans deduplication is using serialized alerts
      // to find out whether we've already notified the user or not
      const serialized = JSON.stringify(alert);
      newRecentAlerts.push(serialized);
      return recentAlerts.includes(serialized) === false;
    });

  if (newAlerts.length > 1) {
    active.postMessage({
      type: "MULTIPLE_NOTIFICATIONS",
      payload: {
        alertCount: newAlerts.length,
        timestamp: startTime,
      },
    });
  } else {
    for (const alert of newAlerts) {
      active.postMessage({
        type: "NOTIFICATION",
        payload: {
          alert,
          timestamp: startTime,
        },
      });
    }
  }

  return newRecentAlerts;
}

export function getRecentTimeRange(start?: string) {
  const to = new Date();
  const from = start || new Date(to.getTime() - 1000 * 5).toISOString();
  return {
    from,
    to: to.toISOString(),
  };
}
