function simplifyFormattedNumber(value: string): string {
  return value.replace(/\.?0+$/, "").replace(/\.$/, "");
}

export function formatNumber(value: number) {
  // Check if the value is greater than or equal to 100
  if (value >= 100) {
    // Limit the value of digits after the decimal point to 2
    return simplifyFormattedNumber(value.toFixed(2));
  }

  if (value >= 10) {
    // Limit the value of digits after the decimal point to 2
    return simplifyFormattedNumber(value.toFixed(4));
  }

  if (value >= 1) {
    // Limit the value of digits after the decimal point to 2
    return simplifyFormattedNumber(value.toFixed(6));
  }

  return simplifyFormattedNumber(value.toFixed(7));
}
