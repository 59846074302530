import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { getFunctionPath } from "../../../../utils";
import { Icon } from "../../../UI";
import { useFunctionPageParams } from "../hooks";

export const NodeLabel = (props: {
  name: string;
  module?: string;
  service?: string;
  x: number;
  y: number;
  width: number;
  height: number;
  align: "start" | "end";
  value: string;
}) => {
  const { y, width, height, name, module, align, value } = props;
  const x = Number.isNaN(props.x) ? 20 : props.x;
  const { metricType, searchParams } = useFunctionPageParams();

  const generateUrl = (name: string, module?: string, service?: string) => {
    return {
      pathname: getFunctionPath(name, module, service, metricType),
      search: searchParams.toString(),
    };
  };

  return (
    <foreignObject x={x} y={y} width={width} height={height}>
      <Container>
        <Label to={generateUrl(name, module)} aria-label={`${value}`}>
          <TextSection>
            <Text $align={align}>
              <strong>{name}</strong>
            </Text>
            <Text $align={align}>{module || null}</Text>
          </TextSection>
          <Icon type="link_out" />
        </Label>
      </Container>
    </foreignObject>
  );
};

const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const Label = styled(Link)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 20px;
    align-items: center;
    gap: 8px;
    color: ${theme.color.fg.default};
    cursor: pointer;
    background-color: ${theme.color.bg.hover};

    padding: 4px 8px;
    border-radius: 8px;
    min-width: 0;

    &:hover {
      color: ${theme.color.fg.default};
      background-color: ${theme.color.bg.elevated.hover};
    }
  `
);

const TextSection = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.3em;
`;

const Text = styled.div<{ $align: "start" | "end" }>(
  ({ theme, $align }) => css`
    fill: ${theme.color.fg.default};
    font: ${theme.font.body.sm.regular};
    text-align: ${$align};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
);
