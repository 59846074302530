import { useEffect } from "react";
import { useNavigate, type NavigateOptions, type To } from "react-router-dom";

type RedirectProps = {
  to: To;
  options?: NavigateOptions;
};

/**
 * To be used in a <Route> component to redirect to another route.
 */
export function Redirect({ to, options }: RedirectProps) {
  const redirect = useNavigate();

  useEffect(() => {
    redirect(to, options);
  }, [options, redirect, to]);

  return null;
}
