import { useSelector } from "react-redux";

import { selectActivePrometheus } from "../../../selectors";
import { usePrometheusQueryRangeQuery } from "../../../api";
import { useTimeRange } from "../../../hooks";
import { EventTimelineContent } from "./EventTimelineContent";
import { Loading } from "../../FallbackStates";
import { useAlertsQuery } from "../../Alerts";

const INVALID_LABELS = new Set(["lastseen", "state"]);

export function EventTimeline() {
  const environment = useSelector(selectActivePrometheus);

  const { timeRange } = useTimeRange();

  const { data: alerts } = usePrometheusQueryRangeQuery({
    environmentUrl: environment?.url,
    query: "ALERTS",
    start: timeRange.from,
    end: timeRange.to,
  });

  const {
    result: { data: groupedAlerts },
  } = useAlertsQuery(timeRange);

  // TODO: handle loading data gracefully
  if (!alerts || !groupedAlerts) {
    return <Loading />;
  }

  const mappedAlerts = alerts.map((detailedAlert) => {
    const matchingAlerts: Array<number> = [];
    for (const [index, alert] of groupedAlerts.entries()) {
      const hasMismatchedLabel = Object.entries(alert.labels)
        .filter(([key]) => INVALID_LABELS.has(key) === false)
        .some(([key, value]) => detailedAlert.labels[key] !== value);

      if (hasMismatchedLabel === false) {
        matchingAlerts.push(index);
      }
    }

    return {
      alert: detailedAlert,
      matchingIndex: matchingAlerts[0] ?? -1,
    };
  });

  return <EventTimelineContent data={mappedAlerts} />;
}
