/**
 * This is the rectangle that the graph lines are drawn to/from
 */
export const NODE_WIDTH = 10;

/**
 * This is the value that we use to calculate the initial height of the graph
 * The basic math is incoming nodes amount * this value (and also for the outgoing nodes amount)
 * The label size can be set higher, but this is used to determine the "height" of the lines. Setting this
 * to the height of the LABEL_HEIGHT can make the graph very tall when there's a large difference between
 * the amounts related to each node (i.e. one node has 1 and the others have 0)
 */
export const TYPICAL_NODE_HEIGHT = 25;

/**
 * This is the minimum height that a node can be. Even if the
 * label is higher than this, the node (rectangle) will be this height
 */
export const MINIMAL_NODE_HEIGHT = 5;

/**
 * This is the minimal spacing we want between nodes (and labels)
 */
export const PADDING = 7;

/**
 * The space we reserve for the labels on the left
 */
export const LEFT = 200;

/**
 * The space we reserve for the labels
 */
export const LABEL_HEIGHT = 48;

/**
 * The padding we reserve at the top of the graph
 * This helps with some lines around labels etc not getting cut off
 */
export const TOP = 2;
