import styled, { css } from "styled-components";

import type { MetricReportType } from "./common";

type MetricDisplayProps = {
  className?: string;
  rate?: number | string;
  report?: MetricReportType;
  unit?: string;
};

export function MetricDisplay({
  className,
  rate,
  report,
  unit,
}: MetricDisplayProps) {
  return (
    <MetricDisplayContainer className={className}>
      {rate || rate === 0 ? (
        <MetricRate>{rate}</MetricRate>
      ) : (
        <MetricRate isEmpty>&mdash;</MetricRate>
      )}
      {unit && <MetricUnit>{unit}</MetricUnit>}
      {report && (
        <MetricLatest>
          {report[0]?.toUpperCase() + report.slice(1)}
        </MetricLatest>
      )}
    </MetricDisplayContainer>
  );
}

const MetricDisplayContainer = styled.div`
  display: grid;
  grid:
    "rate unit" 1fr
    "latest latest" auto / auto 1fr;
  gap: 8px;
`;

const MetricRate = styled.p<{ isEmpty?: boolean }>(
  ({ isEmpty, theme }) => css`
    grid-area: rate;
    align-self: end;
    font: ${theme.font.body.xl.medium};
    line-height: 1;

    ${isEmpty &&
    css`
      opacity: 0.6;
    `}
  `
);

const MetricLatest = styled.p(
  ({ theme }) => css`
    grid-area: latest;
    align-self: end;
    font: ${theme.font.body.sm.medium};
    line-height: 1;
    margin: 0;
    color: var(--text-alternative-color);
  `
);

const MetricUnit = styled(MetricLatest)`
  grid-area: unit;
  align-self: end;
`;
