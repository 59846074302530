import { Row, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { css, useTheme } from "styled-components";
import { Timeseries } from "@fiberplane/charts";

import { PrometheusAlertingRuleGroup } from "../../schemas";
import { getObjectivePath } from "../../utils";
import { FadeIn } from "../Animations";
import { DataTable, TableLinkOut } from "../DataTable";
import { PrometheusError } from "../FallbackStates";
import {
  LabelWithColor,
  PageContainer,
  PageDescription,
  PageHeader,
  PageHeading,
} from "../UI";
import { NoAlertsFound } from "./NoAlertsFound";
import { useHandler, useTimeRange } from "../../hooks";
import { useAlertsQuery } from "./hooks";
import { AlertDetails } from "./AlertDetails";

const EMPTY_ALERTS_LIST: Timeseries[] = [];

const columnHelper = createColumnHelper<Timeseries>();

export const Alerts = function Alerts() {
  const [currentSearchParams] = useSearchParams();
  const { timeRange } = useTimeRange();
  const { result: activeAlertsQuery, step } = useAlertsQuery(timeRange);

  const columns = useMemo(
    () => [
      columnHelper.accessor("labels.state", {
        cell: (cellContext) => (
          <StatusLabel
            state={
              cellContext.row.original.labels?.state === "firing"
                ? "firing"
                : "inactive"
            }
          />
        ),
        header: "Status",
      }),
      columnHelper.accessor("labels.lastseen", {
        cell: (cellContext) => {
          const value = cellContext.getValue() || "";
          if (value === "") {
            return value;
          }

          return value === "still firing" ? (
            <WarnableDateSpan>{value}</WarnableDateSpan>
          ) : (
            value
          );
        },
        header: `Last seen (± ${step})`,
      }),
      columnHelper.accessor("labels.severity", {
        cell: (cellContext) => cellContext.getValue() || "",
        header: "Severity",
      }),
      columnHelper.accessor("labels.alertname", {
        cell: (cellContext) => cellContext.getValue() || "",
        header: "Name",
      }),
      columnHelper.accessor("labels.objective_name", {
        cell: (cellContext) => {
          const { sloth_id } = cellContext.row.original.labels;
          const objective_name = cellContext.getValue();
          if (sloth_id && objective_name) {
            const metric = sloth_id.includes("latency")
              ? "latency"
              : "successRate";

            return (
              <TableLinkOut
                to={{
                  pathname: getObjectivePath({
                    metric,
                    name: objective_name,
                  }),
                  search: currentSearchParams.toString(),
                }}
              >
                {objective_name}
              </TableLinkOut>
            );
          }
        },
        header: "SLO",
      }),
    ],
    [currentSearchParams, step]
  );

  const {
    data: alerts = EMPTY_ALERTS_LIST,
    error: alertsError,
    isLoading: isAlertsLoading,
    isUninitialized: isAlertsUninitialized,
  } = activeAlertsQuery;

  const isEmpty =
    !isAlertsUninitialized &&
    !isAlertsLoading &&
    alertsError &&
    alerts.length === 0;

  const renderExpanded = useHandler((data: Row<Timeseries>) => {
    return <AlertDetails data={data} />;
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageHeading>Alerts</PageHeading>
        <PageDescription>
          Below you can see the alerts that have fired in the following time
          range:{" "}
          {"relativeTimeRangeLabel" in timeRange
            ? timeRange.relativeTimeRangeLabel?.toLowerCase()
            : `${timeRange.from} - ${timeRange.to}`}
          . Read more about alerts in the{" "}
          <a
            href="https://docs.autometrics.dev/features#alerts-and-slos-without-yaml"
            target="_blank"
            rel="noreferrer noopener"
          >
            documentation
          </a>
          .
        </PageDescription>
      </PageHeader>
      {alertsError ? (
        <FadeIn key="alertsPageError">
          <PrometheusError />
        </FadeIn>
      ) : isEmpty ? (
        <FadeIn key="alertsPageEmptyState">
          <NoAlertsFound />
        </FadeIn>
      ) : (
        <FadeIn key="alertsPageList">
          <DataTable
            columns={columns}
            data={alerts}
            isLoading={isAlertsLoading}
            renderExpanded={renderExpanded}
          />
        </FadeIn>
      )}
      {/* <PageHeader> */}
      {/*   <PageHeading>Rules</PageHeading> */}
      {/* </PageHeader> */}
      {/* {isRulesUninitialized ? null : isRulesFetching ? ( */}
      {/*   <FadeIn key="rulesPageIsLoading"> */}
      {/*     <Loading /> */}
      {/*   </FadeIn> */}
      {/* ) : rulesError ? ( */}
      {/*   <FadeIn key="rulesPageError"> */}
      {/*     <PrometheusError /> */}
      {/*   </FadeIn> */}
      {/* ) : ( */}
      {/*   <FadeIn key="rulesPageEmptyState"> */}
      {/*     <pre>{JSON.stringify(rules, null, 2)}</pre> */}
      {/*   </FadeIn> */}
      {/* )} */}
    </PageContainer>
  );
};

function StatusLabel({
  state,
}: {
  state: PrometheusAlertingRuleGroup["rules"][0]["state"];
}) {
  const theme = useTheme();

  if (state === "inactive") {
    return (
      <LabelWithColor color={theme.color.neutral[400]}>Inactive</LabelWithColor>
    );
  }

  if (state === "firing") {
    return (
      <LabelWithColor color={theme.color.fg.danger}>Firing</LabelWithColor>
    );
  }

  return null;
}

const WarnableDateSpan = styled.span(
  ({ theme }) =>
    css`
      color: ${theme.color.fg.danger};
    `
);
