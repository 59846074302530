import styled, { css } from "styled-components";
import { Timeseries } from "@fiberplane/charts";
import { top } from "@popperjs/core";

import { ELEMENT_HEIGHT } from "../constants";
import { GraphTooltip } from "../Tooltips";
import { BlockData, formatLabel } from "../utils";
import { DatePickerTimeRange, secondsToTimestamp } from "../../../../utils";
import { TooltipState } from "../../../../state";

type Props = {
  x: number;
  width: number;
  data: Pick<Timeseries, "labels">;
  showTooltip: (tooltip: TooltipState | null) => void;
  navigateToAlert: (timeRange?: DatePickerTimeRange) => void;
  stepSize: number;
} & BlockData;

export function Block(props: Props) {
  const { navigateToAlert, x, width, data, start, end, showTooltip, stepSize } =
    props;

  const from = secondsToTimestamp(start);
  const to = secondsToTimestamp(end);
  return (
    <Content
      style={{
        transform: `translateX(${x}px)`,
        width,
      }}
      onClick={() => {
        const options =
          end - start > 0
            ? {
                from,
                to,
              }
            : {
                from: secondsToTimestamp(start - 0.5 * stepSize),
                to: secondsToTimestamp(end + 0.5 * stepSize),
              };
        navigateToAlert(options);
      }}
      onMouseOver={(event: React.PointerEvent<HTMLDivElement>) => {
        const { currentTarget: target } = event;

        const anchor = () => target;
        const content = () => (
          <GraphTooltip
            state="firing"
            name={formatLabel(data)}
            from={from}
            to={to}
          />
        );
        const options = {
          placement: top,
        };

        showTooltip({
          anchor,
          content,
          options,
        });
      }}
      onMouseLeave={() => {
        showTooltip(null);
      }}
    >
      <BlockText>Firing</BlockText>
    </Content>
  );
}

const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    height: ${ELEMENT_HEIGHT}px;
    border-radius: ${theme.radius.default};
    background: ${theme.color.timeline.firing.bg.default};
    color: ${theme.color.timeline.firing.fg};
    font: ${theme.font.headings.h6};
    overflow: hidden;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: ${theme.color.timeline.firing.bg.hover};
    }
  `
);

const BlockText = styled.div`
  flex: 0 0 max-content;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 4px;
`;
