import {
  MouseInteractionState,
  TimeRange,
  getMaxXTickValue,
  getTicks,
  getTimeFormatterForAxis,
  getXAxisFromTimeRange,
  useScales,
} from "@fiberplane/charts";
import { useMemo } from "react";

import { TICK_LABEL_WIDTH } from "../constants";
import { useHandler } from "../../../../hooks";

export function useTimeTicks({
  width,
  height,
  mouseInteraction,
  from,
  to,
}: TimeRange & {
  width: number;
  height: number;
  mouseInteraction: MouseInteractionState;
}) {
  const scales = useScales({ xMax: width, yMax: height }, mouseInteraction);
  const axis = useMemo(() => getXAxisFromTimeRange({ from, to }), [from, to]);

  const ticks = useMemo(() => {
    const numberOfTicks = Math.min(12, Math.floor(width / TICK_LABEL_WIDTH));
    return getTicks(
      axis,
      width,
      scales.xScale,
      numberOfTicks,
      getMaxXTickValue
    );
  }, [axis, width, scales.xScale]);

  const formatter = useMemo(() => getTimeFormatterForAxis(axis), [axis]);
  const getX = useHandler((value: number) =>
    scales.xScale((value - axis.minValue) / (axis.maxValue - axis.minValue))
  );

  return {
    ticks,
    formatter,
    axis,
    getX,
  };
}
