import { ProviderEvent, SeriesSource } from "@fiberplane/charts";
import { Metric } from "@fiberplane/prometheus-query";

import { EventTooltipContent } from "./EventTooltipContent";
import { TimeseriesTooltipContent } from "./TimeseriesTooltipContent";

type Props = {
  content: [SeriesSource, Metric | ProviderEvent];
};

export function ChartTooltip(props: Props) {
  const [series, point] = props.content;
  if (series.type === "target_latency") {
    return null;
  }

  return series.type === "events" ? (
    <EventTooltipContent event={point as ProviderEvent} />
  ) : (
    <TimeseriesTooltipContent timeseries={series} metric={point as Metric} />
  );
}
