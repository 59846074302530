import { shallowEqual } from "react-redux";

import { api, pingApi } from "../api";
import type { AppAction } from "../actions";
import type { RootState } from "../state";
import { routerReducer } from "./routerReducer";
import { askAutometricsSlice, environmentSlice } from "../slices";
import { uiReducer } from "./uiReducer";

export function rootReducer(
  state: RootState | undefined,
  action: AppAction
): RootState {
  const newState: RootState = {
    [api.reducerPath]: api.reducer(state?.[api.reducerPath], action),
    [pingApi.reducerPath]: pingApi.reducer(
      state?.[pingApi.reducerPath],
      action
    ),
    askAutometrics: askAutometricsSlice.reducer(state?.askAutometrics, action),
    environment: environmentSlice.reducer(state?.environment, action),
    router: routerReducer(state?.router, action),
    ui: uiReducer(state, action),
  };

  return state && shallowEqual(state, newState) ? state : newState;
}
