import { sanitize } from "dompurify";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { parse } from "marked";

import { useAppDispatch } from "../../../store";
import {
  CommandIcon,
  CommandItemName,
  StyledCommandGroup,
  StyledCommandItem,
  StyledInputMixin,
} from "../styled";
import { CommandMenuPageProps } from "./CommandMenuPage";
import { RootState } from "../../../state";
import { createAskPrompt } from "../../../thunks";
import { Icon, Thinking } from "../../UI";
import { Query } from "../../../slices";
import { useAutosizeTextArea } from "../../../hooks";

type AskAutometricsPageProps = Omit<CommandMenuPageProps, "pageType">;

const OPTIONS = [
  "What is Autometrics?",
  "How to setup Autometrics?",
  "How to call a function in Autometrics?",
  "How does Autometrics work?",
];

export function AskAutometricsPage({
  query,
  setPage,
  setQuery,
}: AskAutometricsPageProps) {
  const dispatch = useAppDispatch();
  const { ids, items, isVisible } = useSelector(
    (state: RootState) => state.askAutometrics
  );
  const idArray = useMemo(() => [...ids].reverse(), [ids]);
  const ref = useRef<HTMLTextAreaElement>(null);
  const [prompt, setPrompt] = useState("");
  useAutosizeTextArea(ref.current, prompt);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
      ref.current.focus();
    }
  }, [isVisible, ids, items]);

  if (isVisible) {
    return (
      <ResultsContainer>
        <InputContainer>
          <StyledInput
            ref={ref}
            placeholder="Ask a question about Autometrics..."
            value={prompt}
            onChange={(event) => {
              setPrompt(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                dispatch(createAskPrompt(prompt));
                setPrompt("");
                setQuery("");
              }

              if (!prompt && event.key === "Backspace") {
                setPage(null);
              }
            }}
          />
        </InputContainer>
        <ExperimentalWarning>
          Experimental: ‘Ask Autometrics’ is an experiment and may give you
          incorrect answers.
        </ExperimentalWarning>
        {idArray
          .map((id) => items[id] && <Prompt key={id} prompt={items[id]!} />)
          .filter(Boolean)}
      </ResultsContainer>
    );
  }

  return (
    <>
      <StyledCommandGroup heading="Examples">
        {OPTIONS.map((option) => {
          return (
            <StyledCommandItem
              key={option}
              onSelect={() => {
                dispatch(createAskPrompt(option));
                setPrompt("");
                setQuery("");
              }}
            >
              <CommandIcon type="autometrics_logo_purple" />
              <CommandItemName>{option}</CommandItemName>
            </StyledCommandItem>
          );
        })}
      </StyledCommandGroup>
      {query && (
        <StyledCommandItem
          key="query"
          onSelect={() => {
            dispatch(createAskPrompt(query));
            setPrompt("");
          }}
          value={`"${query}"`}
        >
          <CommandIcon type="autometrics_logo_purple" />
          <CommandItemName>{query}</CommandItemName>
        </StyledCommandItem>
      )}
    </>
  );
}

const Prompt = memo(function Prompt({ prompt }: { prompt: Query }) {
  const htmlContent = useMemo(() => {
    if (!prompt.response) {
      return "";
    }

    const parsed = parse(prompt.response);
    return sanitize(parsed);
  }, [prompt.response]);
  return (
    <PromptContainer>
      <header>
        <Icon type="autometrics_logo_purple" />
        <div>{prompt.prompt}</div>
      </header>
      <p>
        <Icon type="sparkle" />
        <ResponseBubble isError={prompt.isError}>
          {prompt.response ? (
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          ) : (
            <Thinking />
          )}
        </ResponseBubble>
      </p>
    </PromptContainer>
  );
});

const PromptContainer = styled.article(
  ({ theme }) => css`
    & > header,
    & > p {
      display: flex;
    }

    div {
      flex: 1;
      overflow: hidden;
    }

    svg {
      flex-basis: 24px;
      margin-right: 16px;
      flex-shrink: 0;
      path {
        fill: ${theme.color.primary.grape[600]};
      }
    }

    header {
      margin-bottom: 20px;
      color: ${theme.color.fg.muted};
    }
  `
);

const InputContainer = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.border.muted};
  width: 100%;
`;

const ExperimentalWarning = styled.div(
  ({ theme }) => css`
    margin: 16px 24px 0 64px;
    padding: ${theme.radius.minimal} ${theme.radius.rounded};
    font: ${theme.font.headings.h6};
    line-height: 16px;
    border-radius: ${theme.radius.minimal};
    border: 1px solid #f8941f;
    background: linear-gradient(
      90deg,
      #f99421 0%,
      #f948ea 52.08%,
      #9027ff 100%
    );
  `
);

const ResultsContainer = styled.div`
  padding: 16px 0 0;
  min-height: 512px;
  display: flex;
  flex-direction: column-reverse;
  ${InputContainer}, ${PromptContainer}, ${ExperimentalWarning} {
    padding-left: 24px;
    padding-right: 24px;
  }
  article + article {
    margin-bottom: 32px;
  }
`;

const ResponseBubble = styled.div<{ isError: boolean }>(
  ({ isError, theme }) => css`
    background: ${isError
      ? theme.color.support.danger[400]
      : theme.color.primary.grape.alpha[50]};
    padding: 16px;
    border-radius: 16px;
    transition: background-color 0.25s ease-in;

    table {
      border-collapse: collapse;
      max-width: 100%;
    }
    tr {
      border-bottom: 1px solid ${theme.color.border.default};
    }
    thead tr {
      border-bottom-width: 2px;
    }
    th {
      padding: 8px 16px;
    }

    pre {
      background-color: ${theme.color.bg.subtle};
      border-radius: ${theme.radius.rounded};
      padding: 12px;
      overflow-x: scroll;
      white-space: nowrap;
    }
    pre,
    code {
      font: ${theme.font.code.regular};
      background-color: ${theme.color.bg.subtle};
      text-shadow: 0 1px ${theme.color.bg.default};

      transition: background-color 0.2s ease-in-out;
    }
    code[class*="language-"],
    pre[class*="language-"] {
      color: ${theme.color.fg.default};
    }
    .language-autometrics {
      .token.punctuation {
        color: ${theme.color.fg.default};
      }

      .token.function {
        color: ${theme.color.fg.primary};
      }
      .token.keyword {
        color: ${theme.color.fg.primary};
      }

      .token.string {
        color: ${theme.color.fg.accent[1]};
      }

      .token.class-name {
        color: ${theme.color.fg.default};
      }
      .token.constant {
        color: ${theme.color.fg.default};
      }

      .token.function-definition.function {
        color: ${theme.color.fg.accent[2]};
      }

      .token.namespace {
        color: ${theme.color.fg.accent[3]};
      }

      .token.attribute.attr-name {
        color: ${theme.color.fg.default};
      }

      .token.number {
        color: ${theme.color.fg.accent[1]};
      }

      .token.operator {
        background-color: transparent;
        color: ${theme.color.fg.default};
      }
    }
  `
);

const StyledInput = styled.textarea`
  ${StyledInputMixin}
  padding: 16px 0 0;
  resize: none;
  width: 100%;
`;
