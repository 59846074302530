import { useSelector } from "react-redux";
import { TimeRange, getStepFromTimeRange } from "@fiberplane/prometheus-query";
import { useMemo } from "react";

import { selectActivePrometheus } from "../../../selectors";
import { usePrometheusQueryRangeQuery } from "../../../api";
import { timestampToSeconds } from "../../../utils";

export function useAlertsQuery(timeRange: TimeRange) {
  const environment = useSelector(selectActivePrometheus);
  const [step, stepSeconds] = getStepFromTimeRange(timeRange);

  const result = usePrometheusQueryRangeQuery(
    {
      environmentUrl: environment?.url,
      start: timeRange.from,
      end: timeRange.to,
      query:
        "sum by (alertname, objective_name, severity, category, objective_percentile, sloth_id) (ALERTS)",
      useTimeRangeAsSpecified: true,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !environment?.url,
    }
  );

  const modifiedData = useMemo(() => {
    if (!result.data) {
      return result.data;
    }

    const isFiringCutOffTime = timestampToSeconds(timeRange.to) - stepSeconds;

    return result.data.map((alert) => {
      const lastTime = alert.metrics?.[alert.metrics.length - 1]?.time;
      const isFiring =
        (lastTime && timestampToSeconds(lastTime) >= isFiringCutOffTime) ||
        false;

      const labels = {
        ...alert.labels,
        // TODO: Add support for handling pending alerts
        //       Right now autometrics doesn't have any alerts that can be in a pending state
        //       so we don't need to worry about this for now
        state: isFiring ? "firing" : "inactive",
        lastseen: isFiring ? "still firing" : lastTime || "",
      };
      return { ...alert, labels };
    });
  }, [result.data, timeRange.to, stepSeconds]);

  return {
    result: {
      ...result,
      data: modifiedData,
    },
    step,
    stepSeconds,
  };
}
