import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import styled, { css } from "styled-components";

import { Environment } from "../../../slices";
import { Icon } from "../../UI";

type EnvironmentErrorsProps = {
  status: Environment["connectionStatus"];
};

export function EnvironmentErrors({ status }: EnvironmentErrorsProps) {
  const errors = Object.entries(status).filter(
    ([, status]) => status === QueryStatus.rejected
  );

  if (errors.length === 0) {
    return null;
  }

  return (
    <List>
      {errors.map(([errorKey]) => {
        return (
          <ListItem key={errorKey}>
            <ErrorIcon type="close" />
            {getErrorMessage(errorKey as keyof Environment["connectionStatus"])}
          </ListItem>
        );
      })}
    </List>
  );
}

function getErrorMessage(key: keyof Environment["connectionStatus"]) {
  switch (key) {
    case "ping":
      return "Unable to connect to the Prometheus instance";
    case "query":
      return "Unable to query the Prometheus instance";
  }
}

const List = styled.ul(
  () => css`
    grid-area: errors;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 0;
  `
);

const ListItem = styled.li(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font: ${theme.font.body.sm.medium};
    color: ${theme.color.fg.muted};
  `
);

const ErrorIcon = styled(Icon)(
  ({ theme }) =>
    css`
      color: ${theme.color.fg.danger};
    `
);
