import { applyMiddleware, compose, createStore } from "redux";
import { enableMapSet } from "immer";
import thunk from "redux-thunk";
import { setupListeners } from "@reduxjs/toolkit/query";

import type { AppDispatch } from "./types";
import { createReduxHistory, rootReducer, routerMiddleware } from "../reducers";
import { api, pingApi } from "../api";

// Change `trace` to `true` to enable action specific stack traces on the Redux Dev Tools
const trace = false;
const traceLimit = 25;

// Allow ES6 `Map` and `Set` types to be used in our store:
enableMapSet();

const isProduction = process.env.NODE_ENV === "production";

const composeEnhancers =
  // @ts-ignore
  (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function" &&
    !isProduction &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace,
      traceLimit,
      serialize: {
        options: { map: true, undefined: true },
        replacer: (key: string, value: any) => {
          // We permit storing mutable values in our UI slice, which includes
          // DOM nodes in some cases. DOM nodes make the Redux DevTools horribly
          // slow however, so we replace them with a constant string here...
          if (value instanceof HTMLElement) {
            return "(HTML element)";
          }

          if (value instanceof Function) {
            return value.toString();
          }

          return value;
        },
      },
    })) ||
  compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware,
      thunk,
      api.middleware,
      // HACK - I don't know why Typescript gets mad here, and I just wanna push this hotfix
      // @ts-ignore
      pingApi.middleware
    )
  )
);

// Required for refetchOnFocus / refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;

export * from "./types";

export const dispatch = store.dispatch as AppDispatch;
export const getState = store.getState;
export { useAppDispatch, useAppSelector } from "./dispatch";

export const history = createReduxHistory(store);
