import styled from "styled-components";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Input } from "@fiberplane/ui";

import { ErrorMessage } from "../UI";
import {
  EnvironmentButtonsContainer,
  EnvironmentListItem,
  EnvironmentTitle,
} from "./common";
import { dispatch } from "../../store";
import {
  addInstance,
  Environment,
  switchInstance,
  updateInstance,
} from "../../slices";
import { useKeyPressEvent } from "../../hooks";

type AddEnvironmentFormProps = {
  closeForm: () => void;
  environment?: Environment;
};

// A simple regex pattern for a valid URL path
const urlPathPattern = /^\/[^ "]*$/;

const UrlOrPathSchema = z.union([
  z.string().nonempty("A url or path is required").url(),
  z
    .string()
    .nonempty()
    .refine((value) => urlPathPattern.test(value), {
      message: "Not a valid URL or path",
    }),
]);

const formSchema = z.object({
  name: z.string().nonempty("A name is required"),
  url: UrlOrPathSchema,
});

type FormSchema = z.infer<typeof formSchema>;

export function EnvironmentForm({
  closeForm,
  environment,
}: AddEnvironmentFormProps) {
  const { handleSubmit, register, formState } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: environment?.name ?? "",
      url: environment?.url ?? "",
    },
  });

  const handleEscape = () => {
    const activeElement = document.activeElement;
    const isInput = activeElement instanceof HTMLInputElement;

    if (
      isInput &&
      (activeElement.id === "name" || activeElement.id === "url")
    ) {
      closeForm();
    }
  };

  useKeyPressEvent("Escape", handleEscape);

  const onSubmitForm = (changes: FormSchema) => {
    if (environment) {
      const { id } = environment;

      dispatch(updateInstance({ ...changes, id }));
      dispatch(switchInstance(id));
    } else {
      const id = String(Date.now());

      dispatch(addInstance({ ...changes, id }));
      dispatch(switchInstance(id));
    }

    closeForm();
  };

  return (
    <AddEnvironment as="form" onSubmit={handleSubmit(onSubmitForm)}>
      <EnvironmentInputFields>
        <InputLabel as="label" htmlFor="prometheusName">
          Name
        </InputLabel>
        <StyledTextInput
          placeholder="e.g., Production"
          id="prometheusName"
          autoFocus
          {...register("name")}
          data-invalid={formState.errors.name === undefined ? undefined : true}
        />
        {formState.errors.name && (
          <ErrorMessage>{formState.errors.name.message}</ErrorMessage>
        )}

        <InputLabel as="label" htmlFor="url">
          URL
        </InputLabel>
        <StyledTextInput
          placeholder="e.g., http://localhost:9090"
          id="url"
          {...register("url")}
          data-invalid={formState.errors.url === undefined ? undefined : true}
        />
        {formState.errors.url && (
          <ErrorMessage>{formState.errors.url.message}</ErrorMessage>
        )}
      </EnvironmentInputFields>

      <FormButtonsContainer>
        <Button
          type="reset"
          buttonStyle="secondary"
          onClick={() => closeForm()}
        >
          Cancel
        </Button>
        <Button type="submit" buttonStyle="primary">
          Save
        </Button>
      </FormButtonsContainer>
    </AddEnvironment>
  );
}

const FormButtonsContainer = styled(EnvironmentButtonsContainer)`
  gap: 8px;
  justify-content: end;
`;

const AddEnvironment = styled(EnvironmentListItem)`
  grid:
    "input" auto
    "buttons" auto
    / 1fr;
  gap: 16px;
  cursor: default;
`;

const InputLabel = styled(EnvironmentTitle)`
  grid-area: unset;
`;

const StyledTextInput = styled(Input).attrs({ type: "text" })``;

const EnvironmentInputFields = styled.div`
  grid-area: input;
  display: grid;
  align-items: center;
  grid: auto-flow / auto 1fr;
  gap: 12px 16px;

  ${InputLabel} {
    grid-column: 1;
  }

  ${StyledTextInput}, ${ErrorMessage} {
    grid-column: 2;
  }
`;
