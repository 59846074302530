import { z } from "zod";

// Define a schema for the labels and annotations
const LabelsSchema = z.record(z.string());
const AnnotationsSchema = z.record(z.string());

// Schemas for queries from Prometheus

const PrometheusAlertSchema = z
  .object({
    labels: z.optional(LabelsSchema),
    annotations: z.optional(AnnotationsSchema),
    state: z.string(), // e.g., "firing"
    activeAt: z.optional(z.string()), // e.g., "2023-07-28T12:34:56.789Z"
    updatedAt: z.optional(z.string()), // e.g., "2023-07-28T12:34:56.789Z"
    value: z.string(), // e.g., "1e+00"
  })
  .passthrough();

export type PrometheusAlert = z.infer<typeof PrometheusAlertSchema>;

/**
 * Schema for a response from the Prometheus `/alert` endpoint
 */
export const PrometheusAlertsResponseSchema = z.object({
  status: z.literal("success"),
  data: z.object({
    alerts: z.array(PrometheusAlertSchema),
  }),
});

export type PrometheusAlertsResponse = z.infer<
  typeof PrometheusAlertsResponseSchema
>;

// Helpers for rules schema

// Define a schema for an individual alerting rule
const AlertingRuleSchema = z.object({
  name: z.string(),
  state: z.optional(z.string()),
  query: z.string(),
  // duration: z.string(),
  labels: z.optional(LabelsSchema),
  annotations: z.optional(AnnotationsSchema),
});

// Define a schema for a group of alerting rules
const AlertingRuleGroupSchema = z.object({
  name: z.string(),
  file: z.string(),
  rules: z.array(AlertingRuleSchema),
});

export type PrometheusAlertingRuleGroup = z.infer<
  typeof AlertingRuleGroupSchema
>;

/**
 * Schema for a response from the Prometheus `/rules` endpoint
 */
export const PrometheusRulesResponseSchema = z.object({
  status: z.literal("success"),
  data: z.object({
    groups: z.array(AlertingRuleGroupSchema),
  }),
});

export type PrometheusRulesResponse = z.infer<
  typeof PrometheusRulesResponseSchema
>;

/**
 * EXAMPLE ALERT RESPONSE
{
  "status": "success",
  "data": {
    "alerts": [
      {
        "labels": {
          "alertname": "HighErrorRate",
          "severity": "critical",
          "instance": "webserver1",
          "job": "webserver",
          "monitor": "prometheus",
          "service": "myapp"
        },
        "annotations": {
          "summary": "High error rate detected on webserver1",
          "description": "The error rate is above the critical threshold."
        },
        "state": "firing",
        "activeAt": "2023-07-28T12:34:56.789Z",
        "value": "0.123",
        "updatedAt": "2023-07-28T12:34:56.789Z"
      },
      // Additional alert entries if there are more active alerts
    ]
  }
}
 */
