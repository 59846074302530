import type { Variants } from "framer-motion";
import { motion } from "framer-motion";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, ANIMATE_INITIAL } from "./constants";

export function AnimatedAlertsIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        opacity="0.2"
        x="5"
        y="4"
        width="10"
        height="12"
        fill="currentColor"
      />
      <path
        d="M15 4H5C5 2.89543 5.89543 2 7 2L13 2C14.1046 2 15 2.89543 15 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7ZM9 10C9 9.44772 9.44772 9 10 9C10.5523 9 11 9.44772 11 10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10Z"
        fill="currentColor"
      />
      <path
        d="M13 18C14.1046 18 15 17.1046 15 16L5 16C5 17.1046 5.89543 18 7 18L13 18Z"
        fill="currentColor"
      />

      <motion.g
        variants={variants}
        animate={controls}
        onAnimationComplete={onAnimationComplete}
      >
        <path
          d="M6.87832 12.3708C6.41597 11.7997 6.10108 10.9636 6.10108 10C6.10108 9.03635 6.41597 8.20034 6.87832 7.6292L5.32383 6.3708C4.55197 7.32428 4.10108 8.61322 4.10108 10C4.10108 11.3868 4.55197 12.6757 5.32383 13.6292L6.87832 12.3708Z"
          fill="currentColor"
        />
        <path
          d="M3.00006 10C3.00006 11.6791 3.68846 13.1956 4.80098 14.2858L3.40118 15.7142C1.92059 14.2634 1.00006 12.2383 1.00006 10C1.00006 7.76171 1.9206 5.73661 3.40118 4.28576L4.80098 5.71424C3.68846 6.80442 3.00006 8.32092 3.00006 10Z"
          fill="currentColor"
        />
      </motion.g>
      <motion.g variants={variants} animate={controls}>
        <path
          d="M13.2228 7.6292C13.6851 8.20034 14 9.03635 14 10C14 10.9636 13.6851 11.7997 13.2228 12.3708L14.7773 13.6292C15.5491 12.6757 16 11.3868 16 10C16 8.61322 15.5491 7.32428 14.7773 6.3708L13.2228 7.6292Z"
          fill="currentColor"
        />
        <path
          d="M17.101 10C17.101 8.32092 16.4126 6.80442 15.3001 5.71424L16.6999 4.28576C18.1805 5.73661 19.101 7.76171 19.101 10C19.101 12.2383 18.1805 14.2634 16.6999 15.7142L15.3001 14.2858C16.4126 13.1956 17.101 11.6791 17.101 10Z"
          fill="currentColor"
        />
      </motion.g>
    </svg>
  );
}

const variants: Variants = {
  [ANIMATE_INITIAL]: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  [ANIMATE]: {
    skewX: [5, -5, 5, -5, 5, -5, 5, 0],
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};
