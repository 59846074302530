import { Timestamp } from "@fiberplane/charts";
import styled, { css } from "styled-components";
import { useState } from "react";

import { MonthTable } from "./MonthTable";
import { DatePickerForm } from "./DatePickerForm";
import { TimeRangePresets } from "./TimeRangePresets";
import { DatePickerTimeRange } from "../../utils";

type Props = {
  timeRange: DatePickerTimeRange;
  onChange: (timeRange: DatePickerTimeRange) => void;
};

export function DatePickerContent(props: Props) {
  const { onChange, timeRange } = props;

  const { from, to } = timeRange;

  const [draftFrom, setDraftFrom] = useState<Timestamp>(from);
  const [draftTo, setDraftTo] = useState<Timestamp>(to);

  return (
    <Container>
      <MonthTableSection>
        <MonthTable
          setStartTime={setDraftFrom}
          setEndTime={setDraftTo}
          startTime={draftFrom}
          endTime={draftTo}
        />
        <DatePickerForm
          from={draftFrom}
          to={draftTo}
          onChange={onChange}
          updateDraftFrom={setDraftFrom}
          updateDraftTo={setDraftTo}
        />
      </MonthTableSection>
      <Section>
        <TimeRangePresets onChange={onChange} />
      </Section>
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: ${theme.color.bg.elevated.default};
    color: ${theme.color.fg.default};
    border-radius: ${theme.radius.rounded};
  `
);

const Section = styled.div`
  padding: 16px;
`;

const MonthTableSection = styled(Section)`
  display: grid;
  gap: 20px;
  align-content: baseline;
`;
