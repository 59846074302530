import { motion } from "framer-motion";
import styled from "styled-components";

export const opacityYoYoVariants = {
  initial: {
    opacity: 0.3,
  },
  in: {
    opacity: [0.3, 0.9, 1, 0.9, 0.3],
  },
  out: {
    opacity: 0,
  },
};

export const opacityYoYoTransition = {
  repeat: Number.POSITIVE_INFINITY,
  ease: "easeInOut",
  duration: 2,
};

export const OpacityYoYo = styled(motion.div).attrs({
  initial: "initial",
  animate: "in",
  exit: "out",
  transition: opacityYoYoTransition,
  variants: opacityYoYoVariants,
})``;
