import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const DashboardSection = styled.section(
  ({ theme }) =>
    css`
      border-radius: 12px;
      border: 1px solid ${theme.color.border.muted};
      background: ${theme.color.bg.subtle};
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      ${theme.media.md`
        padding: 12px;
      `}
    `
);

export const DashboardSectionHeader = styled.header(
  () =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: max-content;
    `
);

export const DashboardSectionHeading = styled.h4(
  ({ theme }) =>
    css`
      display: inline-block;
      color: ${theme.color.fg.default};
      font: ${theme.font.headings.h4};
    `
);

export const TargetInfo = styled.span(
  ({ theme }) =>
    css`
      display: grid;
      gap: ${theme.radius.rounded};
      color: ${theme.color.fg.muted};
      font: ${theme.font.body.sm.regular};
      line-height: 20px; /* 166.667% */
    `
);

export const TargetNumber = styled(Link)<{ $isOnTarget: boolean }>(
  ({ theme, $isOnTarget }) =>
    css`
      width: max-content;
      color: ${$isOnTarget ? theme.color.fg.success : theme.color.fg.danger};

      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 120% */
    `
);
