import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { FunctionExplorerIndex } from "./FunctionExplorerIndex";
import { FunctionsPage } from "./FunctionPage";
import { selectActivePrometheus } from "../../selectors";
import {
  NoDataUrlProvided,
  NoFunctionsDetected,
  PrometheusError,
} from "../FallbackStates";
import {
  useGetAllTrackedFunctionsQuery,
  usePingPrometheusQuery,
} from "../../api";
import { FunctionsQueryPage } from "./FunctionsQueryPage";
import { ResizableFunctionTreeList } from "./FunctionTree";

export const FunctionsExplorer = () => {
  // Use selector to read the active prometheus instance error state
  const instance = useSelector(selectActivePrometheus);

  const { error: prometheusError } = usePingPrometheusQuery(instance?.url, {
    refetchOnMountOrArgChange: true,
    skip: !instance?.url,
  });

  const { data: autometricsData, isFetching: autometricsDataIsFetching } =
    useGetAllTrackedFunctionsQuery(instance?.url, {
      skip: !instance?.url,
    });

  if (!instance?.url) {
    return <NoDataUrlProvided />;
  }

  if (prometheusError) {
    return <PrometheusError />;
  }

  if (
    (!autometricsData || autometricsData.length === 0) &&
    !autometricsDataIsFetching
  ) {
    return <NoFunctionsDetected />;
  }

  return (
    <FunctionExplorerGrid>
      <ResizableFunctionTreeList
        autometricsData={autometricsData}
        isLoading={autometricsDataIsFetching}
      />
      <MainSection>
        <Routes>
          <Route index element={<FunctionExplorerIndex />} />
          <Route
            path="query/*"
            element={<FunctionsQueryPage autometricsData={autometricsData} />}
          />
          <Route
            path="details/m/:module/:name/:metricType?"
            element={<FunctionsPage />}
          />
          <Route
            path="details/s/:service/m/:module/:name/:metricType?"
            element={<FunctionsPage />}
          />
          <Route
            path="details/:name/:metricType?"
            element={<FunctionsPage />}
          />
        </Routes>
      </MainSection>
    </FunctionExplorerGrid>
  );
};

const MainSection = styled.div`
  /* HACK - Knows height of nav bar */
  height: calc(100vh - 60px);
  overflow: auto;
`;

const FunctionExplorerGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0;
`;
