import styled, { css } from "styled-components";
import { Route, Routes } from "react-router-dom";

import { IconType, PageContainer } from "../UI";
import { Feature } from "../../types";
import { SettingsTabPanel, SettingsTabPanelBeta } from "./SettingsTabPanel";
import { SettingsTabLink, SettingsTabLinkBeta } from "./SettingsTabLink";
import { Redirect } from "../Redirect";

type SettingsBlockIdentifier =
  | "experimental"
  | "notifications"
  | "source-code-links"
  | "theme"
  | "prometheus-urls";

export type Setting = {
  title: string;
  iconType: IconType;
  url: SettingsBlockIdentifier;
  description: string;
  feature?: Feature;
};

const SETTINGS: Array<Setting> = [
  {
    title: "Prometheus URLs",
    iconType: "environments_duotone",
    url: "prometheus-urls",
    description:
      "Add Prometheus URLs to see real time metrics for your Autometrics functions.",
  },
  {
    title: "Notifications",
    iconType: "alarm_duotone",
    url: "notifications",
    description: "Get a notification when there is a new alert",
  },
  {
    title: "Theme",
    iconType: "moon_duotone",
    url: "theme",
    description: "Switch theme of your Autometrics explorer",
  },
  {
    title: "Preview",
    iconType: "bolt_duotone",
    url: "experimental",
    description: "Be the first to try out new features.",
  },
  {
    title: "Source code links",
    iconType: "code_duotone",
    url: "source-code-links",
    description: "Enable source code information or links to your source code",
    feature: "source-code-links",
  },
];

export function Settings() {
  const firstElementUrl = SETTINGS[0]?.url ?? "/";

  return (
    <PageContainer>
      <h1>Settings</h1>

      <SettingsContentDesktop>
        <SettingsContent>
          <SettingsSideMenu>
            {SETTINGS.map(({ title, iconType, url, feature }) => (
              <SettingsMenuListItem key={title}>
                {feature ? (
                  <SettingsTabLinkBeta
                    title={title}
                    url={url}
                    feature={feature}
                    iconType={iconType}
                  />
                ) : (
                  <SettingsTabLink
                    title={title}
                    url={url}
                    iconType={iconType}
                    showBetaTag={url === "experimental"}
                  />
                )}
              </SettingsMenuListItem>
            ))}
          </SettingsSideMenu>

          <Routes>
            {SETTINGS.map(({ url, title, description, feature }) => (
              <Route
                key={url}
                path={url}
                element={
                  feature ? (
                    <SettingsTabPanelBeta
                      url={url}
                      title={title}
                      description={description}
                      feature={feature}
                    />
                  ) : (
                    <SettingsTabPanel
                      url={url}
                      title={title}
                      description={description}
                    />
                  )
                }
              />
            ))}
            <Route path="*" element={<Redirect to={firstElementUrl} />} />
          </Routes>
        </SettingsContent>
      </SettingsContentDesktop>

      {/*
        TODO (Oscar): update mobile designs/routing once designs are updated
      */}
      <SettingsContentMobile>
        {SETTINGS.map(({ url, title, description, feature }) => {
          if (feature) {
            return (
              <SettingsTabPanelBeta
                key={url}
                url={url}
                title={title}
                description={description}
                feature={feature}
              />
            );
          }

          return (
            <SettingsTabPanel
              key={url}
              url={url}
              title={title}
              description={description}
            />
          );
        })}
      </SettingsContentMobile>
    </PageContainer>
  );
}

const SettingsContentDesktop = styled.div(
  ({ theme: { media } }) => css`
    display: none;

    ${media.md`
      display: block;
    `}
  `
);

const SettingsContentMobile = styled.div(
  ({ theme: { media } }) => css`
    display: grid;
    gap: 64px;

    ${media.md`
      display: none;
    `}
  `
);

const SettingsContent = styled.div(
  ({ theme: { media } }) => css`
    display: grid;

    ${media.md`
      grid-template-columns:
        minmax(max-content, 170px) minmax(min-content, 640px);
      gap: 0 115px;
    `}
  `
);

const SettingsSideMenu = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin: 0;
`;

const SettingsMenuListItem = styled.li`
  list-style: none;
`;
