import type { Placement } from "@popperjs/core";
import { useEffect } from "react";

import { dispatch } from "../../store";
import { showTooltip } from "../../thunks";

/**
 * Sets up `mouseover` handlers that will detect the presence of elements with
 * `aria-label` attributes and display tooltips for them when hovered.
 *
 * Keyboard shortcuts can be configured using a `data-shortcut` attribute.
 *
 * Example:
 *
 * ```
 * <button aria-label="Run Cell" data-shortcut="cmd+enter">
 *    Run
 * </button>
 * ```
 */
export function useTextTooltips() {
  useEffect(() => {
    document.body.addEventListener("mouseover", onMouseOver);

    return () => document.body.removeEventListener("mouseover", onMouseOver);
  }, []);
}

function onMouseOver({ target }: MouseEvent) {
  const isIconSVG = target instanceof SVGElement && target.nodeName === "svg";
  const isMatchableElement = target instanceof HTMLElement || isIconSVG;
  const match =
    isMatchableElement &&
    (target.closest(
      `[aria-label]:not([aria-pressed="true"]):not(.active):not([data-no-tooltip]),
          [data-shortcut]:not([aria-pressed="true"]):not(.active)`
    ) as HTMLElement);
  if (!match) {
    return;
  }

  const text = match.getAttribute("aria-label") || "";
  const content = text;

  dispatch(
    showTooltip(match, content, {
      placement: getPlacement(match.dataset.tooltipPlacement ?? null),
    })
  );
}

function getPlacement(placement: string | null): Placement | undefined {
  switch (placement) {
    case "top":
    case "right":
    case "bottom":
    case "left":
      return placement;
  }
}
