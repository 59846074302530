import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import useMeasure from "react-use/lib/useMeasure";

import { AlertsFiring } from "./AlertsFiring";
import {
  DashboardSection,
  DashboardSectionHeader,
  DashboardSectionHeading,
} from "./styled";
import { SlosOffTarget } from "./SlosOffTarget";
import { LatestNews } from "./LatestNews";
import { FunctionPerformance } from "./FunctionPerformance";
import { PageContainer } from "../UI";
import { PrometheusError } from "../FallbackStates";
import { selectActivePrometheus } from "../../selectors";
import { usePingAnyPrometheusQuery } from "../../api";
import { EventTimeline } from "./EventTimeline";

export function Dashboard() {
  const [graphContainerRef, { height: graphHeight }] =
    useMeasure<HTMLDivElement>();

  const instance = useSelector(selectActivePrometheus);

  usePingAnyPrometheusQuery(instance?.url, {
    refetchOnMountOrArgChange: true,
    skip: !instance?.url,
  });

  if (
    !instance ||
    !instance.connectionStatus ||
    instance.connectionStatus.ping === QueryStatus.rejected
  ) {
    return <PrometheusError />;
  }

  return (
    <DashboardPageContainer>
      <FunctionPerformance ref={graphContainerRef} />
      <AlertsTimelineSection>
        <DashboardSectionHeader>
          <DashboardSectionHeading>Alerts timeline</DashboardSectionHeading>
        </DashboardSectionHeader>
        <EventTimeline />
      </AlertsTimelineSection>
      <AlertsFiring />
      <SlosOffTarget />
      <LatestNews graphHeight={graphHeight} />
    </DashboardPageContainer>
  );
}

const DashboardPageContainer = styled(PageContainer)(
  ({ theme }) =>
    css`
      ${theme.media.md`
        grid-template-columns: repeat(8, 1fr);
        grid-auto-rows: auto;
      `}
    `
);

const AlertsTimelineSection = styled(DashboardSection)(
  ({ theme }) =>
    css`
      padding: 24px;

      ${DashboardSectionHeader} {
        margin-bottom: auto;
      }

      ${theme.media.md`
        grid-column: 1 / 7;
        grid-row: 2 / 4;
      `}
    `
);
