import styled, { css } from "styled-components";
import { Input } from "@fiberplane/ui";

import { usePingAnyPrometheusQuery } from "../../../api";
import { Environment, removeInstance, switchInstance } from "../../../slices";
import { dispatch, useAppSelector } from "../../../store";
import {
  EnvironmentButtonsContainer,
  EnvironmentListItem,
  EnvironmentTitle,
} from "../common";
import { EnvironmentConnectionStatus } from "../EnvironmentConnectionStatus";
import { EnvironmentErrors } from "./EnvironmentErrors";
import { EnvironmentMenu } from "./EnvironmentMenu";

const DEFAULT_INTERVAL = 20_000;

type EnvironmentProps = {
  environment: Environment;
  onEdit: () => void;
};

export function EnvironmentItem({ environment, onEdit }: EnvironmentProps) {
  const { selectedInstance } = useAppSelector((state) => state.environment);
  const isActiveInstance = environment.id === selectedInstance;

  // We poll the ping endpoint regularly to keep track of connection status.
  // The environment slice keeps track of the rtk queries and updates the
  // connection status per environment.
  usePingAnyPrometheusQuery(environment.url, {
    refetchOnMountOrArgChange: true,
    skip: !environment.url,
    pollingInterval: DEFAULT_INTERVAL,
  });

  return (
    <EnvironmentListItem
      $isActive={isActiveInstance}
      key={environment.id}
      onClick={() => dispatch(switchInstance(environment.id))}
    >
      <Input type="radio" checked={isActiveInstance} readOnly />

      <EnvironmentTitle>
        {environment.name}
        <EnvironmentConnectionStatus status={environment.connectionStatus} />
      </EnvironmentTitle>
      <EnvironmentUrl>{environment.url}</EnvironmentUrl>
      <EnvironmentErrors status={environment.connectionStatus} />

      <EnvironmentButtonsContainer>
        <EnvironmentMenu
          onRemove={() => dispatch(removeInstance(environment))}
          onEdit={onEdit}
        />
      </EnvironmentButtonsContainer>
    </EnvironmentListItem>
  );
}

const EnvironmentUrl = styled.p(
  ({ theme }) =>
    css`
      grid-area: url;
      font: ${theme.font.body.sm.regular};
      color: ${theme.color.fg.muted};
    `
);
