import { useSelector } from "react-redux";
import { forwardRef, useMemo, useState } from "react";
import {
  getDurationFormatterForAxis,
  getPercentageFormatter,
  getScientificFormatterForAxis,
  getTimeFormatterForAxis,
  MetricsChart,
  TickFormattersFactory,
  Timeseries,
} from "@fiberplane/charts";
import styled, { css } from "styled-components";
import { noop } from "@tanstack/react-table";

import { MetricType, usePrometheusQueryRangeQuery } from "../../api";
import { selectActivePrometheus } from "../../selectors";
import {
  useChartTheme,
  useScrapeIntervalAsBuildInfoInterval,
  useTimeRange,
} from "../../hooks";
import {
  DashboardSection,
  DashboardSectionHeader,
  DashboardSectionHeading,
} from "./styled";
import { Tab, TabList } from "../UI";
import { Prometheus } from "../../services";
import { Skeleton } from "../Skeleton";
import { sortBy } from "../../utils";
import { dispatch } from "../../store";
import { showTooltip } from "../../thunks";
import { ChartTooltip } from "../Tooltip";

const EMPTY_TIMESERIES_DATA: Timeseries[] = [];

export const FunctionPerformance = forwardRef<HTMLDivElement>(
  function FunctionPerformance(_props, graphContainerRef) {
    const { timeRange } = useTimeRange();

    const [metricType, setMetricType] = useState<MetricType>("requestRate");

    const tickFormattersFactory = useMemo(
      (): TickFormattersFactory => (xAxis, yAxis) => ({
        xFormatter: getTimeFormatterForAxis(xAxis),
        yFormatter:
          metricType === "latency"
            ? getDurationFormatterForAxis(yAxis)
            : metricType === "errorRatio"
            ? getPercentageFormatter()
            : getScientificFormatterForAxis(yAxis),
      }),
      [metricType]
    );

    const graphTheme = useChartTheme(metricType);
    const { data: timeseriesData = EMPTY_TIMESERIES_DATA, isFetching } =
      useTimeseriesDataByMetricType(metricType);

    return (
      <FunctionPerformanceSection>
        <DashboardSectionHeader>
          <DashboardSectionHeading>Top 5 functions</DashboardSectionHeading>
          <FunctionPerformanceTabList>
            <FunctionPerformanceTab
              isActive={metricType === "requestRate"}
              onClick={() => setMetricType("requestRate")}
            >
              Request rate
            </FunctionPerformanceTab>
            <FunctionPerformanceTab
              isActive={metricType === "errorRatio"}
              onClick={() => setMetricType("errorRatio")}
            >
              Error rate
            </FunctionPerformanceTab>
            <FunctionPerformanceTab
              isActive={metricType === "latency"}
              onClick={() => setMetricType("latency")}
            >
              Latency
            </FunctionPerformanceTab>
          </FunctionPerformanceTabList>
        </DashboardSectionHeader>
        <GraphContainer ref={graphContainerRef}>
          {isFetching ? (
            <SkeletonGraph />
          ) : (
            <MetricsChartContainer>
              <MetricsChart
                chartTheme={graphTheme}
                tickFormatters={tickFormattersFactory}
                timeRange={timeRange}
                graphType={"line"}
                stackingType={"none"}
                timeseriesData={timeseriesData}
                footerShown={false}
                showTooltip={(anchor, [series, point]) => {
                  if (point) {
                    return dispatch(
                      showTooltip(
                        anchor,
                        <ChartTooltip content={[series, point]} />,
                        { placement: "top" }
                      )
                    );
                  }

                  return noop;
                }}
              />
            </MetricsChartContainer>
          )}
        </GraphContainer>
      </FunctionPerformanceSection>
    );
  }
);

function useTimeseriesDataByMetricType(metricType: MetricType) {
  const instance = useSelector(selectActivePrometheus);
  const { timeRange } = useTimeRange();

  const { buildInfoInterval, shouldWaitForBuildInfoInterval } =
    useScrapeIntervalAsBuildInfoInterval(instance?.url);

  const query = getQueryByMetricType(metricType, buildInfoInterval);

  const { data, ...rangeQuery } = usePrometheusQueryRangeQuery(
    {
      environmentUrl: instance?.url,
      query,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      // NOTE - This allows us to refetch data when the time range changes
      refetchOnMountOrArgChange: true,
      // HACK - to prevent double-queries, we skip the query if we haven't determined the scrape interval yet
      skip: shouldWaitForBuildInfoInterval,
    }
  );

  // topk(n, ...) returns the top N per timestamp, so we need to grab the top 5 from those
  const top5Data = useMemo(() => {
    if (data) {
      return sortBy(
        [...data],
        ({ metrics }) => {
          const sorted = sortBy([...metrics], ({ value }) => value, true);
          return sorted[0]?.value ?? 0;
        },
        true
      ).slice(0, 5);
    }

    return data;
  }, [data]);

  return {
    ...rangeQuery,
    data: top5Data,
  };
}

function getQueryByMetricType(
  metricType: MetricType,
  buildInfoInterval: ReturnType<
    typeof useScrapeIntervalAsBuildInfoInterval
  >["buildInfoInterval"]
) {
  switch (metricType) {
    case "errorRatio":
      return Prometheus.createTop5FunctionErrorRateQuery({ buildInfoInterval });
    case "latency":
      return Prometheus.createTop5FunctionLatencyQuery({ buildInfoInterval });
    case "requestRate":
      return Prometheus.createTop5FunctionRequestRateQuery({
        buildInfoInterval,
      });
  }
}

const FunctionPerformanceSection = styled(DashboardSection)(
  ({ theme }) =>
    css`
      padding: 24px;

      ${DashboardSectionHeader} {
        align-items: flex-start;
      }

      ${theme.media.md`
        grid-column: 1 / 6;
        grid-row: 1 / 2;
      `}
    `
);

const FunctionPerformanceTabList = styled(TabList)(
  ({ theme }) =>
    css`
      background: ${theme.color.bg.default};
      gap: 4px;
    `
);

const FunctionPerformanceTab = styled(Tab)(
  ({ theme }) =>
    css`
      padding: 6px 4px;
      border-radius: ${theme.radius.rounded};
      font: ${theme.font.buttons.sm};

      &[aria-selected="true"] {
        background: ${theme.color.bg.emphasis.primary};
        color: ${theme.color.fg.onemphasis.default};
      }
    `
);

const GraphContainer = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      height: 100%;

      ${theme.media.md`
        min-height: 580px;
      `}

      ${theme.media.lg`
        min-height: 520px;
      `}
    `
);

const SkeletonGraph = styled(Skeleton)`
  flex: 1;
`;

const MetricsChartContainer = styled.div`
  flex: 1;
`;
