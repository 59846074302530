import { Prometheus } from "../services";
import { baseApi, EnvironmentUrl } from "./base";
import {
  PrometheusAmSeriesResponseSchema,
  FunctionModulePair,
  SourceLocation,
  SourceFunctionSchema,
} from "../schemas";
import { fetchAndDecode } from "./utils";

// TODO - move to `schemas` folder
export type MetricType = "requestRate" | "errorRatio" | "latency";

export const functionsApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllTrackedFunctions: builder.query<
        FunctionModulePair[],
        EnvironmentUrl
      >({
        query: () => {
          // Search for all function_calls series, and only include results that have both a function and module label
          const params = new URLSearchParams();
          params.append(
            "match[]",
            '{__name__=~"function_calls(_count)?(_total)?", function!="", module!=""}'
          );

          return {
            url: `/api/v1/series?${params.toString()}`,
          };
        },
        // eslint-disable-next-line require-await
        transformResponse: async (response: any) => {
          // NOTE - parse can throw, but the error will be caught by RTK Query and the UI will think there were no results
          const series = PrometheusAmSeriesResponseSchema.parse(response).data;

          return Prometheus.filterUniqueFunctionModulePairs(
            series.map((entry) => ({
              name: entry.function,
              module: entry.module,
              service_name: entry.service_name,
            }))
          );
        },
      }),
      getAllSourceLocations: builder.query<SourceLocation[], {}>({
        query: () => {
          const { origin } = window.location;

          return {
            url: `${origin}/api/functions`,
            mode: "cors",
          };
        },
        // eslint-disable-next-line require-await
        transformResponse: async (response: any) => {
          // NOTE - parse can throw, but the error will be caught by RTK Query and the UI will think there were no results
          return SourceFunctionSchema.parse(response);
        },
      }),

      getAllChildFunctions: builder.query<
        FunctionModulePair[],
        {
          callerFunction: string;
          callerModule?: string;
          environmentUrl: EnvironmentUrl;
        }
      >({
        async queryFn(args, _queryApi, _extraOptions, fetchWithBq) {
          const { callerFunction, callerModule } = args;
          const oldCallerLabelParams = new URLSearchParams();
          oldCallerLabelParams.append(
            "match[]",
            `{__name__=~"function_calls(_count)?(_total)?", function!="", module!="", caller=~"${callerFunction}"}`
          );

          const newCallerLabelParams = new URLSearchParams();

          if (callerModule) {
            newCallerLabelParams.append(
              "match[]",
              `{__name__=~"function_calls(_count)?(_total)?", function!="", module!="", caller_module="${callerModule}", caller_function=~"${callerFunction}"}`
            );
          } else {
            newCallerLabelParams.append(
              "match[]",
              `{__name__=~"function_calls(_count)?(_total)?", function!="", module!="", caller_module!="", caller_function=~"${callerFunction}"}`
            );
          }

          // Fetch the old caller series and new caller series in parallel
          const [oldCallerSeries, newCallerSeries] = await Promise.all([
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: oldCallerLabelParams,
              },
              PrometheusAmSeriesResponseSchema
            ),
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: newCallerLabelParams,
              },
              PrometheusAmSeriesResponseSchema
            ),
          ]);

          // If either request failed, return the rtk-query error
          // NOTE - the error could happen due to a network error, or a decoding error
          if ("error" in oldCallerSeries) {
            return oldCallerSeries;
          }

          if ("error" in newCallerSeries) {
            return newCallerSeries;
          }

          return {
            data: Prometheus.filterUniqueFunctionModulePairs(
              [...newCallerSeries.data.data, ...oldCallerSeries.data.data].map(
                (entry) => ({
                  name: entry.function,
                  module: entry.module,
                })
              )
            ),
          };
        },
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ["Functions", "ChildFunctions"],
    endpoints: {
      getAllTrackedFunctions: {
        providesTags: (_functions, _error, environmentUrl) => {
          if (environmentUrl) {
            return [{ type: "Functions", id: environmentUrl }];
          }

          return [];
        },
      },
      getAllChildFunctions: {
        providesTags: (
          _functions,
          _error,
          { callerFunction, callerModule, environmentUrl }
        ) => {
          if (environmentUrl) {
            const id = `${environmentUrl}-${callerFunction}-${callerModule}`;
            return [{ type: "ChildFunctions", id }];
          }

          return [];
        },
      },
    },
  });

export const {
  useGetAllTrackedFunctionsQuery,
  useGetAllChildFunctionsQuery,
  useGetAllSourceLocationsQuery,
  useLazyGetAllSourceLocationsQuery,
} = functionsApi;
