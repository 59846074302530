import styled, { css } from "styled-components";

import { Icon } from "../../UI";

type Props = {
  x: number;
  height: number;
};

export function CurrentLine(props: Props) {
  return (
    <>
      <NowLine style={{ transform: `translateX(${props.x}px)` }} />
      <Triangle
        type="triangle_down"
        style={{ transform: `translateX(${-props.x}px)` }}
      />
    </>
  );
}

const NowLine = styled.div(
  ({ theme }) => css`
    width: 1px;
    border: 1px dashed ${theme.color.fg.primary};
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
  `
);

const Triangle = styled(Icon)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    bottom: 0;
    display: inline-block;
    color: ${theme.color.fg.primary};
    margin-left: -9px;
    pointer-events: none;
    rotate: 180deg;
  `
);
