import styled, { css } from "styled-components";
import { Input } from "@fiberplane/ui";

import ThemeLight from "./ThemeLight.svg";
import ThemeDark from "./ThemeDark.svg";
import { EnvironmentTitle } from "./common";
import { ScrollContainer } from "../UI";
import { useThemeSelect } from "../../hooks";
import { isValidTheme } from "../../types";

export function ThemeSwitcher() {
  const {
    currentTheme,
    isSystemPreference,
    setThemePreference,
    setToSystemTheme,
  } = useThemeSelect();

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const themePreference = event.target.value;
    const validTheme = isValidTheme(themePreference);

    if (validTheme) {
      setThemePreference(themePreference);
      return;
    }

    setToSystemTheme();
  };

  const isLightActive = !isSystemPreference && currentTheme === "light";
  const isDarkActive = !isSystemPreference && currentTheme === "dark";

  return (
    <ScrollContainer>
      <ThemeCard $isActive={isSystemPreference}>
        <ThemeImageContainer>
          <SystemThemeVisual onClick={setToSystemTheme}>
            <HalfIcon>
              <ThemeLightSvg />
            </HalfIcon>
            <ThemeDarkSvg />
          </SystemThemeVisual>
        </ThemeImageContainer>
        <ThemeCardTitle as="label">
          <Input
            type="radio"
            value="system"
            checked={isSystemPreference}
            onChange={onRadioChange}
            id="system"
            name="theme"
          />
          System setting
        </ThemeCardTitle>
      </ThemeCard>

      <ThemeCard $isActive={isLightActive}>
        <ThemeImageContainer>
          <ThemeLightSvg onClick={() => setThemePreference("light")} />
        </ThemeImageContainer>

        <ThemeCardTitle as="label" htmlFor="light">
          <Input
            type="radio"
            id="light"
            value="light"
            name="theme"
            onChange={onRadioChange}
            checked={isLightActive}
          />
          Light
        </ThemeCardTitle>
      </ThemeCard>

      <ThemeCard $isActive={isDarkActive}>
        <ThemeImageContainer>
          <ThemeDarkSvg onClick={() => setThemePreference("dark")} />
        </ThemeImageContainer>
        <ThemeCardTitle as="label" htmlFor="dark">
          <Input
            type="radio"
            id="dark"
            value="dark"
            name="theme"
            onChange={onRadioChange}
            checked={isDarkActive}
          />
          Dark
        </ThemeCardTitle>
      </ThemeCard>
    </ScrollContainer>
  );
}

const ThemeImageContainer = styled.div`
  transition: 0.2s transform ease-in-out;
`;

const SystemThemeVisual = styled.div`
  position: relative;
  margin-bottom: -8px; /* offset invisible overflow from SVG */
`;

// Wrap the SVGs in styled component so we can use selectors to style them
const ThemeLightSvg = styled(ThemeLight)``;
const ThemeDarkSvg = styled(ThemeDark)``;

const ThemeCard = styled.div<{ $isActive: boolean }>(
  ({ $isActive, theme }) => css`
    display: grid;
    row-gap: 8px;
    cursor: pointer;

    ${$isActive &&
    css`
      ${ThemeLightSvg}, ${ThemeDarkSvg} {
        border-radius: ${theme.radius.default};
        outline: 2px solid ${theme.color.border.primary};
      }
    `}

    &:hover ${ThemeImageContainer} {
      transform: scale(1.02);
    }
  `
);

const ThemeCardTitle = styled(EnvironmentTitle)`
  grid-area: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const HalfIcon = styled.div`
  position: absolute;
  clip-path: polygon(0 0, 85% 0, 0 110%);
`;
