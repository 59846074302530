import { AskAutometricsPage } from "./AskAutometricsPage";
import { NoPage } from "./NoPage";
import { TimeRangePage } from "./TimeRangePage";

export type PageType = "timeRange" | "askDora";

export type CommandMenuPageProps = {
  hideCommandMenu: () => void;
  pageType: PageType | null;
  query: string;
  setPage: (pageType: PageType | null) => void;
  setQuery: (query: string) => void;
};

export function CommandMenuPage({ pageType, ...props }: CommandMenuPageProps) {
  switch (pageType) {
    case "timeRange":
      return <TimeRangePage {...props} />;
    case "askDora":
      return <AskAutometricsPage {...props} />;
  }

  return <NoPage {...props} />;
}
