import { baseApi, EnvironmentUrl } from "./base";
import {
  PrometheusAlertsResponseSchema,
  PrometheusAlert,
  PrometheusRulesResponseSchema,
  PrometheusAlertingRuleGroup,
} from "../schemas";
import { fetchAndDecode } from "./utils";

export const alertsApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      // NOTE - Returns active alerts
      getAllAlerts: builder.query<
        PrometheusAlert[],
        { environmentUrl: EnvironmentUrl }
      >({
        async queryFn(_args, _queryApi, _extraOptions, fetchWithBq) {
          // Fetch all active alerts
          //
          // https://prometheus.io/docs/prometheus/latest/querying/api/#alerts
          //
          // NOTE: "As the /alerts endpoint is fairly new, it does not have the same stability guarantees as the overarching API v1"
          const alertsResult = await fetchAndDecode(
            fetchWithBq,
            {
              url: `/api/v1/alerts`,
            },
            PrometheusAlertsResponseSchema
          );

          // NOTE - the error could happen due to a network error, or a decoding error
          if ("error" in alertsResult) {
            return alertsResult;
          }

          const { alerts } = alertsResult.data.data;

          return {
            data: alerts,
          };
        },
      }),

      getAllRules: builder.query<
        PrometheusAlertingRuleGroup[],
        { environmentUrl: EnvironmentUrl }
      >({
        async queryFn(_args, _queryApi, _extraOptions, fetchWithBq) {
          // https://prometheus.io/docs/prometheus/latest/querying/api/#rules
          const rulesResult = await fetchAndDecode(
            fetchWithBq,
            {
              url: `/api/v1/rules`,
            },
            PrometheusRulesResponseSchema
          );

          // NOTE - the error could happen due to a network error, or a decoding error
          if ("error" in rulesResult) {
            return rulesResult;
          }

          const { groups: rules } = rulesResult.data.data;

          return {
            data: rules,
          };
        },
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ["Alerts"],
    endpoints: {
      getAllAlerts: {
        providesTags: (_functions, _error, { environmentUrl }) => {
          if (environmentUrl) {
            return [{ type: "Alerts", id: environmentUrl }];
          }

          return [];
        },
      },
    },
  });

export const { useGetAllAlertsQuery, useGetAllRulesQuery } = alertsApi;
