import type { Variants } from "framer-motion";
import { motion } from "framer-motion";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, INITIAL } from "./constants";

export function AnimatedLinkOutIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M3.99902 4L9.99902 4V2L3.99902 2C2.89445 2 1.99902 2.89445 1.99902 3.99902L1.99902 15.999C1.99902 17.1036 2.89445 17.999 3.99902 17.999L15.999 18C17.1036 18 17.999 17.1046 17.999 16L17.999 9H15.999L15.999 16L3.99902 16V4Z"
        fill="currentColor"
      />

      <motion.path
        variants={variants}
        animate={controls}
        onAnimationComplete={onAnimationComplete}
        d="M18.0016 2.00092L17.9985 7.00015L16.0016 7L16.0035 5.65348L9.4126 12.2444L7.99838 10.8302L14.829 3.99959L13.0024 4.00006L13.0016 2L18.0016 2.00092Z"
        fill="currentColor"
      />
    </svg>
  );
}

const variants: Variants = {
  [INITIAL]: {
    x: 0,
    y: 0,
    transition: {
      type: "just",
    },
  },
  [ANIMATE]: {
    x: [0, -1, 2, 2],
    y: [0, 1, -2, -2],
    transition: {
      duration: 0.6,
      type: "tween",
    },
  },
};
