import { Timeseries } from "@fiberplane/prometheus-query";

import { LabelItem } from "./LabelItem";
import { TooltipState } from "../../../../state";

type Props = {
  data: Array<{ alert: Timeseries; matchingIndex: number }>;
  showTooltip(tooltip: TooltipState | null): void;
};

export function Labels(props: Props) {
  return (
    <div>
      {props.data.map(({ alert: timeseries, matchingIndex }, index) => {
        return (
          <LabelItem
            labels={timeseries.labels}
            key={index}
            index={matchingIndex}
            showTooltip={props.showTooltip}
          />
        );
      })}
    </div>
  );
}
