import { Fragment } from "react";
import { useTheme } from "styled-components";

import {
  MINIMAL_NODE_HEIGHT,
  NODE_WIDTH,
  PADDING,
  LEFT,
  LABEL_HEIGHT,
  TOP,
} from "./constants";
import {
  EmptyPattern,
  GradientDefinition,
  StyledPath,
  StyledRectangle,
} from "./sharedStyles";
import { NodeLabel } from "./NodeLabel";
import { CallGraphNode } from "./types";

type Props = {
  totalNodeHeight: number;
  incomingNodes: CallGraphNode[];
  valueToPixelRatio: number;
  middle: number;
  renderValue: (value: number) => string;
  renderGradients?: boolean;
};

export function IncomingNodes(props: Props) {
  const {
    totalNodeHeight,
    incomingNodes,
    valueToPixelRatio,
    renderValue,
    middle,
    renderGradients = false,
  } = props;
  let incomingNodeY = TOP;

  let centerY = 1;
  const halfwayPoint = (middle - LEFT) * 0.5 + LEFT;
  const theme = useTheme();

  return (
    <g>
      <EmptyPattern id="incoming-empty" />
      {incomingNodes.map((node, index) => {
        const nodeHeight = Math.max(
          node.value * valueToPixelRatio,
          MINIMAL_NODE_HEIGHT
        );
        centerY += nodeHeight;

        const topCoordinate =
          nodeHeight < LABEL_HEIGHT
            ? incomingNodeY + 0.5 * (LABEL_HEIGHT - nodeHeight)
            : incomingNodeY;
        const labelY = Math.max(
          incomingNodeY,
          incomingNodeY - 0.5 * (LABEL_HEIGHT - nodeHeight)
        );
        incomingNodeY += Math.max(nodeHeight, LABEL_HEIGHT) + PADDING;

        const topAmountCoordinate = centerY - nodeHeight;
        const fraction =
          (2 * (node.value * valueToPixelRatio)) / totalNodeHeight;

        return (
          <Fragment key={node.name}>
            {renderGradients && (
              <defs>
                <GradientDefinition
                  id={`incoming${index}`}
                  to={theme.color.bg.accent[1]}
                  toOpacity={Math.min(0.7, fraction)}
                />
              </defs>
            )}
            <StyledRectangle
              x={LEFT}
              y={topCoordinate}
              width={NODE_WIDTH}
              height={nodeHeight}
              aria-label={renderValue(node.value)}
            />
            <StyledPath
              fill={node.value === 0 ? "url(#incoming-empty)" : undefined}
              d={`
                M${NODE_WIDTH + LEFT} ${topCoordinate} 
                C${halfwayPoint},${topCoordinate} ${halfwayPoint},${topAmountCoordinate} ${middle},${topAmountCoordinate} 
                L${middle},${centerY} 
                C${halfwayPoint},${centerY} ${halfwayPoint},${
                topCoordinate + nodeHeight
              } ${NODE_WIDTH + LEFT},${topCoordinate + nodeHeight}
                Z
            `}
            />
            {renderGradients && (
              <StyledPath
                fill={`url(#incoming${index})`}
                d={`
                  M${NODE_WIDTH + LEFT} ${topCoordinate} 
                  C${halfwayPoint},${topCoordinate} ${halfwayPoint},${topAmountCoordinate} ${middle},${topAmountCoordinate} 
                  L${middle},${centerY} 
                  C${halfwayPoint},${centerY} ${halfwayPoint},${
                  topCoordinate + nodeHeight
                } ${NODE_WIDTH + LEFT},${topCoordinate + nodeHeight}
                  Z
                `}
              />
            )}
            <NodeLabel
              x={0}
              y={labelY}
              width={192}
              height={LABEL_HEIGHT}
              align="start"
              name={node.name}
              module={node.module}
              service={node.service_name}
              value={renderValue(node.value)}
            />
          </Fragment>
        );
      })}
    </g>
  );
}
