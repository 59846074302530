import styled, { css, DefaultTheme } from "styled-components";

import {
  DashboardSection,
  DashboardSectionHeader,
  DashboardSectionHeading,
} from "./styled";
import { useAnimatedIcon } from "../UI";
import { NewsItem, newsItems } from "./constants";

type LatestNewsProps = {
  graphHeight: number;
};

export function LatestNews({ graphHeight }: LatestNewsProps) {
  return (
    <LatestNewsSection>
      <DashboardSectionHeader>
        <DashboardSectionHeading>Latest news</DashboardSectionHeading>
      </DashboardSectionHeader>
      <DashboardNewsList graphHeight={graphHeight}>
        {newsItems.map((newsItem) => (
          <NewsItemComponent key={newsItem.url} newsItem={newsItem} />
        ))}
      </DashboardNewsList>
    </LatestNewsSection>
  );
}

type NewsItemComponentProps = { newsItem: NewsItem };

function NewsItemComponent({ newsItem }: NewsItemComponentProps) {
  const {
    icon: LinkOutIcon,
    startAnimation,
    animateToInitial,
  } = useAnimatedIcon("link_out");

  return (
    <DashboardNewsItem
      key={newsItem.title}
      href={newsItem.url}
      target="_blank"
      onMouseEnter={startAnimation}
      onMouseLeave={animateToInitial}
    >
      <DashboardNewsItemHeader>
        <DashboardNewsItemType type={newsItem.type}>
          {getNewsTypeLabel(newsItem.type)}
        </DashboardNewsItemType>
        <LinkOutIcon width={16} height={16} />
      </DashboardNewsItemHeader>
      <div>
        <DashboardNewsItemTitle>{newsItem.title}</DashboardNewsItemTitle>
        <DashboardNewsItemDescription>
          {newsItem.description}
        </DashboardNewsItemDescription>
      </div>
      <DashboardNewsItemDate>{newsItem.date}</DashboardNewsItemDate>
    </DashboardNewsItem>
  );
}

function getTypeColor(type: NewsItem["type"], theme: DefaultTheme) {
  switch (type) {
    case "changelog":
      return theme.color.fg.accent[1];
    case "tutorial":
      return theme.color.fg.warning;
    case "news":
      return theme.color.fg.accent[3];
    case "blogPost":
      return theme.color.fg.primary;
  }
}

function getNewsTypeLabel(type: NewsItem["type"]): string {
  return `${type.substring(0, 1).toUpperCase()}${type
    .substring(1)
    .toLowerCase()}`;
}

const LatestNewsSection = styled(DashboardSection)(
  ({ theme }) =>
    css`
      align-items: flex-start;
      min-height: 400px;

      ${DashboardSectionHeader} {
        padding: 12px;
      }

      ${theme.media.md`
        grid-column: 6 / 9;
        grid-row: 1 / 2;
      `}
    `
);

const DashboardNewsList = styled.div<{ graphHeight: number }>(
  ({ graphHeight, theme }) =>
    css`
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      gap: 4px;
      overflow-y: auto;
      max-height: auto;

      ${theme.media.md`
        max-height: ${graphHeight}px;
      `}
    `
);

const DashboardNewsItem = styled.a(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      padding: 12px;
      color: inherit;
      border-radius: ${theme.radius.default};
      gap: 4px;

      ${DashboardNewsItemHeader} svg {
        opacity: 0;
      }

      &:hover {
        background: ${theme.color.bg.hover};

        ${DashboardNewsItemHeader} svg {
          opacity: 1;
        }
      }
    `
);

const DashboardNewsItemHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DashboardNewsItemType = styled.span<{ type: NewsItem["type"] }>(
  ({ theme, type }) =>
    css`
      color: ${getTypeColor(type, theme)};
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 160% */
      letter-spacing: 0.6px;
      text-transform: uppercase;
    `
);

const DashboardNewsItemTitle = styled.h1(
  ({ theme }) =>
    css`
      color: var(--color-fg-default, #000);
      font: ${theme.font.headings.h4};
    `
);

const DashboardNewsItemDescription = styled.p(
  ({ theme }) =>
    css`
      color: var(--color-fg-default, #000);
      font: ${theme.font.body.sm.regular};
    `
);

const DashboardNewsItemDate = styled.span(
  ({ theme }) =>
    css`
      color: var(--color-fg-muted, #8c898f);
      font: ${theme.font.body.xs.regular};
    `
);
