import styled, { css } from "styled-components";
import { Timeseries } from "@fiberplane/prometheus-query";

type Props = Pick<Timeseries, "labels">;

export function AlertLabelTooltip(props: Props) {
  return (
    <Container>
      <table>
        <tbody>
          {Object.entries(props.labels).map(([key, value]) => (
            <tr key={key}>
              <Td>{key}:</Td>
              <Td>{value}</Td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.xs};
  `
);

const Td = styled.td(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.xxs};
  `
);
