import { useMemo } from "react";
import useEffectOnce from "react-use/lib/useEffectOnce";

import { useLocalStorage } from "./useLocalStorage";
import { FEATURES, FEATURES_KEY } from "../constants";
import { type Feature, isFeature } from "../types";

type Features = {
  /**
   * Indicates whether (beta) features are enabled or not. Regardless of
   * individual feature flags' enabled state, this property determines whether
   * the app should enable features or not.
   */
  enabled: boolean;
  features: Array<{
    name: Feature;
    enabled: boolean;
  }>;
};

const initialFeatures: Features = {
  enabled: false,
  features: FEATURES.map((name) => ({
    name,
    enabled: false,
  })),
};

/**
 * Hook that accepts a supported feature name and returns both its enabled state
 * and a setter for changing the state.
 */
export function useFeature(feature: Feature) {
  const [{ enabled: featuresEnabled, features }, setStoredFeatures] =
    useValidStoredFeatures();

  const isFeatureEnabled = useMemo(() => {
    const storedFeature = features.find(({ name }) => name === feature);
    if (!storedFeature) {
      return false;
    }

    return featuresEnabled && storedFeature.enabled;
  }, [feature, features, featuresEnabled]);

  const setIsFeatureEnabled = (featureEnabled: boolean) => {
    const updatedFeatures = FEATURES.map((key) => {
      const existing = features.find(({ name }) => name === key);

      const enabled =
        key === feature ? featureEnabled : existing?.enabled ?? false;

      return {
        name: key,
        enabled,
      };
    });

    setStoredFeatures({ enabled: featuresEnabled, features: updatedFeatures });
  };

  return [isFeatureEnabled, setIsFeatureEnabled] as const;
}

/**
 * Hook that returns the current enabled state of all features and a setter for
 * changing the state.
 */
export function useFeatures() {
  const [storedFeatures, setStoredFeatures] = useValidStoredFeatures();
  const enableFeatures = storedFeatures.enabled;

  const setEnableFeatures = (enabled: boolean) => {
    if (enabled) {
      setStoredFeatures({
        ...storedFeatures,
        enabled,
      });
      return;
    }

    // When features are disabled, we fall back to the initial feature state
    setStoredFeatures(initialFeatures);
  };

  return [enableFeatures, setEnableFeatures] as const;
}

/**
 * Hook that handles the initial state of features based on the query params.
 * This hook should only be called once on app load, and filters out unsupported
 * features.
 */
export function useFeaturesFromSearchParams() {
  const [{ features: validStoredFeatures }, setStoredFeatures] =
    useValidStoredFeatures();

  const searchParams = new URLSearchParams(window.location.search);
  const parameterFeatures = searchParams.get(FEATURES_KEY)?.split(",") || [];
  const validFeatures = new Set(parameterFeatures.filter(isFeature));

  // As the localStorage features aren't updated immediately on initial load, we
  // use an empty array as a fallback. We can remove this fallback once we get
  // rid of the temporary hook below.
  const updatedFeatures = (validStoredFeatures || []).map((storedFeature) => {
    if (validFeatures.has(storedFeature.name)) {
      return {
        ...storedFeature,
        enabled: true,
      };
    }

    return storedFeature;
  });

  useEffectOnce(() => {
    if (validFeatures.size === 0) {
      return;
    }

    setStoredFeatures({
      enabled: true,
      features: updatedFeatures,
    });
  });
}

/**
 * Temporary hook to handle the transition from the old feature to the new
 * feature state. As we're using the same key for both, we need to handle both
 * cases.
 */
function useValidStoredFeatures() {
  const [features, setFeatures] = useLocalStorage<Features>(
    FEATURES_KEY,
    initialFeatures
  );

  if (Array.isArray(features)) {
    const validFeatures = new Set(features.filter(isFeature));

    setFeatures({
      enabled: validFeatures.size > 0,
      features: FEATURES.map((name) => ({
        name,
        enabled: validFeatures.has(name),
      })),
    });
  }

  return [features, setFeatures] as const;
}
