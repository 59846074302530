import { ReactNode } from "react";
import styled, { css } from "styled-components";

type Props = {
  x: number;
  children: ReactNode;
  type: "current" | "now";
};

/**
 * This shows a label to accompany the line showing the time for
 * the current mouse position
 */
export function CurrentTimeLabel(props: Props) {
  return (
    <Label
      style={{
        transform: `translateX(${props.x}px)`,
      }}
    >
      <Content $type={props.type}>{props.children}</Content>
    </Label>
  );
}

const Label = styled.label`
  position: absolute;
  width: max-content;
`;

const Content = styled.div<{ $type: Props["type"] }>(
  ({ theme, $type }) => css`
    border-radius: 4px;
    padding: 0 4px;
    margin-left: -50%;
    width: max-content;
    ${$type === "current"
      ? css`
          background-color: ${theme.color.bg.emphasis.primary};
        `
      : css`
          background-color: ${theme.color.bg.emphasis.default};
        `}
    color: ${theme.color.fg.onemphasis.default};
  `
);
