import { ObjectiveWithCurrentValue } from "../../../schemas";
import { PopIn } from "../../Animations";
import { SloTarget } from "../SloTarget";
import { SloValue } from "../SloValue";
import {
  CardContainer,
  CardInfo,
  CardTarget,
  CardTitle,
  CardValue,
} from "./styled";

type CurrentValueCardProps = {
  objective: ObjectiveWithCurrentValue;
};

export function CurrentValueCard({ objective }: CurrentValueCardProps) {
  const metricLabel =
    objective?.metric === "latency"
      ? "Latency"
      : objective?.metric === "successRate"
      ? "Success rate"
      : "Objective";

  return (
    <CardContainer>
      <CardTitle>{metricLabel} (current)</CardTitle>
      <CardInfo>
        <CardValue>
          <PopIn>
            <SloValue
              currentValue={objective.currentValue}
              objective={objective}
              highlightSuccess
            />
          </PopIn>
        </CardValue>
        <CardTarget>
          Target: <SloTarget objective={objective} />
        </CardTarget>
      </CardInfo>
    </CardContainer>
  );
}
