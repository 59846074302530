import { Prometheus } from "../services";
import { baseApi, EnvironmentUrl } from "./base";
import { fetchAndDecode, getScalarFromPrometheusQueryResponse } from "./utils";
import {
  PrometheusSuccessObjectiveSeriesResponseSchema,
  PrometheusLatencyObjectiveSeriesResponseSchema,
  AmSeriesWithLatencyObjective,
  AmSeriesWithSuccessObjective,
  LatencyObjective,
  SuccessRateObjective,
  Objective,
  ObjectiveWithCurrentValue,
  FunctionModulePair,
  FunctionModulePairWithCurrentValue,
} from "../schemas";
import { sortBy } from "../utils";

export const SLOsApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllTrackedObjectives: builder.query<
        Array<LatencyObjective | SuccessRateObjective>,
        {
          // environmentUrl is used to invalidate the cache when we switch environments, see: providesTags, below
          environmentUrl: EnvironmentUrl;
          start: string;
          end: string;
        }
      >({
        async queryFn(args, _queryApi, _extraOptions, fetchWithBq) {
          // Create search params for series that have success rate objective attached
          // Search for all function_calls series, and only include results that have the following labels set:
          //  - function
          //  - module
          //  - objective_name
          //  - objective_percentile
          const successRateParams = new URLSearchParams();
          successRateParams.append(
            "match[]",
            '{__name__=~"function_calls(_count)?(_total)?", objective_name!="", objective_percentile!="", function!="", module!=""}'
          );

          // Create search params for series that have latency objective attached
          // Search for all function_calls_duration(_seconds)?_count series, and only include results that have the following labels set:
          //  - function
          //  - module
          //  - objective_name
          //  - objective_percentile
          //  - objective_latency_threshold
          const latencyParams = new URLSearchParams();
          latencyParams.append(
            "match[]",
            '{__name__=~"function_calls_duration(_seconds)?_count", objective_name!="", objective_percentile!="", objective_latency_threshold!="", function!="", module!=""}'
          );

          // Fetch the success rate and latency series in parallel
          const [successRateResult, latencyResult] = await Promise.all([
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: successRateParams,
              },
              PrometheusSuccessObjectiveSeriesResponseSchema
            ),
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: latencyParams,
              },
              PrometheusLatencyObjectiveSeriesResponseSchema
            ),
          ]);

          // If either request failed, return the rtk-query error
          // NOTE - the error could happen due to a network error, or a decoding error
          if ("error" in successRateResult) {
            return successRateResult;
          }

          if ("error" in latencyResult) {
            return latencyResult;
          }

          // Combine the success rate and latency objectives into one array
          const successObjectives = getSuccessObjectiveFromSeries(
            successRateResult.data.data
          );
          const latencyObjectives = getLatencyObjectiveFromSeries(
            latencyResult.data.data
          );

          // Collect and sort the objectives by name
          return {
            data: sortBy(
              [...successObjectives, ...latencyObjectives],
              (objective) => objective.name
            ),
          };
        },
      }),
      getAllTrackedObjectivesWithCurrentValue: builder.query<
        ObjectiveWithCurrentValue[],
        {
          // environmentUrl is used to invalidate the cache when we switch environments, see: providesTags, below
          environmentUrl: EnvironmentUrl;
          start: string;
          end: string;
        }
      >({
        async queryFn(args, _queryApi, _extraOptions, fetchWithBq) {
          // Create search params for series that have success rate objective attached
          // Search for all function_calls series, and only include results that have the following labels set:
          //  - function
          //  - module
          //  - objective_name
          //  - objective_percentile
          const successRateParams = new URLSearchParams();
          successRateParams.append(
            "match[]",
            '{__name__=~"function_calls(_count)?(_total)?", objective_name!="", objective_percentile!="", function!="", module!=""}'
          );

          // Create search params for series that have latency objective attached
          // Search for all function_calls_duration(_seconds)?_count series, and only include results that have the following labels set:
          //  - function
          //  - module
          //  - objective_name
          //  - objective_percentile
          //  - objective_latency_threshold
          const latencyParams = new URLSearchParams();
          latencyParams.append(
            "match[]",
            '{__name__=~"function_calls_duration(_seconds)?_count", objective_name!="", objective_percentile!="", objective_latency_threshold!="", function!="", module!=""}'
          );

          // Fetch the success rate and latency series in parallel
          const [successRateResult, latencyResult] = await Promise.all([
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: successRateParams,
              },
              PrometheusSuccessObjectiveSeriesResponseSchema
            ),
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: latencyParams,
              },
              PrometheusLatencyObjectiveSeriesResponseSchema
            ),
          ]);

          // If either request failed, return the rtk-query error
          // NOTE - the error could happen due to a network error, or a decoding error
          if ("error" in successRateResult) {
            return successRateResult;
          }

          if ("error" in latencyResult) {
            return latencyResult;
          }

          // Combine the success rate and latency objectives into one array
          const successObjectives = getSuccessObjectiveFromSeries(
            successRateResult.data.data
          );
          const latencyObjectives = getLatencyObjectiveFromSeries(
            latencyResult.data.data
          );

          // Collect and sort the objectives by name
          const objectives = sortBy(
            [...successObjectives, ...latencyObjectives],
            (objective) => objective.name
          );

          // HACK - fetch all "current values" in parallel, and fail silently for each request
          const { start, end } = args;
          const objectivesWithCurrentValue = await Promise.all(
            objectives.map(async (objective) => {
              const query = getQueryForObjective(objective, start, end);

              const params = new URLSearchParams();
              params.append("query", query);

              const currentValueQuery = await fetchWithBq({
                url: `/api/v1/query`,
                params,
              });

              // NOTE - the `getScalarFromPrometheusQueryResponse` will fail silently if there's no value present
              //        it should log a debug statement, however, about the unexpected response
              const currentValue =
                getScalarFromPrometheusQueryResponse(currentValueQuery);

              return {
                ...objective,
                currentValue,
              };
            })
          );

          return {
            data: objectivesWithCurrentValue,
          };
        },
      }),

      getCurrentValueForObjective: builder.query<
        ObjectiveWithCurrentValue,
        {
          objective: Objective;
          // environmentUrl is used to invalidate the cache when we switch environments, see: providesTags, below
          environmentUrl: EnvironmentUrl;
          start: string;
          end: string;
        }
      >({
        async queryFn(
          { objective, start, end },
          _queryApi,
          _extraOptions,
          fetchWithBq
        ) {
          const query = getQueryForObjective(objective, start, end);

          const params = new URLSearchParams();
          params.append("query", query);

          const currentValueQuery = await fetchWithBq({
            url: `/api/v1/query`,
            params,
          });

          // NOTE - the `getScalarFromPrometheusQueryResponse` will fail silently if there's no value present
          //        it should log a debug statement, however, about the unexpected response
          const currentValue =
            getScalarFromPrometheusQueryResponse(currentValueQuery);

          return {
            data: { ...objective, currentValue },
          };
        },
      }),

      getObjectiveFunctionsWithCurrentValue: builder.query<
        FunctionModulePairWithCurrentValue[],
        {
          // environmentUrl is used to invalidate the cache when we switch environments, see: providesTags, below
          environmentUrl: EnvironmentUrl;
          objective: Objective;
          start: string;
          end: string;
        }
      >({
        async queryFn(args, _queryApi, _extraOptions, fetchWithBq) {
          const successRateParams = new URLSearchParams();
          successRateParams.append(
            "match[]",
            '{__name__=~"function_calls(_count)?(_total)?", objective_name!="", objective_percentile!="", function!="", module!=""}'
          );

          const latencyParams = new URLSearchParams();
          latencyParams.append(
            "match[]",
            '{__name__=~"function_calls_duration(_seconds)?_count", objective_name!="", objective_percentile!="", objective_latency_threshold!="", function!="", module!=""}'
          );

          // Fetch the success rate and latency series in parallel
          const [successRateResult, latencyResult] = await Promise.all([
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: successRateParams,
              },
              PrometheusSuccessObjectiveSeriesResponseSchema
            ),
            fetchAndDecode(
              fetchWithBq,
              {
                url: `/api/v1/series`,
                params: latencyParams,
              },
              PrometheusLatencyObjectiveSeriesResponseSchema
            ),
          ]);

          // If either request failed, return the rtk-query error
          // NOTE - the error could happen due to a network error, or a decoding error
          if ("error" in successRateResult) {
            return successRateResult;
          }

          if ("error" in latencyResult) {
            return latencyResult;
          }

          // Combine the success rate and latency objectives into one array
          const successObjectives = getSuccessObjectiveFromSeries(
            successRateResult.data.data
          );
          const latencyObjectives = getLatencyObjectiveFromSeries(
            latencyResult.data.data
          );

          // Collect and sort the objectives by name
          const objectives = sortBy(
            [...successObjectives, ...latencyObjectives],
            (objective) => objective.name
          );

          const objective = objectives.find(
            ({ name, metric }) =>
              name === args.objective.name && metric === args.objective.metric
          );

          if (objective) {
            // HACK - fetch all "current values" in parallel, and fail silently for each request
            const functionsWithCurrentValue = await Promise.all(
              objective.functions.map(async (fn) => {
                const query = getQueryForObjective(
                  objective,
                  args.start,
                  args.end,
                  {
                    name: fn.name,
                    module: fn.module,
                    service_name: fn.service_name,
                  }
                );

                const params = new URLSearchParams();
                params.append("query", query);

                const currentValueQuery = await fetchWithBq({
                  url: `/api/v1/query`,
                  params,
                });

                const currentValue =
                  getScalarFromPrometheusQueryResponse(currentValueQuery);

                return { ...fn, currentValue };
              })
            );

            // OPTIMIZE - We don't have to N+1 query for the data we need.
            //            Here is an example of how to get all the function currentValues we need
            //            using only one query:
            //
            // if (objective.metric === "latency") {
            //   const _mehParams = new URLSearchParams();
            //   _mehParams.append(
            //     "query",
            //     Prometheus.createSLOQueryLatencyUnderThresholdByFunction(
            //       objective,
            //       args.start,
            //       args.end
            //     )
            //   );

            //   const functionsWithCurrentValueAlt = await fetchAndDecode(
            //     fetchWithBq,
            //     {
            //       url: `/api/v1/query`,
            //       params: _mehParams,
            //     },
            //     PrometheusObjectiveFunctionModulePairCurrentValueResponseSchema
            //   );

            //   console.log("HIII", functionsWithCurrentValueAlt);
            // }

            return {
              data: functionsWithCurrentValue,
            };
          }

          return { data: [] };
        },
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ["Objective", "ObjectiveCurrentValue", "ObjectiveFunctions"],
    endpoints: {
      getAllTrackedObjectives: {
        providesTags: (objectives, _error, { environmentUrl, start, end }) => {
          if (environmentUrl && objectives && objectives.length > 0) {
            return objectives.map((objective) => {
              return {
                type: "Objective",
                id: `${environmentUrl}:${getObjectiveCacheKey(
                  objective
                )}:${start}:${end}`,
              };
            });
          }

          return [{ type: "Objective" }];
        },
      },

      getCurrentValueForObjective: {
        providesTags: (objective, _error, { environmentUrl, start, end }) => {
          if (environmentUrl && objective) {
            return [
              {
                type: "ObjectiveCurrentValue",
                id: `${environmentUrl}:${getObjectiveCacheKey(
                  objective
                )}:${start}:${end}`,
              },
            ];
          }

          return [{ type: "ObjectiveCurrentValue" }];
        },
      },

      getObjectiveFunctionsWithCurrentValue: {
        providesTags: (
          _functions,
          _error,
          { environmentUrl: instanceUrl, objective }
        ) => {
          if (instanceUrl) {
            return [
              {
                type: "ObjectiveFunctions",
                id: `${instanceUrl}:${getObjectiveCacheKey(objective)}`,
              },
            ];
          }

          return [{ type: "ObjectiveFunctions" }];
        },
      },
    },
  });

function getObjectiveCacheKey({ name, metric, target }: Objective) {
  return `${name}:${metric}:${target.percentile}${
    "threshold" in target ? `:${target.threshold}` : ""
  }`;
}

export const {
  useGetAllTrackedObjectivesQuery,
  useGetAllTrackedObjectivesWithCurrentValueQuery,
  useGetCurrentValueForObjectiveQuery,
  useGetObjectiveFunctionsWithCurrentValueQuery,
} = SLOsApi;

function getLatencyObjectiveKey({
  objective_name,
  objective_percentile,
  objective_latency_threshold,
}: AmSeriesWithLatencyObjective): string {
  return `${objective_name}:latency:${objective_percentile}:${objective_latency_threshold}`;
}

function getLatencyObjectiveFromSeries(
  series: AmSeriesWithLatencyObjective[]
): LatencyObjective[] {
  // Create an object whose keys are a combination of objective names and settings.
  const objectivesByName = series.reduce((result, currentSeries) => {
    const key = getLatencyObjectiveKey(currentSeries);
    const objectiveName = currentSeries.objective_name;

    if (!result[key]) {
      result[key] = {
        series: [],
        name: objectiveName,
        functions: [],
        metric: "latency",
        target: {
          percentile: currentSeries.objective_percentile,
          threshold: currentSeries.objective_latency_threshold,
        },
      };
    }

    result[key]?.series.push(currentSeries);

    return result;
  }, {} as Record<string, LatencyObjective>);

  // NOTE - If you modify your objectives, old labels will also show up.
  //        We need some form of conflict resolution :grimace:
  return transformObjectiveMetadata(objectivesByName);
}

function getSuccessObjectiveKey({
  objective_name,
  objective_percentile,
}: AmSeriesWithSuccessObjective): string {
  return `${objective_name}:successRate:${objective_percentile}`;
}

function getSuccessObjectiveFromSeries(
  series: AmSeriesWithSuccessObjective[]
): SuccessRateObjective[] {
  // Create an object whose keys are a combination of objective names and settings.
  const objectivesByName = series.reduce((result, currentSeries) => {
    const key = getSuccessObjectiveKey(currentSeries);
    const objectiveName = currentSeries.objective_name;

    if (!result[key]) {
      result[key] = {
        series: [],
        name: objectiveName,
        functions: [],
        metric: "successRate",
        target: {
          percentile: currentSeries.objective_percentile,
        },
      };
    }

    result[key]?.series.push(currentSeries);

    return result;
  }, {} as Record<string, SuccessRateObjective>);

  return transformObjectiveMetadata(objectivesByName);
}

/**
 * Helper function for the final step of transforming objective metadata
 */
function transformObjectiveMetadata<
  T extends LatencyObjective | SuccessRateObjective
>(objectivesByName: Record<string, T>): T[] {
  // NOTE - If you modify your objectives, old labels will also show up.
  //        We need some form of conflict resolution :grimace:
  for (const objectiveKey of Object.keys(objectivesByName)) {
    const objectiveMeta = objectivesByName[objectiveKey];
    if (!objectiveMeta) {
      continue;
    }

    // First, find all functions associated with this SLO
    // TODO - Should we check against the latest build? To make sure we're not using old functions that are no longer part of the SLO
    objectiveMeta.functions = Prometheus.filterUniqueFunctionModulePairs(
      objectiveMeta.series.map((entry) => ({
        name: entry.function,
        module: entry.module,
        service_name: "",
      }))
    );
    sortBy(objectiveMeta.functions, (objective) => objective.name);
    objectiveMeta.functionsCount = objectiveMeta.functions.length;
  }

  return Object.values(objectivesByName);
}

// Helper function to get query for SL0
function getQueryForObjective(
  objective: Objective,
  start: string,
  end: string,
  functionModuleLabelPair?: FunctionModulePair
) {
  if (objective.metric === "latency") {
    return Prometheus.createSLOQueryLatencyUnderThreshold(
      objective,
      start,
      end,
      functionModuleLabelPair
    );
  }

  if (objective.metric === "successRate") {
    return Prometheus.createSLOQuerySuccessRate(
      objective,
      start,
      end,
      functionModuleLabelPair
    );
  }

  return "";
}
