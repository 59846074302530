import type {
  CTACardProps,
  DocumentationCardProps,
  TutorialCardProps,
  VisualCardProps,
} from "./ContentCards";
import RustIcon from "./icons/Rust.svg";
import TypeScriptIcon from "./icons/TypeScript.svg";
import GoLangIcon from "./icons/GoLang.svg";
import PythonIcon from "./icons/Python.svg";
import CSharpIcon from "./icons/CSharp.svg";

export const CTA_CARDS: Array<
  Omit<CTACardProps, "brandColor" | "brandColorGradientTo">
> = [
  {
    cta: "Watch the quickstart video",
    title: "Rust",
    url: "https://docs.autometrics.dev/rust/quickstart",
    icon: RustIcon,
  },
  {
    cta: "Watch the quickstart video",
    title: "Typescript",
    url: "https://docs.autometrics.dev/typescript/quickstart",
    icon: TypeScriptIcon,
  },
  {
    cta: "Watch the quickstart video",
    title: "Go",
    url: "https://docs.autometrics.dev/go/quickstart",
    icon: GoLangIcon,
  },
  {
    cta: "Watch the quickstart video",
    title: "Python",
    url: "https://docs.autometrics.dev/python/quickstart",
    icon: PythonIcon,
  },
  {
    cta: "Visit the Repo",
    title: "C#",
    description: "Community contributed!",
    url: "https://github.com/autometrics-dev/autometrics-cs#getting-started",
    icon: CSharpIcon,
  },
];

export const DOCUMENTATION_CARDS: Array<
  Omit<DocumentationCardProps, "brandColor">
> = [
  {
    title: "Why Autometrics?",
    description:
      "Learn more about using metrics for faster and cheaper monitoring.",
    iconType: "autometrics_color",
    url: "https://docs.autometrics.dev/why-autometrics",
  },
  {
    title: "Local development",
    description:
      "Test your metrics and alerts with a proper local development workflow.",
    iconType: "desktop",
    url: "https://docs.autometrics.dev/local-development",
  },
  {
    title: "Configuring Prometheus",
    description:
      "Let's ship it. Learn more how to configure Prometheus in production.",
    iconType: "prometheus",
    url: "https://docs.autometrics.dev/configuring-prometheus",
  },
];

export const TUTORIAL_CARDS: Array<TutorialCardProps> = [
  {
    title: "Rust",
    image: "https://i.ytimg.com/vi/Slnjt1TPDBo/hqdefault.jpg",
    description: "Learn how to get started with Autometrics in Rust.",
    ctas: [
      {
        title: "Watch the quickstart video",
        url: "https://www.youtube.com/watch?v=Slnjt1TPDBo&t=18s",
      },
      {
        title: "Read the docs",
        url: "https://docs.autometrics.dev/rust/quickstart",
      },
    ],
  },
  {
    title: "TypeScript",
    image: "https://i.ytimg.com/vi/iRmaufIu-rk/hqdefault.jpg",
    description: "Learn how to get started with Autometrics in TypeScript.",
    ctas: [
      {
        title: "Watch the quickstart video",
        url: "https://www.youtube.com/watch?v=iRmaufIu-rk&t=19s",
      },
      {
        title: "Read the docs",
        url: "https://docs.autometrics.dev/typescript/quickstart",
      },
    ],
  },
  {
    title: "Go",
    image: "https://i.ytimg.com/vi/TbHKYjzsX-c/hqdefault.jpg",
    description: "Learn how to get started with Autometrics in Go.",
    ctas: [
      {
        title: "Watch the quickstart video",
        url: "https://www.youtube.com/watch?v=TbHKYjzsX-c&t=3s",
      },
      {
        title: "Read the docs",
        url: "https://docs.autometrics.dev/go/quickstart",
      },
    ],
  },
  {
    title: "Python",
    image: "https://i.ytimg.com/vi/X3U1d9wzYM8/hqdefault.jpg",
    description: "Learn how to get started with Autometrics in Python.",
    ctas: [
      {
        title: "Watch the quickstart video",
        url: "https://www.youtube.com/watch?v=X3U1d9wzYM8&t=6s",
      },
      {
        title: "Read the docs",
        url: "https://docs.autometrics.dev/python/quickstart",
      },
    ],
  },
  {
    title: "C#",
    description: "Learn how to get started with Autometrics in C#.",
    icon: CSharpIcon,
    ctas: [
      {
        title: "Read the docs",
        url: "https://github.com/autometrics-dev/autometrics-cs#getting-started",
      },
    ],
  },
];

export const VISUAL_CARDS: Array<VisualCardProps> = [
  {
    title: "Functions",
    image: new URL("images/functions.png", import.meta.url).toString(),
    description:
      "Dig into useful metrics for your instrumented functions, like their request rate, error rate, and latency.",
    url: "/functions",
  },
  {
    title: "SLOs",
    image: new URL("images/slos.png", import.meta.url).toString(),
    description:
      "Check up on the performance targets for your applications, and make sure everything's running smoothly.",
    url: "/slos",
  },
  {
    title: "Alerts",
    image: new URL("images/alerts.png", import.meta.url).toString(),
    description:
      "Look at current or historical alerts, and investigate which functions are causing issues.",
    url: "alerts",
  },
];
