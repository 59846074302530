import { memo } from "react";
import { Route, Routes } from "react-router-dom";

import { Layout } from "../Layout";
import { FunctionsExplorer } from "../FunctionsExplorer";
import { Settings } from "../Settings";
import { Home } from "../Home";
import { Slos } from "../Slos";
import { Alerts } from "../Alerts";
import { usePrometheusUrlQueryParameter } from "./hooks";
import { AlertsPolling } from "../AlertsPolling";
import { useCurrentTheme, useFeaturesFromSearchParams } from "../../hooks";
import { CommandMenu } from "../CommandMenu";
import { ActiveTooltip, useTextTooltips } from "../Tooltip";
import { Dashboard } from "../Dashboard";

// TODO - Implement 404
function NotFound() {
  return <div>404 - Not Found</div>;
}

export const App = memo(function App() {
  usePrometheusUrlQueryParameter();
  useCurrentTheme();
  useTextTooltips();
  useFeaturesFromSearchParams();

  return (
    <Layout>
      <AlertsPolling />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/functions/*" element={<FunctionsExplorer />} />
        <Route path="/slos/*" element={<Slos />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <CommandMenu />
      <ActiveTooltip />
    </Layout>
  );
});
