import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { MetricType } from "../../../api";
import { getFunctionName, getMetricType, getModuleName } from "./utils";

export function usePrometheusQuery() {
  const [params] = useSearchParams();
  const rawQuery = params.get("g0.expr") ?? "";

  const result = useMemo(():
    | undefined
    | {
        functionName?: string;
        moduleName?: string;
        metricType?: MetricType;
      } => {
    if (rawQuery === "") {
      return;
    }

    const functionName = getFunctionName(rawQuery);
    const moduleName = getModuleName(rawQuery);
    const metricType = getMetricType(rawQuery);

    return {
      functionName,
      moduleName,
      metricType,
    };
  }, [rawQuery]);

  return {
    query: rawQuery,
    result,
  };
}
