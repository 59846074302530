import { useState, useMemo } from "react";
import {
  MetricsChart,
  ProviderEvent,
  TickFormattersFactory,
  getDurationFormatterForAxis,
  getPercentageFormatter,
  getScientificFormatterForAxis,
  getTimeFormatterForAxis,
} from "@fiberplane/charts";

import { NoData } from "./NoData";
import { TooltipState } from "../../../state";
import { Tooltip, ChartTooltip } from "../../Tooltip";
import type { MetricType } from "../../../api";
import { GraphKeyboardShortcutsHelp } from "../../GraphKeyboardShortcutsHelp";
import { useChartTheme } from "../../../hooks";
import { DatePickerTimeRange } from "../../../utils";
import { FunctionDetailsMetricPrometheusData } from "./hooks";
import {
  GraphObjectiveMetricsWrapper,
  GraphWrapper,
  Grid,
  SubtleMetricDisplay,
} from "./common";

type GraphProps = {
  color: string;
  currentMetric: FunctionDetailsMetricPrometheusData;
  currentMetricData: any;
  events?: Array<ProviderEvent>;
  metricType: MetricType;
  onChangeTimeRange: (newTimeRange: DatePickerTimeRange) => void;
  timeRange: DatePickerTimeRange;
};

export const Graph = (props: GraphProps) => {
  const {
    color,
    currentMetric,
    currentMetricData,
    metricType,
    onChangeTimeRange,
    events,
    timeRange,
  } = props;

  const graphTheme = useChartTheme(color);

  const [tooltipState, setTooltipState] = useState<null | TooltipState>(null);

  const { hideableMetricData, toggleSeriesMatchingLabel } =
    useHideableMetricData(currentMetricData);

  const hasMultipleMetrics = currentMetricData?.length > 1;

  const tickFormattersFactory = useMemo(
    (): TickFormattersFactory => (xAxis, yAxis) => ({
      xFormatter: getTimeFormatterForAxis(xAxis),
      yFormatter:
        metricType === "latency"
          ? getDurationFormatterForAxis(yAxis)
          : metricType === "errorRatio"
          ? getPercentageFormatter()
          : getScientificFormatterForAxis(yAxis),
    }),
    [metricType]
  );

  return (
    <Grid>
      {currentMetricData && currentMetricData.length > 0 && (
        // grid-area: "metrics"
        <GraphObjectiveMetricsWrapper>
          {currentMetric.metrics?.map(({ reportType, value, unit }) => (
            <SubtleMetricDisplay
              key={reportType}
              rate={value}
              unit={unit}
              report={reportType}
            />
          ))}
        </GraphObjectiveMetricsWrapper>
      )}

      {/* grid-area: "graph" */}
      <GraphWrapper>
        <MetricsChart
          chartTheme={graphTheme}
          graphType="line"
          stackingType="none"
          timeRange={timeRange}
          timeseriesData={hideableMetricData ?? []}
          onChangeTimeRange={(newTimeRange) => {
            onChangeTimeRange(newTimeRange);
          }}
          footerShown={false}
          showTooltip={(anchor, [series, point]) => {
            if (point) {
              setTooltipState({
                anchor: () => anchor,
                content: () => <ChartTooltip content={[series, point]} />,
                options: { placement: "top" },
              });
            }

            return () => setTooltipState(null);
          }}
          events={events}
          onToggleTimeseriesVisibility={(metric) => {
            toggleSeriesMatchingLabel({
              function: metric?.timeseries?.labels?.function ?? "",
              module: metric?.timeseries?.labels?.module ?? "",
              percentile_latency:
                metric?.timeseries?.labels?.percentile_latency ?? "",
              version: metric?.timeseries?.labels?.version ?? "",
              commit: metric?.timeseries?.labels?.commit ?? "",
              service_name: metric?.timeseries?.labels?.service_name ?? "",
            });
          }}
          legendShown={hasMultipleMetrics}
          tickFormatters={tickFormattersFactory}
          shouldAnimateYScale={false}
        />

        {!currentMetricData || (currentMetricData.length === 0 && <NoData />)}

        {currentMetricData && currentMetricData.length > 0 && (
          <GraphKeyboardShortcutsHelp />
        )}
      </GraphWrapper>
      {tooltipState && <Tooltip tip={tooltipState} />}
    </Grid>
  );
};

function useHideableMetricData(currentMetricData: any) {
  const [hiddenLabels, setHiddenLabels] = useState<
    {
      function: string;
      module: string;
      percentile_latency: string;
      version: string;
      commit: string;
      service_name: string;
    }[]
  >([]);

  const hideableMetricData = useMemo(() => {
    return currentMetricData?.map((metric: any) => {
      const isHidden = hiddenLabels.some((label) => {
        return (
          metric?.labels?.function === label.function &&
          metric?.labels?.module === label.module &&
          (metric?.labels?.percentile_latency ?? "") ===
            label.percentile_latency &&
          (metric?.labels?.version ?? "") === label.version &&
          (metric?.labels?.commit ?? "") === label.commit &&
          (metric?.labels?.service_name ?? "") === label.service_name
        );
      });

      return {
        ...metric,
        visible: !isHidden,
      };
    });
  }, [hiddenLabels, currentMetricData]);

  const toggleSeriesMatchingLabel = (timeseriesLabels: {
    function: string;
    module: string;
    percentile_latency: string;
    version: string;
    commit: string;
    service_name: string;
  }) => {
    setHiddenLabels((prevHiddenLabels) => {
      const isHidden = prevHiddenLabels.some(
        (label) =>
          label.function === timeseriesLabels?.function &&
          label.module === timeseriesLabels?.module &&
          label.percentile_latency === timeseriesLabels?.percentile_latency &&
          label.version === timeseriesLabels?.version &&
          label.commit === timeseriesLabels?.commit &&
          label.service_name === timeseriesLabels?.service_name
      );
      return isHidden
        ? prevHiddenLabels.filter((label) => {
            return (
              label.function !== timeseriesLabels?.function &&
              label.module !== timeseriesLabels?.module &&
              label.percentile_latency !==
                timeseriesLabels?.percentile_latency &&
              label.version !== timeseriesLabels?.version &&
              label.commit !== timeseriesLabels?.commit &&
              label.service_name !== timeseriesLabels?.service_name
            );
          })
        : [
            ...prevHiddenLabels,
            {
              function: timeseriesLabels?.function,
              module: timeseriesLabels?.module,
              percentile_latency: timeseriesLabels?.percentile_latency,
              version: timeseriesLabels?.version,
              commit: timeseriesLabels?.commit,
              service_name: timeseriesLabels?.service_name,
            },
          ];
    });
  };

  return { hideableMetricData, toggleSeriesMatchingLabel };
}
