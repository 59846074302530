import React, { useMemo, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

import type { FunctionData, FunctionTreeNodeData } from "../../../types";
import { useIsActiveModuleNode } from "./hooks";
import { Icon } from "../../UI";
import { getFunctionTreeData } from "./data";
import { getFunctionPath, isSafari } from "../../../utils";

export const FunctionTreeList: React.FC<{
  autometricsData: FunctionData[];
  filterValue: string;
}> = ({ autometricsData, filterValue }) => {
  const filteredAutometricsData = useMemo<FunctionData[]>(() => {
    if (!filterValue) {
      return autometricsData;
    }

    return autometricsData.filter(
      (data) =>
        data.name.toLowerCase().includes(filterValue.toLowerCase()) ||
        data.module?.toLowerCase().includes(filterValue.toLowerCase())
    );
  }, [autometricsData, filterValue]);

  const tree = useMemo<FunctionTreeNodeData[]>(() => {
    return getFunctionTreeData(filteredAutometricsData || []);
  }, [filteredAutometricsData]);

  const showEmptyFilterState =
    !!filterValue && filteredAutometricsData.length === 0;

  return (
    <>
      <FunctionTreeContainer>
        {tree.map((node) => (
          <FunctionTreeNode
            key={node.id}
            node={node}
            forceExpand={!!filterValue}
          />
        ))}
        {showEmptyFilterState && (
          <NoFilteredFunctions
            key="functionTreeListLoadingOuter"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              duration: 0.1,
            }}
          >
            <Icon type="autometrics" />
            <div>No matching functions</div>
          </NoFilteredFunctions>
        )}
      </FunctionTreeContainer>
    </>
  );
};

type FunctionTreeNodeProps = {
  node: FunctionTreeNodeData;
  forceExpand: boolean;
};

/**
 * Render {@link FunctionTreeNodeData}
 *
 * Note: This component is recursive
 */
function FunctionTreeNode({ node, forceExpand }: FunctionTreeNodeProps) {
  const isActiveModuleNode = useIsActiveModuleNode(node);
  const [isModuleExpanded, setIsModuleExpanded] = useState(isActiveModuleNode);
  const [currentSearchParams] = useSearchParams();

  const isModule = node.type === "module" || node.type === "service";
  const iconClosed =
    node.type === "service" ? "modules_duotone" : "folder_duotone";
  const iconOpen =
    node.type === "service" ? "modules_duotone" : "folder_open_duotone";

  const onModuleClick = () => setIsModuleExpanded((expanded) => !expanded);

  return (
    <FunctionTreeNodeContainer isModule={isModule}>
      {isModule ? (
        <>
          <ModuleName onClick={onModuleClick}>
            <ModuleIcon
              type={
                isModuleExpanded || forceExpand ? "caret_down" : "caret_right"
              }
            />
            <ModuleIcon
              type={isModuleExpanded || forceExpand ? iconOpen : iconClosed}
            />
            <Ellipsis title={node.name}>{node.name}</Ellipsis>
          </ModuleName>

          {node.children && (isModuleExpanded || forceExpand) && (
            <FunctionTreeNodeChildrenContainer
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
            >
              {node.children.map((node) => (
                <FunctionTreeNode
                  key={node.id}
                  node={node}
                  forceExpand={false}
                />
              ))}
            </FunctionTreeNodeChildrenContainer>
          )}
        </>
      ) : (
        <FunctionNavLink
          to={{
            pathname: getFunctionPath(
              node.name,
              node.module,
              node.service_name
            ),
            // Preserve current search params because they'll contain the time range
            search: currentSearchParams.toString(),
          }}
        >
          {({ isActive }) => (
            <FunctionName $isActive={isActive}>
              <ModuleIcon type="function_duotone" />
              <Ellipsis title={node.name}>{node.name}</Ellipsis>
            </FunctionName>
          )}
        </FunctionNavLink>
      )}
    </FunctionTreeNodeContainer>
  );
}

const gridTemplateColumns = css`
  grid-template-columns: 24px 32px 1fr;
`;

const GridContainer = styled(motion.div)`
  display: grid;
  row-gap: 8px;
`;

const FunctionTreeContainer = styled(GridContainer)`
  position: relative;
`;

const FunctionTreeNodeContainer = styled(GridContainer)<{ isModule: boolean }>(
  ({ isModule }) => isModule && gridTemplateColumns
);

const FunctionTreeNodeChildrenContainer = styled(GridContainer)`
  grid-column: 2 / -1;
`;

const ModuleIcon = styled(Icon)`
  width: 24px;
`;

const ModuleName = styled.div(
  ({ theme }) =>
    css`
      ${gridTemplateColumns};

      display: grid;
      grid-column: 1 / -1;
      align-items: center;
      cursor: pointer;
      color: ${theme.color.fg.muted};
      isolation: isolate;
      user-select: none;

      ${ModuleIcon} {
        color: ${theme.color.fg.subtle};
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        border-radius: ${theme.radius.default};
        background-color: transparent;
        height: 32px;
        width: 100%;
        z-index: -1;

        ${isSafari &&
        css`
          transform: translateY(-4px);
        `}
      }

      &:hover::before {
        background-color: ${theme.color.bg.hover};
      }
    `
);

const FunctionName = styled(ModuleName)<{ $isActive: boolean }>(
  ({ $isActive, theme }) =>
    css`
      grid-template-columns: 24px 1fr;
      column-gap: 8px;
      transition: background-color 0.1s ease-in-out;

      ${$isActive &&
      css`
        font: ${theme.font.body.md.medium};
        border-radius: ${theme.radius.default};
        color: ${theme.color.fg.onemphasis.default};

        &&::before {
          background-color: ${theme.color.bg.emphasis.primary};
        }
      `}

      ${ModuleIcon} {
        color: ${$isActive ? "inherit" : theme.color.fg.subtle};
      }
    `
);

const FunctionNavLink = styled(NavLink)`
  text-decoration: none;
`;

const NoFilteredFunctions = styled(motion.div)`
  margin-top: 22px; /* magic number - when search is unhidden, change to 20px */
  padding-top: 40px; /* offset for the hover bg element */
  text-align: center;

  color: ${({ theme }) => theme.color.fg.muted};
  font-size: 12px;
`;

const Ellipsis = styled.span`
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
`;
