import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowData,
  SortingState,
  useReactTable,
  Row,
} from "@tanstack/react-table";
import { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import { useSearchParams } from "react-router-dom";

import { Icon, IconButton } from "../UI";
import { useHandler } from "../../hooks";
import { createExpandedSearchParamsUpdater } from "./utils";
import { Skeleton } from "../Skeleton";

type DataTableProps<TData extends RowData> = {
  columns: ColumnDef<TData, any>[];
  data: TData[];
  isLoading?: boolean;
  onRowClick?: (row: TData) => void;
  /**
   * If this is set the table will render an expandable row
   * and when a cell is expanded, this function is called to
   * render the expanded content (which is rendered in a row
   * with a single table cell that spans the full width)
   */
  renderExpanded?: (cell: Row<TData>) => React.ReactNode;
};

export function DataTable<TData extends RowData>({
  columns,
  data,
  isLoading,
  onRowClick,
  renderExpanded,
}: DataTableProps<TData>) {
  const [sorting, setSorting] = useState<SortingState>([]);
  // State for search params
  const [searchParams, setSearchParams] = useSearchParams();

  // Get the expanded row ID from the URL query parameter
  const expandedRowIds = searchParams.getAll("expanded");

  // Set up the state for the table
  const state = renderExpanded
    ? {
        sorting,
        expanded:
          expandedRowIds.length > 0
            ? expandedRowIds.reduce((object, item: string) => {
                object[item] = true;
                return object;
              }, {} as Record<string, boolean>)
            : {},
      }
    : { sorting };

  const onExpandedChange = useHandler((updater: unknown) => {
    // The type of updater is either a function a Record<string, boolean> or a boolean
    // However we don't support boolean values right now (that would mean we'd expand all?)

    if (typeof updater === "function") {
      const value = updater(state.expanded);
      setSearchParams(createExpandedSearchParamsUpdater(value));
      return;
    }

    if (updater && typeof updater === "object") {
      // Final possible type is a Record<string, boolean>
      setSearchParams(
        createExpandedSearchParamsUpdater(updater as Record<string, boolean>)
      );
    }
  });

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onExpandedChange: renderExpanded ? onExpandedChange : undefined,
    state,
  });

  return (
    <StyledTable>
      <StyledThead>
        {table.getHeaderGroups().map((headerGroup) => (
          <StyledTr key={headerGroup.id}>
            {renderExpanded && <StyledTh />}
            {headerGroup.headers.map((header) => {
              const canSort = header.column.getCanSort();
              const isSorted = header.column.getIsSorted();
              const onClick = header.column.getToggleSortingHandler();

              return (
                <StyledTh key={header.id}>
                  {header.isPlaceholder ? null : (
                    <ColumnSortButton
                      $active={isSorted !== false}
                      $canSort={canSort}
                      onClick={onClick}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <SortIcon
                        type={
                          isSorted === "desc"
                            ? "caret_down"
                            : isSorted === "asc"
                            ? "caret_up"
                            : "caret_double"
                        }
                      />
                    </ColumnSortButton>
                  )}
                </StyledTh>
              );
            })}
          </StyledTr>
        ))}
      </StyledThead>

      <tbody>
        {isLoading ? (
          <>
            {[1, 2, 3, 4].map((i) => (
              <StyledTr key={i}>
                {renderExpanded && (
                  <StyledTd>
                    <StyledSkeleton />
                  </StyledTd>
                )}
                {Array.from({ length: columns.length }).map((_, index) => (
                  <StyledTd key={index}>
                    <StyledSkeleton />
                  </StyledTd>
                ))}
              </StyledTr>
            ))}
          </>
        ) : (
          <>
            {table.getRowModel().rows.map((row) => {
              const isExpanded = row.getIsExpanded() || false;
              const result = (
                <StyledTr
                  key={row.id}
                  $elevated={isExpanded}
                  $borderLess={isExpanded}
                  onClick={
                    onRowClick ? () => onRowClick(row.original) : undefined
                  }
                >
                  {renderExpanded && (
                    <StyledTd>
                      <IconButton
                        onClick={() => row.toggleExpanded()}
                        iconType={isExpanded ? "caret_down" : "caret_right"}
                        buttonStyle="tertiary-grey"
                      />
                    </StyledTd>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <StyledTd key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </StyledTd>
                  ))}
                </StyledTr>
              );
              return isExpanded && renderExpanded ? (
                <Fragment key={row.id}>
                  {result}
                  <StyledTr $elevated={isExpanded}>
                    <StyledTd colSpan={columns.length + 1}>
                      {renderExpanded(row)}
                    </StyledTd>
                  </StyledTr>
                </Fragment>
              ) : (
                result
              );
            })}
          </>
        )}
      </tbody>

      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </StyledTable>
  );
}

const StyledTable = styled.table`
  border-collapse: collapse;
  max-width: 100%;
  table-layout: fixed;
`;

const StyledTr = styled.tr<{ $elevated?: boolean; $borderLess?: boolean }>(
  ({
    theme,
    onClick,
    $elevated: elevated = false,
    $borderLess: borderLess = false,
  }) => css`
    border-bottom: ${borderLess
      ? "none"
      : `1px solid ${theme.color.border.default}`};
    background: ${elevated ? theme.color.bg.subtle : "transparent"};
    height: 56px;

    ${onClick &&
    css`
      &:hover {
        background: ${theme.color.bg.subtle};
        cursor: pointer;
      }
    `}
  `
);

const StyledTd = styled.td(
  ({ theme }) => css`
    color: ${theme.color.fg.default};
    padding: ${theme.spacing.xs};
    font: ${theme.font.body.sm.medium};
    align-items: center;
    width: 1%;
    white-space: nowrap;

    &:first-child {
      padding-left: ${theme.spacing.s};
    }

    &:last-child {
      padding-right: ${theme.spacing.s};
    }
  `
);

const StyledTh = styled(StyledTd).attrs({ as: "th" })(
  () => css`
    text-align: left;
    font-weight: 600;
    padding: 4px 3px;
  `
);

const StyledThead = styled.thead`
  ${StyledTr} {
    border-bottom-width: 2px;

    &:hover {
      background: inherit;
    }
  }
`;

const ColumnSortButton = styled.button<{
  $active?: boolean;
  $canSort: boolean;
}>(
  ({ theme, $active, $canSort }) => css`
    display: inline-flex;
    align-items: center;
    gap: 4px;
    padding: 2px 4px;
    font: inherit;
    letter-spacing: inherit;

    border-radius: ${theme.radius.minimal};
    border: 1px solid ${theme.color.bg.default};
    color: ${$active ? theme.color.fg.default : theme.color.fg.muted};
    background: ${theme.color.bg.default};

    ${$canSort &&
    css`
      user-select: none;
      cursor: ${$active === undefined ? "initial" : "pointer"};

      &:hover {
        background: ${theme.color.bg.hover};
        border-color: ${theme.color.bg.hover};
      }

      &:focus {
        outline: none;
        background: ${theme.color.bg.default};
        border-color: ${theme.color.border.primary};
        box-shadow: 0px 0px 0px 4px rgba(165, 80, 255, 0.2);
      }
    `}
  `
);

export const SortIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

const StyledSkeleton = styled(Skeleton)`
  min-height: 38px;
`;
