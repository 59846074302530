import styled from "styled-components";
import { Button } from "@fiberplane/ui";

import { Icon } from "../UI";
import {
  Container,
  Content,
  Header,
  Heading,
  HelperText,
  BigIcon,
} from "./common";

export function NoFunctionsDetected() {
  return (
    <Container>
      <Content>
        <Header>
          <BigIcon type="autometrics_black_faded" />
          <Heading>No Functions Detected</Heading>
          <HelperText>
            Check that your project is exposing metrics to Prometheus, and that
            your functions have executed at least once. If you need any help,
            reach out on{" "}
            <a
              href="https://discord.com/invite/MJr7pYzZQ4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discord
            </a>
            , or consult the{" "}
            <a
              href="https://docs.autometrics.dev/"
              target="_blank"
              rel="noopener noreferrer"
            >
              docs
            </a>
            .
          </HelperText>
        </Header>
        <ButtonGroup>
          <DiscordButton
            asElement="externalLink"
            href="https://discord.com/invite/MJr7pYzZQ4"
          >
            <StyledIcon type="discord_logo" />
            Get Help on Discord
          </DiscordButton>
          <DocumentationButton
            asElement="externalLink"
            href="https://docs.autometrics.dev/"
          >
            <StyledIcon type="notebook" />
            View the Docs
          </DocumentationButton>
        </ButtonGroup>
      </Content>
    </Container>
  );
}

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
`;

const DiscordButton = styled(Button)``;

const DocumentationButton = styled(Button).attrs({
  buttonStyle: "baseInverted",
})``;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;
