import { Objective } from "../../schemas";
import { getLatencyTarget, getSuccessRateTarget } from "../../utils";

export const SloTarget = ({ objective }: { objective: Objective }) => {
  return (
    <>
      {objective.metric === "successRate"
        ? getSuccessRateTarget(objective.target)
        : getLatencyTarget(objective.target)}
    </>
  );
};
