import {
  LABEL_HEIGHT,
  MINIMAL_NODE_HEIGHT,
  PADDING,
  TYPICAL_NODE_HEIGHT,
} from "./constants";
import { CallGraphNode } from "./types";

export function analyzeNodes(
  incomingNodes: CallGraphNode[],
  outgoingNodes: CallGraphNode[]
) {
  const incomingNodesCount = incomingNodes.length;
  const outgoingNodesCount = outgoingNodes.length;

  const maxNodes = Math.max(incomingNodesCount, outgoingNodesCount);
  const estimatedHeight = maxNodes * TYPICAL_NODE_HEIGHT - PADDING;

  // Get the total amount of incoming/outgoing calls
  const incomingAmount = incomingNodes.reduce(
    (accumulator, node) => accumulator + node.value,
    0
  );
  const outgoingAmount = outgoingNodes.reduce(
    (accumulator, node) => accumulator + node.value,
    0
  );
  const largestAmount = Math.max(incomingAmount, outgoingAmount);

  // Calculate the total space for the lines/nodes (excluding PADDING)
  // This estimatedHeight might change later if we have many nodes that are too small
  let totalNodeHeight =
    largestAmount === 0 ? maxNodes * MINIMAL_NODE_HEIGHT : estimatedHeight;

  // Calculate the target ratio amount -> pixels (height)
  // However if the largestAmount === 0 there's no valueToPixelRatio to calculate
  const valueToPixelRatio =
    largestAmount === 0 ? 0 : totalNodeHeight / largestAmount;

  // Recalculate the height based on the valueToPixelRatio (and allow for a minimal height of MINIMAL_NODE_HEIGHT)
  totalNodeHeight = Math.max(
    incomingNodes.reduce(
      (accumulator, node) =>
        accumulator +
        Math.max(node.value * valueToPixelRatio, MINIMAL_NODE_HEIGHT),
      0
    ),
    outgoingNodes.reduce(
      (accumulator, node) =>
        accumulator +
        Math.max(node.value * valueToPixelRatio, MINIMAL_NODE_HEIGHT),
      0
    )
  );

  const incomingNodeHeight = incomingNodes.reduce(
    (accumulator, node) =>
      accumulator +
      Math.max(
        node.value * valueToPixelRatio,
        MINIMAL_NODE_HEIGHT,
        LABEL_HEIGHT
      ) +
      PADDING,
    0
  );

  const outgoingNodeHeight = outgoingNodes.reduce(
    (accumulator, node) =>
      accumulator +
      Math.max(
        node.value * valueToPixelRatio,
        MINIMAL_NODE_HEIGHT,
        LABEL_HEIGHT
      ) +
      PADDING,
    0
  );

  return {
    height: Math.max(incomingNodeHeight, outgoingNodeHeight),
    totalNodeHeight,
    valueToPixelRatio,
  };
}
