import { Timeseries } from "@fiberplane/prometheus-query";

import { timestampToSeconds } from "../../../utils";

export type BlockData = {
  start: number;
  end: number;
};

export function extractBlockData(data: Timeseries, stepSize: number) {
  const blocks: BlockData[] = [];

  let lastTime: number | undefined;
  let startTime: number | undefined;

  for (const metric of data.metrics) {
    const time = timestampToSeconds(metric.time);
    const value = metric.value;
    if (value === 1 && (!lastTime || time < lastTime + 1.5 * stepSize)) {
      if (startTime === undefined) {
        startTime = time;
      }

      lastTime = time;
    } else if (startTime && lastTime) {
      blocks.push({
        start: startTime,
        end: lastTime,
      });
      startTime = undefined;
      lastTime = undefined;
    }
  }

  if (startTime && lastTime) {
    blocks.push({
      start: startTime,
      end: lastTime,
    });
  }

  return blocks;
}

export function formatLabel(timeseries: Pick<Timeseries, "labels">) {
  return `${timeseries.labels.severity} ${
    timeseries.labels.objective_name || timeseries.labels.alertname
  }`;
}
