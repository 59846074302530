import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";

import {
  useGetAllTrackedFunctionsQuery,
  useGetAllTrackedObjectivesQuery,
} from "../../../api";
import { useFeature, useThemeSelect, useTimeRange } from "../../../hooks";
import { selectActivePrometheus } from "../../../selectors";
import { switchInstance } from "../../../slices";
import { useAppDispatch, useAppSelector } from "../../../store";
import { createAskPrompt } from "../../../thunks";
import { getFunctionPath, getObjectivePath } from "../../../utils";
import { MetricLabel } from "../../UI";
import {
  CommandGroupHeading,
  CommandIcon,
  CommandItemDescription,
  CommandItemInfo,
  CommandItemName,
  ExperimentalLabel,
  StyledCommandGroup,
  StyledCommandItem,
} from "../styled";
import { CommandMenuPageProps } from "./CommandMenuPage";

// We limit the amount of commands when there's no query entered, so the user doesn't get overwhelmed
// with commands for functions and objectives
const COMMAND_PREVIEW_LIMIT = 5;

type NoPageProps = Omit<CommandMenuPageProps, "pageType">;

export function NoPage({
  hideCommandMenu,
  query,
  setPage,
  setQuery,
}: NoPageProps) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    currentTheme,
    isSystemPreference,
    setThemePreference,
    setToSystemTheme,
  } = useThemeSelect();

  const systemPrefersDark = useMedia("(prefers-color-scheme: dark)");

  const activeInstance = useSelector(selectActivePrometheus);
  const { instances } = useAppSelector((state) => state.environment);

  const limitResults = query.length === 0;

  // Filter instances that are not active
  const filteredInstances = activeInstance
    ? instances.filter(({ id }) => id !== activeInstance.id)
    : instances;

  const { data: trackedFunctions } = useGetAllTrackedFunctionsQuery(
    activeInstance?.url,
    {
      skip: !activeInstance?.url,
    }
  );

  // Limit the amount of function commands when there's no query
  const limitedTrackedFunctions = useMemo(() => {
    if (limitResults && trackedFunctions) {
      return trackedFunctions.slice(0, COMMAND_PREVIEW_LIMIT);
    }

    return trackedFunctions;
  }, [limitResults, trackedFunctions]);

  const { timeRange } = useTimeRange();

  const { data: trackedObjectives } = useGetAllTrackedObjectivesQuery(
    {
      environmentUrl: activeInstance?.url,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      skip: !activeInstance?.url,
    }
  );

  // Limit the amount of objective commands when there's no query
  const limitedTrackedObjectives = useMemo(() => {
    if (limitResults && trackedObjectives) {
      return trackedObjectives.slice(0, COMMAND_PREVIEW_LIMIT);
    }

    return trackedObjectives;
  }, [limitResults, trackedObjectives]);
  return (
    <>
      <StyledCommandGroup heading="Documentation">
        <StyledCommandItem
          onSelect={() => {
            setPage("askDora");
            if (query) {
              dispatch(createAskPrompt(query));
              setQuery("");
            }
          }}
          value={`ask "${query}"`}
        >
          <CommandIcon type="autometrics_logo_purple" />
          <CommandItemName>
            Ask Autometrics{query && `: ${query}`}
          </CommandItemName>
          <CommandItemInfo>
            <ExperimentalLabel>Experimental</ExperimentalLabel>
          </CommandItemInfo>
        </StyledCommandItem>
      </StyledCommandGroup>
      <StyledCommandGroup heading="Time range">
        <StyledCommandItem
          onSelect={() => {
            setPage("timeRange");
            setQuery("");
          }}
        >
          <CommandIcon type="time_duotone" />
          <CommandItemName>Change time range</CommandItemName>
        </StyledCommandItem>
      </StyledCommandGroup>
      <StyledCommandGroup heading="Theme">
        {currentTheme === "light" && (
          <StyledCommandItem
            onSelect={() => {
              setThemePreference("dark");
              hideCommandMenu();
            }}
          >
            <CommandIcon type="moon_duotone" />
            Switch to dark theme
          </StyledCommandItem>
        )}
        {currentTheme === "dark" && (
          <StyledCommandItem
            onSelect={() => {
              setThemePreference("light");
              hideCommandMenu();
            }}
          >
            <CommandIcon type="sun_duotone" />
            Switch to light theme
          </StyledCommandItem>
        )}
        {!isSystemPreference && (
          <StyledCommandItem
            onSelect={() => {
              setToSystemTheme();
              hideCommandMenu();
            }}
          >
            <CommandIcon
              type={systemPrefersDark ? "moon_duotone" : "sun_duotone"}
            />
            Switch to system theme
          </StyledCommandItem>
        )}
      </StyledCommandGroup>
      <StyledCommandGroup heading="Navigation">
        <StyledCommandItem
          onSelect={() => {
            navigate("/dashboard");
            hideCommandMenu();
          }}
        >
          <CommandIcon type="dashboard_duotone" />
          Go to Dashboard
        </StyledCommandItem>
        <StyledCommandItem
          onSelect={() => {
            navigate("/functions");
            hideCommandMenu();
          }}
        >
          <CommandIcon type="function_duotone" />
          Go to Functions
        </StyledCommandItem>
        <StyledCommandItem
          onSelect={() => {
            navigate("/slos");
            hideCommandMenu();
          }}
        >
          <CommandIcon type="slo_duotone" />
          Go to SLOs
        </StyledCommandItem>
        <StyledCommandItem
          onSelect={() => {
            navigate("/alerts");
            hideCommandMenu();
          }}
        >
          <CommandIcon type="alerts_duotone" />
          Go to Alerts
        </StyledCommandItem>
        <StyledCommandItem
          onSelect={() => {
            navigate("/settings");
            hideCommandMenu();
          }}
        >
          <CommandIcon type="settings_duotone" />
          Go to Settings
        </StyledCommandItem>
        <StyledCommandItem
          onSelect={() => {
            window.open("https://docs.autometrics.dev/", "_blank")?.focus();
            hideCommandMenu();
          }}
        >
          <CommandIcon type="docs_duotone" />
          Go to Docs
        </StyledCommandItem>
      </StyledCommandGroup>
      {instances.length > 1 && (
        <StyledCommandGroup heading="Environments">
          {filteredInstances.map((instance) => {
            return (
              <StyledCommandItem
                key={instance.id}
                onSelect={() => {
                  dispatch(switchInstance(instance.id));
                  hideCommandMenu();
                }}
              >
                <CommandIcon type="environments_duotone" />
                Switch environment to {instance.name}
              </StyledCommandItem>
            );
          })}
        </StyledCommandGroup>
      )}
      {limitedTrackedObjectives && (
        <StyledCommandGroup
          heading={
            <CommandGroupHeading>
              SLOs
              {trackedObjectives!.length > limitedTrackedObjectives.length &&
                ` (${trackedObjectives!.length})`}
            </CommandGroupHeading>
          }
        >
          {limitedTrackedObjectives.map((objective) => {
            return (
              <StyledCommandItem
                key={`${objective.metric}:${objective.name}`}
                onSelect={() => {
                  navigate(getObjectivePath(objective));
                  hideCommandMenu();
                }}
                value={`${objective.metric} ${objective.name}`}
              >
                <CommandIcon type="slo_duotone" />
                <CommandItemName>
                  {objective.name} <MetricLabel metric={objective.metric} />
                </CommandItemName>
              </StyledCommandItem>
            );
          })}
        </StyledCommandGroup>
      )}
      {limitedTrackedFunctions && (
        <StyledCommandGroup
          heading={
            <CommandGroupHeading>
              Functions
              {trackedFunctions!.length > limitedTrackedFunctions.length &&
                ` (${trackedFunctions!.length})`}
            </CommandGroupHeading>
          }
        >
          {limitedTrackedFunctions.map((fn) => {
            const path = getFunctionPath(fn.name, fn.module, fn.service_name);

            return (
              <StyledCommandItem
                key={path}
                onSelect={() => {
                  navigate(path);
                  hideCommandMenu();
                }}
                value={`${fn.module} ${fn.name}`}
              >
                <CommandIcon type="function_duotone" />
                <CommandItemName>{fn.name}</CommandItemName>
                {fn.module && (
                  <CommandItemDescription>{fn.module}</CommandItemDescription>
                )}
              </StyledCommandItem>
            );
          })}
        </StyledCommandGroup>
      )}
    </>
  );
}
