import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";

import type {
  Objective,
  ObjectiveMetric,
  ObjectiveWithCurrentValue,
} from "../../../schemas";
import { useGetAllTrackedObjectivesWithCurrentValueQuery } from "../../../api";
import { selectActivePrometheus } from "../../../selectors";
import { PrometheusError } from "../../FallbackStates";
import { NoSlosFound } from "../NoSlosFound";
import { DataTable } from "../../DataTable";
import { SloValue } from "../SloValue";
import {
  getObjectivePath,
  getIsActualFunctionValueViolatingTarget,
} from "../../../utils";
import { useThrottledLoadingState, useTimeRange } from "../../../hooks";
import { pageVariants } from "../animations";
import { FadeIn } from "../../Animations";
import { SloTarget } from "../SloTarget";
import { Icon, MetricLabel, Select } from "../../UI";
import { NoSlosMatched } from "../NoSlosMatched";

const EMPTY_SLO_LIST: ObjectiveWithCurrentValue[] = [];

type SloOverviewPageProps = {
  timeRange: ReturnType<typeof useTimeRange>["timeRange"];
};

type TargetFilterType = "all" | "onTarget" | "offTarget";

const columnHelper = createColumnHelper<ObjectiveWithCurrentValue>();

const columns = [
  columnHelper.accessor("name", {
    cell: (cellContext) => cellContext.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("metric", {
    cell: (cellContext) => <MetricLabel metric={cellContext.getValue()} />,
    header: "Metric",
  }),
  columnHelper.accessor("functionsCount", {
    cell: (cellContext) => (
      <FunctionsCountLabel>{cellContext.getValue()}</FunctionsCountLabel>
    ),
    header: "# Functions",
  }),
  columnHelper.accessor("target", {
    cell: (cellContext) => <SloTarget objective={cellContext.row.original} />,
    header: "Target",
  }),
  columnHelper.accessor("currentValue", {
    cell: (cellContext) => (
      <SloValue
        currentValue={cellContext.getValue()}
        objective={cellContext.row.original}
      />
    ),
    header: "Actual",
  }),
];

export function SloOverviewPage({ timeRange }: SloOverviewPageProps) {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const instance = useSelector(selectActivePrometheus);

  const {
    data = EMPTY_SLO_LIST,
    error,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetAllTrackedObjectivesWithCurrentValueQuery(
    {
      environmentUrl: instance?.url,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      skip: !instance?.url,
    }
  );

  const throttledIsLoading = useThrottledLoadingState(
    isLoading || isFetching,
    1500
  );

  const [metricFilter, setMetricFilter] = useState<ObjectiveMetric | "all">(
    "all"
  );
  const [targetFilter, setTargetFilter] = useState<TargetFilterType>("all");

  const resetFilters = () => {
    setMetricFilter("all");
    setTargetFilter("all");
  };

  const filteredData = useMemo(() => {
    return data.filter((objective) => {
      const matchesMetricFilter =
        metricFilter === "all" || metricFilter === objective.metric;

      let matchesTargetFilter = true;

      const isViolating = getIsActualFunctionValueViolatingTarget(
        objective,
        objective.currentValue
      );

      if (targetFilter === "onTarget") {
        matchesTargetFilter = !isViolating;
      } else if (targetFilter === "offTarget") {
        matchesTargetFilter = isViolating;
      }

      return matchesMetricFilter && matchesTargetFilter;
    });
  }, [data, metricFilter, targetFilter]);

  const isEmpty = data.length === 0;
  const isEmptyFiltered = filteredData.length === 0;

  if (isUninitialized) {
    return null;
  }

  if (error) {
    return (
      <FadeIn key="sloOverviewError">
        <PrometheusError />
      </FadeIn>
    );
  }

  if (!throttledIsLoading && isEmpty) {
    return (
      <FadeIn key="sloOverviewEmptyState">
        <NoSlosFound />
      </FadeIn>
    );
  }

  // NOTE - we render `null` when uninitialized to accomodate funkiness of using
  // AnimatePresence with our routing
  return (
    <Container
      key="sloOverviewContent"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <FilterList>
        <Select
          disabled={throttledIsLoading}
          name="target"
          onChange={(value: string) => {
            setTargetFilter(value as TargetFilterType);
          }}
          options={[
            { value: "all", children: "All targets" },
            { value: "onTarget", children: "On target" },
            { value: "offTarget", children: "Off target" },
          ]}
          value={targetFilter ?? "all"}
          leadingIcon={() => <Icon type="target_duotone" />}
        />
        <Select
          disabled={throttledIsLoading}
          name="metric"
          onChange={(value: string) => {
            setMetricFilter(value as Objective["metric"]);
          }}
          options={[
            {
              value: "all",
              children: "All metrics",
            },
            {
              value: "successRate",
              children: "Success rate",
            },
            {
              value: "latency",
              children: "Latency",
            },
          ]}
          value={metricFilter ?? "all"}
          leadingIcon={() => <Icon type="graph_duotone" />}
        />
      </FilterList>
      <DataTable
        isLoading={throttledIsLoading}
        data={filteredData}
        columns={columns}
        onRowClick={(objective) =>
          navigate({
            pathname: getObjectivePath(objective),
            search: searchParams.toString(),
          })
        }
      />
      {isEmptyFiltered && !throttledIsLoading && (
        <NoSlosMatched resetFilters={resetFilters} />
      )}
    </Container>
  );
}

const Container = styled(motion.div)`
  display: grid;
  gap: 32px;
`;

const FunctionsCountLabel = styled.span(
  ({ theme }) =>
    css`
      border-radius: ${theme.radius.minimal};
      background: ${theme.color.bg.emphasis["primary-subtle"]};
      color: ${theme.color.fg.primary};
      text-align: center;
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: inline-block;
      height: 20px;
      min-width: 20px;
    `
);

const FilterList = styled.div`
  display: flex;
  gap: 8px;
`;
