import { Link } from "react-router-dom";
import styled from "styled-components";

import { Content, Header, Heading, HelperText, Container } from "./common";
import { LazilyLoadedImage } from "../UI";

const { pathname } = new URL("no-data-url-provided.png", import.meta.url);

export function NoDataUrlProvided() {
  return (
    <Container>
      <Content>
        <LazilyLoadedImage src={pathname} />

        <Header>
          <StyledHeading>Missing Prometheus Host</StyledHeading>
          <HelperText>
            To connect to Prometheus, add a host on the{" "}
            <Link to="/settings">settings</Link> page.
          </HelperText>
        </Header>
      </Content>
    </Container>
  );
}

const StyledHeading = styled(Heading)`
  margin: 0;
`;
