import { useTheme } from "styled-components";

import { ObjectiveMetric } from "../../schemas";
import { LabelWithColor } from "./LabelWithColor";

export function MetricLabel({ metric }: { metric: ObjectiveMetric }) {
  const {
    color: { fg },
  } = useTheme();

  const label = metric === "successRate" ? "Success Rate" : "Latency";
  const color = metric === "successRate" ? fg.accent[1] : fg.accent[2];

  return <LabelWithColor color={color}>{label}</LabelWithColor>;
}
