import useMedia from "react-use/lib/useMedia";

import { BREAKPOINTS } from "../theme";

type MediaQuery = keyof typeof BREAKPOINTS;

/**
 * Returns a boolean indicating if the current screen size matches the given
 * themed media query.
 */
export function useMediaQuery(breakpoint: MediaQuery): boolean {
  return useMedia(`(min-width: ${BREAKPOINTS[breakpoint]}px)`);
}
