import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
// Import fetchBaseQuery from query instead of query/react, otherwise parcel builds break, see:
// https://github.com/parcel-bundler/parcel/issues/7622
import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import {
  getStepFromTimeRange,
  metricEntryToTimeseries,
  roundToGrid,
  Timeseries,
} from "@fiberplane/prometheus-query";

import {
  PrometheusQueryResponse,
  PrometheusQueryResponseSchema,
} from "../schemas";
import { selectActivePrometheus } from "../selectors";
import { RootState } from "../state";
import { parsePrometheusConfigScrapeIntervalsFromYaml } from "./utils";

/**
 * This is a custom baseQuery that uses the baseUrl from the Prometheus config.
 * We need this because the baseUrl is dynamic (the user can configure it themselves)
 */
const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const instance = selectActivePrometheus(WebApi.getState() as RootState);

  const baseUrl = instance?.url;

  if (!baseUrl) {
    // eslint-disable-next-line no-console
    console.error("No baseUrl found in Prometheus config, throwing error");
    throw new Error("Must configure Prometheus URL");
  }

  const rawBaseQuery = fetchBaseQuery({ baseUrl, mode: "cors" });
  return rawBaseQuery(args, WebApi, extraOptions);
};

export type EnvironmentUrl = string | null | undefined;

export const baseApi = createApi({
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    pingPrometheus: builder.query<boolean, EnvironmentUrl>({
      query: () => ({
        url: `/api/v1/query`,
        method: "GET",
        params: {
          query: "up",
        },
      }),
      transformResponse: () => {
        return true;
      },
      providesTags: (_tags, _result, environmentUrl) => {
        if (environmentUrl) {
          return [{ type: "Environment", id: environmentUrl }];
        }

        return [];
      },
    }),
    prometheusQueryRange: builder.query<
      Timeseries[],
      {
        environmentUrl: EnvironmentUrl;
        query: string;
        start: string;
        end: string;
        /**
         * useTimeRangeAsSpecified. By default time ranges will be adjusted/expanded for use in graphs
         */
        useTimeRangeAsSpecified?: boolean;
      }
    >({
      query: ({ query, start, end, useTimeRangeAsSpecified = false }) => {
        const [stepParameter, stepSeconds] = getStepFromTimeRange({
          from: start,
          to: end,
        });

        return {
          url: "/api/v1/query_range",
          method: "GET",
          params: {
            query,
            start: useTimeRangeAsSpecified
              ? start
              : roundToGrid(start, stepSeconds, Math.floor),
            end: useTimeRangeAsSpecified
              ? end
              : roundToGrid(end, stepSeconds, Math.ceil),
            step: stepParameter,
          },
        };
      },
      transformResponse: (response: any) => {
        return response.data.result.map(metricEntryToTimeseries);
      },
      providesTags: (_tags, _result, { environmentUrl }) => {
        if (environmentUrl) {
          return [{ type: "Environment", id: environmentUrl }];
        }

        return [];
      },
    }),
    prometheusQuery: builder.query<
      PrometheusQueryResponse,
      {
        environmentUrl: EnvironmentUrl;
        query: string;
        time: string;
      }
    >({
      query: ({ query, time }) => {
        return {
          url: "/api/v1/query",
          method: "GET",
          params: {
            query,
            time,
          },
        };
      },
      transformResponse: (response: any) => {
        return PrometheusQueryResponseSchema.parse(response);
      },
      providesTags: (_tags, _result, { environmentUrl }) => {
        if (environmentUrl) {
          return [{ type: "Environment", id: environmentUrl }];
        }

        return [];
      },
    }),
    prometheusScrapeIntervals: builder.query<
      number[] | null,
      {
        environmentUrl: EnvironmentUrl;
      }
    >({
      query: () => {
        return {
          url: "/api/v1/status/config",
          method: "GET",
        };
      },
      transformResponse: (response: any) => {
        const canParseResponse = typeof response?.data?.yaml === "string";
        if (canParseResponse) {
          return parsePrometheusConfigScrapeIntervalsFromYaml(
            response.data?.yaml
          );
        }

        return null;
      },
      providesTags: (_tags, _result, { environmentUrl }) => {
        if (environmentUrl) {
          return [{ type: "Environment", id: environmentUrl }];
        }

        return [];
      },
    }),
  }),
  reducerPath: "api",
  tagTypes: ["Environment"],
});

export const {
  usePingPrometheusQuery,
  usePrometheusQueryRangeQuery,
  // Lol - the "Query query" means that this is hitting
  //       the `/query` endpoint and not the `/query_range` endpoint
  usePrometheusQueryQuery,
  usePrometheusScrapeIntervalsQuery,
} = baseApi;
