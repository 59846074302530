import styled, { css } from "styled-components";
import { Button } from "@fiberplane/ui";

import { Icon } from "../../UI";
import { dispatch } from "../../../store";
import { toggleDatepickerVisibility } from "../../../actions";

export function NoData() {
  return (
    <NoDataContainer>
      <Icon type="autometrics" width={32} height={32} />

      <NoDataTitle>No data for this graph</NoDataTitle>
      <NoDataDescription>
        There is no data for your selected time range. Edit the time range or
        wait for Prometheus to collect more data.
      </NoDataDescription>

      <Button onClick={() => dispatch(toggleDatepickerVisibility())}>
        Edit time range
      </Button>
    </NoDataContainer>
  );
}

const NoDataContainer = styled.div`
  backdrop-filter: blur(4px);
  position: absolute;
  inset: 0;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  place-items: center;
`;

const NoDataTitle = styled.h4`
  margin: 16px 0 8px;
  font-style: italic;
`;

const NoDataDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    max-width: 320px;
    margin: 0 20px 20px;
  `
);
