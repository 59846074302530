import { useEffect, useMemo, useRef, useState } from "react";
import { AnimationDefinition, useAnimationControls } from "framer-motion";

import type { AnimatedIconProps } from "./icons";
import {
  ANIMATE,
  ANIMATE_INITIAL,
  AnimatedAlertsIcon,
  AnimatedDashboardIcon,
  AnimatedDocumentationIcon,
  AnimatedFunctionIcon,
  AnimatedHamburgerIcon,
  AnimatedLinkOutIcon,
  AnimatedSettingsIcon,
  AnimatedSloIcon,
  INITIAL,
} from "./icons";

const ANIMATED_ICON_MAP = {
  alerts_duotone: AnimatedAlertsIcon,
  dashboard_duotone: AnimatedDashboardIcon,
  documentation_duotone: AnimatedDocumentationIcon,
  function_duotone: AnimatedFunctionIcon,
  hamburger: AnimatedHamburgerIcon,
  link_out: AnimatedLinkOutIcon,
  settings_duotone: AnimatedSettingsIcon,
  slo_duotone: AnimatedSloIcon,
};

export type AnimatedIconType = keyof typeof ANIMATED_ICON_MAP;

/**
 * Hook that returns an animated icon component and animation controls.
 * @returns icon, startAnimation, animateToInitial
 */
export function useAnimatedIcon(type: AnimatedIconType) {
  const [isAnimating, setIsAnimating] = useState(false);
  const controls = useAnimationControls();

  // As `startAnimation` is an async function, we need to keep track of whether
  // the component is mounted or not. If the component is unmounted while the
  // animation is running, we need to stop the animation.
  const isMounted = useRef(true);

  const onAnimationComplete = (definition: AnimationDefinition) => {
    if (definition === ANIMATE) {
      setIsAnimating(false);
    }
  };

  /**
   * Start the icon's animation. If the animation is already running ignore the
   * call.
   */
  const startAnimation = async () => {
    if (isAnimating) {
      return;
    }

    await controls.start(ANIMATE_INITIAL);
    if (!isMounted.current) {
      controls.stop();
      return;
    }

    controls.start(ANIMATE);
    setIsAnimating(true);
  };

  /**
   * Animate the icon to its initial state. For cases when the icon stays at its
   * animated state until the user stops interacting with it.
   */
  const animateToInitial = () => {
    controls.start(INITIAL);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
      controls.stop();
    };
  }, [controls]);

  const IconComponent = useMemo(() => {
    const Component = ANIMATED_ICON_MAP[type];

    return function Icon(props: Omit<AnimatedIconProps, "controls">) {
      return (
        <Component
          controls={controls}
          onAnimationComplete={onAnimationComplete}
          {...props}
        />
      );
    };
  }, [controls, type]);

  return {
    icon: IconComponent,
    startAnimation,
    animateToInitial,
  };
}
