import { ComponentProps } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { Icon } from "../UI";

export function TableLinkOut(props: ComponentProps<typeof Link>) {
  return (
    <StyledLink {...props}>
      {props.children}
      <LinkOutIcon />
    </StyledLink>
  );
}

const StyledLink = styled(Link)(
  ({ theme }) => css`
    display: inline-flex;
    padding: 6px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    color: currentColor;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      background: ${theme.color.bg.hover};
    }
  `
);

const LinkOutIcon = styled(Icon).attrs({ type: "arrow_out" })`
  width: 14px;
  height: 14px;
`;
