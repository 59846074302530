import { useState, useEffect } from "react";
import styled from "styled-components";

export function Thinking() {
  const [loadingIndicator, setLoadingIndicator] = useState("");
  useEffect(() => {
    const id = setInterval(() => {
      setLoadingIndicator((oldText) => {
        let newText = oldText + ". ";
        if (newText.length > 6) {
          newText = "";
        }

        return newText;
      });
    }, 200);
    return () => {
      clearInterval(id);
    };
  }, []);

  return <ThinkingContainer>{loadingIndicator || " "}</ThinkingContainer>;
}

const ThinkingContainer = styled.div`
  white-space: break-spaces;
`;
