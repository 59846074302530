import { createColumnHelper } from "@tanstack/react-table";
import { TimeRange } from "@fiberplane/charts";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useHandler, useThrottledLoadingState } from "../../../hooks";
import {
  FunctionModulePairWithCurrentValue,
  Objective,
  ObjectiveTarget,
} from "../../../schemas";
import { selectActivePrometheus } from "../../../selectors";
import { getFunctionPath } from "../../../utils";
import { FadeIn } from "../../Animations";
import { DataTable, TableLinkOut } from "../../DataTable";
import { PrometheusError } from "../../FallbackStates";
import { pageVariants } from "../animations";
import { Loading } from "../Loading";
import { SloTarget } from "../SloTarget";
import { SloValue } from "../SloValue";
import { useGetObjectiveFunctionsWithCurrentValueQuery } from "../../../api";

type Row = FunctionModulePairWithCurrentValue & { target: ObjectiveTarget };

const EMPTY_FUNCTIONS_LIST: Row[] = [];

const columnHelper = createColumnHelper<Row>();

type FunctionsTableProps = {
  objective: Objective;
  timeRange: TimeRange;
};

export function FunctionsTable({ objective, timeRange }: FunctionsTableProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const instance = useSelector(selectActivePrometheus);

  const {
    data: functions = EMPTY_FUNCTIONS_LIST,
    error,
    isFetching,
    isUninitialized,
  } = useGetObjectiveFunctionsWithCurrentValueQuery(
    {
      environmentUrl: instance?.url,
      objective: objective!,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      skip: !objective,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (cellContext) => {
          const name = cellContext.getValue();
          const module = cellContext.row.original.module;
          const service = cellContext.row.original.service_name;
          const path = getFunctionPath(name, module, service);
          return (
            <TableLinkOut
              to={`${path}?${searchParams}`}
              onClick={(event) => event.stopPropagation()}
            >
              {name}
            </TableLinkOut>
          );
        },
        header: "Name",
      }),
      columnHelper.accessor("module", {
        cell: (cellContext) => <>{cellContext.getValue()}</>,
        header: "Module",
      }),
      columnHelper.accessor("currentValue", {
        cell: (cellContext) => (
          <SloValue
            objective={objective}
            currentValue={cellContext.getValue()}
          />
        ),
        header: "Actual",
      }),
      columnHelper.accessor("target", {
        cell: () => <SloTarget objective={objective} />,
        header: "Target",
      }),
    ],
    [objective, searchParams]
  );

  const throttledIsFetching = useThrottledLoadingState(isFetching, 250);

  const tableData = useMemo(() => {
    if (!objective) {
      return [];
    }

    return functions.map((fn) => ({ ...fn, target: objective.target }));
  }, [functions, objective]);

  const onRowClick = useHandler((fn: (typeof tableData)[number]) => {
    const path = getFunctionPath(fn.name, fn.module, fn.service_name);
    navigate([path, searchParams].join("?"));
  });

  return isUninitialized ? null : throttledIsFetching ? (
    <FadeIn key="sloDetailIsLoading">
      <Loading />
    </FadeIn>
  ) : error ? (
    <FadeIn key="sloDetailError">
      <PrometheusError />
    </FadeIn>
  ) : (
    <FadeIn
      key="sloFunctionsTable"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <DataTable columns={columns} data={tableData} onRowClick={onRowClick} />
    </FadeIn>
  );
}
