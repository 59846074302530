import styled from "styled-components";
import { Input } from "@fiberplane/ui";

import { useSourceCodeLinkSettings } from "../../hooks";

export function SourceCodeLinkSwitcher() {
  const { value, set: updateSettings, supported } = useSourceCodeLinkSettings();
  return (
    <Container>
      <FormContainer>
        <h4>Show links to source code</h4>
        <FormContent>
          <StyledLabel>
            <Input
              type="radio"
              name="notificationSettings"
              value="off"
              disabled={!supported}
              checked={value === "off"}
              onChange={() => {
                updateSettings("off");
              }}
            />
            Off
          </StyledLabel>
          <StyledLabel>
            <Input
              type="radio"
              name="notificationSettings"
              value="vscode"
              disabled={!supported}
              checked={value === "vscode"}
              onChange={() => {
                updateSettings("vscode");
              }}
            />
            Link to Visual Studio Code
          </StyledLabel>
          <StyledLabel>
            <Input
              type="radio"
              name="notificationSettings"
              value="text"
              disabled={!supported}
              checked={value === "text"}
              onChange={() => {
                updateSettings("text");
              }}
            />
            Plain text
          </StyledLabel>
        </FormContent>
      </FormContainer>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  gap: 24px;
`;

const FormContainer = styled.div`
  display: grid;
  gap: 12px;
`;

const FormContent = styled.div`
  display: grid;
  gap: 8px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;
