import styled, { css } from "styled-components";
import { Button } from "@fiberplane/ui";

import { Icon, PageContainer, useAnimatedIcon } from "../UI";
import { DocumentationCard, TutorialCard, VisualCard } from "./ContentCards";
import {
  DOCUMENTATION_CARDS,
  TUTORIAL_CARDS,
  VISUAL_CARDS,
} from "./cardContent";
import { useAccentColorForIndex } from "../../hooks";

export function Home() {
  const { getAccentColorForIndex } = useAccentColorForIndex();

  const {
    icon: LinkOutIcon,
    startAnimation,
    animateToInitial,
  } = useAnimatedIcon("link_out");

  return (
    <HomeContainer>
      <Header>
        <PageTitle>Welcome to Autometrics</PageTitle>
        <PageDescription>
          An open source micro framework for observability that makes it trivial
          to instrument your code and writes Prometheus queries for you.
        </PageDescription>
        <HeaderCTA>
          <Button asElement="link" to="/functions">
            <Icon type="magnifying_glass" height="18" width="18" />
            Start Exploring
          </Button>
          <Button
            asElement="externalLink"
            buttonStyle="secondary"
            href="https://marketplace.visualstudio.com/items?itemName=Fiberplane.autometrics"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="vscode" height="18" width="18" />
            Get the VSCode Extension
          </Button>
        </HeaderCTA>
      </Header>

      <ContentBlock>
        <BlockTitle>Getting started</BlockTitle>

        <ContentContainer>
          {VISUAL_CARDS.map(({ title, description, url, image }) => (
            <VisualCard
              key={title}
              title={title}
              description={description}
              url={url}
              image={image}
            />
          ))}
        </ContentContainer>
      </ContentBlock>

      <ContentBlock>
        <BlockTitle>
          Documentation
          <StyledButton
            buttonStyle="tertiary-color"
            to="https://docs.autometrics.dev/"
            target="_blank"
            rel="noopener noreferrer"
            asElement="link"
            onMouseEnter={startAnimation}
            onMouseLeave={animateToInitial}
          >
            View all
            <LinkOutIcon />
          </StyledButton>
        </BlockTitle>

        <ContentContainer>
          {DOCUMENTATION_CARDS.map(
            ({ description, iconType, title, url }, index) => {
              const accentColor = getAccentColorForIndex(index);

              return (
                <DocumentationCard
                  key={title}
                  brandColor={accentColor}
                  description={description}
                  title={title}
                  iconType={iconType}
                  url={url}
                />
              );
            }
          )}
        </ContentContainer>
      </ContentBlock>

      <ContentBlock>
        <BlockTitle>Get started with these tutorials</BlockTitle>

        <ContentContainer>
          {TUTORIAL_CARDS.map(
            ({ title, description, url, image, ctas, icon }) => (
              <TutorialCard
                key={title}
                title={title}
                description={description}
                url={url}
                image={image}
                ctas={ctas}
                icon={icon}
              />
            )
          )}
        </ContentContainer>
      </ContentBlock>
    </HomeContainer>
  );
}

const HomeContainer = styled(PageContainer)(
  ({ theme: { media } }) => css`
    padding: 40px 0;
    gap: 40px;

    ${media.md`
      padding: 80px 0;
      gap: 64px;
    `}
  `
);

const ContentBlock = styled.section`
  display: grid;
  gap: 24px;
`;

const BlockTitle = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Basier Square";
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.01em;
`;

const Header = styled.header`
  display: grid;
  row-gap: 8px;
`;

const PageTitle = styled.h1`
  font: ${({ theme }) => theme.font.display[1]};
`;

const PageDescription = styled.p(
  ({ theme }) =>
    css`
      font: ${theme.font.body.l.regular};
      color: ${theme.color.fg.muted};
      margin: 0;
    `
);

const HeaderCTA = styled.div(
  ({ theme: { media } }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

    ${media.sm`
      flex-direction: row;
    `}
  `
);

const ContentContainer = styled.div(
  ({ theme: { spacing } }) => css`
    display: grid;
    grid-auto-rows: fit-content;
    gap: ${spacing.content.gap.lg};
    grid-template-columns: ${spacing.content.templateColumns};
  `
);

const StyledButton = styled(Button)`
  align-items: end;
`;
