import { useMemo } from "react";

import { useAccentColors } from "./useAccentColors";
import { useAccentColorForIndex } from "./useAccentColorForIndex";
import { getSafeIndex } from "./utils";

/**
 * Hook that returns an array of colors for graph lines. The colors are based on
 * the bg.accent color and maps them to fg.accent colors.
 * @param color bg.accent color
 */
export function useGraphLineColors(color: string) {
  const backgroundAccents = useAccentColors("bg");
  const { getAccentColorForIndex: fgAccent } = useAccentColorForIndex("fg");

  const fgAccent0 = fgAccent(0);
  const fgAccent1 = fgAccent(1);
  const fgAccent2 = fgAccent(2);
  const fgAccent3 = fgAccent(3);

  // Hardcoded colors as they're only used for graph lines for this single use
  // only. Only the first, which is the taccent color, is coming from the theme.
  const graphLineColors = useMemo(
    () => [
      [
        fgAccent0,
        "#D43DFF",
        "#6C61FF",
        "#FF38E3",
        "#54A9FF",
        "#B947FF",
        "#8B61FF",
        "#EF3BFF",
        "#5E7EFF",
      ],
      [
        fgAccent1,
        "#FA418C",
        "#B740E3",
        "#FF453D",
        "#9D53FC",
        "#FA3FB8",
        "#DC46F0",
        "#FC3F5F",
        "#A143E8",
      ],
      [
        fgAccent2,
        "#FF8A24",
        "#E83925",
        "#FFB230",
        "#FF3D72",
        "#FF7C2B",
        "#F2532C",
        "#FF9F29",
        "#F5313E",
      ],
      [
        fgAccent3,
        "#FAB423",
        "#E36724",
        "#FFD630",
        "#FA5C3C",
        "#FFAB2E",
        "#EB7E2A",
        "#FFC936",
        "#F06030",
      ],
    ],
    [fgAccent0, fgAccent1, fgAccent2, fgAccent3]
  );

  const index = backgroundAccents.findIndex((set) => set.includes(color));
  const safeIndex = getSafeIndex(index, graphLineColors.length);
  return graphLineColors[safeIndex]!;
}
