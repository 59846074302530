import { useState } from "react";
import styled from "styled-components";
import { Button } from "@fiberplane/ui";

import { useAppSelector } from "../../../store";
import { Icon } from "../../UI";
import { EnvironmentForm } from "../EnvironmentForm";
import { EnvironmentItem } from "./EnvironmentItem";

export function PrometheusEnvironments() {
  const [showAddEnvironment, setShowAddEnvironment] = useState(false);
  const [editEnvironmentId, setEditEnvironmentId] = useState<string>();

  const { instances } = useAppSelector((state) => state.environment);

  return (
    <EnvironmentsContainer>
      {instances.length > 0 && (
        <EnvironmentsList>
          {instances.map((instance) => {
            if (editEnvironmentId === instance.id) {
              return (
                <EnvironmentForm
                  // eslint-disable-next-line unicorn/no-useless-undefined
                  closeForm={() => setEditEnvironmentId(undefined)}
                  environment={instance}
                  key={instance.id}
                />
              );
            }

            return (
              <EnvironmentItem
                environment={instance}
                key={instance.id}
                onEdit={() => setEditEnvironmentId(instance.id)}
              />
            );
          })}
        </EnvironmentsList>
      )}

      {showAddEnvironment && (
        <EnvironmentForm closeForm={() => setShowAddEnvironment(false)} />
      )}

      <StyledButton onClick={() => setShowAddEnvironment(true)}>
        <Icon type="plus" /> New URL
      </StyledButton>
    </EnvironmentsContainer>
  );
}

const StyledButton = styled(Button)(
  ({ theme: { media } }) => media.sm`
    justify-self: start;
  `
);

const EnvironmentsList = styled.ul`
  padding: 0;
  margin: 0;
  display: grid;
  gap: ${({ theme }) => theme.spacing.content.gap.md};
`;

const EnvironmentsContainer = styled.div`
  display: grid;
  row-gap: 20px;
`;
