import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button } from "@fiberplane/ui";

import {
  useFeature,
  useHandler,
  useMediaQuery,
  useTimeRange,
} from "../../../hooks";
import { Icon, StyledNavLink, useAnimatedIcon } from "../../UI";
import { DatePicker } from "../../DatePicker";
import { EnvironmentSelect } from "./EnvironmentSelect";
import { ThemeLightSwitch } from "./ThemeLightSwitch";
import { CommandMenuToggle } from "./CommandMenuToggle";
import { NavBarItem, NavBarList, StyledNavLinkContent } from "./common";
import { AppLinksCollapsed } from "./AppLinksCollapsed";
import { useAppDispatch } from "../../../store";
import { selectNavMenuIsOpen } from "../../../selectors";
import { setNavMenuOpened } from "../../../actions";

type NavBarContainerProps = {
  children: React.ReactNode;
};

export function NavBarContainer({ children }: NavBarContainerProps) {
  const { timeRange, onChangeTimeRange } = useTimeRange();
  const [currentSearchParams] = useSearchParams();
  const search = currentSearchParams.toString();

  const [hasThemeSwitch] = useFeature("theme-switch");

  const documentation = useAnimatedIcon("documentation_duotone");
  const hamburger = useAnimatedIcon("hamburger");
  const settings = useAnimatedIcon("settings_duotone");

  const dispatch = useAppDispatch();
  const menuIsOpen = useSelector(selectNavMenuIsOpen);
  const isLg = useMediaQuery("lg");
  const shouldOpenMenu = !isLg && menuIsOpen;
  const navRef = useRef<HTMLElement>(null);

  const closeMenu = useHandler(() => {
    dispatch(setNavMenuOpened(false));
  });

  const handleHamburgerOnClick = () => dispatch(setNavMenuOpened(!menuIsOpen));

  useEffect(() => {
    hamburger[menuIsOpen ? "startAnimation" : "animateToInitial"]();
  }, [hamburger, menuIsOpen]);

  useEffect(() => {
    if (isLg) {
      closeMenu();
    }
  }, [closeMenu, isLg]);

  return (
    <NavWrapper ref={navRef}>
      <NavContainer $isOpen={shouldOpenMenu}>
        <BrandLink to={{ pathname: "/", search }} onClick={closeMenu}>
          <Icon type="autometrics_color" height={24} width={24} />
          <Icon type="autometrics_logo_type" />
        </BrandLink>

        {shouldOpenMenu && (
          <AppLinksCollapsed navRef={navRef} closeMenu={closeMenu}>
            {children}
          </AppLinksCollapsed>
        )}

        <AppLinks>{children}</AppLinks>

        <AppSettings>
          <NavBarDatePicker
            timeRange={timeRange}
            onChange={onChangeTimeRange}
          />
          <EnvironmentSelect />
        </AppSettings>

        <HamburgerIconButton
          onClick={handleHamburgerOnClick}
          buttonStyle="tertiary-grey"
        >
          <hamburger.icon />
        </HamburgerIconButton>

        <ButtonLinks>
          {hasThemeSwitch && <ThemeLightSwitch />}
          <CommandMenuToggle />

          <StyledNavLink
            to={{ pathname: "/settings", search }}
            aria-label="settings"
          >
            {({ isActive }) => (
              <IconNavLink
                onMouseEnter={isActive ? undefined : settings.startAnimation}
              >
                <settings.icon />
              </IconNavLink>
            )}
          </StyledNavLink>

          <StyledNavLink
            as="a"
            href="https://docs.autometrics.dev/"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="go to docs"
          >
            <IconNavLink onMouseEnter={documentation.startAnimation}>
              <documentation.icon />
            </IconNavLink>
          </StyledNavLink>
        </ButtonLinks>
      </NavContainer>
    </NavWrapper>
  );
}

const NavWrapper = styled.nav`
  position: relative;
`;

const NavContainer = styled.div<{ $isOpen: boolean }>(
  ({ $isOpen, theme: { color, media, spacing } }) => {
    return css`
      display: grid;
      grid:
      /*
        CSS variable inherited from Layout.tsx as it's a responsive layout value
      */
        "branding app-settings hamburger" var(--spacing-nav-container)
        "app-links app-links app-links" / auto 1fr;
      gap: 0 ${spacing.content.gap.md};
      padding-inline: ${spacing.page.inline};
      background: ${color.bg.default};
      border-bottom: 1px solid ${color.border.default};
      position: absolute;
      inset: auto 0;
      z-index: 10; /* arbitrary value */

      ${$isOpen &&
      css`
        padding-block-end: 20px;
      `}

      ${media.lg`
        grid:
          "branding app-links app-settings button-links"
          minmax(var(--spacing-nav-container), auto)
            / max-content 1fr auto auto;
        gap: 0 16px;
      `}
    `;
  }
);

const BrandLink = styled(Link)(
  ({ theme }) => css`
    grid-area: branding;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${theme.color.fg.default};
    margin-right: ${theme.spacing.content.gap.md};
  `
);

const AppSettings = styled(NavBarList)`
  grid-area: app-settings;
  justify-self: end;
`;

const AppLinks = styled(NavBarList)(
  ({ theme: { media } }) => css`
    display: none;

    ${media.lg`
      display: flex;
      gap: 24px;

      & > ${NavBarItem}[data-hidden-lg="true"] {
        display: none;
      }
    `}
  `
);

const HamburgerIconButton = styled(Button)(
  ({ theme: { media } }) => css`
    grid-area: hamburger;
    align-self: center;
    height: 36px;
    aspect-ratio: 1;

    ${media.lg`
      display: none;
    `}
  `
);

export const NavBarDatePicker = styled(DatePicker)(
  ({ theme: { media } }) => css`
    span {
      display: none;

      ${media.sm`
        display: inline-block;
      `}
    }
  `
);

const ButtonLinks = styled(NavBarList)(
  ({ theme: { media } }) => css`
    grid-area: button-links;
    display: none;

    ${media.lg`
      display: flex;
    `}
  `
);

const IconNavLink = styled(StyledNavLinkContent)`
  padding: 8px;
`;
