import { NavLink } from "react-router-dom";

import {
  type AnimatedIconType,
  useAnimatedIcon,
  StyledNavLink,
} from "../../UI";
import { NavBarItem, StyledNavLinkContent } from "./common";

type ComponentProps = {
  children: React.ReactNode;
  animatedIconType: AnimatedIconType;
  "data-hidden-lg"?: boolean;
};

type NavBarNavLinkProps = ComponentProps & React.ComponentProps<typeof NavLink>;

export function NavBarNavLink({
  children,
  animatedIconType,
  "data-hidden-lg": hiddenLg = false,
  ...navLinkProps
}: NavBarNavLinkProps) {
  const animatedIcon = useAnimatedIcon(animatedIconType);

  return (
    <NavBarItem data-hidden-lg={hiddenLg}>
      <StyledNavLink {...navLinkProps}>
        {({ isActive }) => (
          <StyledNavLinkContent
            onMouseEnter={isActive ? undefined : animatedIcon.startAnimation}
          >
            <animatedIcon.icon />
            {children}
          </StyledNavLinkContent>
        )}
      </StyledNavLink>
    </NavBarItem>
  );
}

type NavBarAnchorProps = ComponentProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export function NavBarAnchor({
  animatedIconType,
  children,
  "data-hidden-lg": hiddenLg = false,
  ...anchorProps
}: NavBarAnchorProps) {
  const animatedIcon = useAnimatedIcon(animatedIconType);

  return (
    <NavBarItem data-hidden-lg={hiddenLg}>
      <a {...anchorProps}>
        <StyledNavLinkContent onMouseEnter={animatedIcon.startAnimation}>
          <animatedIcon.icon />
          {children}
        </StyledNavLinkContent>
      </a>
    </NavBarItem>
  );
}
