import { motion, useReducedMotion } from "framer-motion";
import type { Variants } from "framer-motion";
import { forwardRef } from "react";

type AnimatedMenuProps = {
  children: React.ReactNode;
  className?: string;
  initialX?: number;
  initialY?: number;
};

export const MENU_ANIMATION_DISTANCE = 10;

export const AnimatedMenu = forwardRef(function AnimatedMenu(
  {
    children,
    className: string,
    initialX = 0,
    initialY = MENU_ANIMATION_DISTANCE * -1,
  }: AnimatedMenuProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const shouldReduceMotion = useReducedMotion();
  const variants = getVariants(initialX, initialY);

  return (
    <motion.div
      ref={ref}
      className={string}
      transition={{ duration: shouldReduceMotion ? 0 : 0.1, ease: "easeOut" }}
      variants={variants}
      initial="initial"
      animate="animate"
      exit="initial"
    >
      {children}
    </motion.div>
  );
});

function getVariants(initialX: number, initialY: number): Variants {
  return {
    initial: {
      opacity: 0,
      x: initialX,
      y: initialY,
    },
    animate: {
      opacity: 1,
      x: 0,
      y: 0,
    },
  };
}
