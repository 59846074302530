import styled, { css } from "styled-components";
import { Timeseries } from "@fiberplane/charts";
import { top } from "@popperjs/core";

import { formatLabel } from "../utils";
import { useNavigateToAlert } from "../hooks";
import { LineTooltip } from "../Tooltips";
import { TooltipState } from "../../../../state";

export function LabelItem(
  props: Pick<Timeseries, "labels"> & {
    index: number;
    showTooltip: (tooltip: TooltipState | null) => void;
  }
) {
  const navigateToAlert = useNavigateToAlert(props.index);

  return (
    <LabelContent
      key={props.index}
      onClick={() => navigateToAlert()}
      onMouseOver={(event: React.PointerEvent<HTMLDivElement>) => {
        const { currentTarget: target } = event;

        const anchor = () => target;
        const content = () => <LineTooltip labels={props.labels} />;
        const options = {
          placement: top,
        };

        props.showTooltip({
          anchor,
          content,
          options,
        });
      }}
      onMouseLeave={() => {
        props.showTooltip(null);
      }}
    >
      <Text>{formatLabel(props)}</Text>
    </LabelContent>
  );
}

const LabelContent = styled.div(
  ({ theme }) => css`
    &::before {
      content: "";
      height: 40px;
      position: absolute;
      width: 100%;
      left: 0;
    }

    &:hover::before {
      background: ${theme.color.bg.hover};
    }
  `
);

const Text = styled.div(
  ({ theme }) => css`
    position: relative;
    font: ${theme.font.body.sm.medium};
    padding: 10px 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 0 ${theme.radius.default} ${theme.radius.default} 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  `
);
