export type FunctionTreeNodeData = {
  id: string | number;
  name: string;
  module: string;
  service_name: string;
  type: "module" | "function" | "service";
  children?: FunctionTreeNodeData[];
};

export type FunctionData = {
  name: string;
  // NOTE - Queries coming from the autometrics vscode Typescript plugin, module might not be present
  module?: string;
  // DOM means the FunctionData was parsed from the DOM,
  //     in this case it's a hint that we know the user has autometricized this function,
  //     even if Prometheus has not yet seen it.
  // PROM means the FunctionData was inferred from the Prometheus API
  service_name?: string;
  source?: "DOM" | "PROM";
};
