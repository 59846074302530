import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useGetAllTrackedObjectivesWithCurrentValueQuery } from "../../../api";
import { selectActivePrometheus } from "../../../selectors";
import { PrometheusError } from "../../FallbackStates";
import { SloNotFound } from "./SloNotFound";
import { useThrottledLoadingState } from "../../../hooks";
import { Loading } from ".././Loading";
import { FadeIn } from "../../Animations";
import { FunctionsTable } from "./FunctionsTable";
import { Graph } from "./Graph";
import { CurrentValueCard } from "./CurrentValueCard";
import { CardContainer } from "./styled";
import { ThirtyDayValueCard } from "./ThirtyDayValueCard";
import { DatePickerTimeRange } from "../../../utils";

type SloDetailPageProps = {
  timeRange: DatePickerTimeRange;
  onChangeTimeRange: (newTimeRange: DatePickerTimeRange) => void;
};

export function SloDetailPage({
  timeRange,
  onChangeTimeRange,
}: SloDetailPageProps) {
  const instance = useSelector(selectActivePrometheus);

  const { objectiveName, metricType } = useParams();

  // OPTIMIZE: - If there were a lot of objectives, this would be making many unnecessary requests
  const {
    data: objective,
    error,
    isFetching,
  } = useGetAllTrackedObjectivesWithCurrentValueQuery(
    {
      environmentUrl: instance?.url,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      skip: !objectiveName,
      selectFromResult: ({ data, ...rest }) => ({
        data:
          data &&
          data.find(
            ({ name, metric }) =>
              name === objectiveName && metric === metricType
          ),
        ...rest,
      }),
    }
  );

  // Use a throttled loading state to avoid too much of a flash-in when data loads quickly
  const throttledIsFetching = useThrottledLoadingState(
    isFetching && objective === undefined,
    250
  );

  return (
    <>
      {throttledIsFetching ? (
        <FadeIn key="sloDetailIsLoading">
          <Loading />
        </FadeIn>
      ) : error ? (
        <FadeIn key="sloDetailError">
          <PrometheusError />
        </FadeIn>
      ) : objective ? (
        <>
          <Grid>
            <CurrentValueCard objective={objective} />
            <ThirtyDayValueCard objective={objective} />
            <Graph
              objective={objective}
              timeRange={timeRange}
              onChangeTimeRange={onChangeTimeRange}
            />
          </Grid>
          <FunctionsTable objective={objective} timeRange={timeRange} />
        </>
      ) : (
        <FadeIn key="sloDetailEmptyState">
          <SloNotFound objectiveName={objectiveName} />
        </FadeIn>
      )}
    </>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 16px 12px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);

    ${CardContainer}:nth-of-type(3) {
      grid-column: 1 / 3;
    }
  }
`;
