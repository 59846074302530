import { useSearchParams } from "react-router-dom";

import { NavBarContainer } from "./NavBar";
import { NavBarAnchor, NavBarNavLink } from "./Links";
import { Icon } from "../../UI";
import { useAppDispatch } from "../../../store";
import { setNavMenuOpened } from "../../../actions";
import { useFeature } from "../../../hooks";

export function NavBar() {
  const [currentSearchParams] = useSearchParams();
  const search = currentSearchParams.toString();

  const dispatch = useAppDispatch();
  const closeNavMenuOnClick = () => dispatch(setNavMenuOpened(false));

  return (
    <NavBarContainer>
      <NavBarNavLink
        to={{ pathname: "/dashboard", search }}
        animatedIconType="dashboard_duotone"
        onClick={closeNavMenuOnClick}
      >
        Dashboard
      </NavBarNavLink>

      <NavBarNavLink
        to={{ pathname: "/functions", search }}
        animatedIconType="function_duotone"
        onClick={closeNavMenuOnClick}
      >
        Functions
      </NavBarNavLink>

      <NavBarNavLink
        to={{ pathname: "/slos", search }}
        animatedIconType="slo_duotone"
        onClick={closeNavMenuOnClick}
      >
        SLOs
      </NavBarNavLink>

      <NavBarNavLink
        to={{ pathname: "/alerts", search }}
        animatedIconType="alerts_duotone"
        onClick={closeNavMenuOnClick}
      >
        Alerts
      </NavBarNavLink>

      <NavBarNavLink
        to={{ pathname: "/settings", search }}
        animatedIconType="settings_duotone"
        aria-label="settings"
        onClick={closeNavMenuOnClick}
        data-hidden-lg
      >
        Settings
      </NavBarNavLink>

      <NavBarAnchor
        animatedIconType="documentation_duotone"
        href="https://docs.autometrics.dev/"
        rel="noopener noreferrer"
        target="_blank"
        onClick={closeNavMenuOnClick}
        data-hidden-lg
      >
        <span>
          Documentation <Icon height="1em" width="1em" type="link_out" />
        </span>
      </NavBarAnchor>
    </NavBarContainer>
  );
}
