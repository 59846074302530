import { useEffect, useRef, useState } from "react";

import { useAlertsQuery } from "../../Alerts";
import { notifications } from "../../../services";
import { useHandler } from "../../../hooks";
import { getRecentTimeRange, notify } from "../utils";

const { useNotificationSettings, getServiceWorker, hasServiceWorkerSupport } =
  notifications;

// TODO add logic so we don't send multiple notifications when multiple tabs are open
export function useAlertsPolling() {
  const navigateToAlerts = notifications.useNavigateToAlerts();
  const [pollingTimeRange, setPollingTimeRange] = useState(getRecentTimeRange);
  const { result } = useAlertsQuery(pollingTimeRange);
  const [activeAlerts, setActiveAlerts] = useState(false);

  const { settings: notificationSettings } = useNotificationSettings();

  // TODO only send notifications if the user is not on the alerts page
  // Right now we might show notifications for alerts that are already on display
  // However given that we're polling and the alerts page is not, the alerts page
  // might be out of date
  const showNotifications =
    (notificationSettings.desktopNotificationsEnabled ??
      notificationSettings.notificationState) &&
    hasServiceWorkerSupport;
  const handleMessageEvent = useHandler((event: MessageEvent) => {
    if (event.data.type === "FOCUS_ALERT") {
      navigateToAlerts(event.data.payload.timestamp);
    }
  });

  useEffect(() => {
    if (!showNotifications) {
      return;
    }

    navigator.serviceWorker.addEventListener("message", handleMessageEvent);
    return () => {
      navigator.serviceWorker.removeEventListener(
        "message",
        handleMessageEvent
      );
    };
  }, [handleMessageEvent, showNotifications]);

  useEffect(() => {
    // No need to poll if the user doesn't want notifications (or if they aren't supported)
    if (showNotifications === false) {
      return;
    }

    const timeoutId = setTimeout(
      () => setPollingTimeRange(getRecentTimeRange(pollingTimeRange.to)),
      30_000
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, [pollingTimeRange.to, showNotifications]);

  const alertsRef = useRef<Array<string>>([]);
  useEffect(() => {
    const data = result.data;
    if (data && hasServiceWorkerSupport) {
      // If the service worker is not registered or working, don't do anything
      if (getServiceWorker() === null) {
        return;
      }

      // Check settings and ask the user if they want to enable notifications
      if (notificationSettings.notificationState === "granted") {
        setActiveAlerts(true);
      }

      // If the user wants notifications, notify!
      if (showNotifications) {
        setActiveAlerts(true);
        notify(data, alertsRef.current, pollingTimeRange.to).then(
          (newRecentAlerts) => {
            alertsRef.current = newRecentAlerts;
          }
        );
      }
    }
  }, [
    result.data,
    pollingTimeRange.to,
    pollingTimeRange.from,
    notificationSettings.notificationState,
    showNotifications,
    navigateToAlerts,
  ]);
  return {
    activeAlerts,
  };
}
