import styled, { css } from "styled-components";

export const PageContainer = styled.div(
  ({ theme: { spacing } }) => css`
    width: min(100% - calc(${spacing.page.inline} * 2), 1120px);
    margin-inline: auto;
    padding-block: ${spacing.page.block};
    display: grid;
    gap: ${spacing.page.gap};
  `
);

export const PageHeader = styled.header`
  display: grid;
  gap: 8px;
`;

export const PageHeading = styled.h1`
  font-size: 28px;
  font-family: Basier Square;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
`;

export const PageDescription = styled.p(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    font-size: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;

    & a {
      color: currentColor;
    }
  `
);
