/**
 * @deprecated Prefer `rtk-query` over `LoadableData`
 */
export interface LoadableData<D> {
  readonly loading: boolean;
  readonly error?: string;
  // Optional, can be used to calculate backoff for future requests
  readonly consecutiveErrorCount?: number;
  // If data is undefined, we've never (successfully) loaded it
  readonly data?: D;
}
