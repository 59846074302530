import styled from "styled-components";
import { memo } from "react";

import { TICK_LABEL_WIDTH } from "./constants";

type Props = {
  ticks: number[];
  getX: (tick: number) => number;
  formatter: (tick: number) => string;
  width: number;
};

export const TimelineTickLabels = memo(function TimelineTickLabels(
  props: Props
) {
  return (
    <Container>
      {props.ticks.map((tick, index) => {
        const x = props.getX(tick);
        if (x < TICK_LABEL_WIDTH || x > props.width - TICK_LABEL_WIDTH) {
          return null;
        }

        return (
          <Tick key={index} style={{ transform: `translateX(${x}px)` }}>
            <TickContent>{props.formatter(tick)}</TickContent>
          </Tick>
        );
      })}
    </Container>
  );
});

const Container = styled.div`
  position: relative;
`;

const Tick = styled.div`
  position: absolute;
`;

const TickContent = styled.div`
  transform: translateX(-50%);
`;
