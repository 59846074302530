import { useMemo } from "react";
import { useTheme } from "styled-components";

/**
 * Helper hook to get accent colors from the theme.
 */
export function useAccentColors(type: "bg" | "fg") {
  const { color } = useTheme();
  return useMemo(() => {
    return [
      type === "bg" ? color.bg.emphasis.primary : color.fg.primary,
      ...Object.values(type === "bg" ? color.bg.accent : color.fg.accent),
    ];
  }, [color, type]);
}
