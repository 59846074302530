import styled from "styled-components";

import { NavLinkContent } from "../../UI";

export const NavBarList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const NavBarItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const StyledNavLinkContent = styled(NavLinkContent)(
  ({ theme: { media } }) => media.lg`
    gap: 8px;
  `
);
