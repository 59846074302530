import type { MetricType } from "../api";
import { Objective } from "../schemas";

export function getFunctionPath(
  functionName: string,
  moduleName: string | undefined,
  serviceName?: string,
  metricType?: MetricType
) {
  let path = "/functions/details";

  if (serviceName) {
    path = `${path}/s/${encodeURIComponent(serviceName)}`;
  }

  if (moduleName) {
    path = `${path}/m/${encodeURIComponent(moduleName)}`;
  }

  path = `${path}/${encodeURIComponent(functionName)}`;

  if (metricType) {
    return `${path}/${metricType}`;
  }

  return path;
}

export function getObjectivePath(
  objective: Pick<Objective, "name" | "metric">
): string {
  // NOTE - we need to escape the name because it can contain slashes and percentages and stuff
  return `/slos/${encodeURIComponent(objective.name)}/${encodeURIComponent(
    objective.metric
  )}`;
}
