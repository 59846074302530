import { useRef, useState } from "react";
import useClickAway from "react-use/lib/useClickAway";
import styled, { css } from "styled-components";

import { cancelEvent } from "../../../utils";
import { AttachedPopup, IconButton } from "../../UI";

type EnvironmentMenuProps = {
  onEdit: () => void;
  onRemove: () => void;
};

export function EnvironmentMenu({ onEdit, onRemove }: EnvironmentMenuProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    cancelEvent(event);
    setMenuIsVisible((current) => !current);
  };

  const menuRef = useRef<HTMLDivElement>(null);
  useClickAway(menuRef, () => {
    if (menuIsVisible) {
      setMenuIsVisible(false);
    }
  });

  return (
    <>
      <IconButton
        buttonStyle="tertiary-grey"
        iconType="dots_three"
        onClick={toggleMenu}
        ref={buttonRef}
        type="button"
      />
      {menuIsVisible && (
        <AttachedPopup
          element={buttonRef.current}
          offset={[0, 8]}
          placement="bottom"
        >
          <MenuContainer ref={menuRef}>
            <MenuItem onClick={onRemove}>Delete</MenuItem>
            <MenuItem onClick={onEdit}>Edit</MenuItem>
          </MenuContainer>
        </AttachedPopup>
      )}
    </>
  );
}

const MenuContainer = styled.nav(
  ({ theme }) =>
    css`
      display: flex;
      padding: 8px;
      gap: 8px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-radius: ${theme.radius.default};
      background: ${theme.color.bg.elevated.default};
      box-shadow: ${theme.effect.shadow.m};
    `
);

const MenuItem = styled.button(
  ({ theme }) =>
    css`
      display: flex;
      padding: 8px 12px;
      align-items: center;
      gap: 12px;
      width: 100%;
      border: none;
      border-radius: ${theme.radius.default};
      box-shadow: none;
      background: transparent;
      font: ${theme.font.body.md.regular};
      color: ${theme.color.fg.default};

      &:hover {
        background: ${theme.color.bg.elevated.hover};
        cursor: pointer;
      }
    `
);
