import { TooltipAnchor } from "@fiberplane/charts";

import { Thunk } from "../store";
import {
  CloseTooltipFn,
  ConfirmationDialogModalProps,
  ConfirmationDialogType,
  TooltipOptions,
} from "../types";
import {
  HIDE_MODAL,
  SHOW_MODAL,
  hideActiveTooltip,
  setActiveTooltip,
} from "../actions";
import { selectActiveTooltip } from "../selectors";

let hideTooltipTimeout: ReturnType<typeof setTimeout> | null = null;

export const showConfirmationDialog =
  (key: string, modalProps: ConfirmationDialogModalProps): Thunk =>
  (dispatch) => {
    const onRequestClose = () => {
      dispatch(hideConfirmationDialog(key));
    };

    const modal: ConfirmationDialogType = {
      ...modalProps,
      type: "confirmationDialog",
    };

    dispatch({
      type: SHOW_MODAL,
      payload: {
        key,
        modal: { onRequestClose, modal },
      },
    });
  };

export const hideConfirmationDialog =
  (key: string): Thunk =>
  (dispatch) => {
    dispatch({ type: HIDE_MODAL, payload: { key } });
  };

export const showTooltip =
  (
    anchor: TooltipAnchor,
    content: React.ReactNode,
    options: TooltipOptions = {}
  ): Thunk<CloseTooltipFn> =>
  (dispatch, getState) => {
    if (hideTooltipTimeout != null) {
      clearTimeout(hideTooltipTimeout);
      hideTooltipTimeout = null;
    }

    const currentAnchor = selectActiveTooltip(getState())?.anchor();
    if (anchor !== currentAnchor) {
      dispatch(setActiveTooltip({ anchor, content, options }));
    }

    return () => dispatch(hideActiveTooltip());
  };

export const hideTooltipWithTimeout =
  (timeout: number): Thunk =>
  (dispatch) => {
    if (hideTooltipTimeout != null) {
      clearTimeout(hideTooltipTimeout);
    }

    hideTooltipTimeout = setTimeout(() => {
      hideTooltipTimeout = null;
      dispatch(hideActiveTooltip());
    }, timeout);
  };
