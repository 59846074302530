import styled from "styled-components";
import { Input } from "@fiberplane/ui";

import { useFeatures, useHandler } from "../../../hooks";
import { ExperimentalFeatures } from "./ExperimentalFeatures";

export function ExperimentalContentBlock() {
  const [hasFeaturesEnabled, setHasFeaturesEnabled] = useFeatures();

  const handleLightSwitchOnChange = useHandler(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setHasFeaturesEnabled(event.target.checked)
  );

  return (
    <ExperimentalContent>
      <LightSwitchContainer>
        <Input
          type="lightswitch"
          checked={hasFeaturesEnabled}
          onChange={handleLightSwitchOnChange}
        />
        <h4>Enable beta features</h4>
      </LightSwitchContainer>

      {hasFeaturesEnabled && <ExperimentalFeatures />}
    </ExperimentalContent>
  );
}

const ExperimentalContent = styled.div`
  display: grid;
  gap: 16px;
`;

const LightSwitchContainer = styled.div`
  display: flex;
  gap: 12px;
`;
