import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const NavLinkContent = styled.span(
  ({ theme: { color, font } }) => css`
    display: grid;
    grid: auto / 20px auto;
    align-items: center;
    gap: 16px;
    color: ${color.fg.muted};
    font: ${font.buttons.md};
    transition: color 0.1s ease-in-out;

    &:hover {
      color: ${color.fg.default};
    }
  `
);

export const StyledNavLink = styled(NavLink)`
  &.active ${NavLinkContent} {
    color: ${({ theme }) => theme.color.fg.primary};
  }
`;
