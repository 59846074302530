import { useMemo, useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import { SparkChart, TimeRange, Timeseries } from "@fiberplane/charts";

import { Container } from "./common";
import { MetricDisplay } from "./MetricDisplay";
import { useGraphLineColors } from "../../../hooks";
import { Icon } from "../../UI";

type MetricCardProps = {
  brandColor: string;
  isActive: boolean;
  latestValue?: number | string;
  latestValueUnit?: string;
  title: string;
  info: string;
  data: Timeseries[] | undefined;
  timeRange: TimeRange;
};

export function MetricCard({
  brandColor,
  isActive,
  title,
  latestValue,
  latestValueUnit,
  data,
  timeRange,
  info,
}: MetricCardProps) {
  const theme = useTheme();
  const graphLineColors = useGraphLineColors(brandColor);
  const colors = useMemo(() => {
    return isActive
      ? [theme.color.fg.onemphasis.default, theme.color.fg.onemphasis.subtle]
      : graphLineColors;
  }, [theme, graphLineColors, isActive]);
  const ref = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    const cardElement = ref.current;
    if (!cardElement) {
      return;
    }

    cardElement.scrollIntoView({
      behavior: "smooth",
      inline: "center",
    });
  };

  return (
    <MetricCardContainer
      ref={ref}
      brandColor={brandColor}
      isActive={isActive}
      onClick={handleOnClick}
    >
      <MetricTitle>
        <h4>{title}</h4>
        <InfoIcon aria-label={info} />
      </MetricTitle>
      <MetricDisplay
        rate={latestValue}
        unit={latestValueUnit}
        report="latest"
      />

      <SparkChartContainer>
        {data && timeRange && (
          <SparkChart
            graphType="line"
            stackingType="none"
            timeRange={timeRange}
            timeseriesData={data}
            colors={colors}
          />
        )}
      </SparkChartContainer>
    </MetricCardContainer>
  );
}

const MetricCardContainer = styled(Container)<
  Pick<MetricCardProps, "brandColor" | "isActive">
>(
  ({ brandColor, isActive, theme }) => css`
    --text-alternative-color: ${theme.color.fg.muted};

    display: grid;
    grid:
      "title title" auto
      "metric graph" 54px / minmax(120px, 176px) minmax(80px, 136px);
    cursor: pointer;
    gap: 16px;

    color: ${theme.color.fg.default};
    background-color: ${theme.color.bg.subtle};
    border: 1px solid ${theme.color.border.muted};
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    ${isActive
      ? css`
          --text-alternative-color: ${theme.color.fg.onemphasis.default};

          color: ${theme.color.fg.onemphasis.default};
          background-color: ${brandColor};
          border-color: ${brandColor};
        `
      : css`
          &:hover {
            background-color: ${theme.color.bg.hover};
          }
        `};
  `
);

const MetricTitle = styled.div`
  grid-area: title;
  display: grid;
  grid-template-columns: auto 20px;
  margin: 0;
`;

const InfoIcon = styled(Icon).attrs({
  type: "info_duotone",
})`
  align-self: top;
`;

export const SparkChartContainer = styled.div`
  grid-area: graph;
  overflow: hidden;
  pointer-events: none;
`;
