import type { Variants } from "framer-motion";
import { motion } from "framer-motion";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, ANIMATE_INITIAL } from "./constants";

export function AnimatedDashboardIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        opacity="0.2"
        x="4"
        y="4"
        width="12"
        height="12"
        fill="currentColor"
      />
      <path
        d="M4 15.998L15.998 15.998C15.998 17.1026 15.1026 17.998 13.998 17.998H5.99805C4.89413 17.998 3.9991 17.1037 3.99805 16C2.89438 15.9989 2 15.1039 2 14L2 6C2 4.89543 2.89543 4 4 4L4 15.998Z"
        fill="currentColor"
      />
      <path
        d="M18 14L18 6C18 4.8964 17.1062 4.00158 16.0029 4C16.0029 2.89543 15.1075 2 14.0029 2L6.00293 2C4.89836 2 4.00293 2.89543 4.00293 4L16 4L16 16C17.1046 16 18 15.1046 18 14Z"
        fill="currentColor"
      />
      <motion.g
        variants={verticalVariants}
        animate={controls}
        onAnimationComplete={onAnimationComplete}
      >
        <path d="M10 4H12V9H10V4Z" fill="currentColor" />
      </motion.g>
      <motion.g
        variants={verticalVariants}
        animate={controls}
        onAnimationComplete={onAnimationComplete}
      >
        <path d="M8 11H10V16H8V11Z" fill="currentColor" />
      </motion.g>
      <motion.g
        variants={horizontalVariants}
        animate={controls}
        onAnimationComplete={onAnimationComplete}
      >
        <path d="M16 9V11H4V9L16 9Z" fill="currentColor" />
      </motion.g>
    </svg>
  );
}

const verticalVariants: Variants = {
  [ANIMATE_INITIAL]: {
    scaleY: 0,
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  [ANIMATE]: {
    scaleY: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const horizontalVariants: Variants = {
  [ANIMATE_INITIAL]: {
    scaleX: 0,
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  [ANIMATE]: {
    scaleX: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};
