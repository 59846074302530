import styled from "styled-components";

import { notifications } from "../../services";
import { Banner, PageContainer } from "../UI";

export function NudgeUser() {
  const { requestPermission } = notifications.useNotificationSettings();
  return (
    <Container>
      <Banner
        type="info"
        title="Desktop notifications"
        copy="Do you want to receive desktop notifications for future alerts?"
        initialSize="small"
        actions={[
          {
            label: "Allow",
            onClick: () => {
              requestPermission();
            },
          },
        ]}
      />
    </Container>
  );
}

const Container = styled(PageContainer)`
  display: flex;
  padding: 40px 0 0;
`;
