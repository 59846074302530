import { VirtualElement } from "@fiberplane/charts";
import type { Placement } from "@popperjs/core";

export type CloseTooltipFn = () => void;

export type ShowTooltipFn = (
  anchor: TooltipAnchor,
  content: React.ReactNode,
  options?: TooltipOptions
) => CloseTooltipFn;

export type TooltipAnchor = HTMLElement | VirtualElement;

export type TooltipOptions = {
  /**
   * By default, tooltips don't receive any mouse events and will hide if you
   * attempt to hover over them. By setting this to `false`, the user is able
   * to interact with the tooltip.
   */
  hideOnHover?: boolean;

  placement?: Placement;
};
