import styled from "styled-components";

import { FallbackCommon } from "../FallbackStates";

const { Container, Content, Header, Heading, HelperText, BigIcon } =
  FallbackCommon;

export function NoAlertsFound() {
  return (
    <StyledContainer>
      <StyledContent>
        <Header>
          <BigIcon type="autometrics_black_faded" />
          <Heading>No alerts found</Heading>
          <HelperText>
            Read more about alerts in the{" "}
            <a
              href="https://docs.autometrics.dev/features#alerts-and-slos-without-yaml"
              target="_blank"
              rel="noreferrer noopener"
            >
              documentation
            </a>
          </HelperText>
        </Header>
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  height: 100%;
  min-height: 400px;
  align-items: flex-start;
  padding: 72px 0;
`;

const StyledContent = styled(Content)`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
