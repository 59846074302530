import styled, { css } from "styled-components";
import { Timeseries } from "@fiberplane/prometheus-query";

import { Container, Top } from "./shared";

type Props = Pick<Timeseries, "labels">;

export function LineTooltip(props: Props) {
  return (
    <Container>
      <Top>
        <Hint>Open in alerts view</Hint>
        <table>
          <tbody>
            {Object.entries(props.labels).map(([key, value]) => (
              <tr key={key}>
                <Td>{key}:</Td>
                <Td>{value}</Td>
              </tr>
            ))}
          </tbody>
        </table>
      </Top>
    </Container>
  );
}

const Hint = styled.div`
  text-align: center;
`;

const Td = styled.td(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.xxs};
  `
);
