import { Children, useEffect } from "react";
import { createPortal } from "react-dom";
import styled, { css } from "styled-components";
import useClickAway from "react-use/lib/useClickAway";

import { useKeyPressEvent } from "../../../hooks";
import { NavBarItem, NavBarList } from "./common";

type AppLinksCollapsedProps = {
  children: React.ReactNode;
  navRef: React.RefObject<HTMLElement>;
  closeMenu: () => void;
};

/**
 * Menu that renders on small screens. When rendered, it introduces animated
 * menu items once the menu is opened. It adds a page overlay & prevents page
 * scrolling.
 */
export function AppLinksCollapsed({
  children,
  closeMenu,
  navRef,
}: AppLinksCollapsedProps) {
  const childrenCount = Children.count(children);
  useClickAway(navRef, closeMenu);
  useKeyPressEvent("Escape", closeMenu);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <AppLinks $childrenCount={childrenCount}>
      {children}
      {createPortal(<PageOverlay />, document.body)}
    </AppLinks>
  );
}

const PageOverlay = styled.div(
  ({ theme }) => css`
    @keyframes pageOverlayFadeInAnimation {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    position: absolute;
    inset: 0;
    background: ${theme.color.neutral.alpha.dark[300]};
    animation: pageOverlayFadeInAnimation 0.2s ease-in;
  `
);

const AppLinks = styled(NavBarList)<{ $childrenCount: number }>(
  ({ $childrenCount }) => {
    // Create an array of CSS custom properties with animation delays for each
    // child
    const animationDelays = Array.from({ length: $childrenCount }).map(
      (_, i) => css`
        &:nth-child(${i + 1}) {
          animation-delay: calc(var(--animation-delay-app-link) * ${i});
        }
      `
    );

    return css`
      --animation-delay-app-link: 0.1s;

      grid-area: app-links;
      display: grid;
      grid-auto-rows: 36px;
      gap: 16px;
      padding-block-start: 4px;

      @keyframes navBarItemAnimation {
        0% {
          opacity: 0;
          transform: translateX(8px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }

      /*
        Apply styles to each nav bar item, including their :nth-child animation
        delays.
      */
      & > ${NavBarItem} {
        animation: navBarItemAnimation 0.15s ease-in-out forwards;
        opacity: 0;

        ${animationDelays}
      }
    `;
  }
);
