import styled from "styled-components";
import { Button, Input } from "@fiberplane/ui";

import { notifications } from "../../services";
import { Banner, Icon } from "../UI";
import { SettingsHeaderDescription } from "./common";

export function NotificationSwitcher() {
  const {
    settings: notificationSettings,
    setDesktopNotificationsEnabled,
    requestPermission,
  } = notifications.useNotificationSettings();

  return (
    <Container>
      <StatusBanner
        notificationState={notificationSettings.notificationState}
        requestPermission={requestPermission}
      />
      <FormContainer>
        <h4>Receive notifications about</h4>
        <FormContent>
          <StyledLabel>
            <Input
              type="radio"
              name="notificationSettings"
              value="alerts"
              disabled={notificationSettings.notificationState === "denied"}
              checked={notificationSettings.desktopNotificationsEnabled}
              onChange={() => {
                setDesktopNotificationsEnabled(true);
              }}
            />
            Alerts
          </StyledLabel>
          <StyledLabel>
            <Input
              type="radio"
              name="notificationSettings"
              value="nothing"
              checked={!notificationSettings.desktopNotificationsEnabled}
              disabled={notificationSettings.notificationState === "denied"}
              onChange={() => {
                setDesktopNotificationsEnabled(false);
              }}
            />
            Nothing
          </StyledLabel>
        </FormContent>
      </FormContainer>
    </Container>
  );
}

function StatusBanner(
  props: Pick<notifications.NotificationSettings, "notificationState"> & {
    requestPermission: () => Promise<void>;
  }
): JSX.Element | null {
  const { notificationState, requestPermission } = props;
  switch (notificationState) {
    case "default":
      return (
        <IntroContainer>
          <Intro>
            <h4>Enable desktop notifications</h4>
            <SettingsHeaderDescription>
              You will be notified as long as you keep a browser tab open
            </SettingsHeaderDescription>
          </Intro>
          <div>
            <Button onClick={() => requestPermission()}>
              <Icon type="alarm_duotone" />
              Enable notifications
            </Button>
          </div>
        </IntroContainer>
      );
    case "prompt":
      return (
        <Banner
          type="info"
          title=""
          initialSize="small"
          copy={
            <>
              Click `<em>Allow</em>` in the browser popup to enable
              notifications
            </>
          }
        />
      );
    case "denied":
      // Check if the browser supports service workers
      if (!notifications.hasServiceWorkerSupport) {
        return (
          <Banner
            type="danger"
            title="Desktop notifications are not supported"
            copy="This browser may be running in incognito mode or not support notifications."
          />
        );
      }

      // If there's a service worker, if so the user denied notifications
      // otherwise the service worker failed to load
      return notifications.getServiceWorker() ? (
        <Banner
          type="danger"
          title="Desktop notifications not enabled"
          copy="
          You've disallowed notifications in your browser. You'll need to open your browser preferences to change that."
        />
      ) : (
        <Banner
          type="danger"
          title="Desktop notifications are not supported"
          copy="For some reason the service worker failed to load or start, so we can't enable notifications."
        />
      );

    case "granted":
      return null;
  }

  return null;
}

const Container = styled.div`
  display: grid;
  gap: 24px;
`;

const IntroContainer = styled.div`
  display: grid;
  gap: 12px;
`;

const Intro = styled.div`
  display: grid;
  gap: 4px;
`;

const FormContainer = styled.div`
  display: grid;
  gap: 12px;
`;

const FormContent = styled.div`
  display: grid;
  gap: 4px;
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;
