import { useMemo, SyntheticEvent, useCallback } from "react";
import { useParams } from "react-router-dom";
import type { ResizeCallbackData } from "react-resizable";

import { FunctionTreeNodeData } from "../../../types";
import { useLocalStorage } from "../../../hooks";

/**
 * Hook that determines if a module node is active based on the current URL.
 */
export function useIsActiveModuleNode(node: FunctionTreeNodeData) {
  // Currently FunctionTree doesn't have access to the named routes, so we have
  // to use the wildcard route and parse the params ourselves.
  const params = useParams()["*"];

  const moduleNode = useMemo(() => {
    if (!params || node.type === "function") {
      return;
    }

    let module: string = "";
    let service: string = "";

    const kvPairs = params.split("/").slice(1, -1);

    for (let index = 0; index < kvPairs.length; index += 2) {
      const key = kvPairs[index];

      if (key === "m" && typeof kvPairs[index + 1] === "string") {
        module = kvPairs[index + 1]!;
      }

      if (key === "s" && typeof kvPairs[index + 1] === "string") {
        service = kvPairs[index + 1]!;
      }
    }

    if (node.type === "service" && node.name === service) {
      return true;
    }

    if (node.type === "module" && node.name === module) {
      return true;
    }
  }, [node.name, node.type, params]);

  return !!moduleNode;
}

/**
 * Hook for setting the width of the functions list,
 * intended for use with react-resizable.
 *
 * Note: Persists the selected width in localStorage
 */
export function useFunctionsListWidth({
  default: default_,
  min,
  max,
}: {
  default: number;
  min: number;
  max: number;
}) {
  const [storedWidth, setStoredWidth] = useLocalStorage<number | null>(
    "explorer.functionsListWidth",
    null
  );

  const getClampedWidth = useCallback(
    (newWidth: number) => {
      return Math.min(max, Math.max(newWidth, min));
    },
    [min, max]
  );

  const width = useMemo<number>(() => {
    if (storedWidth) {
      return getClampedWidth(storedWidth);
    }

    return getClampedWidth(default_);
  }, [storedWidth, getClampedWidth, default_]);

  // NOTE - It could be good to debounce calls to localstorage in this handler,
  //        but I didn't notice any lag while testing.
  const handleResize = useCallback(
    (event: SyntheticEvent, { size }: ResizeCallbackData) => {
      setStoredWidth(getClampedWidth(size.width));
    },
    [setStoredWidth, getClampedWidth]
  );

  return { width, handleResize };
}
