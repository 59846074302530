import type { Variants } from "framer-motion";
import { motion } from "framer-motion";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, INITIAL } from "./constants";

export function AnimatedDocumentationIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <motion.g
        variants={variantsTop}
        initial="initial"
        animate={controls}
        onAnimationComplete={onAnimationComplete}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2L4 2C2.89543 2 2 2.89543 2 4L2 16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V4C18 2.89543 17.1046 2 16 2ZM16 16V4L6 4L6 16H16Z"
          fill="currentColor"
        />
        <path d="M13 6H8V8H13V6Z" fill="currentColor" />
      </motion.g>

      <motion.path
        variants={variantsBottom}
        animate={controls}
        initial="initial"
        opacity="0.2"
        d="M16 2C17.1046 2 18 2.89543 18 4L18 16C18 17.1046 17.1046 18 16 18L4 18L4 2L16 2Z"
        fill="currentColor"
      />
    </svg>
  );
}

const initial: Variants = {
  [INITIAL]: {
    originX: 0,
    originY: "bottom",
  },
};

const variantsTop: Variants = {
  ...initial,
  [ANIMATE]: {
    rotate: ["0deg", "-5deg", "0deg"],
  },
};

const variantsBottom: Variants = {
  ...initial,
  [ANIMATE]: {
    rotate: ["0deg", "4deg", "0deg"],
  },
};
