import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import styled, { css } from "styled-components";

import { Environment } from "../../slices";

type EnvironmentConnectionStatusProps = {
  status: Environment["connectionStatus"];
};

export function EnvironmentConnectionStatus({
  status,
}: EnvironmentConnectionStatusProps) {
  const errorCount = Object.values(status).filter(
    (success) => success === QueryStatus.rejected
  ).length;

  const text = getText(errorCount);

  return <Container $errorCount={errorCount}>{text}</Container>;
}

function getText(errorCount: number) {
  switch (errorCount) {
    case 0:
      return "Connected";
    case 1:
      return "1 error";
    default:
      return `${errorCount} errors`;
  }
}

const Container = styled.span<{ $errorCount: number }>(
  ({ $errorCount, theme }) =>
    css`
      display: inline-flex;
      padding: 0px 6px;
      align-items: center;
      gap: 6px;
      color: ${theme.color.fg.muted};
      border: 1px solid ${theme.color.border.muted};
      border-radius: ${theme.radius.minimal};
      font: ${theme.font.body.sm.regular};

      ${() => {
        switch ($errorCount) {
          case 0:
            return css`
              color: ${theme.color.fg.success};
              border-color: ${theme.color.border.success};
            `;
          default:
            return css`
              color: ${theme.color.fg.danger};
              border-color: ${theme.color.border.danger};
            `;
        }
      }}
    `
);
