import styled, { css } from "styled-components";

import { NavBar } from "./App";

type LayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <AppContainer>
      <NavBar />
      <Main>{children}</Main>
    </AppContainer>
  );
}

const AppContainer = styled.div(
  ({ theme: { media } }) => css`
    --spacing-nav-container: 56px;

    display: grid;
    grid-template-rows: var(--spacing-nav-container) 1fr;
    height: 100%;
    overflow: hidden;

    ${media.sm`
      --spacing-nav-container: 60px;
    `}
  `
);

const Main = styled.main`
  overflow-y: auto;
`;
