import { FallbackCommon } from "../FallbackStates";

const { Container, Content, Header, Heading, HelperText, BigIcon } =
  FallbackCommon;

export function FunctionExplorerIndex() {
  return (
    <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
    >
      <Content>
        <Header>
          <BigIcon type="autometrics_color" />
          <Heading>Select a function to continue</Heading>
          <HelperText>
            To start exploring, select a function from the explorer on the left.
          </HelperText>
        </Header>
      </Content>
    </Container>
  );
}
