import styled, { css } from "styled-components";

import { Icon, PillBox, PillItem } from "../../UI";
import { useThemeSelect } from "../../../hooks";

/**
 * Light switch for toggling the theme between light and dark. Keeping the
 * component around for development use only.
 */
export function ThemeLightSwitch() {
  const { currentTheme, toggleTheme } = useThemeSelect();
  const isDarkTheme = currentTheme === "dark";

  return (
    <TogglePillBox onClick={toggleTheme}>
      <TogglePillItem isActive={!isDarkTheme}>
        <Icon type="sun_duotone" height={18} width={18} />
      </TogglePillItem>

      <TogglePillItem isActive={isDarkTheme}>
        <MoonIcon height={18} width={18} />
      </TogglePillItem>
    </TogglePillBox>
  );
}

const TogglePillBox = styled(PillBox)(
  ({ theme }) => css`
    padding: 4px;
    cursor: pointer;
    border: 1px solid ${theme.color.button.secondary.border};
    background-color: ${theme.color.button.secondary.bg};
    box-sizing: border-box;
  `
);

// We're scaling the moon here to have the moon's body the same size as the
// sun's.
const MoonIcon = styled(Icon).attrs({ type: "moon_duotone" })`
  transform: scale(1.2);
`;

const TogglePillItem = styled(PillItem)`
  padding: 4px;
  aspect-ratio: 1;
  cursor: unset;
  line-height: 0; /* HACK: force the 1 / 1 aspect ratio in Chrome & SafarIE */
`;
