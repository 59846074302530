import styled, { css } from "styled-components";

export const CardContainer = styled.section(
  ({ theme }) => css`
    display: grid;
    gap: 16px;
    border: 1px solid ${theme.color.border.default};
    border-radius: ${theme.radius.default};
    padding: 24px;
  `
);

export const CardTitle = styled.strong(
  ({ theme }) => css`
    color: ${theme.color.fg.subtle};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.5px;
    display: flex;
  `
);

export const CardInfo = styled.span`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

export const CardValue = styled.div`
  font-family: Basier square;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -1px;
`;

export const CardTarget = styled.span(
  ({ theme }) => css`
    color: ${theme.color.fg.subtle};
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.5px;
  `
);
