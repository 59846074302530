import styled, { css, keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const animateBackground = keyframes`
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: var(--background-width) 0%;
  }
`;

export const Skeleton = styled.div.attrs({
  "aria-label": "Loading placeholder",
})(
  ({ theme }) =>
    css`
      --background-width: 600px;

      animation: ${fadeInAnimation} 0.5s,
        ${animateBackground} 1s 0.5s linear infinite;
      background: ${theme.color.bg.placeholder.gradient};
      background-size: var(--background-width) 100%;
      clip-path: inset(0 0 0 0 round 4px);
      width: 100%;
      height: 100%;
    `
);
