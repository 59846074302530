import type { ButtonStyle } from "./buttonStyleTypes";
import type { IconVisualType } from "./iconVisualTypes";

export type ConfirmationDialogModalProps = {
  children: React.ReactNode;
  iconVisual: IconVisualType;
  onCancel?: () => void;
  onCancelLabel?: string;
  onConfirm?: () => void;
  onConfirmButtonStyle?: ButtonStyle;
  onConfirmLabel?: string;
  title: string;
};

export type ConfirmationDialogType = {
  type: "confirmationDialog";
} & ConfirmationDialogModalProps;

export type ModalTypeProperties = ConfirmationDialogType;

export type ModalType = Pick<ModalTypeProperties, "type">["type"];
