/**
 * The animation state of the icon to be used in variants.
 */
export const ANIMATE = "animate";

/**
 * The initial animation state of the icon to be used in variants.
 */
export const ANIMATE_INITIAL = "animateInitial";

/**
 * The initial state of the icon to be used in variants.
 */
export const INITIAL = "initial";
