import styled, { css } from "styled-components";
import { useContext } from "react";

import Arrow from "./Arrow.svg";
import { AttachedPopup, ArrowContext } from "../UI";
import type { TooltipState } from "../../state";

type Props = {
  tip: TooltipState;
};

export function Tooltip({ tip }: Props): JSX.Element | null {
  const { anchor, content, options } = tip;
  const { placement = "bottom" } = options;

  const children = content();
  return (
    <AttachedPopup
      element={anchor()}
      placement={placement}
      withArrow
      offset={[0, 12]}
    >
      <TooltipContent>{children}</TooltipContent>
    </AttachedPopup>
  );
}

type ContentProps = {
  children: React.ReactNode;
};

/**
 * The real content of a tooltip. Declared separately because we
 * need to use the ArrowContext
 */
function TooltipContent({ children }: ContentProps) {
  const { style: arrowStyle, setRef } = useContext(ArrowContext);
  return (
    <StyledToolTip>
      <ArrowContainer style={arrowStyle} ref={setRef}>
        <StyledArrow />
      </ArrowContainer>
      <StyledContent>{children}</StyledContent>
    </StyledToolTip>
  );
}

const StyledToolTip = styled.div`
  flex-grow: 0;
  border-radius: 0;
  padding: 0;
  max-width: min(600px, 90vw);
`;

const StyledContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 8px;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    border-radius: ${theme.radius.default};
    padding: 8px 14px;
    box-shadow: ${theme.effect.shadow.m};
    background: ${theme.color.bg.emphasis.default};
    color: ${theme.color.fg.onemphasis.default};
    font: ${theme.font.body.md.medium};
    width: max-content;
  `
);

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 1;

  [data-popper-placement^="top"] & {
    bottom: -12px;
  }

  [data-popper-placement^="right"] & {
    left: -12px;
  }

  [data-popper-placement^="bottom"] & {
    top: -12px;
  }

  [data-popper-placement^="left"] & {
    right: -12px;
  }
`;

const StyledArrow = styled(Arrow)`
  color: ${({ theme }) => theme.color.bg.emphasis.default};
  overflow: hidden;

  [data-popper-placement^="top"] & {
    transform: rotate(180deg);
  }

  [data-popper-placement^="right"] & {
    transform: rotate(-90deg);
  }

  [data-popper-placement^="left"] & {
    transform: rotate(90deg);
  }
`;
