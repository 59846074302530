import { TimeRange } from "@fiberplane/prometheus-query";

import { Prometheus } from "../../../services";

type QueryArguments = {
  functionName: string;
  moduleName?: string;
  buildInfoInterval?: string;
};

type Top5QueryArguments = {
  buildInfoInterval?: string;
};

export function createVersionQuery({
  functionName,
  moduleName,
}: QueryArguments) {
  return Prometheus.createVersionQuery({
    functionName,
    moduleName,
  });
}

export function createLatencyQuery({
  functionName,
  moduleName,
  buildInfoInterval,
}: QueryArguments) {
  return Prometheus.createFunctionLatencyQuery({
    functionName,
    moduleName,
    buildInfoInterval,
  });
}

export function createTop5LatencyQuery({
  buildInfoInterval,
}: Top5QueryArguments) {
  return Prometheus.createTop5FunctionLatencyQuery({
    buildInfoInterval,
  });
}

export function createRequestRateQuery({
  functionName,
  moduleName,
  buildInfoInterval,
}: QueryArguments) {
  return Prometheus.createFunctionRequestRateQuery({
    functionName,
    moduleName,
    buildInfoInterval,
  });
}

export function createTop5FunctionRequestRateQuery({
  buildInfoInterval,
}: Top5QueryArguments) {
  return Prometheus.createTop5FunctionRequestRateQuery({
    buildInfoInterval,
  });
}

export function createErrorRatioQuery({
  functionName,
  moduleName,
  buildInfoInterval,
}: QueryArguments) {
  return Prometheus.createFunctionErrorRateQuery({
    functionName,
    moduleName,
    buildInfoInterval,
  });
}

export function createTop5ErrorRatioQuery({
  buildInfoInterval,
}: Top5QueryArguments) {
  return Prometheus.createTop5FunctionErrorRateQuery({
    buildInfoInterval,
  });
}

type RequestCountQueryArguments = QueryArguments & {
  timeRange: TimeRange;
};

export function createRequestCountQuery({
  functionName,
  moduleName,
  timeRange,
}: RequestCountQueryArguments) {
  const interval = Prometheus.getPrometheusWindowFromTimeRange(
    timeRange.from,
    timeRange.to
  );

  return Prometheus.createRequestCountQuery({
    functionName,
    moduleName,
    interval,
  });
}

export function createErrorCountQuery({
  functionName,
  moduleName,
  timeRange,
}: RequestCountQueryArguments) {
  const interval = Prometheus.getPrometheusWindowFromTimeRange(
    timeRange.from,
    timeRange.to
  );

  return Prometheus.createErrorCountQuery({
    functionName,
    moduleName,
    interval,
  });
}
