import { Navigate, Link } from "react-router-dom";
import styled from "styled-components";

import { FunctionData } from "../../../types";
import { getFunctionPath } from "../../../utils";
import { usePrometheusQuery } from "./hook";
import { FunctionsQueryError } from "./FunctionsQueryError";

type Props = {
  autometricsData?: FunctionData[];
};

export function FunctionsQueryPage(props: Props) {
  const { query, result } = usePrometheusQuery();
  if (!result) {
    return <div>This page expects parameters that were not specified</div>;
  }

  if (!props.autometricsData) {
    return null;
  }

  if (!result.functionName) {
    return (
      <FunctionsQueryError
        heading="Unable to extract function name from query"
        helperText={
          <>
            Unable to extract the relevant function name from the query.
            Received:
            <StyledPre>
              <code>{query}</code>
            </StyledPre>
          </>
        }
      />
    );
  }

  if (!result.metricType) {
    return (
      <FunctionsQueryError
        heading="Unable to extract metric type"
        helperText={
          <>
            The query doesn&rsquo;t match the expected format of autometrics
            related queries. The function name was extracted, so instead you may
            want to look at the function page for{" "}
            <Link to={getFunctionPath(result.functionName, result.moduleName)}>
              {" "}
              {result.functionName}
            </Link>
            . If you feel like this is a mistake feel free to open an issue on{" "}
            <a href="https://github.com/autometrics-dev/am">
              https://github.com/autometrics-dev/am
            </a>
            . Attempted to extract query type from:
            <StyledPre>
              <code>{query}</code>
            </StyledPre>
          </>
        }
      />
    );
  }

  const items = props.autometricsData.filter((item) => {
    return item.name === result.functionName;
  });

  if (items.length === 0 || items.length === 1) {
    const functionName = items[0]?.name ?? result.functionName;
    const moduleName = items[0]?.module ?? result.moduleName;
    const serviceName = items[0]?.service_name;

    return (
      <Navigate
        to={getFunctionPath(
          functionName,
          moduleName,
          serviceName,
          result.metricType
        )}
      />
    );
  }

  return (
    <Navigate
      to={getFunctionPath(
        result.functionName,
        undefined,
        undefined,
        result.metricType
      )}
    />
  );
}

// TODO: use syntax highlighting for query
const StyledPre = styled.pre`
  text-align: initial;
  min-width: 0;
  max-width: 100%;
  overflow-x: scroll;
  display: block;
  background: transparent;
  color: inherit;
`;
