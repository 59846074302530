import { createApi } from "@reduxjs/toolkit/query/react";
// Import fetchBaseQuery from query instead of query/react, otherwise parcel builds break, see:
// https://github.com/parcel-bundler/parcel/issues/7622
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

export type EnvironmentUrl = string | null | undefined;

export const pingApi = createApi({
  baseQuery: fetchBaseQuery({ mode: "cors" }),
  endpoints: (builder) => ({
    pingAnyPrometheus: builder.query<boolean, EnvironmentUrl>({
      query: (environmentUrl) => ({
        url: `${sanitizeEnvironmentUrl(environmentUrl ?? "")}/api/v1/query`,
        method: "GET",
        params: {
          query: "up",
        },
      }),
      transformResponse: () => {
        return true;
      },
      providesTags: (_tags, _result, environmentUrl) => {
        if (environmentUrl) {
          return [{ type: "Environment", id: environmentUrl }];
        }

        return [];
      },
    }),
  }),
  reducerPath: "pingApi",
  tagTypes: ["Environment"],
});

export const { usePingAnyPrometheusQuery } = pingApi;

/**
 * Helper function that removes a trailing slash from a URL, if it exists
 */
function sanitizeEnvironmentUrl(environmentUrl: string): string {
  return environmentUrl.replace(/\/$/, "");
}
