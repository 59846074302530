import styled, { css } from "styled-components";
import { Input } from "@fiberplane/ui";

import { isFeature, type Feature } from "../../../types";
import { useFeature } from "../../../hooks";

export type FeatureProps = {
  feature: Feature;
  description: string;
  title: string;
};

export function FeatureCard({ feature, description, title }: FeatureProps) {
  const [hasFeature, setHasFeature] = useFeature(feature);

  const handleInputChange = ({
    target: { checked, id: feature },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const isValidFeature = isFeature(feature);
    if (!isValidFeature) {
      return;
    }

    setHasFeature(checked);
  };

  return (
    <CardContainer $isSelected={hasFeature}>
      <label htmlFor={feature}>{title}</label>
      <Input
        type="checkbox"
        id={feature}
        checked={hasFeature}
        onChange={handleInputChange}
      />

      <p>{description}</p>
    </CardContainer>
  );
}

const CardContainer = styled.div<{ $isSelected: boolean }>(
  ({ $isSelected, theme }) => css`
    display: grid;
    grid: "checkbox title" "checkbox description" 1fr / min-content 1fr;
    gap: 4px 16px;
    padding: 16px;
    border-radius: ${theme.radius.rounded};
    background-color: ${theme.color.bg.default};
    border: 1px solid ${theme.color.border.default};

    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
      border-color 0.2s ease-in-out;

    & > input {
      grid-area: checkbox;
    }

    & > label {
      grid-area: title;
      align-self: center;
      cursor: pointer;
      line-height: 1;
      font: ${theme.font.headings.h5};
    }

    & > p {
      grid-area: description;
      font: ${theme.font.body.sm.regular};
      color: ${theme.color.fg.muted};
    }

    ${$isSelected &&
    css`
      border-color: ${theme.color.border.primary};
      background-color: ${theme.color.bg.emphasis["primary-subtle"]};
    `}
  `
);
