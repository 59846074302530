import { RootState } from "../state";

export const selectUiState = (state: RootState) => state.ui;

export const selectModals = (state: RootState) => selectUiState(state).modals;

export const selectIsDatePickerOpen = (state: RootState) =>
  selectUiState(state).isDatePickerOpen;

export const selectInsideAM = (state: RootState) =>
  selectUiState(state).insideAM;

export const selectActiveTooltip = (state: RootState) =>
  selectUiState(state).activeTooltip;

export const selectCommandMenuIsOpen = (state: RootState) =>
  selectUiState(state).commandMenuIsOpen;

export const selectNavMenuIsOpen = (state: RootState) =>
  selectUiState(state).navMenuIsOpen;
