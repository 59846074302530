import type { Transition, Variants } from "framer-motion";
import { motion } from "framer-motion";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, INITIAL } from "./constants";

export function AnimatedHamburgerIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <motion.path
        variants={variantsTop}
        animate={controls}
        transition={transition}
        onAnimationComplete={onAnimationComplete}
        d="M16 5V7L4 7L4 5L16 5Z"
        fill="currentColor"
      />
      <motion.path
        variants={variantsMiddle}
        initial="initial"
        animate={controls}
        transition={transition}
        d="M16 9V11L4 11L4 9L16 9Z"
        fill="currentColor"
      />
      <motion.path
        variants={variantsBottom}
        animate={controls}
        transition={transition}
        d="M16 13V15L4 15L4 13H16Z"
        fill="currentColor"
      />
    </svg>
  );
}

const transition: Transition = {
  duration: 0.1,
  type: "spring",
  stiffness: 300,
  damping: 30,
};

const initial: Variants = {
  [INITIAL]: {
    rotate: 0,
    y: 0,
  },
};

const variantsTop: Variants = {
  ...initial,
  [ANIMATE]: {
    rotate: -45,
    y: 4,
  },
};

const variantsMiddle: Variants = {
  [INITIAL]: {
    opacity: 1,
  },
  [ANIMATE]: {
    opacity: 0,
  },
};

const variantsBottom: Variants = {
  ...initial,
  [ANIMATE]: {
    rotate: 45,
    y: -4,
  },
};
