import styled, { css } from "styled-components";

import { switchInstance } from "../../../slices";
import { dispatch, useAppSelector } from "../../../store";
import { Icon, Select } from "../../UI";

export const EnvironmentSelect = () => {
  const { instances, selectedInstance } = useAppSelector(
    (state) => state.environment
  );

  const activeInstance = instances.find(
    (instance) => instance.id === selectedInstance
  );

  return (
    <StyledSelect
      className="potato"
      name="instance"
      onChange={(id) => dispatch(switchInstance(id))}
      value={activeInstance?.id ?? ""}
      leadingIcon={() => <Icon type="environments_duotone" />}
      options={instances.map((instance) => ({
        children: instance.name,
        value: instance.id,
      }))}
    />
  );
};

const StyledSelect = styled(Select)(
  ({ theme: { media } }) => css`
    span {
      display: none;

      ${media.md`
        display: inline-block;
      `}
    }
  `
);
