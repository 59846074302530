import "prismjs/themes/prism.css";

import styled, { css } from "styled-components";
import { Button } from "@fiberplane/ui";
import Prism from "prismjs";
import "prismjs/components/prism-promql";

import { Icon, ScrollContainer } from "../../../UI";
import { useCopyToClipboard } from "../../../../hooks";
import { promQlFunctions } from "./promqlFunctions";

// Extend the PromQL syntax highlighting to include PromQL functions
const { promql } = Prism.languages;
if (!promql) {
  throw new Error("PromQL syntax not defined");
}

promql.function = new RegExp(
  "\\b(?:" + promQlFunctions.join("|") + ")(?=\\s*\\()",
  "i"
);

const grammar = promql;

type QueryProps = { text: string };

export const Query = (props: QueryProps) => {
  const { text } = props;

  const { isCopied, handleCopy } = useCopyToClipboard(() => text);

  return (
    <Container>
      <CopyButton disabled={isCopied} onClick={handleCopy}>
        {isCopied ? (
          <>
            <Icon type="check" />
            Copied
          </>
        ) : (
          <>
            <Icon type="copy" />
            Copy
          </>
        )}
      </CopyButton>

      <ScrollContainer as="pre">
        <code
          className="language-promql"
          dangerouslySetInnerHTML={{
            __html: Prism.highlight(text, grammar, "promql"),
          }}
        ></code>
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    --spacing-query-container: 12px;
    --spacing-scroll-container-inline: var(--spacing-query-container);

    position: relative;
    overflow: hidden;
    padding: var(--spacing-query-container);
    background-color: ${theme.color.bg.elevated.default};
    border-radius: ${theme.radius.rounded};

    pre,
    code {
      background-color: inherit;
      text-shadow: 0 1px ${theme.color.bg.default};

      transition: background-color 0.2s ease-in-out;
    }

    code[class*="language-"],
    pre[class*="language-"] {
      color: ${theme.color.fg.default};
    }

    .language-promql {
      .token.punctuation {
        color: ${theme.color.fg.default};
      }

      .token.function {
        color: ${theme.color.fg.primary};
      }
      .token.keyword {
        color: ${theme.color.fg.primary};
      }

      .token.vector-match .token.attr-name {
        color: ${theme.color.fg.accent[3]};
      }

      .context-labels .token.label-key {
        color: ${theme.color.fg.accent[3]};
      }

      .context-labels .token.label-value {
        color: ${theme.color.fg.accent[2]};
      }

      .token.number {
        color: ${theme.color.fg.accent[1]};
      }

      .token.operator {
        background-color: transparent;
        color: ${theme.color.fg.default};
      }
    }
  `
);

const CopyButton = styled(Button).attrs({ buttonStyle: "secondary" })`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
`;
