import styled, { css } from "styled-components";
import { ReactNode } from "react";
import { useMeasure } from "react-use";
import { Button } from "@fiberplane/ui";

import { Icon } from "../Icon";

type Props = {
  type: "danger" | "warning" | "success" | "info";
  title: ReactNode;
  copy: ReactNode;
  initialSize?: "small" | "default";
  actions?: Array<{
    label: string;
    onClick: () => void;
  }>;
  // TODO: add support for close button
};

const SIZE_THRESHOLD = 40;

export function Banner(props: Props): JSX.Element {
  const { title, copy, actions, type, initialSize = "default" } = props;
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  // TODO: allow for resizing from small to default again.
  const size =
    initialSize === "small" && height <= SIZE_THRESHOLD ? "small" : "default";
  const hasButtons = actions ? actions.length > 0 : false;

  return (
    <Container type={type} size={size} hasButtons={hasButtons}>
      <StyledIcon type={getIconType(type)} size={size} />
      <TextContent size={size} ref={ref}>
        {title && <Introduction>{title}</Introduction>}
        <CopyContainer>{copy}</CopyContainer>
      </TextContent>

      {actions && (
        <ButtonContainer>
          {actions.map((action) => (
            <Button
              buttonStyle={"secondary"}
              key={action.label}
              onClick={action.onClick}
            >
              {action.label}
            </Button>
          ))}
        </ButtonContainer>
      )}
    </Container>
  );
}

function getIconType(type: Props["type"]) {
  switch (type) {
    case "danger":
    case "warning":
      return "warning_octagon";
    case "success":
      return "check";
    case "info":
      return "info";
  }
}

const Container = styled.div<
  Pick<Props, "type"> & { size: Props["initialSize"]; hasButtons: boolean }
>(
  ({ theme, type, size = "default", hasButtons }) => css`
    width: 100%;
    padding: 16px 17px;
    display: grid;
    gap: 16px;
    grid-template: ${() => {
      if (!hasButtons) {
        return css`
          "icon text" auto / 20px auto`;
      }

      if (size === "small") {
        return css`
          "icon text button" auto / 20px auto min-content`;
      }

      return css`
        "icon text" auto "icon button" auto / 20px auto`;
    }};

    ${() => {
      switch (type) {
        case "info":
          return css`
            --emphasis-color: ${theme.color.fg.primary};
            --fg-muted: ${theme.color.fg.muted};
            color: ${theme.color.fg.primary};
            border-left-color: ${theme.color.border.primary};
            background-color: ${theme.color.bg.subtle};
          `;
        case "success":
          return css`
            --emphasis-color: ${theme.color.fg.success};
            --fg-muted: ${theme.color.fg.muted};
            color: ${theme.color.fg.success};
            border-left-color: ${theme.color.border.success};
            background-color: ${theme.color.bg.success};
          `;
        case "warning":
          return css`
            --emphasis-color: ${theme.color.fg.warning};
            --fg-muted: ${theme.color.fg.muted};
            color: ${theme.color.fg.warning};
            border-left-color: ${theme.color.border.warning};
            background-color: ${theme.color.bg.warning};
          `;
        default:
          return css`
            --emphasis-color: ${theme.color.fg.danger};
            --fg-muted: ${theme.color.fg.muted};
            color: ${theme.color.fg.danger};
            border-left-color: ${theme.color.border.danger};
            background-color: ${theme.color.bg.danger};
          `;
      }
    }}
    border-radius: 0 ${theme.radius.rounded} ${theme.radius.rounded} 0;
    border-left: 3px solid transparent;
  `
);

const StyledIcon = styled(Icon)<{ size: Props["initialSize"] }>(
  ({ size = "default" }) => css`
    grid-area: icon;
    align-self: ${size === "default" ? "start" : "center"};
  `
);

const TextContent = styled.div<{ size: Props["initialSize"] }>(
  ({ size = "default" }) => css`
    grid-area: text;
    display: flex;
    gap: ${size === "small" ? "6px" : "8px"};
    flex: 1;
    flex-direction: ${size === "small" ? "row" : "column"};
    color: var(--fg-muted);
    align-items: ${size === "default" ? "flex-start" : "center"};
    font: ${({ theme }) => theme.font.body.sm.regular};

    em {
      color: var(--emphasis-color);
    }
  `
);

const Introduction = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--emphasis-color);
  font: ${({ theme }) => theme.font.headings.h5};
`;

const CopyContainer = styled.div`
  // This transform is fix to where things wouldn't align nicely
  transform: translateY(1px);
`;

const ButtonContainer = styled.div`
  grid-area: button;
`;
