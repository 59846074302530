import type { CALL_HISTORY_METHOD } from "redux-first-history";

import type { UiAction } from "./uiActions";

export * from "./uiActions";

export {
  add,
  appendById,
  setAskAutometricsVisibility,
} from "../slices/askAutometrics";

type CallHistoryMethodAction = {
  type: typeof CALL_HISTORY_METHOD;
};

export type AppAction = CallHistoryMethodAction | UiAction;
