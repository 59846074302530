import styled from "styled-components";
import { Input } from "@fiberplane/ui";

import { Icon } from "../../UI";

type FunctionSearchProps = {
  value: string;
  onChange: (value: string) => void;
};

export const FunctionSearch = (props: FunctionSearchProps) => {
  const { value, onChange } = props;
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <SearchContainer>
      <StyledIcon type="search_duotone" />
      <StyledInput
        placeholder="Filter functions"
        value={value}
        onChange={onInputChange}
      />
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 8px 0 16px;
  gap: 8px;
  position: relative;
`;

const StyledIcon = styled(Icon).attrs({ height: 16, width: 16 })`
  position: absolute;
  top: 7px;
  left: 8px;
  color: ${({ theme }) => theme.color.fg.muted};
`;

const StyledInput = styled(Input).attrs({ type: "text" })`
  width: 100%;
  padding: 2px 12px 2px 32px;

  &::placeholder {
    color: ${({ theme }) => theme.color.fg.muted};
    font-size: 12px;
  }
`;
