import styled, { css } from "styled-components";

export const EnvironmentListItem = styled.li<{ $isActive?: boolean }>(
  ({ $isActive, theme }) =>
    css`
      display: grid;
      grid:
        "icon name buttons" auto
        "icon url buttons" auto
        "icon errors buttons" auto
        / auto 1fr auto;
      gap: 2px 16px;
      padding: 16px;
      margin: 0;
      cursor: pointer;
      border: 1px solid ${theme.color.border.default};
      border-radius: ${theme.radius.rounded};
      align-items: center;

      /* TODO (Oscar): this is coming from the Button component; these rules
        should end up in a central place */
      transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
        border-color 0.2s ease-in-out;

      ${$isActive &&
      css`
        border-color: ${theme.color.border.primary};
        background-color: ${theme.color.bg.emphasis["primary-subtle"]};
      `}
    `
);

export const EnvironmentTitle = styled.h5`
  grid-area: name;
  display: flex;
  gap: 8px;
  align-items: center;
`;

// TODO (Oscar): check if this needs to be moved to buttons directory
export const EnvironmentButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: flex-start;
`;

export const SettingsBlockHeader = styled.header`
  display: grid;
  align-content: start;
  gap: 4px;
`;

export const SettingsHeaderDescription = styled.p(
  ({ theme }) => css`
    font: ${theme.font.body.sm.regular};
    color: ${theme.color.fg.muted};
    margin-bottom: 16px;
  `
);
