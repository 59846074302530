import { Routes, Route } from "react-router-dom";

import { notifications } from "../../services";
import { useAlertsPolling } from "./hooks";
import { NudgeUser } from "./NudgeUser";

/**
 * A component that sets up the alerts polling and desktop notifications.
 *
 * On the alerts page it can also show a banner to nudge the user to enable
 * desktop notifications.
 */
export function AlertsPolling() {
  const newAlerts = useAlertsPolling();
  const { settings: notificationSettings } =
    notifications.useNotificationSettings();
  if (
    newAlerts &&
    notificationSettings.notificationState === "default" &&
    notificationSettings.desktopNotificationsEnabled
  ) {
    return (
      <Routes>
        <Route path="/alerts" element={<NudgeUser />} />
        <Route path="*" element={null} />
      </Routes>
    );
  }

  return null;
}
