import {
  Container,
  Content,
  Header,
  Heading,
  HelperText,
  BigIcon,
} from "./common";

export function Loading() {
  return (
    <Container>
      <Content>
        <Header>
          <BigIcon type="autometrics" />
          <Heading>Loading</Heading>
          <HelperText>Getting your data from Prometheus&hellip;</HelperText>
        </Header>
      </Content>
    </Container>
  );
}
