import styled from "styled-components";
import { Button } from "@fiberplane/ui";

import { FallbackCommon } from "../FallbackStates";

const { Container, Content, Header, Heading, BigIcon } = FallbackCommon;

type NoSlosMatchedProps = { resetFilters: () => void };

export function NoSlosMatched({ resetFilters }: NoSlosMatchedProps) {
  return (
    <StyledContainer>
      <StyledContent>
        <Header>
          <BigIcon type="autometrics_black_faded" />
          <Heading>No SLOs match your filters</Heading>
          <Button onClick={resetFilters}>Reset filters</Button>
        </Header>
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  height: 100%;
  min-height: 400px;
  align-items: flex-start;
`;

const StyledContent = styled(Content)`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
