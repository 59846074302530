import "prismjs/themes/prism.css";

import { useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "@fiberplane/ui";
import Prism from "prismjs";
import "prismjs/components/prism-rust";

import { Icon } from "../UI";
import { useCopyToClipboard } from "../../hooks";
import {
  getRustCode,
  getGoCode,
  getPythonCode,
  getTypescriptCode,
} from "./code";

const { rust } = Prism.languages;

if (!rust) {
  throw new Error("Rust syntax not defined");
}

const grammar = rust;

type Language = "rust" | "python" | "go" | "typescript";

export const CodeSamples = () => {
  const [language, setLanguage] = useState<Language>("rust");

  return (
    <>
      <CodeSampleSelector language={language} setLanguage={setLanguage} />
      <CodeSample language={language} />
    </>
  );
};

const CodeSampleSelector = ({
  language,
  setLanguage,
}: {
  language: Language;
  setLanguage: (lang: Language) => void;
}) => {
  return (
    <LanguageButtonGroupContainer>
      <LanguageButtonGroup>
        <Button
          buttonStyle={language === "rust" ? "primary" : "secondary"}
          onClick={() => setLanguage("rust")}
        >
          {/* <Icon type="rust" /> */}
          Rust
        </Button>
        <Button
          buttonStyle={language === "typescript" ? "primary" : "secondary"}
          onClick={() => setLanguage("typescript")}
        >
          {/* <Icon type="typescript" /> */}
          TypeScript
        </Button>
        <Button
          buttonStyle={language === "python" ? "primary" : "secondary"}
          onClick={() => setLanguage("python")}
        >
          {/* <Icon type="python" /> */}
          Python
        </Button>
        <Button
          buttonStyle={language === "go" ? "primary" : "secondary"}
          onClick={() => setLanguage("go")}
        >
          {/* <Icon type="go" /> */}
          Go
        </Button>
      </LanguageButtonGroup>
    </LanguageButtonGroupContainer>
  );
};

const CodeSample = ({ language }: { language: Language }) => {
  let text: string;
  switch (language) {
    case "rust":
      text = getRustCode();
      break;
    case "python":
      text = getPythonCode();
      break;
    case "go":
      text = getGoCode();
      break;
    case "typescript":
      text = getTypescriptCode();
      break;
  }

  const { isCopied, handleCopy } = useCopyToClipboard(() => text);

  return (
    <Container>
      <CopyButton disabled={isCopied} onClick={handleCopy}>
        {isCopied ? (
          <>
            {" "}
            <Icon type="check" />
            Copied
          </>
        ) : (
          <>
            {" "}
            <Icon type="copy" />
            Copy
          </>
        )}
      </CopyButton>
      <pre>
        <code
          className="language-autometrics"
          dangerouslySetInnerHTML={{
            __html: Prism.highlight(text, grammar, language),
          }}
        ></code>
      </pre>
    </Container>
  );
};

const Container = styled.div(
  ({ theme }) => css`
    background-color: ${theme.color.bg.subtle};
    border-radius: ${theme.radius.rounded};
    padding: 12px;
    position: relative;
    margin: 0 auto;

    /* HACK - code samples have been optimized for 600px */
    @media (min-width: 600px) {
      width: 600px;
    }

    pre {
      overflow-x: auto;
      white-space: nowrap;
    }

    pre,
    code {
      background-color: ${theme.color.bg.subtle};
      text-shadow: 0 1px ${theme.color.bg.default};

      transition: background-color 0.2s ease-in-out;
    }

    code[class*="language-"],
    pre[class*="language-"] {
      color: ${theme.color.fg.default};
    }

    .language-autometrics {
      .token.punctuation {
        color: ${theme.color.fg.default};
      }

      .token.function {
        color: ${theme.color.fg.primary};
      }
      .token.keyword {
        color: ${theme.color.fg.primary};
      }

      .token.string {
        color: ${theme.color.fg.accent[1]};
      }

      .token.class-name {
        color: ${theme.color.fg.default};
      }
      .token.constant {
        color: ${theme.color.fg.default};
      }

      .token.function-definition.function {
        color: ${theme.color.fg.accent[2]};
      }

      .token.namespace {
        color: ${theme.color.fg.accent[3]};
      }

      .token.attribute.attr-name {
        color: ${theme.color.fg.default};
      }

      .token.number {
        color: ${theme.color.fg.accent[1]};
      }

      .token.operator {
        background-color: transparent;
        color: ${theme.color.fg.default};
      }
    }
  `
);

const CopyButton = styled(Button)(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    margin: 8px;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: ${theme.radius.minimal};
    background-color: ${theme.color.bg.default};
    color: ${theme.color.fg.default};
    border: 1px solid ${theme.color.bg.subtle};

    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      background-color: ${theme.color.bg.hover};
    }
  `
);

const LanguageButtonGroupContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LanguageButtonGroup = styled.div(
  ({ theme }) => css`
    display: inline-flex;
    gap: 4px;
    padding: 4px;
    border-radius: ${theme.radius.default};
    background-color: ${theme.color.bg.subtle};
  `
);
