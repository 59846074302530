import styled, { css } from "styled-components";

import { Icon } from "../Icon";

export type Props = {
  children: React.ReactNode;
  name: string;
  onSelect: (value: string) => void;
  selected?: boolean;
  value: string;
};

export function SelectOption(props: Props): JSX.Element {
  const { children, onSelect, selected, value } = props;

  return (
    <Container
      role="option"
      aria-selected={selected}
      tabIndex={0}
      onClick={() => onSelect(value)}
    >
      <Label>{children}</Label>
      <SelectedIcon type="check" selected={selected} />
    </Container>
  );
}

const Container = styled.div`
  ${({ theme }) => css`
    font: ${theme.font.body.md.medium};
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
    color: ${theme.color.fg.default};
    cursor: pointer;
    border-radius: ${theme.radius.default};
    outline: none;

    :hover {
      background-color: ${theme.color.bg.elevated.hover};
    }

    &:focus {
      border-color: ${theme.color.border.primary};
      box-shadow: ${theme.effect.focus.primary};
    }
  `}
`;

const Label = styled.label`
  min-width: max-content;
  cursor: pointer;
`;

const SelectedIcon = styled(Icon)<{
  selected?: boolean;
}>(
  ({ theme, selected }) =>
    css`
      color: ${selected ? theme.color.fg.primary : "transparent"};
    `
);
