import styled, { css } from "styled-components";
import { ComponentProps } from "react";
import { Button } from "@fiberplane/ui";

type TabProps = ComponentProps<typeof Button> & {
  isActive: boolean;
  children: React.ReactNode;
};

export function Tab({ isActive, children, ...buttonProps }: TabProps) {
  return (
    <TabButton
      {...buttonProps}
      role="tab"
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      $isActive={isActive}
    >
      {children}
    </TabButton>
  );
}

export const Tabs = styled.div`
  display: grid;
  grid-auto-rows: auto;
  gap: ${({ theme }) => theme.spacing.page.gap};
`;

export const TabList = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    padding: 4px;
    width: var(--width-tab-list, fit-content);
    border-radius: ${theme.radius.default};
    background-color: ${theme.color.bg.elevated.hover};
  `
);

const TabButton = styled(Button).attrs({ buttonStyle: "secondary" })<{
  $isActive: boolean;
}>(
  ({ $isActive, theme }) => css`
    position: relative;
    border-color: transparent;
    box-shadow: none;
    background: transparent;

    &:hover {
      background-color: ${theme.color.primary.grape.alpha[100]};
    }

    &:focus,
    &:focus-visible {
      box-shadow: none;
    }

    ${$isActive &&
    css`
      border-color: ${theme.color.border.primary};
      color: ${theme.color.fg.primary};

      &,
      &:hover {
        background-color: ${theme.color.button.primary.bg.default};
        color: ${theme.color.fg.onemphasis.default};
      }
    `}
  `
);
