import type { Action, PayloadAction, ThunkAction } from "@reduxjs/toolkit";

import type { AppAction } from "../actions";
import type { RootState } from "../state";

export type AppActionOrThunk = AppAction | PayloadAction | Action | Thunk<any>;

export type AppDispatch = <T extends AppActionOrThunk>(
  actionOrThunk: T
) => T extends Thunk<infer Returned> ? Returned : void;

export type Thunk<Returned = void> = ThunkAction<
  Returned,
  RootState,
  any,
  AppAction | PayloadAction | Action
>;
