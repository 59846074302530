import { useEffect } from "react";
import { useSelector } from "react-redux";

import { dispatch } from "../../store";
import { hideTooltipWithTimeout } from "../../thunks";
import { selectActiveTooltip } from "../../selectors";
import { TooltipContainer } from "./TooltipContainer";

export function ActiveTooltip(): JSX.Element {
  const tip = useSelector(selectActiveTooltip);

  useEffect(() => {
    if (tip) {
      document.body.addEventListener("mouseout", mouseOut);
      document.body.addEventListener("click", blur);
      document.body.addEventListener("mousedown", blur);

      return () => {
        document.body.removeEventListener("mouseout", mouseOut);
        document.body.removeEventListener("click", blur);
        document.body.removeEventListener("mousedown", blur);
      };
    }
  }, [tip]);

  return <TooltipContainer tip={tip} />;
}

function blur({ target }: MouseEvent) {
  if (!(target as HTMLElement).closest("[data-tooltip-container]")) {
    dispatch(hideTooltipWithTimeout(0));
  }
}

function mouseOut(event: MouseEvent) {
  const { relatedTarget } = event;

  const isElement = relatedTarget instanceof Element;
  const isTooltipElement =
    (isElement && relatedTarget.hasAttribute("aria-label")) ||
    (relatedTarget instanceof HTMLElement && relatedTarget.dataset["shortcut"]);

  if (
    relatedTarget instanceof Node === false ||
    isTooltipElement ||
    (isElement &&
      relatedTarget.closest("[aria-label],[data-shortcut]") !== null)
  ) {
    return;
  }

  return dispatch(hideTooltipWithTimeout(400));
}
