import styled from "styled-components";
import { forwardRef } from "react";
import { Button } from "@fiberplane/ui";

import { Icon, type IconType } from "../Icon";

type IconButtonProps = React.ComponentProps<typeof Button> & {
  iconType: IconType;
};

export const IconButton = forwardRef(function IconButton(
  { iconType: type, ...buttonProps }: IconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <StyledButton {...buttonProps} ref={ref}>
      <Icon type={type} height={20} width={20} />
    </StyledButton>
  );
});

const StyledButton = styled(Button)`
  padding: unset;
  width: 36px;
  aspect-ratio: 1;
`;
