import { useNavigate, useSearchParams } from "react-router-dom";

import {
  DatePickerTimeRange,
  updateTimeRangeSearchParams,
} from "../../../../utils";

export function useNavigateToAlert(index: number) {
  const navigate = useNavigate();
  const [currentSearchParams] = useSearchParams();

  return (newTimeRange?: DatePickerTimeRange) => {
    const updatedSearchParams = new URLSearchParams(currentSearchParams);
    updatedSearchParams.set("expanded", index.toString());
    if (newTimeRange) {
      updateTimeRangeSearchParams(updatedSearchParams, newTimeRange);
    }

    navigate({
      pathname: "/alerts",
      search: updatedSearchParams.toString(),
    });
  };
}
