import styled from "styled-components";

import { FallbackCommon } from "../FallbackStates";
import { CodeSamples } from "./CodeSamples";

const { Container, Content, Header, Heading, HelperText, BigIcon } =
  FallbackCommon;

export function NoSlosFound() {
  return (
    <StyledContainer>
      <StyledContent>
        <Header>
          <BigIcon type="autometrics_black_faded" />
          <Heading>No SLOs Found</Heading>
          <HelperText>
            SLOs (Service Level Objectives) are performance promises made by a
            service or system. Read more about SLOs in the{" "}
            <a
              href="https://docs.autometrics.dev/features#alerts-and-slos-without-yaml"
              target="_blank"
              rel="noreferrer noopener"
            >
              documentation
            </a>
            , or try one of the code samples below.
          </HelperText>
        </Header>
        <CodeSamples />
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  height: 100%;
  min-height: 400px;
  align-items: flex-start;
  padding: 72px 0;
`;

const StyledContent = styled(Content)`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
