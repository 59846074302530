import type { TimeRange, Timestamp } from "@fiberplane/charts";

export type DatePickerTimeRange = TimeRange & {
  relativeTimeRangeLabel?: TimeRangePresetLabel;
};

export const timeRangePresetLabels = {
  LAST_5_MINUTES: "Last 5 minutes",
  LAST_15_MINUTES: "Last 15 minutes",
  LAST_30_MINUTES: "Last 30 minutes",
  LAST_1_HOUR: "Last 1 hour",
  LAST_3_HOURS: "Last 3 hours",
  LAST_6_HOURS: "Last 6 hours",
  LAST_12_HOURS: "Last 12 hours",
  LAST_24_HOURS: "Last 24 hours",
  LAST_2_DAYS: "Last 2 days",
  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days",
  LAST_90_DAYS: "Last 90 days",
  LAST_6_MONTHS: "Last 6 months",
  LAST_1_YEAR: "Last 1 year",
  LAST_2_YEARS: "Last 2 years",
  LAST_5_YEARS: "Last 5 years",
  LAST_10_YEARS: "Last 10 years",
  LAST_20_YEARS: "Last 20 years",
} as const;

const {
  LAST_5_MINUTES,
  LAST_15_MINUTES,
  LAST_30_MINUTES,
  LAST_1_HOUR,
  LAST_3_HOURS,
  LAST_6_HOURS,
  LAST_12_HOURS,
  LAST_24_HOURS,
  LAST_2_DAYS,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_90_DAYS,
  LAST_6_MONTHS,
  LAST_1_YEAR,
  LAST_2_YEARS,
  LAST_5_YEARS,
  LAST_10_YEARS,
  LAST_20_YEARS,
} = timeRangePresetLabels;

export const timeRangeOptions: Array<TimeRangePresetOption> = [
  {
    label: LAST_5_MINUTES,
    value: 5 * 60 * 1000,
  },
  {
    label: LAST_15_MINUTES,
    value: 15 * 60 * 1000,
  },
  {
    label: LAST_30_MINUTES,
    value: 30 * 60 * 1000,
  },
  {
    label: LAST_1_HOUR,
    value: 60 * 60 * 1000,
  },
  {
    label: LAST_3_HOURS,
    value: 3 * 60 * 60 * 1000,
  },
  {
    label: LAST_6_HOURS,
    value: 6 * 60 * 60 * 1000,
  },
  {
    label: LAST_12_HOURS,
    value: 12 * 60 * 60 * 1000,
  },
  {
    label: LAST_24_HOURS,
    value: 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_2_DAYS,
    value: 2 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_7_DAYS,
    value: 7 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_30_DAYS,
    value: 30 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_90_DAYS,
    value: 90 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_6_MONTHS,
    value: 6 * 30 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_1_YEAR,
    value: 365 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_2_YEARS,
    value: 2 * 365 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_5_YEARS,
    value: 5 * 365 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_10_YEARS,
    value: 10 * 365 * 24 * 60 * 60 * 1000,
  },
  {
    label: LAST_20_YEARS,
    value: 20 * 365 * 24 * 60 * 60 * 1000,
  },
];

// Helper that creates a type restricted to the *values* of a given Record
// We use this to define types from some of our constants
type ValuesOfRecord<T extends Record<string, U>, U = T[keyof T]> = U;

export type TimeRangePresetLabel = ValuesOfRecord<typeof timeRangePresetLabels>;

export const isTimeRangePresetLabel = (
  label?: string
): label is TimeRangePresetLabel => {
  if (label === undefined) {
    return false;
  }

  return (Object.values(timeRangePresetLabels) as string[]).includes(label);
};

export type TimeRangePresetOption = {
  label: TimeRangePresetLabel;
  value: number;
};

export const colors = [
  "var(--vscode-charts-red)",
  "var(--vscode-charts-blue)",
  "var(--vscode-charts-yellow)",
  "var(--vscode-charts-orange)",
  "var(--vscode-charts-green)",
  "var(--vscode-charts-purple)",
];

export type DateParts = {
  month: number;
  date: number;
  year: number;
  timestamp: Timestamp;
};

export function getDateParts(date: Date): DateParts {
  return {
    month: date.getMonth(),
    date: date.getDate(),
    year: date.getFullYear(),
    timestamp: date.toISOString(),
  };
}

export const timestampToDate = (timestamp: Timestamp): Date =>
  new Date(timestamp);

export function pxToEm(px: number, baseSize = 13): string {
  return `${px / baseSize}em`;
}

type Keys = keyof TimeRange | "global";

type ValidationResult = {
  values: TimeRange;
  errors: Record<Keys, undefined | string>;
};

export function validateTimeRange(values: TimeRange): ValidationResult {
  const { from: fromValue, to: toValue } = values;
  const parsedFrom = timestampToSeconds(fromValue);
  const parsedTo = timestampToSeconds(toValue);
  let fromError: undefined | string;
  let toError: undefined | string;
  let globalError: undefined | string;

  if (Number.isNaN(parsedFrom)) {
    fromError = "Invalid date entered";
  }

  if (Number.isNaN(parsedTo)) {
    toError = "Invalid date entered";
  }

  if (parsedTo < parsedFrom) {
    globalError = "End date is before start date";
  }

  return {
    values: {
      from: fromError ? fromValue : secondsToTimestamp(parsedFrom),
      to: toError ? toValue : secondsToTimestamp(parsedTo),
    },
    errors: {
      from: fromError,
      to: toError,
      global: globalError,
    },
  };
}

export const secondsToTimestamp = (seconds: number): Timestamp =>
  new Date(seconds * 1000).toISOString();

export const timestampToSeconds = (timestamp: Timestamp): number =>
  new Date(timestamp).getTime() / 1000;

export const msToTimestamp = (ms: number): Timestamp =>
  new Date(ms).toISOString();

export const isInsideTimeRange = (timestamp: Timestamp, timeRange: TimeRange) =>
  timestamp >= timeRange.from && timestamp <= timeRange.to;

export function getRelativeTimeRangeFromLabel(label: TimeRangePresetLabel) {
  let option = timeRangeOptions.find((option) => option.label === label);
  if (!option) {
    //eslint-disable-next-line no-console
    console.error("Error looking up relative time range for label:", label);
    // HACK - fall back to 15 minutes. Throwing an error would crash the app, and that's no bueno
    option = { label: "Last 15 minutes", value: 15 * 60 * 1000 };
  }

  return computeTimeRangeFromPresetOption(option);
}

export function computeTimeRangeFromPresetOption(
  option: TimeRangePresetOption
) {
  const now = Date.now();
  return {
    from: msToTimestamp(now - option.value),
    to: msToTimestamp(now),
    relativeTimeRangeLabel: option.label,
  };
}

/**
 * Update the searchParams object with the new time range
 */
export function updateTimeRangeSearchParams(
  searchParams: URLSearchParams,
  newTimeRange: DatePickerTimeRange
) {
  searchParams.delete("from");
  searchParams.delete("to");
  searchParams.delete("relativeTimeRangeLabel");

  if (newTimeRange.relativeTimeRangeLabel) {
    searchParams.set(
      "relativeTimeRangeLabel",
      newTimeRange.relativeTimeRangeLabel
    );
  }

  searchParams.set("to", newTimeRange.to);
  searchParams.set("from", newTimeRange.from);
}
