import {
  LatencyTarget,
  Objective,
  ObjectiveTarget,
  ObjectiveWithCurrentValue,
  SuccessRateTarget,
} from "../schemas";

export function getIsActualObjectiveValueViolatingTarget(
  objective: ObjectiveWithCurrentValue
) {
  const { target, currentValue } = objective;

  if (target.percentile && typeof currentValue === "string") {
    const value = Number.parseFloat(currentValue);
    const percentile = Number.parseFloat(target.percentile);

    return value < percentile * 0.01;
  }

  return false;
}

export function getIsActualFunctionValueViolatingTarget(
  objective: Objective,
  functionActualValue?: string | null
) {
  const { target } = objective;

  if (target?.percentile && typeof functionActualValue === "string") {
    const value = Number.parseFloat(functionActualValue);
    const percentile = Number.parseFloat(target.percentile);

    return value < percentile * 0.01;
  }

  return false;
}

export function getTargetLatencyBare(target?: ObjectiveTarget) {
  if (target && "threshold" in target) {
    const threshold = Number.parseFloat(target.threshold);
    return `${threshold * 1000}ms`;
  }
}

export function getSuccessRateTarget(target: SuccessRateTarget) {
  const percentile = Number.parseFloat(target.percentile);
  return `> ${percentile}%`;
}

export function getLatencyTarget(target: LatencyTarget) {
  const percentile = Number.parseFloat(target.percentile);
  const threshold = Number.parseFloat(target.threshold);
  return `${percentile}% < ${threshold * 1000}ms`;
}

export function formatPercentageWithSignificantDigits(value: string | null) {
  if (value === null) {
    return "-";
  }

  return Number.parseFloat(value).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
}
