import { Row } from "@tanstack/react-table";
import { Timeseries } from "@fiberplane/prometheus-query";
import { useSelector } from "react-redux";

import { useGetAllRulesQuery } from "../../../api";
import { selectActivePrometheus } from "../../../selectors";
import { PrometheusAlertingRuleGroup } from "../../../schemas";
import { RuleDetail } from "./RuleDetail";
import { Loading } from "../../FallbackStates";

type Props = {
  data: Row<Timeseries>;
};

export function AlertDetails(props: Props) {
  const { data } = props;
  const environment = useSelector(selectActivePrometheus);

  const rules = useGetAllRulesQuery({
    environmentUrl: environment?.url,
  });

  if (rules.data === undefined) {
    return <Loading />;
  }

  let currentRules: PrometheusAlertingRuleGroup["rules"] = [];
  for (const ruleGroup of rules.data) {
    const matching = ruleGroup.rules.filter(
      (rule) => rule.name === data.original.labels.alertname
    );
    if (matching.length > 0) {
      currentRules.push(...matching);
    }
  }

  currentRules = currentRules.filter(
    (rule) => rule.labels?.severity === data.original.labels.severity
  );

  const query =
    currentRules[0]?.query ||
    'ALERTS{alertname="' + data.original.labels.alertname + '"}';

  return <RuleDetail labels={data.original.labels} query={query} />;
}
