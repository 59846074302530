import { Timeseries } from "@fiberplane/charts";

import { extractBlockData } from "../utils";
import { TooltipState } from "../../../../state";
import { Block } from "./Block";
import { useNavigateToAlert } from "../hooks";

export function GraphItem({
  data,
  stepSize,
  getX,
  index: matchingIndex,
  maxWidth,
  showTooltip,
}: {
  data: Timeseries;
  stepSize: number;
  getX: (tick: number) => number;
  maxWidth: number;
  index: number;
  showTooltip(tooltip: TooltipState | null): void;
}) {
  const navigateToAlert = useNavigateToAlert(matchingIndex);
  const blocks = extractBlockData(data, stepSize);

  return (
    <>
      {blocks.map((block, index) => {
        const x = Math.max(getX(block.start) - 4, 0);
        const width = Math.min(getX(block.end) - x + 4, maxWidth);
        return (
          <Block
            key={index}
            data={data}
            navigateToAlert={navigateToAlert}
            x={x}
            width={width}
            showTooltip={showTooltip}
            stepSize={stepSize}
            {...block}
          />
        );
      })}
    </>
  );
}
