import styled, { css } from "styled-components";

import { SourceDefinition } from "../../../../schemas";
import { useCopyToClipboard } from "../../../../hooks";
import { Icon } from "../../../UI";

type Props = {
  definition: SourceDefinition;
};

export function TextLink({ definition }: Props) {
  const { isCopied, handleCopy } = useCopyToClipboard(() =>
    definition ? getText(definition) : ""
  );

  return (
    <Code role="button" onClick={handleCopy}>
      <span>{isCopied ? "Copied" : getText(definition)}</span>
      <Icon type="copy" />
    </Code>
  );
}

const Code = styled.code(
  ({ theme }) => css`
    display: grid;
    grid: auto / max-content auto;
    align-items: center;
    width: min-content;
    gap: 8px;
    color: ${theme.color.fg.muted};
    cursor: pointer;

    &:hover {
      color: ${theme.color.fg.default};
    }
  `
);

function getText(definition: SourceDefinition) {
  const { range, file } = definition;
  const line = range.start.line + 1;
  const column = range.start.column + 1;

  return `${file}:${line}:${column}`;
}
