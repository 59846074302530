import styled, { css } from "styled-components";

import { AlertLabel } from "./AlertLabel";
import { Container, Text, Top } from "./shared";

type Props = {
  state: string;
  name: string;
  from: string;
  to: string;
};

export function GraphTooltip(props: Props) {
  return (
    <Container>
      <Top>
        <div>
          <AlertLabel state={props.state} />
        </div>
        <Text>{props.name}</Text>
      </Top>
      <Time>
        {props.from} - {props.to}
      </Time>
    </Container>
  );
}

const Time = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.md.regular};
    color: ${theme.color.fg.onemphasis.subtle};
  `
);
