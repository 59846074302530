import styled, { css } from "styled-components";
import { useSearchParams } from "react-router-dom";

import { Setting } from "./Settings";
import { Icon, NavLinkContent, StyledNavLink } from "../UI";
import { useFeature } from "../../hooks";

type SettingsTabLinkProps = Omit<Setting, "description" | "feature"> & {
  showBetaTag?: boolean;
};

export function SettingsTabLink({
  iconType,
  title,
  url,
  showBetaTag,
}: SettingsTabLinkProps) {
  const [currentSearchParams] = useSearchParams();
  const search = currentSearchParams.toString();

  return (
    <StyledNavLink to={{ pathname: url, search }} end>
      <StyledNavLinkContent>
        <Icon type={iconType} />

        <TitleSpan>
          {title}
          {showBetaTag && <BetaTag>BETA</BetaTag>}
        </TitleSpan>
      </StyledNavLinkContent>
    </StyledNavLink>
  );
}

type SettingsTabLinkBetaProps = Omit<
  Required<Setting>,
  "showBetaTag" | "description"
>;

/**
 * Same as SettingsTabLink, but only renders if the feature is enabled & passes
 * a prop to `SettingsTabLink` so the beta tag is shown. As we can't call hooks
 * conditionally, we need to use a separate component.
 */
export function SettingsTabLinkBeta(props: SettingsTabLinkBetaProps) {
  const [hasFeature] = useFeature(props.feature);

  if (hasFeature) {
    return <SettingsTabLink {...props} showBetaTag />;
  }

  return null;
}

const StyledNavLinkContent = styled(NavLinkContent)`
  grid-template-columns: 20px max-content;
`;

const TitleSpan = styled.span`
  display: inline-block;
  position: relative;
`;

const BetaTag = styled.span(
  ({ theme }) => css`
    display: block;
    padding: 4px 8px;
    border-radius: ${theme.radius.full};
    font: ${theme.font.buttons.sm};
    line-height: 1;
    color: ${theme.color.fg.primary};
    background-color: ${theme.color.bg.emphasis["primary-subtle"]};
    position: absolute;
    top: -2px;
    right: -52px;
  `
);
