import { useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Setting } from "./Settings";
import { PageDivider } from "../UI";
import { ExperimentalContentBlock } from "./Experimental";
import { NotificationSwitcher } from "./NotificationSwitcher";
import { PrometheusEnvironments } from "./PrometheusEnvironments";
import { SourceCodeLinkSwitcher } from "./SourceCodeLinkSwitcher";
import { ThemeSwitcher } from "./ThemeSwitcher";
import { useFeature } from "../../hooks";
import { SettingsBlockHeader, SettingsHeaderDescription } from "./common";
import { Redirect } from "../Redirect";

type SettingsTabPanelProps = Omit<Setting, "iconType">;

export function SettingsTabPanel({
  url,
  title,
  description,
}: SettingsTabPanelProps) {
  const settingsContent = useMemo(() => {
    switch (url) {
      case "prometheus-urls":
        return <PrometheusEnvironments />;
      case "notifications":
        return <NotificationSwitcher />;
      case "theme":
        return <ThemeSwitcher />;
      case "experimental":
        return <ExperimentalContentBlock />;
      case "source-code-links":
        return <SourceCodeLinkSwitcher />;
    }
  }, [url]);

  return (
    <SettingsBlock>
      <SettingsBlockHeader>
        <h2>{title}</h2>
        <SettingsHeaderDescription>{description}</SettingsHeaderDescription>
        <PageDivider />
      </SettingsBlockHeader>

      {settingsContent}
    </SettingsBlock>
  );
}

type SettingsTabPanelBetaProps = Omit<Required<Setting>, "iconType">;

/**
 * Same as SettingsTabPanel, but only renders if the feature is enabled. As we
 * can't call hooks conditionally, we need to use a separate component.
 */
export function SettingsTabPanelBeta({
  description,
  feature,
  title,
  url,
}: SettingsTabPanelBetaProps) {
  const [hasFeature] = useFeature(feature);
  const params = useParams();

  if (hasFeature) {
    return (
      <SettingsTabPanel url={url} title={title} description={description} />
    );
  }

  // In case the user navigates to a beta tab directly even though the feature
  // is disabled, redirect them to the root settings page.
  if (params["*"] === url) {
    return <Redirect to="settings" options={{ replace: true }} />;
  }

  return null;
}

const SettingsBlock = styled.div`
  display: grid;
  gap: 32px 0;
  align-content: start;
`;
