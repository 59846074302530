export function replaceQueryParameter(
  currentSearchParams: URLSearchParams,
  key: string,
  value: string | null
) {
  const newSearchParams = new URLSearchParams(currentSearchParams.toString());

  if (value === null) {
    newSearchParams.delete(key);
  } else {
    newSearchParams.set(key, value);
  }

  return newSearchParams;
}
