import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import styled, { css } from "styled-components";

import { Icon } from "../Icon";
import { popInTransition } from "../../Animations";

type CaretIconProps = Omit<React.ComponentProps<typeof Icon>, "type"> & {
  isActive: boolean;
};

/**
 * Animated caret icon to be used in 'dropdown' button components. It accepts an
 * `isActive` prop to indicate whether the caret should be rotated or not.
 */
export function AnimatedCaretIcon({
  isActive,
  height = 20,
  width = 20,
  ...svgProps
}: CaretIconProps) {
  return (
    <IconContainer
      $height={height}
      $width={width}
      variants={variants}
      transition={popInTransition}
      animate={isActive ? "active" : "initial"}
    >
      <Icon type="caret_down" height={height} width={width} {...svgProps} />
    </IconContainer>
  );
}

const variants: Variants = {
  initial: {
    rotate: 0,
  },
  active: {
    rotate: 180,
  },
};

type IconContainerProps = {
  $height: number | string;
  $width: number | string;
};

const IconContainer = styled(motion.div)<IconContainerProps>(
  ({ $height, $width, theme }) => css`
    height: ${getDimensionValue($height)};
    width: ${getDimensionValue($width)};
    color: ${theme.color.fg.subtle};
  `
);

// Helper to force the dimension of the animated wrapper as it otherwise can
// result in unexpected element sizes.
const getDimensionValue = (value: number | string) =>
  typeof value === "number" ? `${value}px` : value;
