import styled, { css } from "styled-components";

export function LabelWithColor({
  children,
  color,
}: {
  children: React.ReactNode;
  color: string;
}) {
  return (
    <LabelContainer>
      <LabelIcon color={color} />
      {children}
    </LabelContainer>
  );
}

const LabelContainer = styled.label(
  ({ theme }) =>
    css`
      display: inline-flex;
      align-items: center;
      padding-inline: 6px;
      height: 20px;
      gap: 6px;
      border: 1px solid ${theme.color.border.default};
      border-radius: ${theme.radius.minimal};
    `
);

const LabelIcon = styled.label<{ color: string }>(
  ({ color, theme }) => css`
    content: " ";
    width: 8px;
    height: 8px;
    border-radius: ${theme.radius.minimal};
    background: ${color};
  `
);
