import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type Query = {
  id: string;
  prompt: string;
  response: null | string;
  isError: boolean;
};

export interface AskAutometricsState {
  ids: string[];
  items: Record<string, Query>;
  isVisible: boolean;
}

const initialState: AskAutometricsState = {
  ids: [],
  items: {},
  isVisible: false,
};

export const askAutometricsSlice = createSlice({
  name: "askAutometrics",
  initialState,
  reducers: {
    add: (
      state,
      action: PayloadAction<Omit<Query, "response" | "isError">>
    ) => {
      state.ids.push(action.payload.id);
      state.items[action.payload.id] = {
        ...action.payload,
        response: null,
        isError: false,
      };
    },
    appendById: (
      state,
      action: PayloadAction<{ id: string; text: string }>
    ) => {
      const selectedItem = state.items[action.payload.id];
      if (!selectedItem) {
        return;
      }

      state.items[action.payload.id]!.response =
        (selectedItem.response || "") + action.payload.text;
    },
    markErrorById: (state, action: PayloadAction<string>) => {
      const selectedItem = state.items[action.payload];
      if (!selectedItem) {
        return;
      }

      state.items[action.payload]!.isError = true;
    },

    setVisbility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const {
  add,
  appendById,
  setVisbility: setAskAutometricsVisibility,
  markErrorById,
} = askAutometricsSlice.actions;
