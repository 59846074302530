import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import styled from "styled-components";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, ANIMATE_INITIAL } from "./constants";

const CLIP_PATH_ID = "animated-slo-icon-clip-path";

export function AnimatedSloIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect
        opacity="0.2"
        x="4"
        y="4"
        width="12"
        height="12"
        fill="currentColor"
      />
      <path
        d="M4.00098 15.999L4.00098 13.001L2.00098 13.001L2.00098 14.001C2.00098 15.1049 2.89535 15.9999 3.99902 16.001C4.00008 17.1046 4.8951 17.999 5.99902 17.999L13.999 17.999C15.1036 17.999 15.999 17.1036 15.999 15.999L4.00098 15.999Z"
        fill="currentColor"
      />
      <path
        d="M18.001 13.001L18.001 14.001C18.001 15.1055 17.1055 16.001 16.001 16.001L16.001 13.001L18.001 13.001Z"
        fill="currentColor"
      />
      <path
        d="M16.001 4.00098L4.00391 4.00098C4.00391 2.89641 4.89934 2.00098 6.00391 2.00098L14.0039 2.00098C15.1085 2.00098 16.0039 2.89641 16.0039 4.00098C17.1071 4.00256 18.001 4.89738 18.001 6.00098L18.001 8.00098L16.001 8.00098L16.001 4.00098Z"
        fill="currentColor"
      />
      <path
        d="M2.00098 8.00098L2.00098 6.00098C2.00098 4.89641 2.89641 4.00098 4.00098 4.00098L4.00098 8.00098L2.00098 8.00098Z"
        fill="currentColor"
      />

      <ClippedPath
        d="M13.1273 6.8018C13.0454 6.39878 12.7251 6.087 12.32 6.01599C11.9149 5.94499 11.5076 6.12924 11.2935 6.48037L8.48878 11.0801L7.87916 10.0804C7.69762 9.78264 7.37408 9.60098 7.02537 9.60098L3.00098 9.60098L3.00098 11.601L6.46388 11.601L7.63499 13.5216C7.81653 13.8193 8.14006 14.001 8.48878 14.001C8.8375 14.001 9.16103 13.8193 9.34258 13.5216L11.6748 9.69675L11.8991 10.8002C11.9938 11.2661 12.4035 11.601 12.879 11.601L18.001 11.601L18.001 9.60098L13.6962 9.60098L13.1273 6.8018Z"
        fill="currentColor"
      />

      <clipPath id={CLIP_PATH_ID}>
        <motion.rect
          onAnimationComplete={onAnimationComplete}
          variants={variants}
          animate={controls}
          x="3"
          y="0"
          width="20"
          height="20"
        />
      </clipPath>
    </svg>
  );
}

const ClippedPath = styled.path`
  clip-path: url(#${CLIP_PATH_ID});
`;

const variants: Variants = {
  [ANIMATE_INITIAL]: {
    width: "0%",
    transition: {
      duration: 0,
    },
  },
  [ANIMATE]: {
    width: "100%",
    transition: {
      duration: 1,
    },
  },
};
