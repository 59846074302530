import { Timeseries } from "@fiberplane/prometheus-query";
import { useMeasure } from "react-use";
import styled, { css } from "styled-components";

import { AlertLabelsContent } from "./AlertLabelsContent";

export function AlertLabels(props: Pick<Timeseries, "labels">) {
  const [ref, { width = 0 }] = useMeasure<HTMLDivElement>();

  return (
    <Labels ref={ref}>
      {width > 0 && <AlertLabelsContent width={width} labels={props.labels} />}
    </Labels>
  );
}

const Labels = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-content: end;
    min-width: 0;
    white-space: nowrap;
    overflow: auto;
    gap: ${theme.spacing.xs};
  `
);
