import { z } from "zod";

// Schemas for data from Prometheus

/**
 * Schema for a general Prometheus series for an autometricized function
 * NOTE - Assumes `module` is present in the series labels
 */
const AmSeriesSchema = z
  .object({
    __name__: z.string(),
    function: z.string(),
    module: z.string(),
    service_name: z.string().optional(),
  })
  .passthrough();

/**
 * Schema for a response from Prometheus containing a list of {@link AmSeriesWithLatencyObjective} series
 */
export const PrometheusAmSeriesResponseSchema = z.object({
  status: z.string(),
  data: z.array(AmSeriesSchema),
});

// Schemas for transformed data

export const FunctionModulePairSchema = z.object({
  name: z.string(),
  module: z.string(),
  service_name: z.string().optional(),
});

export type FunctionModulePair = z.infer<typeof FunctionModulePairSchema>;

export const RangeSchema = z.object({
  start: z.object({
    column: z.number(),
    line: z.number(),
  }),
  end: z.object({
    column: z.number(),
    line: z.number(),
  }),
});

export const SourceDefinitionSchema = z.object({
  file: z.string(),
  range: RangeSchema,
});

export type SourceDefinition = z.infer<typeof SourceDefinitionSchema>;

export const SourceIdSchema = z.object({
  function: z.string(),
  module: z.string(),
});

export const SourceLocationSchema = z.object({
  definition: SourceDefinitionSchema.optional(),
  instrumentation: SourceDefinitionSchema.optional(),
  id: SourceIdSchema,
  language: z.string(),
  path: z.string(),
});

export type SourceLocation = z.infer<typeof SourceLocationSchema>;
export const SourceFunctionSchema = z.array(SourceLocationSchema);
