import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";

import { api, EnvironmentUrl, pingApi } from "../api";

const initialConnectionStatus = {
  ping: QueryStatus.uninitialized,
  query: QueryStatus.uninitialized,
};

export interface Environment {
  id: string;
  name: string;
  url: EnvironmentUrl | null;
  connectionStatus: typeof initialConnectionStatus;
}

export interface EnvironmentState {
  instances: Environment[];
  selectedInstance: string;
}

const initialState: EnvironmentState = getInitialState();

export const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    addInstance: (
      state,
      action: PayloadAction<Omit<Environment, "connectionStatus">>
    ) => {
      // HACK - filter possible duplicates
      state.instances = state.instances.filter(
        (instance) =>
          instance.url !== action.payload.url ||
          instance.name !== action.payload.name
      );
      state.instances.unshift({
        ...action.payload,
        connectionStatus: initialConnectionStatus,
      });
      // HACK - persist to localStorage
      saveInstances(state.instances);
    },
    upsertAndSelectInstanceByUrl: (state, action: PayloadAction<string>) => {
      const matchingInstance = state.instances.find(
        (instance) => instance.url === action.payload
      );
      if (matchingInstance) {
        state.selectedInstance = matchingInstance.id;
      } else {
        const id = Date.now().toString();

        let name = "AM Prometheus";
        const nameNumericIdentifier =
          state.instances.filter(
            (instance) => instance.name === "Am Prometheus"
          ).length + 1;
        if (nameNumericIdentifier > 1) {
          name = name + " " + nameNumericIdentifier;
        }

        state.instances.push({
          id,
          name,
          url: action.payload,
          connectionStatus: initialConnectionStatus,
        });
        state.selectedInstance = id;
      }

      // HACK - persist to localStorage
      saveInstances(state.instances);
      saveSelectedInstance(state.selectedInstance);
    },
    removeInstance: (state, action: PayloadAction<Environment>) => {
      state.instances = state.instances.filter(
        (instance) => instance.id !== action.payload.id
      );
      // HACK - persist to localStorage
      saveInstances(state.instances);

      // HACK - if you deleted the currently active instance, select the next one
      if (state.selectedInstance === action.payload.id) {
        state.selectedInstance = state.instances[0]?.id ?? "";
        // HACK - persist to localStorage
        saveSelectedInstance(state.selectedInstance);
      }
    },
    switchInstance: (state, action: PayloadAction<string>) => {
      state.selectedInstance = action.payload;
      // HACK - persist to localStorage
      saveSelectedInstance(state.selectedInstance);
    },
    updateInstance: (state, action: PayloadAction<Partial<Environment>>) => {
      for (const instance of state.instances) {
        if (instance.id === action.payload.id) {
          instance.name = action.payload.name ?? instance.name;
          instance.url = action.payload.url ?? instance.url;
        }
      }

      saveInstances(state.instances);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      pingApi.endpoints.pingAnyPrometheus.matchFulfilled,
      (state, action) => {
        const instance = getEnvironmentByUrl(
          state,
          action.meta.arg.originalArgs
        );

        if (instance) {
          instance.connectionStatus.ping = QueryStatus.fulfilled;
        }
      }
    );

    builder.addMatcher(
      pingApi.endpoints.pingAnyPrometheus.matchPending,
      (state, action) => {
        const instance = getEnvironmentByUrl(
          state,
          action.meta.arg.originalArgs
        );

        if (instance?.connectionStatus?.ping === QueryStatus.uninitialized) {
          instance.connectionStatus.ping = QueryStatus.pending;
        }
      }
    );

    builder.addMatcher(
      pingApi.endpoints.pingAnyPrometheus.matchRejected,
      (state, action) => {
        const instance = getEnvironmentByUrl(
          state,
          action.meta.arg.originalArgs
        );

        if (instance) {
          instance.connectionStatus.ping = QueryStatus.rejected;
        }
      }
    );

    builder.addMatcher(
      api.endpoints.prometheusQuery.matchPending,
      (state, action) => {
        const instance = getEnvironmentByUrl(
          state,
          action.meta.arg.originalArgs.environmentUrl
        );

        if (instance) {
          instance.connectionStatus.query = QueryStatus.pending;
        }
      }
    );

    builder.addMatcher(
      api.endpoints.prometheusQuery.matchRejected,
      (state, action) => {
        const instance = getEnvironmentByUrl(
          state,
          action.meta.arg.originalArgs.environmentUrl
        );

        if (instance) {
          instance.connectionStatus.query = QueryStatus.rejected;
        }
      }
    );

    builder.addMatcher(
      api.endpoints.prometheusQuery.matchFulfilled,
      (state, action) => {
        const instance = getEnvironmentByUrl(
          state,
          action.meta.arg.originalArgs.environmentUrl
        );

        if (instance) {
          instance.connectionStatus.query = QueryStatus.fulfilled;
        }
      }
    );
  },
});

function getEnvironmentByUrl(state: EnvironmentState, url: EnvironmentUrl) {
  return state.instances.find((instance) => instance.url === url);
}

export const {
  addInstance,
  upsertAndSelectInstanceByUrl,
  removeInstance,
  switchInstance,
  updateInstance,
} = environmentSlice.actions;

// NOTE - Reads from localStorage and DOM, so this is pretty impure
// Here be dragons
// * If there is a "selectedInstance" in the localStorage, use that, but only if there's a matching instance in the instances array
// * Else if there is a prometheusUrl defined in the DOM, use that. If there's no matching instance in the instances array, add it.
// * Else if there are instances in localStorage, use the first one
// * Else, the user will end up seeing a splash page that asks them to give a prometheus URL
function getInitialState() {
  const lastSelectedInstance = loadSelectedInstance();

  const state = {
    instances: loadInstances(),
    selectedInstance: loadSelectedInstance(),
  };

  const domPrometheusUrl = getPrometheusUrlFromDom();

  if (domPrometheusUrl) {
    const matchingInstance = state.instances.find(
      (instance) => instance.url === domPrometheusUrl
    );

    if (matchingInstance) {
      state.selectedInstance = matchingInstance.id;
    } else {
      const id = Date.now().toString();
      state.instances.push({
        id,
        name: "Local",
        url: domPrometheusUrl,
        connectionStatus: initialConnectionStatus,
      });
      state.selectedInstance = id;
    }
  } else if (state.instances.length > 0) {
    state.selectedInstance = state.instances[0]?.id ?? "";
  }

  if (
    lastSelectedInstance &&
    state.instances.some((i) => i.id === lastSelectedInstance)
  ) {
    state.selectedInstance = lastSelectedInstance;
  }

  return state;
}

export function getPrometheusUrlFromDom() {
  const url = document
    .querySelector("textarea#am-prometheus-url")
    //@ts-ignore - We know this should be a textarea containing a string
    ?.value?.trim();

  // TODO - Validate URL
  if (typeof url !== "string") {
    return "";
  }

  return url ?? "";
}

function loadInstances(): Environment[] {
  const localStorageInstancesRaw = localStorage.getItem("dora.instances");
  if (localStorageInstancesRaw) {
    try {
      const instances = JSON.parse(localStorageInstancesRaw) as Environment[];
      return instances.map(({ id, name, url }) => ({
        id,
        url,
        name,
        connectionStatus: initialConnectionStatus,
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error loading instances from localStorage:", error);
    }
  }

  return [];
}

function loadSelectedInstance(): string {
  const selectedInstance = localStorage.getItem("dora.selectedInstance");
  if (typeof selectedInstance === "string") {
    return selectedInstance;
  }

  return "";
}

function saveInstances(instances: Environment[]) {
  localStorage.setItem(
    "dora.instances",
    JSON.stringify(
      instances.map(({ id, name, url }) => ({
        id,
        url,
        name,
      }))
    )
  );
}

function saveSelectedInstance(instanceId: string) {
  localStorage.setItem("dora.selectedInstance", instanceId);
}
