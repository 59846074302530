import { add, formatISO, set } from "date-fns";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { useGetCurrentValueForObjectiveQuery } from "../../../api";
import { useThrottledLoadingState } from "../../../hooks";
import { Objective } from "../../../schemas";
import { selectActivePrometheus } from "../../../selectors";
import { FadeIn, PopIn } from "../../Animations";
import { Icon } from "../../UI";
import { SloTarget } from "../SloTarget";
import { SloValue } from "../SloValue";
import {
  CardContainer,
  CardInfo,
  CardTarget,
  CardTitle,
  CardValue,
} from "./styled";
import { Skeleton } from "../../Skeleton";

type ThirtyDayValueCardProps = {
  objective: Objective;
};

export function ThirtyDayValueCard({ objective }: ThirtyDayValueCardProps) {
  const instance = useSelector(selectActivePrometheus);

  const today = set(new Date(), { hours: 23, minutes: 59, seconds: 59 });
  const start = formatISO(add(today, { days: -30 }));
  const end = formatISO(today);

  const { data, error, isFetching, isUninitialized } =
    useGetCurrentValueForObjectiveQuery(
      {
        objective,
        environmentUrl: instance?.url,
        start,
        end,
      },
      {
        skip: !instance,
      }
    );

  // Use a throttled loading state to avoid too much of a flash-in when data loads quickly
  const throttledIsFetching = useThrottledLoadingState(isFetching, 250);

  const metricLabel =
    objective?.metric === "latency"
      ? "Latency"
      : objective?.metric === "successRate"
      ? "Success rate"
      : "Objective";

  return (
    <CardContainer>
      <CardTitle>
        {metricLabel} (30 Days){""}
        <CardTitleLabel>
          <CardTitleIcon type="lock_key" />
          Last 30 days
        </CardTitleLabel>
      </CardTitle>
      <CardInfo>
        <CardValue>
          {isUninitialized ? null : throttledIsFetching ? (
            <FadeIn>
              {/*
               * HACK: Use a realistic value here to avoid a jump when the real
               *       value loads
               *       The component will make text transparent, and indicate to
               *       screen readers that it's a loading placeholder.
               */}
              <StyledShimmer>
                <SloTarget objective={objective} />
              </StyledShimmer>
            </FadeIn>
          ) : error ? (
            <PopIn>
              <ErrorSpan>Error</ErrorSpan>
            </PopIn>
          ) : data ? (
            <PopIn>
              <SloValue
                currentValue={data.currentValue}
                objective={objective}
                highlightSuccess
              />
            </PopIn>
          ) : null}
        </CardValue>
        <CardTarget>
          Target: <SloTarget objective={objective} />
        </CardTarget>
      </CardInfo>
    </CardContainer>
  );
}

const CardTitleLabel = styled.span(
  ({ theme }) =>
    css`
      margin-left: auto;
      display: inline-flex;
      padding: 2px 8px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: ${theme.radius.minimal};
      background: linear-gradient(
        90deg,
        ${theme.color.bg.accent[3]} 0%,
        ${theme.color.bg.accent[2]} 52.08%,
        ${theme.color.bg.accent[1]} 100%
      );
      color: ${theme.color.fg.onemphasis.default};
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    `
);

const CardTitleIcon = styled(Icon)`
  width: 12px;
  height: 12px;
`;

const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.color.fg.danger};
`;

const StyledShimmer = styled(Skeleton)`
  color: transparent;
`;
