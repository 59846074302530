import { Timeseries } from "@fiberplane/prometheus-query";
import { ReactNode } from "react";
import styled, { css } from "styled-components";

import { AlertLabels } from "./AlertLabels";

type Props = {
  children: ReactNode;
} & Pick<Timeseries, "labels">;

export function AlertFrame(props: Props) {
  return (
    <Container>
      <Content>
        <Title>History</Title>
        <AlertLabels labels={props.labels} />
      </Content>
      <Output>{props.children}</Output>
    </Container>
  );
}

const Container = styled.div(
  ({ theme }) => css`
    display: grid;
    padding: ${theme.spacing.md};
    min-width: 0;
    border: 1px solid ${theme.color.border.muted};
    background: ${theme.color.bg.default};
    border-radius: ${theme.radius.rounded};
    white-space: normal;
  `
);

const Content = styled.div(
  ({ theme }) => css`
    height: auto;
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    gap: ${theme.spacing.md};
  `
);

const Output = styled.div`
  min-width: 0;
`;

const Title = styled.h5(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
  `
);
