import { Command } from "cmdk";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

import {
  HIDE_COMMAND_MENU,
  setAskAutometricsVisibility,
  SHOW_COMMAND_MENU,
} from "../../actions";
import { useHandler } from "../../hooks";
import { selectCommandMenuIsOpen } from "../../selectors";
import { RootState } from "../../state";
import { dispatch, useAppDispatch } from "../../store";
import { cancelEvent, isMac } from "../../utils";
import { Icon, IconButton } from "../UI";
import { CommandMenuPage, PageType } from "./CommandMenuPage";
import { StyledInputMixin } from "./styled";

export const CommandMenu = () => {
  const dispatch = useAppDispatch();
  const visible = useSelector(selectCommandMenuIsOpen);
  const isAskAutometricsVisible = useSelector(
    (state: RootState) => state.askAutometrics.isVisible
  );

  // Shortcut handler
  const onKeyDown = useHandler((event: KeyboardEvent) => {
    switch (event.key) {
      case "k":
        if (isMac ? event.metaKey : event.ctrlKey) {
          cancelEvent(event);

          if (visible) {
            hide();
          } else {
            show();
          }
        }

        break;

      case "Escape":
        if (visible) {
          cancelEvent(event);
          hide();
        }

        break;
    }
  });

  useEffect(() => {
    document.body.addEventListener("keydown", onKeyDown);

    return () => document.body.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown]);

  const [query, setQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const menuRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<PageType | null>(null);

  const shouldShowCommandElements =
    page !== "askDora" || !isAskAutometricsVisible;

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus();
      dispatch(setAskAutometricsVisibility(false));
    }
  }, [visible, page, dispatch]);

  return (
    <Wrapper
      isVisible={visible}
      onClick={(event) => {
        if (menuRef.current?.contains(event.target as Node)) {
          return cancelEvent(event);
        }

        hide();
      }}
    >
      <StyledCommand label="Command Menu" ref={menuRef}>
        <StyledHeader
          onClick={(event) => {
            cancelEvent(event);
            inputRef.current?.focus();
          }}
        >
          {page !== null && (
            <PageNav onClick={() => setPage(null)}>
              <Icon type="arrow_square_left_duotone" />
              {page === "timeRange"
                ? "Time range"
                : page === "askDora"
                ? "Ask Autometrics"
                : ""}
            </PageNav>
          )}
          {shouldShowCommandElements && (
            <StyledCommandInput
              autoFocus
              disabled={!visible}
              placeholder={
                page === "askDora"
                  ? "Ask a question about Autometrics..."
                  : "Type a command or search"
              }
              ref={inputRef}
              value={query}
              onValueChange={setQuery}
              onKeyDown={(event) => {
                if (page !== null && !query && event.key === "Backspace") {
                  setPage(null);
                }
              }}
            />
          )}
          <CloseIconButton
            buttonStyle="tertiary-color"
            iconType="close"
            onClick={hide}
          />
        </StyledHeader>
        <Command.List data-no-tooltip>
          {shouldShowCommandElements && (
            <StyledCommandEmpty>No results found.</StyledCommandEmpty>
          )}

          <CommandMenuPage
            hideCommandMenu={hide}
            pageType={page}
            query={query}
            setPage={setPage}
            setQuery={setQuery}
          />
        </Command.List>
        <Footer>
          <FooterSection>
            <FooterKey>esc</FooterKey> to close
            {page !== null && !query && (
              <>
                <FooterKey>backspace</FooterKey> to return
              </>
            )}
          </FooterSection>
          <FooterSection>
            <FooterKey>{isMac ? "⌘" : "ctrl"}</FooterKey>
            <FooterKey>K</FooterKey> to open command bar
          </FooterSection>
        </Footer>
      </StyledCommand>
    </Wrapper>
  );
};

const show = () => dispatch({ type: SHOW_COMMAND_MENU });
const hide = () => dispatch({ type: HIDE_COMMAND_MENU });

const StyledCommand = styled(Command)(
  ({ theme }) =>
    css`
      width: 100%;
      max-width: 728px;
      border-radius: ${theme.radius.default};
      border: 1px solid ${theme.color.border.muted};
      background: ${theme.color.bg.default};
      box-shadow: ${theme.effect.shadow.m};
      display: grid;
      gap: 8px;

      [cmdk-list] {
        height: var(--cmdk-list-height);
        min-height: 256px;
        max-height: 512px;
        transition: height 100ms ease;

        overflow-y: auto;
        scroll-padding-block-start: 16px;
        scroll-padding-block-end: 16px;
        padding: 0 0 16px 0;
      }
    `
);

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20vh 32px 32px;
  transition: opacity 0.1s ease-in 0.05s, background 0.1s ease-in;

  & > ${StyledCommand} {
    transition: transform 0.1s ease-in 0.05s;
    perspective: 800px;
    transform-style: preserve-3d;
  }

  ${({ isVisible }) =>
    isVisible
      ? css`
          background: rgba(0, 0, 0, 0.25);
          opacity: 1;
          pointer-events: auto;

          & > ${StyledCommand} {
            transform: rotateX(0) translateZ(0);
          }
        `
      : css`
          background: transparent;
          opacity: 0;
          pointer-events: none;

          & > ${StyledCommand} {
            transform: rotateX(20deg) translateZ(-100px);
          }
        `}
`;

const StyledHeader = styled.header(
  ({ theme }) =>
    css`
      display: grid;
      gap: 12px;
      grid-template-columns: 1fr auto;
      grid-auto-rows: auto;
      align-items: center;
      padding: 16px 16px 16px 24px;
      border-bottom: 1px solid ${theme.color.border.muted};
    `
);

const PageNav = styled.nav(
  ({ theme }) =>
    css`
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: flex;
      align-items: center;
      gap: 12px;
      color: red;
      cursor: pointer;
      color: ${theme.color.fg.default};
      font: ${theme.font.body.md.medium};
    `
);

const StyledCommandInput = styled(Command.Input)(StyledInputMixin);

const StyledCommandEmpty = styled(Command.Empty)`
  padding: 0 24px;
`;

const Footer = styled.footer(
  ({ theme }) =>
    css`
      border-top: 1px solid ${theme.color.border.muted};
      background: ${theme.color.bg.subtle};
      color: ${theme.color.fg.default};
      padding: 6px 24px;
      font: ${theme.font.body.sm.regular};
      display: flex;
      justify-content: space-between;
    `
);

const FooterSection = styled.section`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const FooterKey = styled.span(
  ({ theme }) =>
    css`
      border-radius: ${theme.radius.minimal};
      border: 1px solid ${theme.color.button.secondary.border};
      background: ${theme.color.bg.default};
      padding: 0 4px;
      display: inline-flex;
    `
);

const CloseIconButton = styled(IconButton)(
  ({ theme }) =>
    css`
      grid-column: 2 / 3;
      grid-row: 1 / 2;

      color: ${theme.color.fg.muted};

      &:hover {
        color: ${theme.color.fg.default};
      }
    `
);
