import { useParams, useSearchParams } from "react-router-dom";

import { MetricType } from "../../../../api";

export const GRAPH = "graph" as const;
export const CALL_GRAPH = "call_graph" as const;

export type FunctionPageContentType = typeof GRAPH | typeof CALL_GRAPH;

export interface Params {
  metricType: MetricType;
  module?: string;
}

export function asContentType(
  value: string | null,
  metricType: MetricType
): FunctionPageContentType {
  const supportedTypes = getSupportedContentTypes(metricType);
  if (value && supportedTypes.includes(value as FunctionPageContentType)) {
    return value as FunctionPageContentType;
  }

  return GRAPH;
}

export function getSupportedContentTypes(
  metricType: MetricType
): FunctionPageContentType[] {
  if (metricType === "latency") {
    return [GRAPH];
  }

  return [GRAPH, CALL_GRAPH];
}

export function useFunctionPageParams() {
  const [currentSearchParams] = useSearchParams();
  const { metricType = "requestRate", module: moduleParameter } = useParams<
    keyof Params
  >() as Params;
  // This determines whether to show the graph or the query
  const contentType: FunctionPageContentType = asContentType(
    currentSearchParams.get("contentType"),
    metricType
  );

  return {
    searchParams: currentSearchParams,
    metricType,
    moduleParameter,
    contentType,
  };
}
