import {
  FunctionModulePair,
  LatencyObjective,
  SuccessRateObjective,
} from "../schemas";

/**
 * Helper to return the build_info join
 * Accepts an indentation parameter to allow for pretty pretting the query in the UI.
 *
 * @note - An build info interval that is significantly smaller than the scrape interval
 *         will result in a "dot-dash" pattern in the UI. (LINK TO EXAMPLE)
 */
const getBuildInfoQuery = ({
  indent = 0,
  interval = "1s",
}: {
  indent: number;
  interval?: string;
}) =>
  `
* on (instance, job) group_left(version, commit) (
  last_over_time(build_info[${interval}])
  or on (instance, job) up
)`
    .trim()
    .split("\n")
    .join("\n" + " ".repeat(indent));

// HACK - this is a temporary workaround to get a unique list of all function/module pairs
export function filterUniqueFunctionModulePairs(
  functionModulePairs: FunctionModulePair[]
): FunctionModulePair[] {
  const uniqueEntries: FunctionModulePair[] = [];

  for (const functionModule of functionModulePairs) {
    const isDuplicate = uniqueEntries.some((otherFunctionModule) => {
      return (
        otherFunctionModule.name === functionModule.name &&
        otherFunctionModule.module === functionModule.module
      );
    });

    if (!isDuplicate) {
      uniqueEntries.push(functionModule);
    }
  }

  return uniqueEntries;
}

export function createSLOQueryLatencyUnderThreshold(
  objective: LatencyObjective,
  start: string,
  end: string,
  function_?: { name: string; module: string }
): string {
  const objectiveName = objective.name;
  // FIXME
  const latencyThreshold = objective.target.threshold;
  const rateInterval = getPrometheusWindowFromTimeRange(start, end);
  let query = `
(
  sum(
    rate(
      {
        __name__=~"function_calls_duration(_seconds)?_bucket",
        objective_name="${objectiveName}",
        le="${latencyThreshold}"
      }[${rateInterval}]
    )
  ) or on() vector(0)
) / (
    sum(
      rate(
        {
          __name__=~"function_calls_duration(_seconds)?_count",
          objective_name="${objectiveName}"
        }[${rateInterval}]
      )
    ) > 0
  )
`.trim();

  // INVESTIGATE - should this query not include the objective_name?
  // That way we can look at data before the objective was defined...
  if (function_) {
    query = query.replace(
      '__name__=~"function_calls_duration(_seconds)?_bucket",',
      `__name__=~"function_calls_duration(_seconds)?_bucket",\nfunction="${function_.name}",\nmodule="${function_.module}",\n`.trim()
    );

    query = query.replace(
      `__name__=~"function_calls_duration(_seconds)?_count",`,
      `__name__=~"function_calls_duration(_seconds)?_count",\nfunction="${function_.name}",\nmodule="${function_.module}",\n`.trim()
    );
  }

  return query;
}

/**
 * Intended for use with the SLO Details page
 * This query will return a list of series, one for each function/module pair
 * and each series will contain the calculated % of request under the objective latency threshold that function/module pair.
 * If there were no requests for a given function/module pair, the series will not contain data for that pair.
 */
export function createSLOQueryLatencyUnderThresholdByFunction(
  objective: LatencyObjective,
  start: string,
  end: string
): string {
  const objectiveName = objective.name;
  // FIXME
  const latencyThreshold = objective.target.threshold;
  const rateInterval = getPrometheusWindowFromTimeRange(start, end);
  return `
(
  sum by (function, module) (
    rate(
      {
        __name__=~"function_calls_duration(_seconds)?_bucket",
        objective_name="${objectiveName}",
        le="${latencyThreshold}"
      }[${rateInterval}]
    )
  ) or on() vector(0)
) / (
    sum by (function, module) (
      rate(
        {
          __name__=~"function_calls_duration(_seconds)?_count",
          objective_name="${objectiveName}"
        }[${rateInterval}]
      )
    ) > 0
  )
`.trim();
}

export function createSLOQuerySuccessRate(
  objective: { name: string },
  start: string,
  end: string,
  function_?: { name: string; module: string }
) {
  const objectiveName = objective.name;
  const rateInterval = getPrometheusWindowFromTimeRange(start, end);

  if (function_) {
    return `
1 - (
  sum(
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?", 
        objective_name="${objectiveName}",
        result="error",
        function="${function_.name}",
        module="${function_.module}"
      }[${rateInterval}])
    ) or on() vector(0)
  ) / (
    sum(
      rate(
        {
          __name__=~"function_calls(_count)?(_total)?", 
          objective_name="${objectiveName}",
          function="${function_.name}",
          module="${function_.module}"
        }[${rateInterval}]
      )
    ) > 0
  )
    `.trim();
  }

  return `
1 - (
  sum(
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?", 
        objective_name="${objectiveName}", 
        result="error"
      }[${rateInterval}])
    ) or on() vector(0)
  ) / (
    sum(
      rate(
        {
          __name__=~"function_calls(_count)?(_total)?", 
          objective_name="${objectiveName}"
        }[${rateInterval}]
      )
    ) > 0
  )
    `.trim();
}

/**
 * Get the query for the latency chart (99th and 95th percentile)
 * for a single function
 */
export function createSloAllFunctionsLatencyQuery(
  objective: LatencyObjective,
  start: string,
  end: string,
  buildInfoInterval?: string
) {
  const { name: objectiveName } = objective;
  const {
    target: { percentile: latencyPercentile, threshold: latencyThreshold },
  } = objective;
  const interval = getPrometheusWindowFromTimeRange(start, end);
  const promQlPercentile =
    translateObjectivePercentileToPromQlPercentile(latencyPercentile);

  return `
label_replace(
  histogram_quantile(
    ${promQlPercentile},
    sum by (le, function, module, commit, version, service_name) (
      rate({
        __name__=~"function_calls_duration(_seconds)?_bucket",
        objective_name="${objectiveName}",
        objective_percentile="${latencyPercentile}",
        objective_latency_threshold="${latencyThreshold}"
      }[${interval}])
      # Attach the version and commit labels from the build_info metric
      ${getBuildInfoQuery({ indent: 6, interval: buildInfoInterval })}
    )
  ),
  # Add the label {percentile_latency="${latencyPercentile}"} to the time series
  "percentile_latency", "${latencyPercentile}", "", ""
)
`.trim();
}

export function createSloAllFunctionsSuccessRateQuery(
  objective: SuccessRateObjective,
  start: string,
  end: string,
  buildInfoInterval?: string
) {
  const { name: objectiveName } = objective;
  const {
    target: { percentile },
  } = objective;

  const interval = getPrometheusWindowFromTimeRange(start, end);

  return `
(
  sum by(function, module, version, commit, service_name) (
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?",
        result="ok", 
        objective_name="${objectiveName}",
        objective_percentile="${percentile}"
      }[${interval}]
    )
    ${getBuildInfoQuery({ indent: 4, interval: buildInfoInterval })}
  )) / (
  sum by(function, module, version, commit, service_name) (
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?",
        objective_name="${objectiveName}",
        objective_percentile="${percentile}"

      }[${interval}]
    )
    ${getBuildInfoQuery({ indent: 4, interval: buildInfoInterval })}
  ) > 0
)`.trim();
}

type SingleFunctionQuery = {
  functionName: string;
  moduleName?: string;
  interval?: string;
  buildInfoInterval?: string;
};

/**
 * Get the query for the latency chart (99th and 95th percentile)
 * for a single function
 */
export function createFunctionLatencyQuery({
  functionName,
  moduleName,
  interval = "5m",
  buildInfoInterval,
}: SingleFunctionQuery) {
  return `
label_replace(
  histogram_quantile(
    0.99,
    sum by (le, function, module, commit, version, service_name) (
      rate({
        __name__=~"function_calls_duration(_seconds)?_bucket",
        function=~"${functionName}"${addModuleLabelIfDefined(moduleName, 8)}
      }[${interval}])
      # Attach the version and commit labels from the build_info metric
      ${getBuildInfoQuery({ indent: 6, interval: buildInfoInterval })}
    )
  ),
  # Add the label {percentile_latency="99"} to the time series
  "percentile_latency", "99", "", ""
)

or

label_replace(
  histogram_quantile(
    0.95,
    sum by (le, function, module, commit, version, service_name) (
      rate({
        __name__=~"function_calls_duration(_seconds)?_bucket",
        function=~"${functionName}"${addModuleLabelIfDefined(moduleName, 8)}
      }[${interval}])
      # Attach the version and commit labels from the build_info metric
      ${getBuildInfoQuery({ indent: 6, interval: buildInfoInterval })}
    )
  ),
  # Add the label {percentile_latency="95"} to the time series
  "percentile_latency", "95", "", ""
)`.trim();
}

export function createTop5FunctionLatencyQuery({
  interval = "5m",
  percentile = 99, // You can specify the desired percentile here
  buildInfoInterval,
}: Top5FunctionQuery & { percentile?: number }) {
  return `
topk(5, label_replace(
  histogram_quantile(
    0.${percentile},
    sum by (le, function, module, commit, version, service_name) (
      rate({
        __name__=~"function_calls_duration(_seconds)?_bucket",
        function=~".*"
      }[${interval}])
      # Attach the version and commit labels from the build_info metric
      ${getBuildInfoQuery({ indent: 6, interval: buildInfoInterval })}
    )
  ),
  # Add the label {percentile_latency="${percentile}"} to the time series
  "percentile_latency", "${percentile}", "", ""
))
`.trim();
}

export function createFunctionErrorRateQuery({
  functionName,
  moduleName,
  interval = "5m",
  buildInfoInterval,
}: SingleFunctionQuery) {
  return `
(
  sum by(function, module, version, commit, service_name) (
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?",
        result="error", 
        function=~"${functionName}"${addModuleLabelIfDefined(moduleName, 8)}
      }[${interval}]
    )
    ${getBuildInfoQuery({ indent: 4, interval: buildInfoInterval })}
  )) / (
  sum by(function, module, version, commit, service_name) (
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?",
        function=~"${functionName}"${addModuleLabelIfDefined(moduleName, 8)}
      }[${interval}]
    )
    ${getBuildInfoQuery({ indent: 4, interval: buildInfoInterval })}
  ) > 0
)`.trim();
}

export function createTop5FunctionErrorRateQuery({
  interval = "5m",
  buildInfoInterval,
}: Top5FunctionQuery) {
  return `
(
  topk(5, sum by(function, module, version, commit, service_name) (
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?",
        result="error", 
        function=~".*"
      }[${interval}]
    )
    ${getBuildInfoQuery({ indent: 4, interval: buildInfoInterval })}
  ))) / (
  sum by(function, module, version, commit, service_name) (
    rate(
      {
        __name__=~"function_calls(_count)?(_total)?",
        function=~".*"
      }[${interval}]
    )
    ${getBuildInfoQuery({ indent: 4, interval: buildInfoInterval })}
  ) > 0
)
`.trim();
}

export function createFunctionRequestRateQuery({
  functionName,
  moduleName,
  interval = "5m",
  buildInfoInterval,
}: SingleFunctionQuery) {
  return `
sum by (function, module, version, commit, service_name) (
  rate(
    {
      __name__=~"function_calls(_count)?(_total)?",
      function=~"${functionName}"${addModuleLabelIfDefined(moduleName, 6)}
    }[${interval}]
  )
  ${getBuildInfoQuery({ indent: 2, interval: buildInfoInterval })}
)`.trim();
}

type Top5FunctionQuery = {
  interval?: string;
  buildInfoInterval?: string;
};

export function createTop5FunctionRequestRateQuery({
  interval = "5m",
  buildInfoInterval,
}: Top5FunctionQuery) {
  return `
topk(5, sum by (function, module, version, commit, service_name) (
  rate(
    {
      __name__=~"function_calls(_count)?(_total)?",
      function=~".*"
    }[${interval}]
  )
  ${getBuildInfoQuery({ indent: 2, interval: buildInfoInterval })}
))
`.trim();
}

/**
 * To find out how many function calls happened in a given time window using our counter metric,
 * you can use the sum() function along with the increase() function.
 * The increase() function calculates the rate of increase of a counter metric over the specified time range,
 * and sum() then adds up the calculated rates, giving you the total count of invocations.
 */
export function createRequestCountQuery({
  functionName,
  moduleName,
  interval,
}: SingleFunctionQuery) {
  return `
sum by (function, module) (
  increase({
    __name__=~"function_calls(_count)?(_total)?", 
    function="${functionName}"${addModuleLabelIfDefined(moduleName, 4)}
  }[${interval}])
)`.trim();
}

/**
 * For an explanation of this query, see the comment above createRequestCountQuery
 */
export function createErrorCountQuery({
  functionName,
  moduleName,
  interval,
}: SingleFunctionQuery) {
  return `
sum by (function, module) (
  increase({
    __name__=~"function_calls(_count)?(_total)?", 
    result="error",
    function="${functionName}"${addModuleLabelIfDefined(moduleName, 4)}
  }[${interval}])
)`.trim();
}

export function createVersionQuery({
  functionName,
  moduleName,
  interval = "5m",
}: SingleFunctionQuery) {
  return `group by (version, commit) (
    last_over_time(
      { 
        __name__=~"function_calls(_count)?(_total)?",
        function="${functionName}"${addModuleLabelIfDefined(moduleName, 8)}
      }[${interval}]
    )* on(instance, job) group_left(version, commit) (
      build_info
    )
  )`;
}

export function getPrometheusWindowFromTimeRange(start: string, end: string) {
  const from = +new Date(start) / 1000;
  const to = +new Date(end) / 1000;

  return `${Math.floor(to - from)}s`;
}

export function translateObjectivePercentileToPromQlPercentile(
  percentile: string
): string {
  const promQlPercentile = Number.parseFloat(percentile) / 100;
  return promQlPercentile.toFixed(3).replace(/0$/, "");
}

/**
 * Helper function that will add the module label to the query if it is a string.
 * This means if module is the empty string, it will be added as `module=""` to the query's labels.
 */
function addModuleLabelIfDefined(moduleName?: string, padLeft = 0) {
  const padding = " ".repeat(padLeft);
  return typeof moduleName === "string"
    ? `,\n${padding}module="${moduleName}"`
    : "";
}
