import type { CompletionChoice, CompletionUsage } from "openai/resources";

export interface CreateCompletionResponse {
  id: string;
  object: string;
  created: number;
  model: string;
  choices: CompletionChoice[];
  usage: CompletionUsage;
}
