import styled, { css } from "styled-components";

import {
  DatePickerTimeRange,
  computeTimeRangeFromPresetOption,
  timeRangeOptions,
} from "../../utils";

type Props = {
  onChange: (timeRange: DatePickerTimeRange) => void;
};

export function TimeRangePresets(props: Props) {
  const { onChange } = props;

  return (
    <OptionsList>
      {timeRangeOptions.map((option) => (
        <TimeOption
          key={option.label}
          onClick={() => {
            onChange(computeTimeRangeFromPresetOption(option));
          }}
        >
          {option.label}
        </TimeOption>
      ))}
    </OptionsList>
  );
}

const OptionsList = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  overflow: auto;
  max-height: 416px;
`;

const TimeOption = styled.button(
  ({ theme }) => css`
    display: block;
    text-decoration: none;
    background: none;
    outline: none;
    margin: 0;
    border: 0;

    padding: 4px 8px;
    border-radius: ${theme.radius.minimal};
    font: ${theme.font.body.sm.regular};
    text-align: left;
    color: ${theme.color.fg.default};
    cursor: pointer;

    &:hover {
      background-color: ${theme.color.bg.elevated.hover};
    }
  `
);
