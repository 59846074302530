import { useTheme } from "styled-components";

import { LabelWithColor } from "../../../UI";

export function AlertLabel({ state }: { state?: string }) {
  const theme = useTheme();

  if (state === "inactive") {
    return (
      <LabelWithColor color={theme.color.neutral[400]}>Inactive</LabelWithColor>
    );
  }

  if (state === "firing") {
    return (
      <LabelWithColor color={theme.color.fg.danger}>Firing</LabelWithColor>
    );
  }

  return null;
}
