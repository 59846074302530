import { useFeature } from "./features";
import { useAmFound } from "./useAmFound";
import { useLocalStorage } from "./useLocalStorage";

type SourceCodeLinkSettings = "off" | "vscode" | "text";

export function useSourceCodeLinkSettings(): {
  value: SourceCodeLinkSettings;
  set: (value: SourceCodeLinkSettings) => void;
  supported: boolean;
} {
  const amDetected = useAmFound();
  const [sourceCodeFeatureEnabled] = useFeature("source-code-links");

  const [value, setValue] = useLocalStorage<SourceCodeLinkSettings>(
    "source-code-link-settings",
    "off"
  );

  // If the feature is not enabled/available, we always return "off"
  const returnValue = amDetected && sourceCodeFeatureEnabled ? value : "off";
  return {
    value: returnValue,
    set: setValue,
    supported: amDetected && sourceCodeFeatureEnabled,
  };
}
