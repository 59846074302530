import { useSourceCodeLinkSettings } from "../../../../hooks";
import { SourceLocation } from "../../../../schemas";
import { SourceCodeLink } from "./SourceCodeLink";

export function SourceCodeLinks(props: { data: Array<SourceLocation> }) {
  const { value: linkSettings } = useSourceCodeLinkSettings();
  return (
    <>
      {props.data.map((sourceLocation: SourceLocation, index) => (
        <SourceCodeLink
          key={index}
          sourceLocation={sourceLocation}
          type={linkSettings === "vscode" ? "vscode" : "text"}
        />
      ))}
    </>
  );
}
