import { z } from "zod";

import { FunctionModulePairSchema } from "./functions";

export type FunctionModulePairWithCurrentValue = z.infer<
  typeof FunctionModulePairSchema
> & {
  currentValue: string | null;
};

// Schemas for data from Prometheus

const SuccessRateSeriesName = z.union([
  z.literal("function_calls"),
  z.literal("function_calls_count_total"),
  z.literal("function_calls_count"),
  z.literal("function_calls_total"),
]);

const LatencySeriesName = z.union([
  z.literal("function_calls_duration_count"),
  z.literal("function_calls_duration_seconds_count"),
]);

/**
 * Schema for an autometrics Prometheus function call counter series
 * with a success rate objective attached via labels
 */
export const AmSeriesWithSuccessObjectiveSchema = z
  .object({
    __name__: SuccessRateSeriesName,
    function: z.string(),
    module: z.string(),
    objective_name: z.string(),
    objective_percentile: z.string(),
  })
  .passthrough();

/**
 * An autometrics Prometheus function call counter series with a success rate objective attached via labels
 */
export type AmSeriesWithSuccessObjective = z.infer<
  typeof AmSeriesWithSuccessObjectiveSchema
>;

/**
 * Schema for a response from Prometheus containing a list of {@link AmSeriesWithSuccessObjective} series
 */
export const PrometheusSuccessObjectiveSeriesResponseSchema = z.object({
  status: z.string(),
  data: z.array(AmSeriesWithSuccessObjectiveSchema),
});

/**
 * Schema for an autometrics Prometheus function call histogram series
 * with a latency objective attached via labels
 */
export const AmSeriesWithLatencyObjectiveSchema = z
  .object({
    __name__: LatencySeriesName,
    function: z.string(),
    module: z.string(),
    objective_name: z.string(),
    objective_latency_threshold: z.string(),
    objective_percentile: z.string(),
  })
  .passthrough();

/**
 * An autometrics Prometheus function call histogram series with a latency objective attached via labels
 */
export type AmSeriesWithLatencyObjective = z.infer<
  typeof AmSeriesWithLatencyObjectiveSchema
>;

/**
 * Schema for a response from Prometheus containing a list of {@link AmSeriesWithLatencyObjective} series
 */
export const PrometheusLatencyObjectiveSeriesResponseSchema = z.object({
  status: z.string(),
  data: z.array(AmSeriesWithLatencyObjectiveSchema),
});

/**
 * Schema for a general Prometheus series for an autometrcized function
 * NOTE - Assumes `module` is present in the series labels
 */
const AmSeriesSchema = z
  .object({
    __name__: z.string(),
    function: z.string(),
    module: z.string(),
  })
  .passthrough();

// Schemas for transformed SLO data

const SuccessRate = z.literal("successRate");

const SuccessTargetSchema = z.object({
  percentile: z.string(),
});

export type SuccessRateTarget = z.infer<typeof SuccessTargetSchema>;

const SuccessRateObjectiveSchema = z.object({
  series: z.array(AmSeriesSchema),
  name: z.string(),
  functions: z.array(FunctionModulePairSchema),
  functionsCount: z.optional(z.number()),
  metric: SuccessRate,
  target: SuccessTargetSchema,
});

/**
 * An SLO based off of success rate
 */
export type SuccessRateObjective = z.infer<typeof SuccessRateObjectiveSchema>;

const Latency = z.literal("latency");

const LatencyTargetSchema = z.object({
  percentile: z.string(),
  threshold: z.string(),
});

export type LatencyTarget = z.infer<typeof LatencyTargetSchema>;

const LatencyObjectiveSchema = z.object({
  series: z.array(AmSeriesSchema),
  name: z.string(),
  functions: z.array(FunctionModulePairSchema),
  functionsCount: z.optional(z.number()),
  metric: Latency,
  target: LatencyTargetSchema,
});

/**
 * An SLO based off of function latency (e.g., 99% of functions must complete in under 100ms)
 */
export type LatencyObjective = z.infer<typeof LatencyObjectiveSchema>;

const ObjectiveSchema = z.discriminatedUnion("metric", [
  SuccessRateObjectiveSchema,
  LatencyObjectiveSchema,
]);

const ObjectiveTargetSchema = z.union([
  SuccessTargetSchema,
  LatencyTargetSchema,
]);

export type ObjectiveTarget = z.infer<typeof ObjectiveTargetSchema>;

/**
 * A union type of {@link SuccessRateObjective} and {@link LatencyObjective}
 */
export type Objective = z.infer<typeof ObjectiveSchema>;

// TODO - add the time range to this schema
const ObjectiveWithCurrentValueSchema = z.intersection(
  ObjectiveSchema,
  z.object({
    currentValue: z.string().nullable(),
  })
);

/**
 * An objective with a "current value" for its metric
 * TODO - add the time range to this type
 */
export type ObjectiveWithCurrentValue = z.infer<
  typeof ObjectiveWithCurrentValueSchema
>;

export type ObjectiveFunctionModulePair = z.infer<
  typeof FunctionModulePairSchema
>;

export type ObjectiveMetric = ObjectiveWithCurrentValue["metric"];

const PrometheusFunctionModuleMetricSchema = z
  .object({
    function: z.string(),
    module: z.string(),
  })
  .passthrough();

const PrometheusObjectiveFunctionModulePairCurrentValueResult = z.object({
  metric: PrometheusFunctionModuleMetricSchema,
  value: z.tuple([z.number(), z.string()]),
});

export const PrometheusObjectiveFunctionModulePairCurrentValueResponseSchema =
  z.object({
    status: z.string(),
    data: z.object({
      result: z.array(PrometheusObjectiveFunctionModulePairCurrentValueResult),
    }),
  });
