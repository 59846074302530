import styled from "styled-components";

import { FeatureCard, type FeatureProps } from "./Feature";

const FEATURES: Array<FeatureProps> = [
  {
    feature: "source-code-links",
    title: "Source code links",
    description:
      "The Source code links feature will enable information about where a function resides in your source code and can even support links to the file/function directly in your (VS Code) editor.",
  },
  {
    feature: "theme-switch",
    title: "Theme switch",
    description: "Adds the theme switcher as a lightswitch to the nav bar.",
  },
];

export function ExperimentalFeatures() {
  return (
    <ExperimentalFeaturesContainer>
      {FEATURES.map(({ feature, description, title }) => (
        <FeatureCard
          key={feature}
          feature={feature}
          description={description}
          title={title}
        />
      ))}
    </ExperimentalFeaturesContainer>
  );
}

const ExperimentalFeaturesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 300px), 1fr));
  justify-content: start;
  gap: 16px;
`;
