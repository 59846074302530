import styled, { css } from "styled-components";

import { useTimeRange } from "../../hooks";
import { useAlertsQuery } from "../Alerts";
import {
  DashboardSection,
  DashboardSectionHeader,
  DashboardSectionHeading,
  TargetInfo,
  TargetNumber,
} from "./styled";
import { Skeleton } from "../Skeleton";

export function AlertsFiring() {
  const { timeRange } = useTimeRange();

  const { result: activeAlertsQuery } = useAlertsQuery(timeRange);

  const alertsFiring =
    activeAlertsQuery.data?.filter((x) => x.labels.state === "firing").length ??
    0;

  return (
    <AlertsFiringSection>
      <DashboardSectionHeader>
        <DashboardSectionHeading>Alerts firing</DashboardSectionHeading>
      </DashboardSectionHeader>
      {activeAlertsQuery.isFetching ? (
        <Skeleton />
      ) : (
        <TargetInfo>
          <TargetNumber $isOnTarget={alertsFiring === 0} to="/alerts">
            {alertsFiring}
          </TargetNumber>
          in selected time range
        </TargetInfo>
      )}
    </AlertsFiringSection>
  );
}

const AlertsFiringSection = styled(DashboardSection)(
  ({ theme }) =>
    css`
      ${theme.media.md`
        padding: 24px;

        ${DashboardSectionHeader} {
          margin-bottom: auto;
        }

        grid-column: 7 / 9;
        grid-row: 2 / 3;
      `}
    `
);
