import { z } from "zod";
import { FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";

export type FetchWithBaseQuery = (
  arg: string | FetchArgs
) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, {}>>;

/**
 *
 * @param queryResponse - response from using fetchWithBq on prometheus /query api
 * @returns
 */
export function getScalarFromPrometheusQueryResponse(
  queryResponse: any
): string | null {
  const result = queryResponse?.data?.data?.result?.[0]?.value?.[1];
  const isString = typeof result === "string";

  if (result && !isString) {
    // eslint-disable-next-line no-console
    console.debug("Got non-string value from prometheus query:", {
      queryResponse,
      result,
    });
  }

  return isString ? result : null;
}

/**
 * Helper for decoding the result of a query made by rtk-query base query function
 * Assumes the decoded response will be an object, and uses zod's `passthrough()` when parsing the result
 *
 * @param queryResult - Result of a prometheus query made by rtk-query base query function
 * @param schema - a Zod schema to decode the result
 * @returns - Either an object with the decoded data under the `data` key, or an object with the error under the `error` key
 */
export function decodeReduxQueryReturnValue<T extends z.ZodRawShape>(
  queryResult: QueryReturnValue<unknown, FetchBaseQueryError, {}>,
  schema: z.ZodObject<T>
): { data: z.infer<z.ZodObject<T>> } | { error: FetchBaseQueryError } {
  if (queryResult.error) {
    return { error: queryResult.error };
  }

  const queryResultParseResult = schema.safeParse(queryResult.data);

  if (queryResultParseResult.success === false) {
    // NOTE - If you are getting a decoding error, this log will tell you why
    // eslint-disable-next-line no-console
    console.error(queryResultParseResult.error);

    return {
      error: {
        status: "CUSTOM_ERROR",
        data: queryResult,
        error: "Decoding error",
        // NOTE - for debugging purposes, we include the error from zod
        //@ts-ignore
        _error: queryResultParseResult.error,
      },
    };
  }

  return { data: queryResultParseResult.data };
}

export async function fetchAndDecode<T extends z.ZodRawShape>(
  fetchWithBq: FetchWithBaseQuery,
  fetchArgs: string | FetchArgs,
  schema: z.ZodObject<T>
) {
  const resultRaw = await fetchWithBq(fetchArgs);

  return decodeReduxQueryReturnValue(resultRaw, schema);
}

/**
 * This is the regex that describes valid duration values inside a Prometheus config.
 *
 * See: https://prometheus.io/docs/prometheus/latest/configuration/configuration/
 *
 * @note - I modified the regex to use a negative-lookahead to prevent matching "ms" in "m" (minutes),
 *         which you can see in `([0-9]+)m(?!s)`
 */
export const PROMETHEUS_DURATION_REGEX =
  // eslint-disable-next-line unicorn/better-regex
  /((([0-9]+)y)?(([0-9]+)w)?(([0-9]+)d)?(([0-9]+)h)?(([0-9]+)m(?!s))?(([0-9]+)s)?(([0-9]+)ms)?|0)/;

/**
 * Given a duration string from a Prometheus YAML config,
 * parse that duration into seconds.
 *
 * Prometheus durations inside a config can be represented by a string matching the regex inside this function
 * (See: https://prometheus.io/docs/prometheus/latest/configuration/configuration/)
 *
 * I modified the regex to use a negative-lookahead to prevent matching "ms" in "m" (minutes)
 *
 * @note - This function does not consider leap years
 */
export function parsePrometheusDurationToSeconds(
  duration: string
): number | null {
  const matches = duration.match(PROMETHEUS_DURATION_REGEX);

  if (!matches) {
    return null;
  }

  const years = matches[3] ? Number.parseInt(matches[3], 10) : 0;
  const weeks = matches[5] ? Number.parseInt(matches[5], 10) : 0;
  const days = matches[7] ? Number.parseInt(matches[7], 10) : 0;
  const hours = matches[9] ? Number.parseInt(matches[9], 10) : 0;
  const minutes = matches[11] ? Number.parseInt(matches[11], 10) : 0;
  const seconds = matches[13] ? Number.parseInt(matches[13], 10) : 0;
  const milliseconds = matches[15] ? Number.parseInt(matches[15], 10) : 0;

  const durationInSeconds =
    years * 31_536_000 + // seconds in a year (considering non-leap years)
    weeks * 604_800 + // seconds in a week
    days * 86_400 + // seconds in a day
    hours * 3600 + // seconds in an hour
    minutes * 60 + // seconds in a minute
    seconds +
    milliseconds / 1000;

  if (Number.isNaN(durationInSeconds)) {
    return null;
  }

  if (durationInSeconds === 0) {
    return null;
  }

  return durationInSeconds;
}

/**
 * Parses Prometheus scrape intervals from a YAML configuration string.
 * @note - This function will return duplicate values if the YAML config contains scrape intervals of the same value
 *
 * @param {string} yaml - The YAML configuration string to parse.
 * @returns {number[]} An array of scrape intervals in seconds.
 */
export function parsePrometheusConfigScrapeIntervalsFromYaml(yaml: string) {
  return yaml.split("\n").reduce((accumulator, line) => {
    const match = line.match(/scrape_interval:\s*(.*)/);

    if (match?.[1]) {
      const duration = parsePrometheusDurationToSeconds(match[1]);

      if (duration) {
        accumulator.push(duration);
      }
    }

    return accumulator;
  }, [] as number[]);
}
