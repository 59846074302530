import { Timeseries } from "@fiberplane/charts";
import styled, { css } from "styled-components";
import { memo } from "react";

import { GraphItem } from "./GraphItem";
import { ELEMENT_HEIGHT, PADDING } from "../constants";
import { TooltipState } from "../../../../state";

type Props = {
  height: number;
  width: number;
  data: Array<{ alert: Timeseries; matchingIndex: number }>;
  ticks: number[];
  stepSize: number;
  getX: (tick: number) => number;
  showTooltip(tooltip: TooltipState | null): void;
};

export const Graph = memo(function Graph(props: Props) {
  const { height, width, ticks, data, stepSize, getX, showTooltip } = props;

  return (
    <Container style={{ width, height }}>
      <Background data-id="background" />
      <div data-id="lines">
        {ticks.map((tick) => {
          return (
            <Line
              key={tick}
              style={{ transform: `translateX(${props.getX(tick)}px)` }}
            />
          );
        })}
      </div>
      <div data-id="graph-elements">
        {data.map(({ alert: timeseries, matchingIndex }, index) => {
          return (
            <div
              key={index}
              style={{
                position: "absolute",
                transform: `translateY(${
                  index * (ELEMENT_HEIGHT + PADDING + PADDING) + PADDING
                }px)`,
              }}
            >
              <GraphItem
                data={timeseries}
                stepSize={stepSize}
                getX={getX}
                maxWidth={width}
                showTooltip={showTooltip}
                index={matchingIndex}
              />
            </div>
          );
        })}
      </div>
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  max-width: 100%;
`;

const Background = styled.div(
  ({ theme }) => css`
    position: absolute;
    background: repeating-linear-gradient(
      -47.87deg,
      transparent 3.5px,
      ${theme.color.border.muted} 3.5px,
      ${theme.color.border.muted} 4.5px,
      transparent 4.5px,
      transparent 8px
    );
    width: 100%;
    height: 100%;
  `
);

const Line = styled.div(
  ({ theme }) => css`
    position: absolute;
    height: 100%;
    width: 1px;
    background: ${theme.color.border.default};
  `
);
