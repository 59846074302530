import styled, { css } from "styled-components";
import { motion } from "framer-motion";

import { Icon, IconType } from "../UI";

export const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  min-height: 400px;
  padding: 0 6px;
`;

export const Content = styled.div`
  max-width: 470px;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 8px;
  min-width: 0;
`;

export const Heading = styled.h2`
  font-family: "Basier Square";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  display: flex;
  align-items: center;
  margin: 24px 0 0;
`;

export const HelperText = styled.p(
  ({ theme }) => css`
    color: ${theme.color.fg.muted};
    text-align: center;
    width: 100%;
  `
);

export const BigIcon = ({
  type,
  noBorder,
}: {
  type: IconType;
  noBorder?: boolean;
}) => {
  return (
    <DropShadow>
      <BigIconContainer noBorder={noBorder}>
        <BigIconInner type={type} />
      </BigIconContainer>
    </DropShadow>
  );
};

const DropShadow = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  filter: drop-shadow(0px 3px 12px rgba(0, 0, 0, 0.15));
`;

const BigIconContainer = styled.div<{ noBorder?: boolean }>`
  border: 1px solid
    ${({ theme, noBorder }) =>
      noBorder ? "transparent" : theme.color.border.default};
  border-radius: 12px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BigIconInner = styled(Icon).attrs({
  width: "40",
  height: "40",
})``;
