import { useState } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css"; // Import the styles for the resizable component

import { Icon } from "../../UI";
import { FunctionTreeList } from "./FunctionTree";
import type { FunctionModulePair } from "../../../schemas";
import { useHandler, useThrottledLoadingState } from "../../../hooks";
import { useFunctionsListWidth } from "./hooks";
import { OpacityYoYo } from "../../Animations";
import { FunctionSearch } from "./FunctionSearch";

const resizableFunctionListDimensions = {
  default: 280,
  min: 140,
  max: 680,
};

export const ResizableFunctionTreeList = ({
  autometricsData,
  isLoading,
}: {
  autometricsData: FunctionModulePair[] | undefined;
  isLoading: boolean;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const throttledIsLoading = useThrottledLoadingState(isLoading, 500);

  const { handleResize, width } = useFunctionsListWidth(
    resizableFunctionListDimensions
  );

  const [filterValue, setFilterValue] = useState("");

  const onFilterChange = useHandler((value: string) => setFilterValue(value));

  return (
    <Resizable
      width={width}
      axis="x" // Restrict resizing to the horizontal axis
      onResize={handleResize}
      resizeHandles={["e"]} // Limit resize handle to just the east (right) handle
      handle={(_, ref) => (
        // Render a custom handle component, so we can indicate "resizability"
        // along the entire right side of the container
        <ResizableHandle
          ref={ref}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      )}
    >
      <FunctionListSection style={{ width }} $isHovered={isHovered}>
        <FunctionTreeListHeader>Explorer</FunctionTreeListHeader>

        {throttledIsLoading ? (
          <LoadingFunctions
            key="functionTreeListLoadingOuter"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              duration: 0.1,
            }}
          >
            <OpacityYoYo>
              <Icon type="autometrics" />
            </OpacityYoYo>
            <div>Loading Functions</div>
          </LoadingFunctions>
        ) : (
          (autometricsData ?? []).length > 0 && (
            <>
              <FunctionSearchContainer
                key="functionTreeListSearch"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <FunctionSearch value={filterValue} onChange={onFilterChange} />
              </FunctionSearchContainer>
              <FunctionTreeListContainer
                key="functionTreeList"
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <FunctionTreeList
                  autometricsData={autometricsData ?? []}
                  filterValue={filterValue}
                />
              </FunctionTreeListContainer>
            </>
          )
        )}
      </FunctionListSection>
    </Resizable>
  );
};

const FunctionListSection = styled.div<{ $isHovered: boolean }>(
  ({ $isHovered, theme }) => css`
    display: grid;
    grid-template-rows: auto auto 1fr;
    padding: 20px 20px 0;
    /* HACK - Knows height of nav bar */
    max-height: calc(100vh - 60px);
    background-color: ${theme.color.bg.subtle};
    border-right: 1px solid ${theme.color.border.muted};
    transition: border-color 0.2s ease-in-out;

    ${$isHovered &&
    css`
      border-color: ${theme.color.border.default};
    `}
  `
);

const FunctionTreeListHeader = styled.div(
  ({ theme }) => css`
    font: ${theme.font.body.md.medium};
    color: ${theme.color.fg.muted};
  `
);

const FunctionTreeListContainer = styled(motion.div)`
  padding-top: 4px; /* offset for the hover bg element */
  overflow: auto;
`;

const FunctionSearchContainer = styled(motion.div)`
  overflow: visible;
`;

const LoadingFunctions = styled(motion.div)`
  margin-top: 22px; /* magic number - when search is unhidden, change to 20px */
  padding-top: 40px; /* offset for the hover bg element */
  text-align: center;

  color: ${({ theme }) => theme.color.fg.muted};
  font-size: 12px;
`;

const ResizableHandle = styled.div`
  width: 15px;
  height: 100%;
  cursor: ew-resize;
  top: 0;
  right: -8px;
  position: absolute;
  z-index: 1;
`;
