export type NewsItem = {
  type: "blogPost" | "changelog" | "tutorial" | "news";
  title: string;
  description: string;
  date: string;
  url: string;
};

export const newsItems: NewsItem[] = [
  {
    type: "tutorial",
    title: "Deploying a monitoring stack on Render",
    description:
      "The case for pushing metrics rather than scraping and a blueprint combining OpenTelemetry collector with Prometheus",
    date: "Oct 30, 2023",
    url: "https://autometrics.dev/blog/deploying-a-monitoring-stack-on-render",
  },
  {
    type: "news",
    title: "Announcing the Call Graph",
    description:
      "Introducing Call Graph visualization to get better, trace-like insights simply with granular metrics.",
    date: "Oct 24, 2023",
    url: "https://autometrics.dev/blog/announcing-the-call-graph",
  },
  {
    type: "blogPost",
    title: "Zero-config Grafana dashboards for application metrics",
    description:
      "A guide on how to use the preconfigured Grafana dashboards with Autometrics",
    date: "Sep 29, 2023",
    url: "https://autometrics.dev/blog/zero-config-grafana-dashboards-for-application-metrics",
  },
  {
    type: "blogPost",
    title: "The road to 1.0",
    description:
      "This blog post describes our plans to get the autometrics spec to version 1.0",
    date: "Sep 21, 2023",
    url: "https://autometrics.dev/blog/the-road-to-1-0",
  },
  {
    type: "tutorial",
    title:
      "Better monitoring insights into your applications running on Railway",
    description:
      "A tutorial how to add Autometrics to applications running on Railway to gain better perfomance insights and enable a better debugging flow",
    date: "Sep 15, 2023",
    url: "https://autometrics.dev/blog/better-monitoring-insights-into-your-applications-running-on-railway",
  },
  {
    type: "blogPost",
    title: "August in Autometrics",
    description:
      "Autometrics Explorer, SLO Guide, new library features, and events",
    date: "Sep 1, 2023",
    url: "https://autometrics.dev/blog/august-in-autometrics",
  },
  {
    type: "news",
    title:
      "Announcing the Autometrics Explorer an opinionated UI for Prometheus metrics",
    description:
      "The Autometrics Explorer is a developer-friendly UI to visualize Prometheus data and quickly go from alert to misbehaving function facilitating a faster debugging feedback loop.",
    date: "Aug 17, 2023",
    url: "https://autometrics.dev/blog/announcing-the-autometrics-explorer-a-clear-and-contextual-ui-for-prometheus-metrics",
  },
];
