import { useEffect, useState } from "react";
import { Timestamp } from "@fiberplane/prometheus-query";
import EventEmitter from "eventemitter3";

import { useHandler, useLocalStorage } from "../../hooks";
import { useNavigateToAlerts } from "./useNavigateToAlerts";
import { notifications } from "..";
import { getServiceWorker, setupNotificationWorker } from "./setup";

export type NotificationState = NotificationPermission | "prompt" | "initial";

export type NotificationSettings = {
  notificationState: NotificationState;
  desktopNotificationsEnabled: boolean;
};

export function useNotificationSettings(): {
  settings: NotificationSettings;
  setDesktopNotificationsEnabled: (value: boolean) => void;
  requestPermission: (timestamp?: Timestamp) => Promise<void>;
} {
  const [desktopNotificationsEnabled, setDesktopNotificationsEnabled] =
    useLocalStorage<boolean>("showDesktopNotifications", true);

  const [notificationState, setNotificationState] = useState(
    DesktopNotificationSettings.getInstance().notificationState
  );
  useEffect(() => {
    const handler = (state: NotificationState) => {
      setNotificationState(state);
    };

    DesktopNotificationSettings.getInstance().on("change", handler);

    return () => {
      DesktopNotificationSettings.getInstance().off("change", handler);
    };
  }, []);

  const navigateToAlerts = useNavigateToAlerts();
  const requestPermissionHandler = useHandler(
    (timestamp?: Timestamp): Promise<void> => {
      return DesktopNotificationSettings.getInstance()
        .requestPermission()
        .then((allowed) => {
          if (allowed) {
            const worker = notifications.getServiceWorker();
            worker?.postMessage({
              type: "NOTIFICATIONS_ENABLED",
            });
          }

          if (timestamp) {
            navigateToAlerts(timestamp);
          }
        });
    }
  );

  return {
    settings: {
      notificationState,
      desktopNotificationsEnabled,
    },
    setDesktopNotificationsEnabled,
    requestPermission: requestPermissionHandler,
  };
}

class DesktopNotificationSettings extends EventEmitter {
  private _notificationState: NotificationState;
  private static _instance: DesktopNotificationSettings | null = null;
  constructor() {
    super();
    this._notificationState = "initial";
    navigator.permissions.query({ name: "notifications" }).then((result) => {
      result.addEventListener("change", () => {
        // Result.state is returning "prompt" also when we've not requested it yet.
        this.notificationState =
          result.state === "prompt" ? "default" : result.state;
      });
    });
  }

  private set notificationState(newState: NotificationState) {
    if (this._notificationState === newState) {
      return;
    }

    this._notificationState = newState;
    this.emit("change", newState);
  }

  public get notificationState() {
    return this._notificationState;
  }

  // Resolves to true if the permission was granted, false otherwise
  public async requestPermission() {
    this.notificationState = "prompt";

    const permission = await window.Notification.requestPermission();
    this.notificationState = permission === "granted" ? "granted" : "denied";
    return permission === "granted";
  }

  public static getInstance() {
    if (DesktopNotificationSettings._instance === null) {
      DesktopNotificationSettings._instance = new DesktopNotificationSettings();

      // Singleton with a side effect: we're initializing the service worker also
      setupNotificationWorker().then(() => {
        DesktopNotificationSettings._instance?.emit(
          "change",
          getServiceWorker() === null
            ? "denied"
            : window.Notification.permission
        );
      });
    }

    return DesktopNotificationSettings._instance;
  }
}
