import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import styled from "styled-components";

import type { AnimatedIconProps } from "./types";
import { ANIMATE, ANIMATE_INITIAL } from "./constants";

const CLIP_PATH_ID = "animated-function-icon-clip-path";

export function AnimatedFunctionIcon({
  controls,
  onAnimationComplete,
  ...svgProps
}: AnimatedIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path opacity="0.2" d="M16 4H4V16H16V4Z" fill="currentColor" />
      <path
        d="M4 3.99805C4.00105 2.89438 4.89608 2 6 2H8V4L4.00391 4L4.00391 15.998C2.89934 15.998 2.00391 15.1026 2.00391 13.998V5.99805C2.00391 4.89478 2.89723 4.00016 4 3.99805Z"
        fill="currentColor"
      />
      <path d="M8 18H6C4.89543 18 4 17.1046 4 16H8V18Z" fill="currentColor" />
      <path
        d="M16 16V4.00293C17.1046 4.00293 18 4.89836 18 6.00293L18 14.0029C18 15.1075 17.1046 16.0029 16 16.0029C15.9984 17.1062 15.1036 18 14 18L12 18L12 16L16 16Z"
        fill="currentColor"
      />
      <path
        d="M12 2H14C15.1046 2 16 2.89543 16 4L12 4V2Z"
        fill="currentColor"
      />

      <ClippedGroup>
        <motion.g custom={1} variants={variants} animate={controls}>
          <path d="M6 6H11V8H6V6Z" fill="currentColor" />
          <path d="M14 6H12V8H14V6Z" fill="currentColor" />
        </motion.g>

        <motion.path
          custom={2}
          variants={variants}
          animate={controls}
          d="M14 9H6V11H14V9Z"
          fill="currentColor"
        />

        <motion.g
          custom={3}
          variants={variants}
          animate={controls}
          onAnimationComplete={onAnimationComplete}
        >
          <path d="M14 12H9V14H14V12Z" fill="currentColor" />
          <path d="M6 12H8V14H6V12Z" fill="currentColor" />
        </motion.g>
      </ClippedGroup>

      <clipPath id={CLIP_PATH_ID}>
        <rect x="6" y="6" width="8" height="8" />
      </clipPath>
    </svg>
  );
}

const variants: Variants = {
  [ANIMATE_INITIAL]: {
    x: -8,
    transition: {
      duration: 0,
    },
  },
  [ANIMATE]: (i: number) => ({
    x: 0,
    transition: {
      delay: 0.2 * i,
      ease: "easeOut",
      type: "linear",
      duration: 0.2,
    },
  }),
};

const ClippedGroup = styled.g`
  clip-path: url(#${CLIP_PATH_ID});
`;
