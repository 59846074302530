import { nanoid } from "@reduxjs/toolkit";

import { FunctionData, FunctionTreeNodeData } from "../../../types";

const HIERARCHY = ["service", "module", "function"] as const;

/**
 * Transforms an array of function data into a hierarchical tree structure,
 * organized from top to bottom by service, module, then function
 */
export function getFunctionTreeData(
  functions: FunctionData[]
): FunctionTreeNodeData[] {
  // Initialize the root of the tree
  const tree: FunctionTreeNodeData = {
    id: "root",
    children: [],
    type: "service",
    name: "",
    module: "",
    service_name: "",
  };

  // Loop through each set of function labels and add it to the tree
  // The strategy is to first add the service (if it doesn't exist),
  // then add the module (if it doesn't exist) as a child of the service,
  // then add the function as a child of the module.
  // Effectively, this is a depth-first approach to building the tree.
  for (const function_ of functions) {
    // Create a `cursor` to track the current position in the tree
    // We always start at the root node
    let cursor: FunctionTreeNodeData = tree;

    const {
      name: functionName,
      module: moduleName,
      service_name: serviceName,
    } = function_;

    // An array of identifiers which will form the path in the tree
    // These are ordered left-to-right according to the hierarchy of the tree,
    // where the service is at the top, then the module, then the function
    const labelValues = [serviceName, moduleName, functionName];

    // Now we loop through the label values according to the hierarchy of the tree
    //
    // Here the `hierarchyIndex` reflects:
    //   service (0) -> module (1) -> function (2)
    //
    // And the `nodeName` will be our way to look for existing nodes in the tree.
    //   For example, if we're at the module level, we'll search for a node
    //   with the same name as the current module name.
    //
    for (const [hierarchyIndex, nodeName] of labelValues.entries()) {
      // If the current node name is undefined, skip it
      if (!nodeName) {
        continue;
      }

      // Reconstruct the node type from known hierarchy.
      // Since we know that index can only be 0, 1, or 2,
      // we can safely map a value from the lookup array.
      const nodeType = HIERARCHY[hierarchyIndex]!;

      // Ensure that the current (cursor) tree node has a children array
      if (!cursor.children) {
        cursor.children = [];
      }

      // Look for an existing node in the tree with the same name as the current node
      const existingChild = cursor.children.find(
        (child) => child.name === nodeName
      );

      if (existingChild) {
        // If there's already a node with this identifier in the tree,
        // update the cursor to point to that node
        cursor = existingChild;
      } else {
        // If this identifier was not found in the tree,
        // create a new child node on the cursor with a unique id,
        // and give it the correct "type" based on its hierarchy (service, function, or module).
        const newChild: FunctionTreeNodeData = {
          id: nanoid(),
          name: nodeName,
          module: moduleName ?? "",
          type: nodeType,
          service_name: serviceName ?? "",
          children: [],
        };

        cursor.children.push(newChild);
        cursor = newChild;
      }
    }
  }

  recursiveSort(tree, sortFunction);

  return tree.children ?? [];
}

function sortFunction(
  node1: FunctionTreeNodeData,
  node2: FunctionTreeNodeData
) {
  if (node1.type === node2.type) {
    return node1.name.localeCompare(node2.name);
  }

  // this ensures that more generic group always comes first
  if (node1.type === "service") {
    return -1;
  }

  if (node1.type === "module") {
    if (node2.type === "service") {
      return 1;
    }

    return -1;
  }

  return 1;
}

function recursiveSort(
  node: FunctionTreeNodeData,
  sortFunction: (
    node1: FunctionTreeNodeData,
    node2: FunctionTreeNodeData
  ) => number
) {
  if (node.children) {
    node.children.sort(sortFunction);
    for (const child of node.children) {
      recursiveSort(child, sortFunction);
    }
  }
}
