import { AnimatePresence } from "framer-motion";
import { Fragment } from "react";
import styled, { css, keyframes } from "styled-components";

import { useThrottledLoadingState, useTimeAgo } from "../../../hooks";
import { Icon } from "../../UI";

const THROTTLE_MS = 250;

type LastUpdatedProps = {
  isRefreshing: boolean;
  lastUpdatedAt: Date;
  refresh: () => void;
};

export function LastUpdated({
  isRefreshing,
  lastUpdatedAt,
  refresh,
}: LastUpdatedProps) {
  const throttledIsRefreshing = useThrottledLoadingState(
    isRefreshing,
    THROTTLE_MS
  );

  const relativeTimeString = useTimeAgo(
    isValidDate(lastUpdatedAt) ? lastUpdatedAt : undefined
  );

  return (
    <Container>
      <AnimatePresence mode="wait">
        {throttledIsRefreshing ? (
          <Fragment key="refreshing">Refreshing...</Fragment>
        ) : (
          <Fragment key="lastUpdated">
            <Label>Last updated:</Label>
            <span>{relativeTimeString ?? <>&mdash;</>}</span>
          </Fragment>
        )}
      </AnimatePresence>

      <RefreshIcon
        $isRefreshing={throttledIsRefreshing}
        onClick={refresh}
        type="arrow_clockwise"
      />
    </Container>
  );
}

const isValidDate = (date: Date) => !Number.isNaN(date.getTime());

const Container = styled.span(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font: ${theme.font.body.md.regular};
  `
);

const Label = styled.span`
  font: ${({ theme }) => theme.font.body.md.medium};
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const RefreshIcon = styled(Icon)<{ $isRefreshing: boolean }>(
  ({ theme, $isRefreshing }) =>
    css`
      color: ${theme.color.fg.primary};
      cursor: pointer;
      animation: ${$isRefreshing
        ? css`
            ${spinAnimation} ${THROTTLE_MS}ms ease-in-out forwards infinite
          `
        : "unset"};
    `
);
