import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { replaceQueryParameter } from "../../../utils";
import { upsertAndSelectInstanceByUrl } from "../../../slices";

/**
 * Hook that reads the Prometheus URL from the URL query parameter and
 * dispatches an action to upsert it to the list of Prometheus instances,
 * then selects it as the active environment.
 *
 * Replaces history state to remove the query parameter from the URL.
 *
 * NOTES
 *   - The "native" search param takes precedence over the hash search param if both are present
 *   - We always delete the hash search param, even if it's not there or used
 */
export function usePrometheusUrlQueryParameter() {
  const dispatch = useDispatch();

  const [hashSearchParams, setHashSearchParams] = useSearchParams();
  const hashPrometheusUrl = hashSearchParams.get("prometheusUrl");

  const currentUrl = new URL(window.location.href);
  const prometheusUrl = currentUrl.searchParams.get("prometheusUrl");

  // NOTE - only runs once
  useEffect(() => {
    // For simplicity, always delete the hash search param
    const hashParamsWithoutPrometheusUrl = replaceQueryParameter(
      hashSearchParams,
      "prometheusUrl",
      null
    );
    setHashSearchParams(hashParamsWithoutPrometheusUrl, { replace: true });

    if (prometheusUrl !== null) {
      // NOTE - we use native browser apis for search parameters because
      //        using `hashRouter` means the search param has to come after the # url
      currentUrl.searchParams.delete("prometheusUrl");
      // Replace the current URL in the browser's history
      window.history.replaceState({}, document.title, currentUrl.toString());

      // Only add the instance if it's not the empty string
      if (prometheusUrl) {
        dispatch(upsertAndSelectInstanceByUrl(prometheusUrl));
      }
    } else if (hashPrometheusUrl) {
      dispatch(upsertAndSelectInstanceByUrl(hashPrometheusUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
