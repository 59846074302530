import { useMeasure } from "react-use";

import { NODE_WIDTH } from "./constants";
import { StyledRectangle } from "./sharedStyles";
import { IncomingNodes } from "./IncomingNodes";
import { OutgoingNodes } from "./OutgoingNodes";
import { analyzeNodes } from "./utils";
import { CallGraphNode } from "./types";

export type GraphNodes = Array<CallGraphNode>;

type Props = {
  incomingNodes: Readonly<GraphNodes>;
  outgoingNodes: Readonly<GraphNodes>;
  renderValue: (value: number) => string;
  renderGradients?: boolean;
};

export function CallGraphOutput(props: Props) {
  const {
    incomingNodes: originalIncomingNodes,
    outgoingNodes: originalOutgoingNodes,
    renderValue,
    renderGradients = false,
  } = props;

  const [ref, { width = 600 }] = useMeasure<HTMLDivElement>();
  const incomingNodes = [...originalIncomingNodes].sort(
    (a, b) => b.value - a.value
  );
  const outgoingNodes = [...originalOutgoingNodes].sort(
    (a, b) => b.value - a.value
  );

  const { totalNodeHeight, height, valueToPixelRatio } = analyzeNodes(
    incomingNodes,
    outgoingNodes
  );
  const right = width - 200 - NODE_WIDTH;
  const leftMiddle = width / 2 - NODE_WIDTH / 2;
  const rightMiddle = leftMiddle + NODE_WIDTH;

  return (
    <div ref={ref}>
      {width > 0 && (
        <svg viewBox={`0 0 ${width} ${height + 15}`}>
          <g>
            <StyledRectangle
              width={NODE_WIDTH}
              height={totalNodeHeight}
              x={width / 2 - NODE_WIDTH / 2}
              y={1}
            />
          </g>
          <IncomingNodes
            incomingNodes={incomingNodes}
            middle={leftMiddle}
            renderValue={renderValue}
            totalNodeHeight={totalNodeHeight}
            valueToPixelRatio={valueToPixelRatio}
            renderGradients={renderGradients}
          />
          <OutgoingNodes
            middle={rightMiddle}
            outgoingNodes={outgoingNodes}
            renderValue={renderValue}
            right={right}
            totalNodeHeight={totalNodeHeight}
            renderGradients={renderGradients}
            valueToPixelRatio={valueToPixelRatio}
          />
        </svg>
      )}
    </div>
  );
}
