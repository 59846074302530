import styled, { css } from "styled-components";
import { useSelector } from "react-redux";

import {
  DashboardSection,
  DashboardSectionHeader,
  DashboardSectionHeading,
  TargetInfo,
  TargetNumber,
} from "./styled";
import { getIsActualFunctionValueViolatingTarget } from "../../utils";
import { useGetAllTrackedObjectivesWithCurrentValueQuery } from "../../api";
import { useTimeRange } from "../../hooks";
import { selectActivePrometheus } from "../../selectors";
import { ObjectiveWithCurrentValue } from "../../schemas";
import { Skeleton } from "../Skeleton";

const EMPTY_SLO_LIST: ObjectiveWithCurrentValue[] = [];

export function SlosOffTarget() {
  const instance = useSelector(selectActivePrometheus);

  const { timeRange } = useTimeRange();

  const { data = EMPTY_SLO_LIST, isFetching } =
    useGetAllTrackedObjectivesWithCurrentValueQuery(
      {
        environmentUrl: instance?.url,
        start: timeRange.from,
        end: timeRange.to,
      },
      {
        skip: !instance?.url,
      }
    );

  const slosOffTarget = data.filter((objective) =>
    getIsActualFunctionValueViolatingTarget(objective, objective.currentValue)
  ).length;

  return (
    <SlosOffTargetSection>
      <DashboardSectionHeader>
        <DashboardSectionHeading>SLOs off target</DashboardSectionHeading>
      </DashboardSectionHeader>
      {isFetching ? (
        <Skeleton />
      ) : (
        <TargetInfo>
          <TargetNumber $isOnTarget={slosOffTarget === 0} to="/slos">
            {slosOffTarget}
          </TargetNumber>
          in selected time range
        </TargetInfo>
      )}
    </SlosOffTargetSection>
  );
}

const SlosOffTargetSection = styled(DashboardSection)(
  ({ theme }) =>
    css`
      ${theme.media.md`
        padding: 24px;

        grid-column: 7 / 9;
        grid-row: 3 / 4;
      `}
    `
);
