import { MetricType } from "../../../api";

export function getMetricType(query: string): MetricType | undefined {
  const caller = getCallerName(query);
  if (caller) {
    // Is a caller related function
    return;
  }

  if (isRequestRateQuery(query)) {
    return "requestRate";
  }

  if (isErrorRatioQuery(query)) {
    return "errorRatio";
  }

  if (isLatencyQuery(query)) {
    return "latency";
  }
}

// See how many times the rate for function_calls_count* is used in the query
function getRequestRateQueryCount(query: string): number {
  const callCountPattern = /rate\s*?\(function_calls_count/g;
  let match = query.match(callCountPattern);
  if (match === null) {
    const alternativeCallPattern =
      /rate\s*?\(\s*?{\s*?__name__=(~?)"function_calls_count/g;
    match = query.match(alternativeCallPattern);
  }

  if (match === null) {
    const alternativeCallPattern =
      /rate\s*?\(\s*?{\s*?__name__=(~?)"function_calls\(_count\)/g;
    match = query.match(alternativeCallPattern);
  }

  return match?.length ?? 0;
}

// If only once it's likely a single function request rate query
function isMultiRequestRateQuery(query: string): boolean {
  return getRequestRateQueryCount(query) > 1;
}

// If its multiple times it's probably an error ratio query
export function isRequestRateQuery(query: string): boolean {
  return getRequestRateQueryCount(query) === 1;
}

export function isErrorRatioQuery(query: string): boolean {
  if (isMultiRequestRateQuery(query) === false) {
    return false;
  }

  const errorRatioPattern = /result="error"/;
  const match = query.match(errorRatioPattern);
  return match !== null;
}

export function isLatencyQuery(query: string): boolean {
  return (
    query.includes('"percentile_latency"') &&
    query.includes("histogram_quantile")
  );
}

export function getFunctionName(query: string): string | undefined {
  const pattern = /function(\s*)=(~?)"(?<name>\w*)"/;
  const match = query.match(pattern);
  if (match === null) {
    return undefined;
  }

  return match.groups?.name;
}

export function getModuleName(query: string): string | undefined {
  const pattern = /module(\s*)=(~?)"(?<name>\w*)"/;
  const match = query.match(pattern);
  if (match === null) {
    return undefined;
  }

  return match.groups?.name;
}

export function getCallerName(query: string): string | undefined {
  const pattern = /cal{2}er(\s*)=(~?)"(?<name>\w*)"/;
  const match = query.match(pattern);
  if (match === null) {
    return undefined;
  }

  return match.groups?.name;
}
