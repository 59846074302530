import { motion } from "framer-motion";
import styled from "styled-components";

export const fadeInVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
    transition: {
      type: "tween",
      ease: "anticipate",
      duration: 0.07,
    },
  },
};

export const fadeInTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.1,
};

export const FadeIn = styled(motion.div).attrs({
  initial: "initial",
  animate: "in",
  exit: "out",
  variants: fadeInVariants,
  transition: fadeInTransition,
})``;
