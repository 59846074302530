import styled, { css } from "styled-components";
import { useMemo } from "react";

import { CallGraphNode } from "./types";
import { CallGraphOutput, GraphNodes } from "./CallGraphOutput";
import { PrometheusQueryResponse } from "../../../../schemas";
import { NoData } from "../NoData";
import { FadeIn } from "../../../Animations";
import { Loading } from "../../../FallbackStates";
import {
  GraphObjectiveMetricsWrapper,
  GraphWrapper,
  Grid,
  SubtleMetricDisplay,
} from "../common";
import { MetricType } from "../../../../api";
import { formatNumber } from "../utils";
import { DetailMetric } from "../hooks";
import { Icon } from "../../../UI";

type QueryResponseResult = PrometheusQueryResponse["data"]["result"];

type Props = {
  functionName: string;
  incomingResult?: QueryResponseResult;
  outgoingResult?: QueryResponseResult;
  metricType: MetricType;
  isLoading: boolean;
};

const EMPTY_CALL_GRAPH_NODE: Readonly<GraphNodes> = [] as const;

export function CallGraph({
  functionName,
  isLoading,
  incomingResult,
  outgoingResult,
  metricType,
}: Props) {
  const incomingNodes = useMemo(
    () =>
      incomingResult?.map(
        (value: QueryResponseResult[0]): CallGraphNode => ({
          name: value.metric.caller_function as string,
          module: value.metric.caller_module as string,
          value: Number.parseFloat(value.value[1]),
        })
      ) ?? EMPTY_CALL_GRAPH_NODE,
    [incomingResult]
  );

  const outgoingNodes = useMemo(
    () =>
      outgoingResult?.map(
        (value: QueryResponseResult[0]): CallGraphNode => ({
          name: value.metric.function as string,
          module: value.metric.module as string,
          value: Number.parseFloat(value.value[1]),
        })
      ) ?? EMPTY_CALL_GRAPH_NODE,
    [outgoingResult]
  );

  if (isLoading) {
    return (
      <FadeIn key="functionPageCallGraphIsLoading">
        <Loading />
      </FadeIn>
    );
  }

  const noData = incomingNodes.length === 0 && outgoingNodes.length === 0;
  const renderValue =
    metricType === "errorRatio"
      ? (value: number) => `Error ratio: ${formatNumber(value)}%`
      : (value: number) => `${formatNumber(value)} calls/sec`;

  const metrics: Array<DetailMetric> =
    noData || metricType !== "requestRate"
      ? []
      : [
          {
            reportType: "incoming",
            value: formatNumber(
              incomingNodes.reduce((sum, { value }) => sum + value, 0)
            ),
            unit: "calls/sec",
          },
          {
            reportType: "outgoing",
            value: formatNumber(
              outgoingNodes.reduce((sum, { value }) => sum + value, 0)
            ),
            unit: "calls/sec",
          },
        ];
  return (
    <Grid>
      <CallGraphHeaderSection>
        {metrics && metrics.length > 0 && (
          // grid-area: "metrics"
          <CallGraphObjectiveMetricsWrapper>
            {metrics.map(({ reportType, value, unit }, index) => (
              <CallGraphMetricDisplay
                key={index}
                rate={value}
                unit={unit}
                report={reportType}
              />
            ))}
          </CallGraphObjectiveMetricsWrapper>
        )}
        <InfoIcon
          type="info"
          width="16"
          height="16"
          aria-label="The height of the lines in the graph below indicate the amount. If line sections have diagonal lines then they indicate a 0 value"
        />
      </CallGraphHeaderSection>

      {/* grid-area: "graph" */}
      <GraphWrapper>
        <CallGraphContainer>
          <CallGraphContainerHeader>
            <CallGraphHeaderIncoming>Incoming</CallGraphHeaderIncoming>
            <CallGraphHeaderMain>{functionName}</CallGraphHeaderMain>
            <CallGraphHeaderOutgoing>Outgoing</CallGraphHeaderOutgoing>
          </CallGraphContainerHeader>
          {noData ? (
            <>
              <NoDataSpacer />
              <NoData />
            </>
          ) : (
            <CallGraphOutput
              renderGradients={metricType === "errorRatio"}
              renderValue={renderValue}
              outgoingNodes={outgoingNodes}
              incomingNodes={incomingNodes}
            />
          )}
        </CallGraphContainer>
      </GraphWrapper>
    </Grid>
  );
}

const CallGraphHeaderSection = styled.div`
  display: grid;
  grid-area: metrics;
  grid-template-columns: auto 16px;
  grid-template-areas: "metrics icon";
  gap: 8px;
`;

const InfoIcon = styled(Icon)`
  grid-area: icon;
`;

const CallGraphObjectiveMetricsWrapper = styled(GraphObjectiveMetricsWrapper)`
  justify-content: space-between;
`;

const CallGraphMetricDisplay = styled(SubtleMetricDisplay)`
  flex: 200px 0 0;
`;

const CallGraphContainer = styled.div`
  margin-top: 16px;
  display: grid;
  gap: 12px;
  align-items: center;
  position: relative;
  min-width: 600px;
`;

const NoDataSpacer = styled.div`
  position: relative;
  height: 207px;
  min-width: 380px;
  margin: 20px 0;
`;

const CallGraphContainerHeader = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 200px auto 200px;
    font: ${theme.font.body.md};
  `
);

const CallGraphHeaderIncoming = styled.div`
  text-align: right;
`;

const CallGraphHeaderMain = styled.div`
  text-align: center;
`;

const CallGraphHeaderOutgoing = styled.div`
  text-align: left;
`;
