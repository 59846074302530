import { CloseTooltipFn, Timeseries } from "@fiberplane/charts";
import { useRef } from "react";
import styled, { css } from "styled-components";

import { showTooltip } from "../../../../thunks";
import { AlertLabelTooltip } from "./AlertLabelTooltip";
import { dispatch } from "../../../../store";

const HIDE_LABELS = new Set([
  "alertname",
  "severity",
  "lastseen",
  "state",
  "objective_name",
]);

type Props = {
  width: number;
} & Pick<Timeseries, "labels">;

export function AlertLabelsContent(props: Props) {
  const pairs = Object.entries(props.labels).filter(
    ([key]) => HIDE_LABELS.has(key) === false
  );

  const visiblePairs = pairs.slice(
    0,
    Math.max(1, Math.floor(props.width / 200))
  );

  const hideRef = useRef<CloseTooltipFn | null>(null);
  const labelRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {visiblePairs.map(([key, value]) => {
        if (HIDE_LABELS.has(key)) {
          return null;
        }

        return (
          <Label key={key}>
            <LabelKey>{key}:</LabelKey>
            <LabelValue>{value}</LabelValue>
          </Label>
        );
      })}
      {pairs.length > visiblePairs.length && (
        <MoreLabel
          ref={labelRef}
          onMouseOver={() => {
            if (labelRef.current) {
              hideRef.current = dispatch(
                showTooltip(
                  labelRef.current,
                  <AlertLabelTooltip labels={props.labels} />,
                  { placement: "top" }
                )
              );
            }
          }}
          onMouseOut={() => {
            if (hideRef.current !== null) {
              hideRef.current();
            }

            hideRef.current = null;
          }}
        >
          More...
        </MoreLabel>
      )}
    </>
  );
}

const Label = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: auto auto;
    gap: ${theme.spacing.xxs};
    color: ${theme.color.fg.primary};
    font: ${theme.font.body.sm.regular};
    border: 1px solid ${theme.color.border.primary};
    border-radius: ${theme.radius.minimal};
    padding: 0 ${theme.spacing.xxs} 0 ${theme.spacing.xxs};
  `
);

const MoreLabel = styled(Label)(
  ({ theme }) => css`
    border-color: ${theme.color.border.default};
    color: ${theme.color.fg.default};
    cursor: pointer;
  `
);

const LabelKey = styled.div`
  border-right: 0;
  flex: 0 0 auto;
  margin: 0;
`;

const LabelValue = styled.div`
  border-left: 0;
  margin: 0;
  max-width: 200px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;
