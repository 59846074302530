import styled, { css } from "styled-components";

import { MetricDisplay } from "./MetricDisplay";

export type MetricReportType =
  | "latest"
  | "average"
  | "sum"
  | "incoming"
  | "outgoing";

export const Container = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing.content.gap.md};
    border-radius: ${theme.radius.default};
  `
);

export const Grid = styled.div`
  display: grid;
  grid: "metrics" auto "graph" 1fr;
  gap: ${({ theme }) => theme.spacing.page.gap};
  padding: 0;
`;

export const GraphObjectiveMetricsWrapper = styled.div`
  grid-area: metrics;
  display: flex;
  gap: 40px;
`;

export const GraphWrapper = styled.div`
  grid-area: graph;
  position: relative;
  min-width: 0;
`;

export const SubtleMetricDisplay = styled(MetricDisplay)`
  --text-alternative-color: ${({ theme }) => theme.color.fg.subtle};
`;
