export function getRustCode() {
  return `
use autometrics::autometrics;
use autometrics::objectives::{
  Objective, ObjectiveLatency, ObjectivePercentile
};

const API_SLO: Objective = Objective::new("api") 
    .success_rate(ObjectivePercentile::P99_9)
    .latency(ObjectiveLatency::Ms250, ObjectivePercentile::P99);

#[autometrics(objective = API_SLO)]
pub fn my_api_handler() {
    // ...
}
`.trim();
}

export function getTypescriptCode() {
  return `
import {
  autometrics,
  Objective,
  ObjectiveLatency,
  ObjectivePercentile
} from "@autometrics/autometrics";
 
const API_SLO: Objective = {
  name: "api",
  successRate: ObjectivePercentile.P99_9,
  latency: [ObjectiveLatency.Ms250, ObjectivePercentile.P99],
};
 
const handler = autometrics(
  { objective: API_SLO },
  function handle(
    // ...
  )
);
`.trim();
}

export function getGoCode() {
  return `
//autometrics:inst --slo "Api" --success-target 90
func RouteHandler(args interface{}) (err error) {
    // ...
    return nil
}
`.trim();
}

export function getPythonCode() {
  return `
from autometrics import autometrics
from autometrics.objectives import (
    Objective, ObjectiveLatency, ObjectivePercentile
)
 
API_SLO = Objective(
    "api_slo",
    success_rate=ObjectivePercentile.P99_9,
    latency=(ObjectiveLatency.Ms250, ObjectivePercentile.P99),
)
 
@autometrics(objective=API_SLO)
def create_user():
    # ...
`.trim();
}
