import { useMemo } from "react";
import {
  Link,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { AnimatePresence } from "framer-motion";

import { useGetAllTrackedObjectivesWithCurrentValueQuery } from "../../api";
import { selectActivePrometheus } from "../../selectors";
import { SloDetailPage } from "./SloDetailPage";
import { SloOverviewPage } from "./SloOverviewPage";
import { NoSlosFound } from "./NoSlosFound";
import { PopIn } from "../Animations";
import { PageContainer, PageDescription, PageHeader, PageHeading } from "../UI";
import { NoDataUrlProvided } from "../FallbackStates";
import { useTimeRange } from "../../hooks";

export function Slos() {
  const { "*": path = "" } = useParams();
  const [searchParams] = useSearchParams();

  const instance = useSelector(selectActivePrometheus);

  // HACK - This is a hack to get the objective name from the path
  const objectiveName = useMemo(() => {
    return path.split("/")[0];
  }, [path]);

  const { timeRange, onChangeTimeRange } = useTimeRange();

  const {
    data: objectives,
    error,
    isFetching,
    isUninitialized,
  } = useGetAllTrackedObjectivesWithCurrentValueQuery(
    {
      environmentUrl: instance?.url,
      start: timeRange.from,
      end: timeRange.to,
    },
    {
      skip: !instance?.url,
    }
  );

  if (!instance?.url) {
    return <NoDataUrlProvided />;
  }

  // NOTE - We handle the "No SLOs Found" path here for stylistic reasons
  //        The empty state component looks a lot better without the page heading and filters
  const shouldShowNoSlosFound =
    !isUninitialized && !isFetching && !error && !objectives?.length;

  return (
    <PageContainer>
      {shouldShowNoSlosFound ? (
        <PopIn>
          <NoSlosFound />
        </PopIn>
      ) : (
        <>
          <PageHeader>
            <PageHeading>
              {objectiveName ? (
                <>
                  <HeadingLink
                    to={{
                      pathname: "/slos",
                      search: searchParams.toString(),
                    }}
                  >
                    SLOs /{" "}
                  </HeadingLink>
                  <HeadingPart $active>{objectiveName}</HeadingPart>
                </>
              ) : (
                <HeadingPart $active>SLOs</HeadingPart>
              )}
            </PageHeading>
            <PageDescription>
              SLOs (Service Level Objectives) are performance promises made by a
              service or system. Read more about SLOs in our{" "}
              <a
                href="https://docs.autometrics.dev/features#alerts-and-slos-without-yaml"
                target="_blank"
                rel="noreferrer noopener"
              >
                documentation
              </a>
              .
            </PageDescription>
          </PageHeader>
          <AnimatePresence mode="wait">
            <Routes>
              <Route
                index
                element={
                  <SloOverviewPage
                    timeRange={timeRange}
                    key="SloOverviewPage"
                  />
                }
              />
              <Route
                path=":objectiveName/:metricType?"
                element={
                  <SloDetailPage
                    onChangeTimeRange={onChangeTimeRange}
                    timeRange={timeRange}
                    key="sloDetailPage"
                  />
                }
              />
            </Routes>
          </AnimatePresence>
        </>
      )}
    </PageContainer>
  );
}

const HeadingLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.color.fg.subtle};
    text-decoration: none;
  `
);

const HeadingPart = styled.span<{ $active?: boolean }>(
  ({ theme, $active }) => css`
    color: ${$active ? theme.color.fg.default : theme.color.fg.subtle};
  `
);
