import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "@fiberplane/ui";

import { FallbackCommon } from "../../FallbackStates";

const { Container, Content, Header, Heading, HelperText, BigIcon } =
  FallbackCommon;

export function SloNotFound({ objectiveName }: { objectiveName?: string }) {
  const [searchParams] = useSearchParams();

  return (
    <StyledContainer>
      <StyledContent>
        <Header>
          <BigIcon type="autometrics_black_faded" />
          <Heading>SLO Not Found</Heading>
          <HelperText>
            {objectiveName
              ? `The SLO "${objectiveName}" was not found in Prometheus.`
              : "No data were found for this SLO."}
          </HelperText>
          <ButtonGroup>
            <GoBackButton
              asElement="link"
              to={{
                pathname: "/slos",
                search: searchParams.toString(),
              }}
            >
              View all SLOs
            </GoBackButton>
          </ButtonGroup>
        </Header>
      </StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled(Container)`
  height: 100%;
  min-height: 400px;
  align-items: flex-start;
  padding: 72px 0;
`;

const StyledContent = styled(Content)`
  max-width: 640px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`;

const GoBackButton = styled(Button)``;
