import { AnimatePresence } from "framer-motion";

import { Tooltip } from "./Tooltip";
import { TooltipState } from "../../state";
import { TooltipPortal } from "./TooltipPortal";

type Props = {
  tip: TooltipState | null;
};

export function TooltipContainer({ tip }: Props): JSX.Element {
  return (
    <TooltipPortal>
      <AnimatePresence>{tip && <Tooltip tip={tip} />}</AnimatePresence>
    </TooltipPortal>
  );
}
