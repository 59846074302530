import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
// import { HistoryRouter as Router } from "redux-first-history/rr6";
// HACK - Use HashRouter to avoid issues when running locally
import { HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import "./index.css";
import { App } from "./components";
import store from "./store";
import { GlobalStyle, extendedTheme } from "./theme";

const root = createRoot(document.querySelector("#root")!);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={extendedTheme}>
      <GlobalStyle />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);
