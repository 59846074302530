/* GENERATED BY FIGMA PLUGIN. PLEASE DO NOT MODIFY */

export const theme = {
  color: {
    primary: {
      grape: {
        50: "var(--color-primary-grape-50)",
        100: "var(--color-primary-grape-100)",
        200: "var(--color-primary-grape-200)",
        300: "var(--color-primary-grape-300)",
        400: "var(--color-primary-grape-400)",
        500: "var(--color-primary-grape-500)",
        600: "var(--color-primary-grape-600)",
        700: "var(--color-primary-grape-700)",
        800: "var(--color-primary-grape-800)",
        900: "var(--color-primary-grape-900)",
        alpha: {
          50: "var(--color-primary-grape-alpha-50)",
          100: "var(--color-primary-grape-alpha-100)",
          200: "var(--color-primary-grape-alpha-200)",
          300: "var(--color-primary-grape-alpha-300)",
          400: "var(--color-primary-grape-alpha-400)",
        },
      },
    },
    neutral: {
      50: "var(--color-neutral-50)",
      100: "var(--color-neutral-100)",
      200: "var(--color-neutral-200)",
      300: "var(--color-neutral-300)",
      400: "var(--color-neutral-400)",
      500: "var(--color-neutral-500)",
      600: "var(--color-neutral-600)",
      700: "var(--color-neutral-700)",
      800: "var(--color-neutral-800)",
      900: "var(--color-neutral-900)",
      white: "var(--color-neutral-white)",
      black: "var(--color-neutral-black)",
      alpha: {
        dark: {
          100: "var(--color-neutral-alpha-dark-100)",
          200: "var(--color-neutral-alpha-dark-200)",
          300: "var(--color-neutral-alpha-dark-300)",
          400: "var(--color-neutral-alpha-dark-400)",
        },
        light: {
          100: "var(--color-neutral-alpha-light-100)",
          200: "var(--color-neutral-alpha-light-200)",
          300: "var(--color-neutral-alpha-light-300)",
          400: "var(--color-neutral-alpha-light-400)",
        },
      },
    },
    accent: {
      magenta: {
        100: "var(--color-accent-magenta-100)",
        300: "var(--color-accent-magenta-300)",
        500: "var(--color-accent-magenta-500)",
        700: "var(--color-accent-magenta-700)",
        900: "var(--color-accent-magenta-900)",
        alpha: {
          100: "var(--color-accent-magenta-alpha-100)",
          200: "var(--color-accent-magenta-alpha-200)",
          300: "var(--color-accent-magenta-alpha-300)",
          400: "var(--color-accent-magenta-alpha-400)",
        },
      },
      mustard: {
        100: "var(--color-accent-mustard-100)",
        300: "var(--color-accent-mustard-300)",
        500: "var(--color-accent-mustard-500)",
        700: "var(--color-accent-mustard-700)",
        900: "var(--color-accent-mustard-900)",
        alpha: {
          100: "var(--color-accent-mustard-alpha-100)",
          200: "var(--color-accent-mustard-alpha-200)",
          300: "var(--color-accent-mustard-alpha-300)",
          400: "var(--color-accent-mustard-alpha-400)",
        },
      },
      rust: {
        100: "var(--color-accent-rust-100)",
        300: "var(--color-accent-rust-300)",
        500: "var(--color-accent-rust-500)",
        700: "var(--color-accent-rust-700)",
        900: "var(--color-accent-rust-900)",
        alpha: {
          100: "var(--color-accent-rust-alpha-100)",
          200: "var(--color-accent-rust-alpha-200)",
          300: "var(--color-accent-rust-alpha-300)",
          400: "var(--color-accent-rust-alpha-400)",
        },
      },
    },
    support: {
      danger: {
        50: "var(--color-support-danger-50)",
        100: "var(--color-support-danger-100)",
        200: "var(--color-support-danger-200)",
        300: "var(--color-support-danger-300)",
        400: "var(--color-support-danger-400)",
        500: "var(--color-support-danger-500)",
        600: "var(--color-support-danger-600)",
        700: "var(--color-support-danger-700)",
        800: "var(--color-support-danger-800)",
        900: "var(--color-support-danger-900)",
        alpha: {
          50: "var(--color-support-danger-alpha-50)",
          100: "var(--color-support-danger-alpha-100)",
          200: "var(--color-support-danger-alpha-200)",
          300: "var(--color-support-danger-alpha-300)",
          400: "var(--color-support-danger-alpha-400)",
        },
      },
      warning: {
        50: "var(--color-support-warning-50)",
        100: "var(--color-support-warning-100)",
        200: "var(--color-support-warning-200)",
        300: "var(--color-support-warning-300)",
        400: "var(--color-support-warning-400)",
        500: "var(--color-support-warning-500)",
        600: "var(--color-support-warning-600)",
        700: "var(--color-support-warning-700)",
        800: "var(--color-support-warning-800)",
        900: "var(--color-support-warning-900)",
        alpha: {
          50: "var(--color-support-warning-alpha-50)",
          100: "var(--color-support-warning-alpha-100)",
          200: "var(--color-support-warning-alpha-200)",
          300: "var(--color-support-warning-alpha-300)",
          400: "var(--color-support-warning-alpha-400)",
        },
      },
      success: {
        50: "var(--color-support-success-50)",
        100: "var(--color-support-success-100)",
        200: "var(--color-support-success-200)",
        300: "var(--color-support-success-300)",
        400: "var(--color-support-success-400)",
        500: "var(--color-support-success-500)",
        600: "var(--color-support-success-600)",
        700: "var(--color-support-success-700)",
        800: "var(--color-support-success-800)",
        900: "var(--color-support-success-900)",
        alpha: {
          50: "var(--color-support-success-alpha-50)",
          100: "var(--color-support-success-alpha-100)",
          200: "var(--color-support-success-alpha-200)",
          300: "var(--color-support-success-alpha-300)",
          400: "var(--color-support-success-alpha-400)",
        },
      },
    },
    bg: {
      default: "var(--color-bg-default)",
      subtle: "var(--color-bg-subtle)",
      hover: "var(--color-bg-hover)",
      disabled: "var(--color-bg-disabled)",
      accent: {
        1: "var(--color-bg-accent-1)",
        2: "var(--color-bg-accent-2)",
        3: "var(--color-bg-accent-3)",
      },
      elevated: {
        default: "var(--color-bg-elevated-default)",
        hover: "var(--color-bg-elevated-hover)",
      },
      emphasis: {
        default: "var(--color-bg-emphasis-default)",
        primary: "var(--color-bg-emphasis-primary)",
        "primary-subtle": "var(--color-bg-emphasis-primary-subtle)",
      },
      danger: "var(--color-bg-danger)",
      success: "var(--color-bg-success)",
      warning: "var(--color-bg-warning)",
    },
    fg: {
      default: "var(--color-fg-default)",
      muted: "var(--color-fg-muted)",
      subtle: "var(--color-fg-subtle)",
      primary: "var(--color-fg-primary)",
      accent: {
        1: "var(--color-fg-accent-1)",
        2: "var(--color-fg-accent-2)",
        3: "var(--color-fg-accent-3)",
      },
      onemphasis: {
        default: "var(--color-fg-onemphasis-default)",
        subtle: "var(--color-fg-onemphasis-subtle)",
      },
      danger: "var(--color-fg-danger)",
      success: "var(--color-fg-success)",
      warning: "var(--color-fg-warning)",
    },
    border: {
      default: "var(--color-border-default)",
      muted: "var(--color-border-muted)",
      primary: "var(--color-border-primary)",
      danger: "var(--color-border-danger)",
      success: "var(--color-border-success)",
      warning: "var(--color-border-warning)",
    },
    button: {
      primary: {
        bg: {
          default: "var(--color-button-primary-bg-default)",
          hover: "var(--color-button-primary-bg-hover)",
          disabled: "var(--color-button-primary-bg-disabled)",
        },
        fg: {
          default: "var(--color-button-primary-fg-default)",
          disabled: "var(--color-button-primary-fg-disabled)",
        },
      },
      secondary: {
        bg: "var(--color-button-secondary-bg)",
        fg: "var(--color-button-secondary-fg)",
        border: "var(--color-button-secondary-border)",
      },
    },
    input: {
      bg: "var(--color-input-bg)",
      fg: {
        input: "var(--color-input-fg-input)",
        placeholder: "var(--color-input-fg-placeholder)",
      },
      border: "var(--color-input-border)",
    },
    timeline: {
      firing: {
        bg: {
          default: "var(--color-timeline-firing-bg-default)",
          hover: "var(--color-timeline-firing-bg-hover)",
        },
        fg: "var(--color-timeline-firing-fg)",
      },
      pending: {
        bg: {
          default: "var(--color-timeline-pending-bg-default)",
          hover: "var(--color-timeline-pending-bg-hover)",
        },
        fg: "var(--color-timeline-pending-fg)",
      },
    },
  },
  spacing: {
    none: "var(--spacing-none)",
    xxs: "var(--spacing-xxs)",
    xs: "var(--spacing-xs)",
    s: "var(--spacing-s)",
    md: "var(--spacing-md)",
    lg: "var(--spacing-lg)",
    xl: "var(--spacing-xl)",
    "2xl": "var(--spacing-2xl)",
    "3xl": "var(--spacing-3xl)",
    "4xl": "var(--spacing-4xl)",
  },
  radius: {
    none: "var(--radius-none)",
    minimal: "var(--radius-minimal)",
    default: "var(--radius-default)",
    rounded: "var(--radius-rounded)",
    full: "var(--radius-full)",
  },
  font: {
    headings: {
      h1: "var(--font-headings-h1)",
      h2: "var(--font-headings-h2)",
      h3: "var(--font-headings-h3)",
      h4: "var(--font-headings-h4)",
      h5: "var(--font-headings-h5)",
      h6: "var(--font-headings-h6)",
    },
    body: {
      xl: {
        regular: "var(--font-body-xl-regular)",
        medium: "var(--font-body-xl-medium)",
      },
      l: {
        regular: "var(--font-body-l-regular)",
        medium: "var(--font-body-l-medium)",
      },
      md: {
        regular: "var(--font-body-md-regular)",
        medium: "var(--font-body-md-medium)",
      },
      sm: {
        regular: "var(--font-body-sm-regular)",
        medium: "var(--font-body-sm-medium)",
      },
      xs: {
        regular: "var(--font-body-xs-regular)",
        medium: "var(--font-body-xs-medium)",
      },
    },
    buttons: {
      md: "var(--font-buttons-md)",
      sm: "var(--font-buttons-sm)",
    },
    code: {
      regular: "var(--font-code-regular)",
      medium: "var(--font-code-medium)",
    },
    display: {
      1: "var(--font-display-1)",
    },
  },
  effect: {
    focus: {
      primary: "var(--focus-primary)",
    },
    shadow: {
      xxs: "var(--shadow-xxs)",
      m: "var(--shadow-m)",
    },
  },
} as const;
