import styled, { css } from "styled-components";

export const StyledRectangle = styled.rect(
  ({ theme }) => css`
    fill: ${theme.color.bg.emphasis.default};
    fill-opacity: 1;
    stroke-width: 1;
    stroke: ${theme.color.border.default};
  `
);

export const StyledPath = styled.path(
  ({ theme, fill }) => css`
    fill: ${fill || theme.color.primary.grape.alpha[300]};
  `
);

export function GradientDefinition(props: {
  id: string;
  from?: string;
  fromOpacity?: number;
  to?: string;
  toOpacity?: number;
}) {
  const { id, from, to, fromOpacity = 0, toOpacity = 1 } = props;
  return (
    <linearGradient id={id}>
      <stop stopColor={from} offset="0%" stopOpacity={fromOpacity} />
      <stop stopColor={to} offset="100%" stopOpacity={toOpacity} />
    </linearGradient>
  );
}

export function EmptyPattern({
  id,
  reverse,
}: {
  id: string;
  reverse?: boolean;
}) {
  return (
    <defs>
      <pattern
        id={id}
        width="8"
        height="8"
        patternTransform={`rotate(${reverse ? "" : "-"}45 0 0)`}
        patternUnits="userSpaceOnUse"
      >
        <EmptyLine x1="1" y1="0" x2="1" y2="8" />
      </pattern>
    </defs>
  );
}

const EmptyLine = styled.line(
  ({ theme }) => css`
    stroke: ${theme.color.primary.grape.alpha[300]};
    stroke-width: 2px;
    stroke-linecap: round;
  `
);
